import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-landing-teams-dialog-two',
  templateUrl: './landing-teams-dialog-two.component.html',
  styleUrls: ['./landing-teams-dialog-two.component.css']
})
export class LandingTeamsDialogTwoComponent {
  isLoading = false;
position:string = '';
  name:string = '';
  text:string = '';
  email:string = '';
  private apiKey:string = '';
  imgFile:any = '';
  isLoadingTwo = false;
constructor(private http:HttpClient,private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
  save(){
    this.isLoading = true;
this.http.post(this.apiKey + '/zap/admin/landing/teams',{name:this.name,position:this.position,image:this.imgFile,email:this.email}).subscribe({
  next:(res:any) => {
    this.messageService.add({severity:'success',summary:'Successful', detail:res.message})
    this.isLoading = false;
location.reload()
    location.reload()
  }, error:(res:any) => {
    this.messageService.add({severity:'error',summary:'Rejected', detail:res.message})
    this.isLoading = false;
  }
})
  }

  inputFile(event:any){
    this.isLoadingTwo = true;
    const formData = new FormData();
    let fileEvent = event.target.files[0];
    formData.append('file',fileEvent);
    let img = fileEvent;
    console.log(fileEvent)
    let fileReader = new FileReader();
    fileReader.readAsDataURL(fileEvent);
    fileReader.onload = () => {
       if(fileEvent.type == 'image/jpeg' || fileEvent.type == 'image/png' || fileEvent.type == 'image/gif'){
    if(fileEvent.size <= 10485760){
      this.http.post( 'https://t-admin.zappy.ng/v2/file/upload/' + fileEvent.name,formData).subscribe({
        next:(res:any) => {
          console.log(res)
          this.imgFile = res.public_url
          this.isLoadingTwo = false;
        },
        error:(res:any) => {
          this.isLoadingTwo = false;
        }
      })
    }
      }
    }
  }  

}
