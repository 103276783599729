<div class="container" >
    <h4 style="font-size: 15px;margin-bottom: 2px;color:#2E1A52;">Step 4</h4>
    <h4 style="font-size: 14px;margin-bottom: 2px;color:#2E1A52;margin-top: 4px;">Preview</h4>
    <p style="font-size: 12px;margin-top: 0px;color: #546881;">Enter information about card</p>
     <div *ngIf="!isLoading">
        <img [src] = 'stepOne.image' style="margin: 0px;padding: 0px;max-width: 250px;"  alt="">
     </div>
     <div style="display: flex;  justify-content: center;padding: 40px;position:relative; left: 120px;padding-bottom: 100px;" *ngIf = 'isLoading'>
        <app-spinner ></app-spinner>
        </div>
     <table *ngIf="!isLoading">
        <tr>
            <th>Issuer</th>
            <th>Brand Name</th>
        </tr>
        <tr>
            <td>{{stepOne.name}}</td>
            <td>{{stepOne.brand}}</td>
        </tr>
        <tr>
            <th>Currency Type</th>
            <th>Card Type</th>
        </tr>
        <tr>
            <td>{{stepOne.currency}}</td>
            <td>{{stepOne.card_type}}</td>
        </tr>
        <tr>
            <th>Creation Fee</th>
            <th>Funding Fee</th>
        </tr>
        <tr>
            <td>${{stepTwo.creationFee}}</td>
            <td>${{stepTwo.fundingFee}}</td>
        </tr>
        <tr>
            <th>Withdrawal Fee</th>
            <th>Decline Fee</th>
        </tr>
        <tr>
            <td>${{stepTwo.widthdrawalFee}}</td>
            <td>${{stepTwo.declineFee}}</td>
        </tr>
        <tr>
            <th>Maintenance Fee</th>
            <th>Transaction Fee</th>
        </tr>
        <tr>
            <td>${{stepTwo.maintenanceFee}}</td>
            <td>${{stepTwo.transactionFee}}</td>
        </tr>
        <tr>
            <th>Provider WithdrawalFee Fee</th>
            <th>Minimum Balance</th>
        </tr>
        <tr>
            <td>${{stepTwo.providerWithdrawalFee}}</td>
            <td>${{stepThree.minimumBalance}}</td>
        </tr>
        <tr>
            <th>Monthly Transaction Amount</th>
            <th>Maximum Funding</th>
        </tr>
        <tr>
            <td>${{stepThree.monthlyTransactionAmount}}</td>
            <td>${{stepThree.maximumFunding}}</td>
        </tr>
        <tr>
            <th>Minimum Funding</th>
        </tr>
        <tr>
            <td>${{stepThree.maximumFunding}}</td>
        </tr>
       </table>
       <div class="container-three">
  <button class="filter" routerLink="/configurations/virtual-card/step-three">Back</button>
        <button class="apply" (click)="save()">Save</button>
    </div>
   </div>
  