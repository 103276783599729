import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-account-manager',
  templateUrl: './account-manager.component.html',
  styleUrls: ['./account-manager.component.css']
})
export class AccountManagerComponent {
  isPassword = false;
oldPassword:string = '';
newPassword:string = '';
confirmPassword:string = '';
email:string = '';
private apiKey:string = '';
private adminKey:string = '';
constructor(private http:HttpClient,private messageService:MessageService){
  this.apiKey = environment.apiUrl;
  this.adminKey = environment.admin_key
}
toggle(){
  this.isPassword = !this.isPassword;
}

updatePassword(){
this.http.post(this.apiKey + '/zap/admin/setting/password',{old_password:this.oldPassword,new_password:this.newPassword,admin_key:this.adminKey}).subscribe({
  next:(res:any) => {
    this.messageService.add({ severity: 'info', summary:'Password', detail:res.message});
  },
  error:(res:any) => {
    this.messageService.add({ severity: 'error', summary:'Rejected!', detail:res.message});
  }
})
}
emailPassword(){
  this.http.post(this.apiKey + '/zap/admin/setting/email',{email:this.email,admin_key:this.adminKey}).subscribe({
    next:(res:any) => {
      this.messageService.add({ severity: 'info', summary:'Password', detail:res.message});
    },
    error:(res:any) => {
      this.messageService.add({ severity: 'error', summary:'Rejected!', detail:res.message});
    }
  })
}
}
