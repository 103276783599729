
<div  class="header-eight">
    <button class="apply" (click)="sendMessage()">Save</button>
  </div>
<div *ngIf="!isLoading">
    <div class="container">
        <h1 style="padding-bottom: 0px;margin: 0px; width:500px;"></h1>
        <div class="header-two">
            <p>Account Number</p>
            <input type="text" placeholder="Account Number" [(ngModel)]="accountNumber">
            <p>Account Name</p>
            <input type="text" placeholder="Account Name" [(ngModel)]="accountName">
    <p>Admin Pin</p>
    <input type="text" placeholder="Admin Pin" [(ngModel)]="adminPin">
    <p>Bank Code</p>
    <input type="text" placeholder="Bank Code" [(ngModel)]="bank_code">
    <p>Session Id</p>
    <input type="text" placeholder="Session Id" [(ngModel)]="sessionId">
    <p>Narration</p>
    <input type="text" placeholder="Narration" [(ngModel)]="narration">
    <p>Amount</p>
    <input type="number" placeholder="Amount" [(ngModel)]="amount">
        </div>
    </div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
  