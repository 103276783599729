<div style="display: flex;justify-content: space-between;align-items: center;">
  <h4>{{section | titlecase}}</h4>
  <button class="btn-message" (click)="save()">Save</button>
</div>
<div class="container" *ngIf="!isLoading">
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;top:-40px;" *ngIf = 'isLoadingTwo'>
    <app-spinner ></app-spinner>
    </div>
  <img [src]="imgFile" width="200" style="margin: 0px;" alt="" *ngIf="!isLoadingTwo">
  <div *ngIf="imgFile ==''">
  <div style="display: flex;align-items: center;gap:7px;">
      <img [src]="imgFile" width="15" alt="">
      <p style="font-size: 9px;color: #546881;">Upload image from your device, (PNG, JPG). Image size 5MB.</p>
  </div>
  </div>
  <div class="file-upload">
      <div class="file-select">
        <div class="file-select-button" id="fileName">Choose Image</div>
        <div class="file-select-name" id="noFile">No file chosen...</div> 
        <input type="file" name="chooseFile" id="chooseFile" (change)="inputFile($event)">
      </div>
    </div>
       <br>
      <div class="inputWithIcon"> 
        <p>Title</p>
        <input type="text" placeholder="Title" [(ngModel)] ='title'>
      </div>
    <div class="inputWithIcon">
        <p>Description</p>
        <br>
      <textarea name="" id="" cols="30" rows="5" placeholder="Description..." [(ngModel)] ='desc'></textarea>
      </div> 
    <div>
</div>
<button class="btn-delete" (click)="delete()">Delete Section</button>
</div>

<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;top: 3px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>
  