import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-airtime-to-cash-manual',
  templateUrl: './airtime-to-cash-manual.component.html',
  styleUrls: ['./airtime-to-cash-manual.component.css']
})
export class AirtimeToCashManualComponent {
  checked: boolean = false;
isLoading = false;
fees:number = 0;
private apiKey:string = '';
constructor(private http:HttpClient, private messageService:MessageService){
this.apiKey = environment.apiUrl
}
activateChecked(){
  this.http.post(this.apiKey + '/zap/admin/configuration/toggle',{service:'airtimeto_cash_manual',status:this.checked}).subscribe({
    next:(res:any) => {
      this.messageService.add({ severity: 'info', summary:'Airtime To Cash Manual', detail:res.message});
    },
    error:(res:any) => {
      this.messageService.add({ severity: 'error', summary:'Rejected', detail: res.message});
    }
  })
}
updateFee(){
  this.isLoading = true
this.http.post(this.apiKey + '/zap/admin/configuration/fees',{service:'airtimeto_cash_manual',fee:this.fees}).subscribe(
 {
   next:(res:any) => {
     this.messageService.add({ severity: 'info', summary:'Airtime To Cash Manual', detail:res.message});
     this.fetchFees();
   },
   error:(res:any) => {
     this.messageService.add({ severity: 'error', summary:'Rejected', detail: res.message});
     this.isLoading = false;
   }
 })
}
fetchStatus(){
  this.http.get(this.apiKey + '/zap/admin/configuration/appsettings').subscribe({
    next:(res:any) => {
      this.isLoading = false;
      console.log(res)
let status = res.data;
this.checked = status.airtime_to_cash_manual;
    }
  })
}
fetchFees(){
  this.http.get(this.apiKey + '/zap/admin/configuration/fees').subscribe({
    next:(res:any) => {
      console.log(res)
      this.isLoading = false;
this.fees = res.data.airtime_to_cash_manual;
    },error:(res:any) => {
      this.isLoading = false;
      this.messageService.add({severity:'error', summary:'Rejected', detail: res.message})
    }
  })
}
  ngOnInit(): void {
this.isLoading = true;
this.fetchStatus();
this.fetchFees()
  }
}
