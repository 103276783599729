import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AirtimeSwapDialogBoxService } from './airtime-swap-dialog.service';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.development';
@Component({
  selector: 'app-airtime-swap-dialog-box',
  templateUrl: './airtime-swap-dialog-box.component.html',
  styleUrls: ['./airtime-swap-dialog-box.component.css']
})
export class AirtimeSwapDialogBoxComponent implements OnInit {
  isLoading:boolean = false
  data:any = {};
  private apiKey:string = '';
  private adminKey:string = '';
constructor(private dialog:MatDialog, private snackbar:MatSnackBar,private dialogService:AirtimeSwapDialogBoxService, private messageService:MessageService,private http:HttpClient){
  this.apiKey = environment.apiUrl;
  this.adminKey = environment.admin_key;
}

openSnackBar(id:any) {
  this.showInfo(id)
  navigator.clipboard.writeText(id);
 }
showInfo(id:any) {
  this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
}

approve(){
  this.isLoading = true;
  this.http.post(this.apiKey + '/zap/admin/transaction/flips/approve',{admin_key:this.adminKey,flip_id:this.data.id}).subscribe({
  next:(res:any) => {
    console.log(res)
    this.isLoading = false;
    this.messageService.add({ severity: 'success', detail:res.message,life:2000 });
    location.reload();
  },error:(res:any) => {
    this.isLoading = false;
    this.messageService.add({ severity: 'warn', detail:res.message,life:2000 });
  }
}) 
}

decline(){
  this.isLoading = true;
  this.http.post(this.apiKey + '/zap/admin/transaction/flips/decline',{admin_key:this.adminKey,flip_id:this.data.id}).subscribe({
  next:(res:any) => {
    console.log(res);
    this.isLoading = false;
    this.messageService.add({ severity: 'success', detail:res.message,life:2000 });
    location.reload();
  },error:(res:any) => {
    this.messageService.add({ severity: 'warn', detail:res.message,life:2000 });
    this.isLoading = false;
  }
}) 
}
ngOnInit(): void {
  this.isLoading = true
  this.dialogService.getUser().subscribe({
    next:(res:any) => {
      this.isLoading = false;
      this.data = res.data
      console.log(res)
    },
    error:(res:any) => {
      this.isLoading = false;
    }
  })
}
}
