import { Component, OnInit } from '@angular/core';
import { PreviewService } from './preview.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { VcService } from '../c-virtual-card.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent  implements OnInit{
  stepOne:any = {};
  stepTwo:any = {};
  stepThree:any = {};
  isLoading = false;
  private apiKey:string = '';
  constructor(private vcService:VcService,private previewService:PreviewService,private http:HttpClient, private route:Router, private messageService:MessageService){
    this.apiKey = environment.apiUrl;
  }
cancel(){
  this.previewService.close();
}
ngOnInit(): void {
  let dataInString:any = localStorage.getItem('stepOne');
  let stepOneData = JSON.parse(dataInString);
  let dataInStringTwo:any = localStorage.getItem('stepTwo');
  let stepTwoData = JSON.parse(dataInStringTwo);
  let dataInStringThree:any = localStorage.getItem('stepThree');
  let stepThreeData = JSON.parse(dataInStringThree);

  this.stepOne = stepOneData;
  this.stepTwo  =stepTwoData;
  this.stepThree = stepThreeData;
}
save(){
  this.isLoading = true;
  let previewState:any = localStorage.getItem('previewState');
if(previewState == 'add'){
  this.http.post(this.apiKey + '/zap/admin/configuration/card-types',{name:this.stepOne.name,brand:this.stepOne.brand,type:this.stepOne.card_type,currency:this.stepOne.currency,funding_fee:this.stepTwo.fundingFee,provider_funding_fee:this.stepTwo.providerFundingFee,withdraw_fee:this.stepTwo.widthdrawalFee,provider_withdraw_fee:this.stepTwo.providerWithdrawalFee,declined_fee:this.stepTwo.declinedFee,initial_funding:this.stepThree.initialFunding,minimum_funding:this.stepThree.minimumFunding,maximum_funding:this.stepThree.maximumFunding,minimum_balance:this.stepThree.minimumBalance,maximum_balance:this.stepThree.maximumBalance,card_image:'',monthly_transactions:this.stepThree.monthlyTransactionAmount,maintenance_fee:this.stepTwo.maintenanceFee,transaction_fee:this.stepTwo.transactionFee,creation_fee:this.stepTwo.creationFee,secure:this.stepThree.secure,can_create:this.stepThree.can_create,can_fund:this.stepThree.can_fund,can_withdraw:this.stepThree.can_withdrawal}).subscribe({
  next:(res:any) => {
    this.messageService.add({ severity: 'info', summary:'Virtual Card', detail:res.message});
localStorage.removeItem('stepOne');
localStorage.removeItem('stepTwo');
localStorage.removeItem('stepThree')
this.cancel()
this.isLoading = false;
this.vcService.updated.next('');
this.route.navigate(['/configurations/virtual-card']);
  },error:(res:any) => {
    this.messageService.add({ severity: 'error', summary:'Rejected', detail:res.message});
    this.isLoading = false;
  }
})
}

else {
  let prevStateObject= JSON.parse(previewState);
  this.http.put(this.apiKey + '/zap/admin/configuration/card-types',{id:prevStateObject.id,name:this.stepOne.name,brand:this.stepOne.brand,type:this.stepOne.card_type,currency:this.stepOne.currency,funding_fee:this.stepTwo.fundingFee,provider_funding_fee:this.stepTwo.providerFundingFee,withdraw_fee:this.stepTwo.widthdrawalFee,provider_withdraw_fee:this.stepTwo.providerWithdrawalFee,declined_fee:this.stepTwo.declineFee,initial_funding:this.stepThree.initialFunding,minimum_funding:this.stepThree.minimumFunding,maximum_funding:this.stepThree.maximumFunding,minimum_balance:this.stepThree.minimumBalance,maximum_balance:this.stepThree.maximumBalance,card_image:'',monthly_transactions:this.stepThree.monthlyTransactionAmount,maintenance_fee:this.stepTwo.maintenanceFee,transaction_fee:this.stepTwo.transactionFee,creation_fee:this.stepTwo.creationFee,secure:this.stepThree.secure,can_create:this.stepThree.can_create,can_fund:this.stepThree.can_fund,can_withdraw:this.stepThree.can_withdrawal}).subscribe({
    next:(res:any) => {
      this.isLoading = false;
      this.messageService.add({ severity: 'info', summary:'Virtual Card', detail:res.message});
  localStorage.removeItem('stepOne');
  localStorage.removeItem('stepTwo');
  localStorage.removeItem('stepThree')
  this.cancel()
  this.vcService.updated.next('');
  this.route.navigate(['/configurations/virtual-card']);
    },error:(res:any) => {
      this.isLoading = false;
      this.messageService.add({ severity: 'error', summary:'Rejected', detail:res.message});
    }
  })
}
}

}
