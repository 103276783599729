import { Component } from '@angular/core';

@Component({
  selector: 'app-push-notifications',
  templateUrl: './push-notifications.component.html',
  styleUrls: ['./push-notifications.component.css']
})
export class PushNotificationsComponent {
  checked:boolean = false;
  checkedTwo:boolean = false;
  checkedThree:boolean = false;
  checkedFour:boolean = false;
}
