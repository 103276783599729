import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-transaction-history',
  templateUrl: './customer-transaction-history.component.html',
  styleUrls: ['./customer-transaction-history.component.css']
})
export class CustomerTransactionHistoryComponent {

}
