<div *ngIf="!isLoading">
  <div class="header-two">
    <img src="../../../assets/search.png" alt="">
    <input type="text" placeholder="Search by....." (keyup.enter)="search()"  [(ngModel)]="saerchText">
</div>
<br>
<div *ngIf="data.length !== 0">
  
<div class="header-four">
  <div class="recent-transaction">
  <table>
   <thead>
      <tr>
          <th>Name</th>
          <th>Card ID</th>
          <th></th>
          <th>Card Type</th>
  
      </tr>
   </thead>
  
     <tbody>
      <tr *ngFor="let transactions of data; let i =index" >
          <td (click) = 'viewDetail(i)'>{{transactions.name}}</td>
                <td (click) = 'viewDetail(i)'>{{ transactions.card_number}}</td>
                <th><img (click)="openSnackBar(transactions.card_id)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt=""></th>
                <td (click) = 'viewDetail(i)'>
                  <div class="order-type">
                    <img src="../../../../assets/MASTERCARD.png" *ngIf="transactions.card_brand == 'mastercard'" width="20" style="margin-right: 5px;" alt="">
                    <img src="../../../../assets/CARD.png" *ngIf="transactions.card_brand == 'visa'" width="20" style="margin-right: 5px;" alt="">
                    <img src="../../../../assets/VERVE 2.png" *ngIf="transactions.card_brand == 'verve'" width="20" style="margin-right: 5px;" alt="">
                    {{ transactions.card_type | titlecase }}
                  
                  </div></td>
           
    </tr>
     </tbody>
  </table>
  <br>
  <div style="display: flex; justify-content: space-between;align-items: center;">
    <p style="color: #151A20;font-weight: 500;font-size: 13px;">Showing 1-10 of {{this.page}} results</p>
    <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
  </div>
  </div>
  </div>
  </div>
</div>
<div style="display: flex;  justify-content: center;padding:80px;position:relative; right:100px;top: 0px;" *ngIf="data.length == 0 && !isLoading">
  
  <div>
   <img src="../../../assets/Empty state illustratio.png" style="margin: 0 auto;display: block;padding-bottom:10px;" width="80" alt="">
   <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">No records to view yet.</h5>
   <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Transaction records will appear here once customers make purchases.</p>
  </div>
 </div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 100px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>
  <p-toast></p-toast>