import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PreviewService } from './preview/preview.service';
import { HttpClient } from '@angular/common/http';
import { VcService } from './c-virtual-card.service';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-c-virtual-card',
  templateUrl: './c-virtual-card.component.html',
  styleUrls: ['./c-virtual-card.component.css']
})
export class CVirtualCardComponent implements OnInit {
  isLoading = false;
  visible: boolean = false;
stepOne = false;
stepTwo = false;
stepThree = false;
data:any = [];
private apiKey:string = '';
constructor(private route:Router,private messageService:MessageService ,private previewService:PreviewService,private http:HttpClient,private vcService:VcService){
  this.apiKey = environment.apiUrl;
}
viewDetail(index:number){
  localStorage.setItem('previewState',JSON.stringify({name:'update',id:this.data[index].id}))
  let data = this.data[index];
let stepOne = {name:data.name,currency:data.currency,card_type:data.type,card_image:data.image,brand:data.brand};
let stepTwo = {creationFee:data.creation_fee,fundingFee:data.funding_fee,widthdrawalFee:data.withdraw_fee,declineFee:data.declined_fee,maintenanceFee:data.maintenance_fee,transactionFee:data.transaction_fee,providerFundingFee:data.provider_funding_fee,providerWithdrawalFee:data.provider_withdraw_fee};
let stepThree = {can_create:data.can_create,can_fund:data.can_fund,can_withdrawal:data.can_withdraw,initialFunding:data.initial_funding,maximumBalance:data.maximum_balance,maximumFunding:data.maximum_funding,minimumBalance:data.minimum_balance,minimumFunding:data.minimum_funding,monthlyTransactionAmount:data.monthly_transactions,secure:data.secure}
  localStorage.setItem('stepOne', JSON.stringify(stepOne))
  localStorage.setItem('stepTwo', JSON.stringify(stepTwo))
  localStorage.setItem('stepThree', JSON.stringify(stepThree))
  this.visible = true;
     
  this.route.navigate(['/configurations/virtual-card/step-one'])
}
  showDialog() {
    localStorage.setItem('previewState','add')
    localStorage.removeItem('stepOne');
localStorage.removeItem('stepTwo');
localStorage.removeItem('stepThree')
      this.visible = true;
      this.route.navigate(['/configurations/virtual-card/step-one'])
  }
  cancel(){
  this.previewService.state.subscribe(res  => {
    this.visible = res;
  })
  }

  activeClass(url:string){
    if(this.route.url == url){
return true
    }
    else {
      return false
    }
  }

  checkedToggle(){
if(this.route.url == '/configurations/virtual-card/step-two' ){
this.stepOne = true;
}
if(this.visible == false){
  this.stepOne = false;
}
return this.stepOne
  }

  checkedToggleTwo(){

    if(this.route.url == '/configurations/virtual-card/step-three'){
      this.stepTwo = true;
      }
      if(this.visible == false){
        this.stepTwo = false;
      }
      return this.stepTwo
        }

        checkedToggleThree(){

          if(this.route.url == '/configurations/virtual-card/preview'){
            this.stepThree = true;
            }
            if(this.visible == false){
              this.stepThree = false;
            }
            return this.stepThree
              }
              @HostListener('click', ['$event'])
              onClick(event: Event): void {
            if(this.visible == false){
this.route.navigate(['/configurations/virtual-card']);
            }
              }
              delete(index:number){
                let id = this.data[index].id;
                this.http.post(this.apiKey + '/zap/admin/configuration/delete/card-types',{id:id}).subscribe({
                  next:(res:any) => {
                    this.fetchRequest();
                  }
                })
                              }
      fetchRequest(){
        this.http.get(this.apiKey + '/zap/admin/configuration/card-types/?page=1&limit=10').subscribe({
          next:(res:any) => {
            this.data = res.data.data;
            this.isLoading = false;
          },error:(res:any) => {
            this.isLoading = false;
            this.messageService.add({severity:'error', summary:'Rejected', detail:'An error occured'})
          }
        })
      }
      reload(){
        location.reload();
      }
      updated(){
       this.vcService.updated.subscribe(res => {
        this.fetchRequest()
       })
      }
              ngOnInit(): void {
                this.isLoading = true;
                this.cancel()
           this.fetchRequest()
           this.vcService.showDialog.subscribe(res => {
            this.showDialog()
           })
           this.updated()
              }
      
}
