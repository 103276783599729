
    <div class="header-two">
        <h4>All Users</h4>
    
       <nav>
          <div class="filter-group" (click)="showDialog('Filter')">
              <p>Filter</p>
              <img src="../../assets/Stockholm-icons (9).png" alt="">
          </div>
      </nav>
    </div>
    <div  *ngIf = 'role == "Administrator"'>
    <div *ngIf="!isLoading">
        <div class="payment-manager">
            <div class="card">
                <img src="../../assets/Widget icons (5).png" alt="">
                <p>Total Users</p>
                <h4>{{overview.total_users | number}}</h4>
            </div>
            <div class="card">
                <img src="../../assets/Widget icons.png" alt="">
                <p>NGN Wallet Balance</p>
                <h4>₦{{overview.ngn_balance | number}}</h4>
            </div>
            <div class="card">
                <img src="../../assets/Widget icons.png" alt="">
                <p>USD Wallet Balance</p>
                <h4>${{overview.usd_balance | number}}</h4>
            </div>
            <div class="card">
                <img src="../../assets/Widget icons.png" alt="">
                <p>Total Transactions</p>
                <h4>{{overview.total_transactions}}</h4>
            </div>
        </div>
    </div>
    <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
        <app-spinner ></app-spinner>
        </div>
</div>
<app-customers-transactions></app-customers-transactions>
<p-dialog [header]="modalName" [(visible)]="visible" [maximizable]="true" [modal]="true" [style]="{ width: '35vw',height:'100%' }" [draggable]="false" [resizable]="false">
    <app-customer-filter-modal *ngIf="visible" style="font-family: poppins;"></app-customer-filter-modal>
  </p-dialog>