<div class="container">
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <div>
            <h4>Push notification</h4>
            <p style="color:#546881;">Message goes here</p>
        </div>
        <p-inputSwitch [(ngModel)]="checked"></p-inputSwitch>
    </div>
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <div>
            <h4>Queries</h4>
            <p style="color:#546881;">Message goes here</p>
        </div>
        <p-inputSwitch [(ngModel)]="checkedTwo"></p-inputSwitch>
    </div>
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <div>
            <h4>KYC Verification</h4>
            <p style="color:#546881;">Message goes here</p>
        </div>
        <p-inputSwitch [(ngModel)]="checkedThree"></p-inputSwitch>
    </div>
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <div>
            <h4>Virtual Wallet Verification</h4>
            <p style="color:#546881;">Message goes here</p>
        </div>
        <p-inputSwitch [(ngModel)]="checkedFour"></p-inputSwitch>
    </div>
</div>