<div *ngIf="data.length !== 0 && !isLoading">
  <div *ngIf="!isLoading">
    <div class="header-two">
      <img src="../../../assets/search.png" alt="">
      <input type="text" placeholder="Search by....."  [(ngModel)]="searchText">
      <div class="filter-group" (click)="showDialog('Filter')">
          <p>Filter</p>
          <img src="../../../../../../assets/Stockholm-icons (9).png" alt="">
      </div>
      <img src="../../../../../../assets/Stockholm-icons (15).png" alt="">
      
  </div>
  <br>
  
  <div class="header-four">
  <div class="recent-transaction">
  <table>
   <thead>
      <tr>
          <th>Order Type</th>
          <th>Transaction ID</th>
          <th></th>
          <th>Amount</th>
          <th>Merchant</th>
          <th>Date</th>
          <th>Status</th>
      </tr>
   </thead>
  
     <tbody>
      <tr *ngFor="let transactions of data; let i = index">
        <td (click)="viewDetail(i)">{{transactions.order_type | titlecase}}</td>
        <td (click)="viewDetail(i)">{{transactions.reference | slice:0:10 }} {{ transactions.reference.length > 10 ? '...' : '' }}</td>
        <td><img (click)="openSnackBar(transactions.reference)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt=""></td>
        <td (click)="viewDetail(i)"><strong>{{transactions.currency == 'usd' ? '$' : '₦'}}</strong>{{transactions.amount | number}}</td>
        <td (click)="viewDetail(i)">{{transactions.beneficiary}}</td>
        
        <td (click)="viewDetail(i)">{{transactions.date | date}}</td>
        <td (click)="viewDetail(i)" *ngIf="transactions.status == 'SUCCESS' || transactions.status == 'SUCCESSFUL' || transactions.status == 'success'"><p><span class="success">Successful</span></p></td>
                <td *ngIf="transactions.status == 'reversed' || transactions.status == 'reversed' "><p><span class="failed">Reversed</span></p></td>
      </tr>
     </tbody>
  </table>
  <br>
  <div style="display: flex; justify-content: right;">
    <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
  </div>
  </div>
  </div>
  </div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 30px;" *ngIf="data.length == 0 && !isLoading">
  
  <div>
   <img src="../../../assets/Empty state illustratio.png" style="margin-left: 20px;" width="80" alt="">
   <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">There is no data here</h5>
   <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Message Goes here</p>
  </div>
 </div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 50px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>
  <p-dialog [header]="modalName" [(visible)]="visible" [modal]="true" [style]="{ width: '35vw', height:'100%' }" [draggable]="false" [resizable]="false">
    <app-cth-filter *ngIf="visible" style="font-family: poppins;"></app-cth-filter>
  </p-dialog>
  <p-dialog [header]="modalName" [(visible)]="visibleTwo" [modal]="true" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false">
    <app-ctht-dialog-box [data]="id" *ngIf="visibleTwo" style="font-family: poppins;"></app-ctht-dialog-box>
  </p-dialog>
  <p-toast></p-toast>