import { Component } from '@angular/core';

@Component({
  selector: 'app-bvn-request',
  templateUrl: './bvn-request.component.html',
  styleUrls: ['./bvn-request.component.css']
})
export class BvnRequestComponent {

}
