<div class="container">
    <h4>User Logs</h4>
    <p style="color: #3D4C5E;">The web-hook logs associated with each scheduled web-hook (event). There's an entry for each attempt to send (POST) data to the web-hook URL. The logs associated with the events from the last 30 days are available</p>
    <div class="header-two">
        <img src="../../../assets/search.png" alt="">
        <input type="text" placeholder="Search by....." (keyup.enter)="search()"  [(ngModel)] = 'searchText'>
        <div class="filter-group" (click)="search()" *ngIf="searchText !== ''">
            <p>search</p>
        </div>
    </div>
<div class="container-two" *ngIf="!isLoading">
    <table>
        <tr>
            <th></th>
            <th></th>
            <th></th>
        </tr>
        <tr *ngFor="let data of data">
            <td><p>{{data.created_at | date}} {{data.created_at | date:'shortTime'}}</p><p style="padding-bottom: 180px;">Response time <strong>0.28sec</strong></p></td>
            <td><p>User id</p><p>Postal Code</p><p>OS</p><p>Latitude</p><p>Longitude</p><p>Country</p><p>State</p><p>City</p><p>Country Code</p><p>Street</p></td>
            <td><p style="display: flex;align-items: center;gap: 10px;"><strong>{{data.user_id == '' || data.user_id == null ? '---' : data.user_id}}</strong><span><img (click)="openSnackBar(data.user_id)" style="cursor: pointer;" src="../../../assets/Stockholm-icons (16).png" width="20" alt=""></span></p><p><strong>{{data.location == '' || data.location == null ? '---' : data.location}}</strong></p><p><strong>{{data.postal_code == '' || data.postal_code == null ? '---' : data.postal_code}}</strong></p><p><strong>{{data.os == '' || data.os == null ? '---' : data.os}}</strong></p><p><strong>{{data.latitude == '' || data.latitude == null ? '---' : data.latitude}}</strong></p><p><strong>{{data.longtitude == '' || data.longtitude == null ? '---' : data.longtitude }}</strong></p><p><strong>{{data.country == '' || data.country == null ? '---' : data.country}}</strong></p><p><strong>{{data.state == '' || data.state == null ? '---' : data.state}}</strong></p><p><strong>{{data.city == '' || data.city == null ? '---' : data.city}}</strong></p><p><p><strong>{{data.country_code == '' || data.country_code == null ? '---' : data.country_code}}</strong></p><p><strong>{{data.street == '' || data.street == null ? '---'  : data.street}}</strong></p></td>
        </tr>
        
    </table>
    
</div>
<div *ngIf="!isLoading" style="display: flex; justify-content: space-between;align-items: center;">
    <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
   </div>

    
   <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right:5px; top: 20px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>