import { Component } from '@angular/core';

@Component({
  selector: 'app-quick-action',
  templateUrl: './quick-action.component.html',
  styleUrls: ['./quick-action.component.css']
})
export class QuickActionComponent {

}
