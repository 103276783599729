import { Component } from '@angular/core';

@Component({
  selector: 'app-card-funding',
  templateUrl: './card-funding.component.html',
  styleUrls: ['./card-funding.component.css']
})
export class CardFundingComponent {

}
