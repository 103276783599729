import { Component, OnInit } from '@angular/core';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';

@Component({
  selector: 'app-zap-pin',
  templateUrl: './zap-pin.component.html',
  styleUrls: ['./zap-pin.component.css']
})
export class ZapPinComponent implements OnInit {
role:string = '';
constructor(private authService:AuthPageService){}
ngOnInit(): void {
  this.authService.roleName.subscribe((res:any) => {
    this.role = res.data.role.role_name
  })
}
}
