import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-landing-section-dialog',
  templateUrl: './landing-section-dialog.component.html',
  styleUrls: ['./landing-section-dialog.component.css']
})
export class LandingSectionDialogComponent implements OnInit{
  isLoading = false;
  desc:string = '';
  title:string = '';
  text:string = '';
  section:string = '';
  private apiKey:string = '';
  imgFile:any = '';
  isLoadingTwo = false;
  @Input() data:any = {};
constructor(private http:HttpClient,private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
  save(){
    this.isLoading = true;
this.http.post(this.apiKey + '/zap/admin/landing/sections',{title:this.title,description:this.desc,image:this.imgFile,section_name:this.section}).subscribe({
  next:(res:any) => {
    this.messageService.add({severity:'success',summary:'Successful', detail:res.message})
    this.isLoading = false;
    this.desc = '';
    this.title = '';
    location.reload()
  }, error:(res:any) => {
    this.messageService.add({severity:'error',summary:'Rejected', detail:res.message})
    this.isLoading = false;
  }
})
  }
  
  delete(){
    this.isLoading = true;
    this.http.post(this.apiKey + '/zap/admin/landing/sections/delete',{id:this.data.id}).subscribe({
      next:(res:any) => {
        this.messageService.add({severity:'success',summary:'Successful', detail:res.message})
        location.reload()
      }, error:(res:any) => {
        this.messageService.add({severity:'error',summary:'Rejected', detail:res.message})
        this.isLoading = false;
      }
    })
  }

  inputFile(event:any){
    this.isLoadingTwo = true;
    const formData = new FormData();
    let fileEvent = event.target.files[0];
    formData.append('file',fileEvent);
    let img = fileEvent;
    console.log(fileEvent)
    let fileReader = new FileReader();
    fileReader.readAsDataURL(fileEvent);
    fileReader.onload = () => {
       if(fileEvent.type == 'image/jpeg' || fileEvent.type == 'image/png' || fileEvent.type == 'image/gif'){
    if(fileEvent.size <= 10485760){
      this.http.post( 'https://t-admin.zappy.ng/v2/file/upload/' + fileEvent.name,formData).subscribe({
        next:(res:any) => {
          console.log(res)
          this.imgFile = res.public_url
          this.isLoadingTwo = false;
        },
        error:(res:any) => {
          this.isLoadingTwo = false;
        }
      })
    }
      }
    }
  }  
  ngOnInit(): void {
    this.section = this.data.section_name;
    this.title = this.data.title;
    this.desc = this.data.description;
    this.imgFile = this.data.image
    console.log(this.data)
  }
}
