<header class="mat-typography">
    <h4 class="mat-typography">Configuration</h4>
    <nav>
        <img class="api" src="../../assets/_master.png" alt="">
        <img (click)="showDialog('Notification')" src="../../assets/bell.png" alt="">
        <img src="../../assets/plus.png" alt="" (click)="showDialog('')">
    </nav>
</header>
<p-dialog [header]="modalName" position="topright" [(visible)]="visible" [modal]="true" [style]="{ width: '30vw' }" [draggable]="false" [resizable]="false">
    <app-notification></app-notification>
</p-dialog>
<p-dialog [header]="'Add Data Plan'" *ngIf="visibleTwo" [(visible)]="visibleTwo" [modal]="true" [style]="{ width: '30vw', 'font-family':'poppins'}" [draggable]="false" [resizable]="false">
    <app-data-plan-modal-two (added)="cancel()"></app-data-plan-modal-two>
</p-dialog>