<div class="header">
    <h4>Business Plan</h4>
<p>Manage Business configurations, plan, durations</p>
</div>
<div class="container" *ngIf="!isLoading">
    <div (click)="showDialog('',i)" class="card" *ngFor="let data of data; let i = index">
    <div>
      <h4>{{data.plan_name | titlecase}}</h4>
      <p>₦{{data.cost | number}} (cost)</p>
    </div>
    <p>{{data.duration | number }} Months</p>
    </div>
  </div>
  <p-dialog *ngIf="visible" [header]="modalName"[maximizable]="true"  [(visible)]="visible" [modal]="true" [style]="{ width: '30vw', 'height':'700px'}" [draggable]="false" [resizable]="false">
    <app-business-plan-dialog *ngIf="visible" [data]="singleData" style="font-family: poppins;"></app-business-plan-dialog>
  </p-dialog>
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 100px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>