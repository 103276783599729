import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BankTransferDialogBoxComponent } from '../bank-transfer-dialog-box/bank-transfer-dialog-box.component';
import { HttpClient } from '@angular/common/http';
import { BankTransferDialogService } from '../bank-transfer-dialog-box/bank-transfer-dialog.service';
import { MessageService } from 'primeng/api';
import { btTransactionsService } from './bt-transactions.service';
import { catchError, exhaustMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-bank-transfer-transactions',
  templateUrl: './bank-transfer-transactions.component.html',
  styleUrls: ['./bank-transfer-transactions.component.css']
})
export class BankTransferTransactionsComponent implements OnInit,OnDestroy{
  searchText = ''
  modalName = ''
  visible:boolean = false;
  isLoading:boolean = false;
  data:any = [];
  page = 1;
  start_date = '';
  end_date = '';
  start_amount = '';
  end_amount = '';
  status = '';
  filtered = false;
  first: number = 1;
  rows: number = 10;
  totalRecord = 20;
  private filterSubscription:any;
  private apiKey:string = '';
constructor(private dialogService:BankTransferDialogService,private http:HttpClient,private messageService:MessageService,private btService:btTransactionsService){
  this.apiKey = environment.apiUrl;
}
  viewDetail(id:number){
    this.dialogService.id = this.data[id].id;
    this.showDialog('Bank Transactions')
  }
  openSnackBar(id:any) {
    this.showInfo(id)
    navigator.clipboard.writeText(id);
   }
  showInfo(id:any) {
    this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
  }


  onPageChange(event:any) {
    this.isLoading = true;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + 1;
    this.getRequest().subscribe((res:any) => {
      this.data = res.data;
      this.isLoading = false;
     })
  }
      showDialog(name:string) {
        this.modalName = name;
          this.visible = true;
      }
      filter(){
       this.filterSubscription =  this.btService.filter.pipe(tap((res:any) => {
          this.status = res.status == '' ? '' : '&status=' + res.status;
          this.start_date = res.start_date == '' ? '' : '&start_date' + res.start_date.toISOString();;
          this.end_date = res.end_date == '' ? '' : '&end_date' + res.end_date.toISOString();;
          this.start_amount = '&start_amount=' + res.start_amount;
          this.end_amount = '&end_amount=' + res.end_amount;
          this.isLoading = true;
          console.log(res.start_amount + 'amount')
         }),exhaustMap((res:any) => {return this.getRequest()})).pipe(catchError(this.handleError)).subscribe({
          next:(res:any) => {
            this.isLoading = false;
            this.data = res.data;
            this.totalRecord = res.total;
          },
          error:(res) => {
            this.isLoading = false;
            setTimeout(() => {
              this.messageService.add({ severity: 'error', detail:res,life:2000 });
             }, 2000);
          }
        })
      }
      search(){
        this.isLoading = true;
        this.getRequest().subscribe((res:any) => {
          this.data = res.data;
          this.totalRecord = res.total;
        this.isLoading = false
         })
        }

        handleError(error:any) { let errorMessage = 'No Internet (transactions)';

        if(error.error.message){
          errorMessage = error.error.message
        }
      
          console.log(errorMessage);
          return throwError(() => {
              return errorMessage;
          });
        }
      getRequest(){
        return   this.http.get(this.apiKey + '/zap/admin/transaction/transfers?page=' + this.page + '&' + 'limit' + '=' + this.rows + this.start_amount + this.end_amount + this.status + this.start_date + this.end_date + '&search_query=' + this.searchText)
      }
      ngOnInit(): void {
        this.isLoading = true;
        this.filter()
      }
      ngOnDestroy(): void {
   
        console.log('ngOnDestroy called');
        // Unsubscribe from the filter subscription to avoid memory leaks
        if (this.filterSubscription) {
          this.filterSubscription.unsubscribe();
        }
      }
}
