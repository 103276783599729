import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable, exhaustMap, take } from 'rxjs';
import { AuthPageService } from './auth-page.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(private authService: AuthPageService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.user.pipe(
            take(1),
            exhaustMap(user => {
                let modifiedRequest = req;
                if (user) {
                    modifiedRequest = req.clone({
                        setHeaders: {
                            Authorization: 'Bearer ' + user._access_token, // Adjust headers as needed
                            'Access-Control-Allow-Credentials': 'true' // Adjust credentials as needed
                        }
                    });
                }
                return next.handle(modifiedRequest);
            })
        );
    }
}
