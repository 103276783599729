<header class="mat-typography">
    <h4 class="mat-typography">Landing Page</h4>
    <nav>
        <img class="api" src="../../assets/_master.png" alt="">
        <img (click)="showDialog('Notification')" src="../../assets/bell.png" alt="">
        <img src="../../assets/plus.png" (click)="showDialogTwo()" alt="">
    </nav>
</header>
<p-dialog [header]="modalName" position="topright" [(visible)]="visible" [modal]="true" [style]="{ width: '30vw' }" [draggable]="false" [resizable]="false">
    <app-notification></app-notification>
</p-dialog>
<p-dialog [header]="modalName" [(visible)]="visibleTwo" [modal]="true" [maximizable]="true"  [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    <app-landing-products-dialog-two *ngIf="modalName == 'Products'"></app-landing-products-dialog-two>
    <app-landing-teams-dialog-two *ngIf="modalName == 'Teams'"></app-landing-teams-dialog-two>
    <app-landing-section-dialog-two *ngIf="modalName == 'Sections'"></app-landing-section-dialog-two>
<app-landing-faq-dialog-two *ngIf="modalName == 'Faq'"></app-landing-faq-dialog-two>
</p-dialog>