<div *ngIf="!isLoading">
    
    <br>
    
    <br>
    <div class="header-three">
    
      
        <table>
            <tr  class="boredred">
                <th>
                    <div style="display: flex; align-items: center;">
                        <img src="../../../../assets/Frame 40462.png" width="50" alt="">
                        <div style="padding-left: 7px;">
                            <p-inputSwitch [(ngModel)]="checked" (click) = 'isActive()'></p-inputSwitch>
                            <p style="margin: 0px; font-size: 12px;color: #151A20;font-weight: 600;">{{data.name}}</p>
                                                      
                        </div>
                    </div>
                </th>
                <th>
                    <div class="btn-group-approved" *ngIf="data.card_status == 'ACTIVE'">
    <p>Card Active</p>
                    </div>
                </th>
                <th>
                    <div class="btn-group-pending" *ngIf="data.card_status == 'PENDING'">
                        <p>Card Pending</p>
                    </div>
                </th>
                <th>
                    <div class="btn-group-message" (click)="confirm3($event)" >
    <p>Update Card Balance</p>
                    </div>
                </th>
                <th *ngIf="data.card_status == 'ACTIVE'" (click)="confirm1($event)"  >
                    <div class="btn-group-suspend"  >     
                        <p>Freeze Card</p>
                    </div>
                </th>
                <th *ngIf="data.card_status == 'PENDING'" (click)="confirm2($event)" >
                    <div class="btn-group-suspend"  >   
    <p>Unfreeze Card</p>
                    </div>
                </th>
            </tr>
            <tr>
                <th>Card ID</th>
                <th>Card Type</th>
                <th>Card Brand</th>
                <th>Card Number</th> 
                 
        </tr>
        <tr >
            <td>{{data.card_id | slice:0:18}}  {{data.card_id.length > 18 ? '...' : ''}}</td>
            <td>{{data.card_type | titlecase}}</td>
            <td>
                <img src="../../../../../assets/CARD.png" width="30" *ngIf="data.card_brand == 'visa'" alt="">
                <img src="../../../../../assets/MASTERCARD.png" width="30" *ngIf="data.card_brand == 'mastercard'" alt="">
                <img src="../../../../../assets/VERVE 2.png" width="30" *ngIf="data.card_brand == 'verve'" alt="">
            </td>
            <td>{{data.card_number}}</td>
            <td></td>
        </tr>
        <br>
        <tr>
            <th>Card Issuer</th>
            <th>Currency</th>
            <th>Country</th>
            <th>Balance</th> 
            <th></th>
             
    </tr>
    <tr class="boredred">
        <td>{{data.card_issuer | titlecase}}</td>
        <td>{{data.currency}}</td>
        <td>{{data.country}}</td>
        <td *ngIf="data.currency == 'USD'">${{data.balance | number}}</td>
        <td *ngIf="data.currency == 'NGN'">₦{{data.balance | number}}</td>
        <td></td>
    </tr>
    <br>
    <tr>
        <th>Created At</th>
        <th>Reference</th>
        <th>Customer Id</th>
        <th></th> 
        <th></th>
         
    </tr>
    <tr>
    <td>{{data.created_at | date }} {{data.created_at | date:'shortTime' }}</td>
    <td>{{data.reference | slice:0:18 }} {{data.reference.length > 18 ? '...' : ''}}</td>
    <td>{{data.customer_id | slice:0:18 }} {{data.customer_id.length > 18 ? '...' : ''}}</td>
    <td></td>
    <td></td>
    </tr>
     
        </table>
    </div>
   
    </div>

    <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
        <app-spinner ></app-spinner>
        </div>
    
        <div class="card flex justify-content-center gap-2">
            <p-toast></p-toast>
            <p-confirmPopup [style]="{'font-family':'poppins','font-size':'12px'}"></p-confirmPopup>
        </div>
    