import { Component } from '@angular/core';

@Component({
  selector: 'app-notification-templates',
  templateUrl: './notification-templates.component.html',
  styleUrls: ['./notification-templates.component.css']
})
export class NotificationTemplatesComponent {

}
