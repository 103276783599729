
<div *ngIf="!isLoading">
  
    <div class="header-two">
      <h5>Transaction Status</h5>

      <p *ngIf="data.status == 'processed'"><span class="success">{{data.status | titlecase}}</span></p>
      <p *ngIf="data.status == 'queued'"><span class="failed">{{data.status | titlecase}}</span></p>
    </div>
    <table class="table-one" style="width: 500px;">
      <tr>
        <th>Wallet Holder</th>
        <th>Customer Id</th>
        <th>Email</th>
      </tr>
      <tr>
        <td>{{data.wallet_holder | titlecase}}</td>
        <td>
          <div style="display: flex; align-items: center;">
          <p>{{data.user_id | slice:0:10 }} {{'...'}}</p>
          <img (click)="openSnackBar(data.user_id)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
          </div>
        </td>
        <td>  <div style="display: flex; align-items: center;">
          <p>{{data.email}}</p>
          <img  (click)="openSnackBar(data.email)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
          </div></td>
    
      </tr>
      <tr>
        <th>Beneficiary</th>
        <th>Level</th>
        <th>Transaction ID</th>
      </tr>
      <tr>
        <td>{{data.phone_number}}</td>
        <td>Tier {{data.level}}</td>
        <td>
            <div style="display: flex; align-items: center;">
            <p>{{data.transaction_id}}</p>
            <img (click)="openSnackBar(data.reference)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
            </div>
          </td>
        
      
    
      </tr>
      <tr>
        <th>Order Type</th>
        <th>Service Provider</th>
        <th>Card Id</th>
      </tr>
      <tr>
        <td><div class="data-group"><img src="../../../../assets/Icons (1).png" style="margin-right: 4px;" width="20" alt="">Card Funding</div></td>
        <td>{{data.service_provider | titlecase}}</td>
        <td>{{data.card_id | slice:0:10 }} {{'...'}}</td>
      </tr>
      <tr>
        <th>Amount</th>
        <th>Transaction Fee</th>
        <th>Date &Time</th>
      </tr>
      <tr>
        <td>{{data.currency == 'usd' ? '$' : '₦'}} {{data.amount | number}}</td>
        <td>${{data.fee}}</td>
        <td>{{data.date | date}} & {{data.created_at | date:'shortTime' }} </td>
      </tr>
      <tr>
        <th>Balance Before</th>
      </tr>
      <tr>
        <td>{{data.currency == 'usd' ? '$' : '₦'}}{{data.balance_before | number}}</td>
      </tr>
    </table>
    
    <div class="button-group" *ngIf="data.status == 'queued'">
                <button class="approve" (click)="approve()">Approve Card Fund</button>         
    </div>
    </div>
    <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoading'>
      <app-spinner ></app-spinner>
      </div>
    