<div *ngIf="!isLoading">
    <h4 style="font-size: 14px; color: #546881; padding-left: 25px; padding-bottom: 10px;">Service Provider</h4>
    <h4 style="font-size: 13px; color: #546881; padding-left: 25px; padding-bottom:0px;">Telecommunication</h4>
    <p style="font-size: 13px; color: #6C6C6C; padding-left: 25px; padding-bottom: 10px;">Information will be provided by service provider</p>
    <div class="header-one">
        <div class="card">
            <div style="display: flex;align-items: center;gap: 10px;">
               <img src="../../../assets/MTN.png" width="30" alt="">
               <p style="font-size: 13px;">MTN</p>
            </div>
                   <div style="display: flex;align-items: flex-start;gap: 10px;">
                       <img src="../../assets/Stockholm-icons (14).png" style="cursor: pointer;" (click)="showDialog('MTN')" width="30" alt="">
                   </div>
               </div>
               <div class="card">
                <div style="display: flex;align-items: center;gap: 10px;">
                    <img src="../../../assets/GLO.png" width="30" alt="">
                    
                    <p style="font-size: 13px;">GLO</p>
                 </div>
                <div style="display: flex;align-items: flex-start;gap: 10px;">
                    <img src="../../assets/Stockholm-icons (14).png" style="cursor: pointer;" (click)="showDialog('GLO')" width="30" alt="">
                </div>
                   </div>
                   <div class="card">
                    <div style="display: flex;align-items: center;gap: 10px;">
                        <img src="../../../assets/AIRTEL.png" width="30" alt="">
                        <p style="font-size: 13px;">Airtel</p>
                     </div>
                    <div style="display: flex;align-items: flex-start;gap: 10px;">
                        <img src="../../assets/Stockholm-icons (14).png" style="cursor: pointer;" (click)="showDialog('AIRTEL')" width="30" alt="">
                    </div>
                       </div>
                       <p-toast></p-toast>
               
       
    </div>
    
    <div class="header-two">
        <div class="card">
            <div style="display: flex;align-items: center;gap: 10px;">
                <img src="../../../assets/9MOBILE.png" width="30" alt="">
                <p style="font-size: 13px;">9Mobile</p>
             </div>
            <div style="display: flex;align-items: flex-start;gap: 10px;">
                <img src="../../assets/Stockholm-icons (14).png" style="cursor: pointer;" (click)="showDialog('9MOBILE')" width="30" alt="">
            </div>
               </div>
               <div class="card" style="opacity: 0;cursor:default;">
                <div>
                   <img src="../../assets/betting.png" width="30" alt="">
                </div>
                <div style="display: flex;align-items: flex-start;gap: 10px;">
                    <img src="../../assets/Stockholm-icons (14).png" width="30" alt="">
                </div>
                   </div>
                   <div class="card" style="opacity: 0;cursor:default;">
                    <div>
                       <img src="../../assets/betting.png" width="30" alt="">
                    </div>
                    <div style="display: flex;align-items: flex-start;gap: 10px;">
                        <img src="../../assets/Stockholm-icons (14).png" width="30" alt="">
                    </div>
                       </div>
                   
    </div>
 </div>
 <p-dialog [header]="modalName" [maximizable]="true" *ngIf="visible" [(visible)]="visible" [modal]="true" [style]="{ width: '35vw' }" [draggable]="false" [resizable]="false">
    <div *ngIf="!isLoadingTwo">
        <img src="../../../assets/MTN-CIRCLE.png" *ngIf="modalName == 'MTN'" width="50" alt="">
        <img src="../../../assets/GLO-CIRCLE.png" *ngIf="modalName == 'GLO'" width="50" alt="">
        <img src="../../../assets/AIRTEL-CIRCLE.png" *ngIf="modalName == 'AIRTEL'" width="50" alt="">
        <img src="../../../assets/9MOBILE-CIRCLE.png" *ngIf="modalName == '9MOBILE'" width="50" alt="">
     <p style="font-size: 12px; color: #546881;font-family: poppins;">Manage your network provider</p>
     <p style="font-size: 12px;color: #111014;font-family: poppins;">Name</p>
         <input type="text" placeholder="Enter name" [(ngModel)]="name">
         <p style="font-size: 12px;color: #111014;font-family: poppins;">Airtime to Cash Phone</p>
         <input type="text" placeholder="Enter name" [(ngModel)]="airtimeToCashPhone">
         <p style="font-size: 12px;color: #111014;font-family: poppins;">Airtime to Cash</p>
         <input type="number" placeholder="0" [(ngModel)]="airtimeToCash">
         <p style="font-size: 12px;color: #111014;font-family: poppins;" >Swap instruction</p>
         <textarea  cols="30" rows="4" [(ngModel)]="swapInstruction"></textarea>
         <p style="font-size: 12px;color: #111014;font-family: poppins;">Swap Code</p>
         <input type="text" placeholder="Enter code" [(ngModel)]="swapCode">
         <div style="display: flex;justify-content: space-between;align-items: center;padding-top: 10px;">
            <div><h4 style="font-family: poppins;font-size: 12px;margin-bottom: 0px;padding-bottom: 0px;">Enabled Swap</h4><p style="font-size: 12px; color: #909DAD;font-family: poppins;padding-top: 0px;margin-top: 0px;">Toggle the button to control the swap option</p></div>
            <p-inputSwitch [(ngModel)]="enabledSwap"></p-inputSwitch>
         </div>
         <div style="display: flex;justify-content: space-between;align-items: center;padding-top: 10px;">
            <div><h4 style="font-family: poppins;font-size: 12px;margin-bottom: 0px;padding-bottom: 0px;">Auto Swap</h4><p style="font-size: 12px; color: #909DAD;font-family: poppins;padding-top: 0px;margin-top: 0px;">Toggle the button to control the swap option</p></div>
            <p-inputSwitch [(ngModel)]="autoSwap"></p-inputSwitch>
         </div>
         <p style="font-size: 12px;color: #111014;font-family: poppins;">Share Pin</p>
         <input type="text" placeholder="Enter PIN" [(ngModel)] = 'sharePin'>
         <div style="display: flex;justify-content: space-between;align-items: center;padding-top: 10px;">
            <div><h4 style="font-family: poppins;font-size: 12px;margin-bottom: 0px;padding-bottom: 0px;">Enabled Cheap Airtime</h4><p style="font-size: 12px; color: #909DAD;font-family: poppins;padding-top: 0px;margin-top: 0px;">Toggle the button to control the swap option</p></div>
            <p-inputSwitch [(ngModel)]="enableCheapAirtime"></p-inputSwitch>
         </div>
         <p style="font-size: 12px;color: #111014;font-family: poppins;">Cheap Airtime</p>
         <input type="text" placeholder="0" [(ngModel)]="cheapAirime">
         <p style="font-size: 12px;color: #111014;font-family: poppins;">Cheap Airtime Token</p>
         <input type="text" placeholder="Enter Token" [(ngModel)]="cheapAirtimeToken">
       <div class="container-three">
         <button class="filter" (click)="cancel()">Cancel</button>
         <button class="apply" *ngIf="modalName == 'MTN'" (click)="update()" [disabled]="name == data[0].name && airtimeToCashPhone == data[0].airtime_to_cash_phone && airtimeToCash == data[0].airtime_to_cash_percent && swapInstruction == data[0].swap_instructions && swapCode == data[0].swap_code && enabledSwap == data[0].swap_enabled && autoSwap == data[0].auto_swap_enabled && sharePin == data[0].share_pin && enableCheapAirtime == data[0].cheap_airtime_enabled && cheapAirime == data[0].cheap_airtime_percent && cheapAirtimeToken == data[0].cheap_airtime_token">Save</button>
         <button class="apply" *ngIf="modalName == 'AIRTEL'" (click)="update()" [disabled]="name == data[1].name && airtimeToCashPhone == data[1].airtime_to_cash_phone && airtimeToCash == data[1].airtime_to_cash_percent && swapInstruction == data[1].swap_instructions && swapCode == data[1].swap_code && enabledSwap == data[1].swap_enabled && autoSwap == data[1].auto_swap_enabled && sharePin == data[1].share_pin && enableCheapAirtime == data[1].cheap_airtime_enabled && cheapAirime == data[1].cheap_airtime_percent && cheapAirtimeToken == data[1].cheap_airtime_token">Save</button>
         <button class="apply" *ngIf="modalName == '9MOBILE'" (click)="update()" [disabled]="name == data[2].name && airtimeToCashPhone == data[2].airtime_to_cash_phone && airtimeToCash == data[2].airtime_to_cash_percent && swapInstruction == data[2].swap_instructions && swapCode == data[2].swap_code && enabledSwap == data[2].swap_enabled && autoSwap == data[2].auto_swap_enabled && sharePin == data[2].share_pin && enableCheapAirtime == data[2].cheap_airtime_enabled && cheapAirime == data[2].cheap_airtime_percent && cheapAirtimeToken == data[2].cheap_airtime_token">Save</button>
         <button class="apply" *ngIf="modalName == 'GLO'" (click)="update()" [disabled]="name == data[3].name && airtimeToCashPhone == data[3].airtime_to_cash_phone && airtimeToCash == data[3].airtime_to_cash_percent && swapInstruction == data[3].swap_instructions && swapCode == data[3].swap_code && enabledSwap == data[3].swap_enabled && autoSwap == data[3].auto_swap_enabled && sharePin == data[3].share_pin && enableCheapAirtime == data[3].cheap_airtime_enabled && cheapAirime == data[3].cheap_airtime_percent && cheapAirtimeToken == data[3].cheap_airtime_token">Save</button>
     </div>
    </div>
  </p-dialog>