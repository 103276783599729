import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';
interface City {
  name: string;
}
@Component({
  selector: 'app-payout-dialog',
  templateUrl: './payout-dialog.component.html',
  styleUrls: ['./payout-dialog.component.css']
})
export class PayoutDialogComponent {
    isLoading = false;
    textBoxLogic = 'email';
accountNumber:string = '';
 @Input() bankName:string = ''
  private apiKey:string = ''
  constructor(private http:HttpClient,private messageService:MessageService){
    this.apiKey = environment.apiUrl;
  }
    cancel(){
  
    }
  
    resolve(){
      this.isLoading = true;
this.http.post(this.apiKey + '/zap/admin/setting/transfer/resolve',{accountNumber:this.accountNumber,bank:this.bankName}).subscribe({
    next:(res:any) => {
      this.messageService.add({ severity: 'info', summary:'Successful', detail:res.message});
      this.clearMessage();
      this.isLoading = false;
    },
    error:(res:any) => {
      this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message });
      this.isLoading = false;
    
    }
  })
    }
  
    clearMessage(){
  this.accountNumber = '';
  this.bankName = '';
    }
    ngOnInit() {
    }
}
