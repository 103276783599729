import { Component, OnInit } from '@angular/core';
import { AuthPageService } from '../auth-page/auth-page.service';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.css']
})
export class ConfigurationsComponent implements OnInit {
role:string = ''
constructor(private authService:AuthPageService){}
ngOnInit(): void {
  this.authService.roleName.subscribe(res => {
    this.role = res.data.role.role_name
  })
}
}
