export class UserModel {
    constructor(public message:string,public code:number, public status:string, private _access_token:string, private _expires:Date){}

    get token(){
        if(new Date() > this._expires){
return null;
        }
        
        else {
  return this._access_token
        }
    }
}