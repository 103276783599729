<div class="header-one">
    <h4>User Management</h4>

   <nav>
      <div class="filter-group" [matMenuTriggerFor]="menu">
          <p>{{dropName}}</p>
          <img src="../../assets/Stockholm-icons (9).png" alt="">
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="active()">
          <span style="font-family: poppins; font-size: 14px;">Active</span>
        </button>
        <button mat-menu-item (click)="inactive()">
            <span style="font-family: poppins; font-size: 14px;">Inactive</span>
          </button>
          <button mat-menu-item (click)="tierOne()">
            <span style="font-family: poppins; font-size: 14px;">Tier 1</span>
          </button>
          <button mat-menu-item (click)="tierTwo()">
            <span style="font-family: poppins; font-size: 14px;">Tier 2</span>
          </button>
          <button mat-menu-item (click)="tierThree()">
            <span style="font-family: poppins; font-size: 14px;">Tier 3</span>
          </button>
          <button mat-menu-item (click)="tierFour()">
            <span style="font-family: poppins; font-size: 14px;">Tier 4</span>
          </button>
          <button mat-menu-item (click)="verified()">
            <span style="font-family: poppins; font-size: 14px;">Verified</span>
          </button>
          <button mat-menu-item (click)="unVerified()">
            <span style="font-family: poppins; font-size: 14px;">Unverified</span>
          </button>
      </mat-menu>
  </nav>
</div>
<div class="card" *ngIf="!isLoading">
    <p-chart type="line" [data]="data" [options]="options"></p-chart>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 45px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>