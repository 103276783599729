import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-withdraw-modal',
  templateUrl: './withdraw-modal.component.html',
  styleUrls: ['./withdraw-modal.component.css']
})
export class WithdrawModalComponent {
  reasons:string = ''
  @Input() id:any = [];
  @Input() allId:any = []
  private adminKey:string = '';
  isLoading = false;
  private apiKey:string = '';
  constructor(private http:HttpClient,private messageService:MessageService){
    this.apiKey = environment.apiUrl;
    this.adminKey = environment.admin_key;
  }
  decline(){
    this.isLoading = true;
    if(this.id.length !== 0){
      this.http.post(this.apiKey + '/zap/admin/funding/withdraw/change-status',{id:this.id,admin_key:this.adminKey,reason:this.reasons,status:'decline'}).subscribe({
        next:(res:any) => {
          console.log(res)
          this.clear()
          this.isLoading = false;
          location.reload()
          this.messageService.add({ severity: 'success', summary: 'Approved', detail:res.message});
        },error:(res:any) => {
          this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message});
          location.reload()
          this.isLoading = false;
        }
      })
       }
  
       else {
        this.http.post(this.apiKey + '/zap/admin/funding/withdraw/change-status',{id:this.allId,admin_key:this.adminKey,reason:this.reasons,status:'decline'}).subscribe({
          next:(res:any) => {
            console.log(res)
            this.isLoading = false;
            this.messageService.add({ severity: 'success', summary: 'Approved', detail:res.message});
            this.clear()
                location.reload()
          },error:(res:any) => {
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message});
            this.isLoading = false;
          }
        })
       }
  }
  
  clear(){
    this.reasons = '';
  }
  ngOnInit(): void {
  
  }
}
