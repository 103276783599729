import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-data-plan-modal-two',
  templateUrl: './data-plan-modal-two.component.html',
  styleUrls: ['./data-plan-modal-two.component.css']
})
export class DataPlanModalTwoComponent implements OnInit{
  name:string = '';
  price:number = 0;
  telcoPrice:number = 0;
  code:string = '';
  isLoading = false;
networkId:number = 0;
  @Output() added = new EventEmitter<any>
  private apiKey:string = '';
  constructor(private http:HttpClient,private messageService:MessageService, private route:Router){
    this.apiKey = environment.apiUrl;
  }
  Add(){
    this.isLoading = true;
this.http.post(this.apiKey + '/zap/admin/configuration/create/data-plans',{price:this.price,telco_price:this.telcoPrice,name:this.name,code:this.code,network_id:this.networkId}).subscribe({
  next:(res:any) => {
    this.messageService.add({ severity: 'info', summary:'Successful', detail:res.message});
    this.isLoading = false;
    this.clear()
    this.added.emit();
  },
  error:(res:any) => {
    this.messageService.add({ severity: 'error', summary:'Rejected', detail: res.message});
    this.isLoading = false;
  }
})
  }
  clear(){
    this.price = 0;
    this.telcoPrice = 0;
    this.code = '';
    this.networkId = 0;
  this.name = '';
  }
ngOnInit(): void {
  if(this.route.url == '/configurations/internet/data-plan/mtn'){
    this.networkId = 1;
        }
        if(this.route.url == '/configurations/internet/data-plan/airtel'){
this.networkId = 2
        }

        if(this.route.url == '/configurations/internet/data-plan/9mobile'){
this.networkId = 3
        }

        if(this.route.url == '/configurations/internet/data-plan/glo'){
          this.networkId = 4
                  }
}
}
