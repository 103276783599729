import { Component, OnInit } from '@angular/core';
import { AuthPageService } from '../auth-page/auth-page.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent  implements OnInit{
  role:string = '';
  constructor(private authService:AuthPageService){}
  ngOnInit(): void {
    this.authService.roleName.subscribe((res:any) => {
      this.role = res.data.role.role_name
    })
  }
}
