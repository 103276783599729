import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-recent-transaction-dialog',
  templateUrl: './recent-transaction-dialog.component.html',
  styleUrls: ['./recent-transaction-dialog.component.css']
})
export class RecentTransactionDialogComponent implements OnInit {
  isLoading = false;
  refundData:any = {status:''};
  accessCode:any = '';
  isLoadingTwo = false;
  @Input() id:any = 0;
    resolveTransaction = false;
    data = {id:0,user_id:'',email:'',level:'',amount:0,method:'',beneficiary:'',reference:'',wallet_holder:'',status:'',fee:0,currency:'',balance_before:0,balance_after:0,date:'',transaction_id:''}
    private apiKey:string = '';
  constructor(private messageService:MessageService,private http:HttpClient){
    // api key
    this.apiKey = environment.apiUrl;
  }
  openSnackBar(id:any) {
    this.showInfo(id)
    navigator.clipboard.writeText(id);
   }
  showInfo(id:any) {
    this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
  }
  
  resolve(){
    this.isLoadingTwo = true;
    this.resolveTransaction = true;
    this.http.get(this.apiKey + '/zap/admin/transaction/bills/resolve/' + this.data.id).subscribe({
      next:(res:any) => {
  this.isLoadingTwo = false;
  this.messageService.add({severity:'success', summary:'Resolve Message', detail:res.message})
  this.refundData = res;
      },error:(res:any) => {
  this.isLoadingTwo = false;
  this.messageService.add({severity:'error', summary:'Rejected', detail:res.message})
      }
    })
  }
  done(){
    this.resolveTransaction = false;
  }
  refund(){
    this.isLoadingTwo = true
  this.http.post(this.apiKey + '/zap/admin/transaction/bills/refund',{admin_key:this.accessCode,bill_id:this.data.id}).subscribe({
    next:(res:any) => {
      this.messageService.add({ severity: 'success', summary: 'Refunded', detail: res.message});
        this.done()
        this.isLoadingTwo = false;
    },
    error:(res:any) => {
      this.messageService.add({ severity: 'error', summary: 'Rejected', detail: res.message});
      this.isLoadingTwo = false;
    }
  })
  }
  ngOnInit(): void {
  this.isLoading = true
  this.http.get(this.apiKey + "/zap/admin/transaction/bills/particular/" + this.id).subscribe({
      next:(res:any) => {
        this.isLoading = false;
        this.data = res.data
      },
      error:(res:any) => {
        this.isLoading = false;
      }
    })
  }
}
