import { Component, OnInit } from '@angular/core';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';

@Component({
  selector: 'app-card-transactions',
  templateUrl: './card-transactions.component.html',
  styleUrls: ['./card-transactions.component.css']
})
export class CardTransactionsComponent implements OnInit{
  role:string = ''
constructor(private authService:AuthPageService){}
ngOnInit(): void {
  this.authService.roleName.subscribe((res:any) => {
    this.role = res.data.role.role_name
  })
}
}
