import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { customerTransactionsService } from '../customers-transactions/customers-transactions.service';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-customers-overview',
  templateUrl: './customers-overview.component.html',
  styleUrls: ['./customers-overview.component.css']
})
export class CustomersOverviewComponent implements OnInit {
  isLoading = true;
  overview:any = {total_users:0,ngn_balance:0,usd_balance:0,total_transactions:0}
  modalName = '';
  role:any = '';
  visible: boolean = false;
  private apiKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService, private customerService:customerTransactionsService,private authService:AuthPageService){
    this.apiKey = environment.apiUrl
  }
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
  ngOnInit(): void {
    this.authService.roleName.subscribe((res:any) => {
      this.role = res.data.role.role_name
    })
    this.customerService.filter.subscribe(res => {this.visible = false})
    this.isLoading = true;
   if(this.role == 'Administrator'){
    this.http.get(this.apiKey + '/zap/admin/users/stats').pipe(catchError(this.handleError)).subscribe(
      {
        next:(res:any) => {
          this.isLoading = false;
this.overview = res.data;
        },
        error:(res)=> {
           this.isLoading = false;
           this.messageService.add({ severity: 'error', detail:res,life:2000 });
        }
      }
    )
   }
  }
  handleError(error:any) { let errorMessage = 'No internet (overview)';

  if(error.error.message){
    errorMessage = error.error.message
  }

    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });

  
  }
}
