import { Component } from '@angular/core';

@Component({
  selector: 'app-savings-details',
  templateUrl: './savings-details.component.html',
  styleUrls: ['./savings-details.component.css']
})
export class SavingsDetailsComponent {

}
