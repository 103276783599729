import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CardTransactionsDialogService } from './card-transactions-dialog.service';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-card-transactions-dialog-box',
  templateUrl: './card-transactions-dialog-box.component.html',
  styleUrls: ['./card-transactions-dialog-box.component.css']
})
export class CardTransactionsDialogBoxComponent {
  isLoading = false;
  data = {id:0,user_id:'',card_id:'',email:'',level:'',amount:0,method:'',reference:'',status:'',fee:0,currency:'',balance_before:0,balance_after:0,created_at:'',transaction_id:'',name:'',card_type:'',receipient:''
}
constructor(private dialogService:CardTransactionsDialogService,private messageService:MessageService){}
openSnackBar(id:any) {
  this.showInfo(id)
  navigator.clipboard.writeText(id);
 }
showInfo(id:any) {
  this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
}
ngOnInit(): void {
this.isLoading = true
  this.dialogService.getUser().subscribe({
    next:(res:any) => {
      this.isLoading = false;
      this.data = res.data
      console.log(res)
    },
    error:(res:any) => {
      this.isLoading = false;
    }
  })
}
}
