import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { zapinTransactionsService } from '../zap-pin-transactions/zapin-transactions.service';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-zap-pin-overview',
  templateUrl: './zap-pin-overview.component.html',
  styleUrls: ['./zap-pin-overview.component.css']
})
export class ZapPinOverviewComponent implements OnInit{
  overview:any = {totalRedmeedPin:0,totalTransaction:0}
  isLoading:boolean = false;
  modalName = '';
  visible: boolean = false;
  role:string = '';
  private apiKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService, private zapinService:zapinTransactionsService,private authService:AuthPageService){
    this.apiKey = environment.apiUrl;
  }
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }

  reload(){
    location.reload()
  }
ngOnInit(): void {
  this.authService.roleName.subscribe(res => {
    this.role = res.data.role.role_name
  })
  if(this.role == 'Administrator'){ this.isLoading = true;}
  this.zapinService.filter.subscribe(res => {this.visible = false})
  if(this.role == 'Administrator'){
    this.http.get(this.apiKey + '/zap/admin/transaction/zappin/stats').pipe(catchError(this.handleError)).subscribe({
    next:(res:any) => {
      this.isLoading = false
      this.overview.totalRedmeedPin = res.data.total_amount;
      this.overview.totalTransaction = res.data.count;
    },
    error:(res:any) => {
      this.isLoading = false;
      this.messageService.add({ severity: 'error', detail:res,life:2000 });
    }
  })

  }
 
}

handleError(error:any) { let errorMessage = 'No Internet (overview)';

if(error.error.message){
  errorMessage = error.error.message
}

  console.log(errorMessage);
  return throwError(() => {
      return errorMessage;
  });
}
}
