<div class="header-one">
  <h5  [class.active] = '!toggleActive' (click) = 'toggle()'>Pending</h5>
  <h5 [class.active] = 'toggleActive' (click) = 'toggle()'>Approved</h5>
</div>
<div *ngIf = '!isLoading'>
<div *ngIf="data.length !== 0">
  
<div class="header-two">
  <div style="display: flex;">
   <img src="../../../assets/search.png" alt="">
   <input type="text" placeholder="Search by....." (keyup.enter)="search()"  [(ngModel)] = 'searchText'>
  </div>
  <div style="display: flex; align-items: center;padding-right: 50px; gap: 3px;" *ngIf="!clearCheckedBox && headerState == 'pending'">
   <button class="approve" (click)="cancel()">Select</button>
  </div>
<div style="display: flex; align-items: center;padding-right: 50px; gap: 3px;" *ngIf="checked == false && clearCheckedBox && headerState == 'pending'">
 <button class="approve" (click)="approve()">Approve</button>
 <button class="decline" (click) = 'decline()'>Decline</button>
 <img src="../../../../assets/Stockholm-icons (18).png" (click)="clear()" alt="">
</div>
<div style="display: flex; align-items: center;padding-right: 50px; gap: 3px;" *ngIf="checked == true && clearCheckedBox && headerState == 'pending'">
 <button class="approve" (click)="approveAll()">Approve All</button>
 <button class="decline" (click) = 'declineAll()'>Decline All</button>
 <img src="../../../../assets/Stockholm-icons (18).png" (click)="clearCheckedBox = false" alt="">
</div>
</div>
<br>

<div class="header-three">
<div class="recent-transaction">
<table>
<thead>
   <tr>
       <th style="padding-left:15px;" class="order-type"><input type="checkbox" (change)="allChecked()" *ngIf="clearCheckedBox && headerState == 'pending'">Order Type</th>
       <th>Amount</th>
       <th>Received</th>
       <th>Date</th>
       <th>Time</th>
       <th>Status</th>
   </tr>
</thead>

  <tbody>
   <tr *ngFor = 'let transaction of data; let i = index'>
       <td>
         <div class="order-type">
           <input type="checkbox" (change)="selected(i)" [checked]="checked" [disabled]="checked || val.checked"  #val *ngIf="clearCheckedBox && headerState == 'pending'" >
        <img src="../../../../assets/MTN.png" *ngIf="transaction.order_type == 'mtn'" width = '22' alt="">
        <img src="../../../../assets/GLO.png" *ngIf="transaction.order_type == 'glo'" width = '22' alt="">
        <img src="../../../../assets/AIRTEL.png" *ngIf="transaction.order_type == 'airtel'" width = '22' alt="">
        <img src="../../../../assets/9MOBILE.png" *ngIf="transaction.order_type == '9mobile'" width = '22' alt="">
             <p (click)="viewDetail(i)">{{transaction.order_type | titlecase}}</p>
           </div>
       </td>
       <td (click)="viewDetail(i)">₦{{transaction.amount}}</td>
       <td (click)="viewDetail(i)">₦{{transaction.received | number}}</td>
             <td (click)="viewDetail(i)">{{transaction.updated_at | date}}</td>
             <td (click)="viewDetail(i)">{{transaction.updated_at | date:'shortTime'}}</td>
       <td (click)="viewDetail(i)" *ngIf="transaction.status == false"><span class="pending">Pending</span></td>
       <td (click)="viewDetail(i)" *ngIf="transaction.status == true"><span class="completed">Approved</span></td>
         </tr>
  </tbody>
</table>
<br>
<div style="display: flex; justify-content: space-between;align-items: center;">
  <p style="color: #151A20;font-weight: 500;font-size: 13px;">Showing 1-10 of {{this.page}} results</p>
  <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
</div>
</div>

</div>
</div>

</div>
  <p-toast></p-toast>
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 50px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    <p-dialog [header]="modalName" [(visible)]="visible" [modal]="true" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false">
      <app-airtime-swap-dialog-box *ngIf="visible" style="font-family: poppins;"></app-airtime-swap-dialog-box>
    </p-dialog>
    <div style="display: flex;  justify-content: center;padding:80px;position:relative; right:100px;top: 0px;" *ngIf="data.length == 0 && !isLoading">
  
  <div>
   <img src="../../../assets/Empty state illustratio.png" style="margin: 0 auto;display: block;padding-bottom:10px;" width="80" alt="">
   <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">No records to view yet.</h5>
   <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Transaction records will appear here once customers make purchases.</p>
  </div>
 </div>