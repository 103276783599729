import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.css']
})
export class LandingHeaderComponent{
  pageUrl = '';
  constructor(private route:Router){}
  visible: boolean = false;
  modalName = '';
  visibleTwo:boolean = false;
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
  showDialogTwo(){
   if(this.route.url == '/landing-page/products') {
    this.modalName = 'Products'
    this.visibleTwo = true;
   }

   else if(this.route.url == '/landing-page/teams') {
    this.modalName = 'Teams'
    this.visibleTwo = true;
   }
   else if(this.route.url == '/landing-page/sections') {
    this.modalName = 'Sections'
    this.visibleTwo = true;
   }

   else if(this.route.url == '/landing-page/faq') {
    this.modalName = 'Faq'
    this.visibleTwo = true;
   }
  }
}
