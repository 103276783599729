import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ReferralService } from '../../referral/referral-transaction/referral.service';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';
import { environment } from 'src/environments/environment.development';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-saving-overview',
  templateUrl: './saving-overview.component.html',
  styleUrls: ['./saving-overview.component.css']
})
export class SavingOverviewComponent {
  isLoading = false
  overview:any = {total_funding:0,funding_count:0}
  modalName = '';
  visible: boolean = false;
 role:string = '';
 private apiKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService,private referralService:ReferralService,private authService:AuthPageService){
    this.apiKey = environment.apiUrl;
  }
  reload(){
  location.reload()
  }
  ngOnInit(): void {
    this.authService.roleName.subscribe((res:any) => {
      this.role = res.data.role.role_name
    })
    if(this.role == 'Administrator') {this.isLoading = true;}
    this.referralService.filter.subscribe(res => this.visible = false)
    // let dataInString:any = localStorage.getItem('userId');
    // let userId = JSON.parse(dataInString)
    if(this.role == 'Administrator'){
    this.http.get(this.apiKey + '/zap/admin/lock/all?page=1&limit=1').pipe(catchError(this.handleError)).subscribe({
      next:(res:any) => {
        this.isLoading = false
     this.overview = res.data.stats;
     console.log(res)
      },
      error:(res:any) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'error', detail:res,life:2000 });
      }
    })
  }
  }
  
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
  handleError(error:any) { let errorMessage = 'No internet (overview)';
  
  if(error.error.message){
    errorMessage = error.error.message
  }
  
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });

  
  }
}
