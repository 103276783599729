<div *ngIf="!isLoading">
    <div class="header-two">
      <div style="display: flex;">
       <img src="../../../assets/search.png" alt="" >
       <input type="text" placeholder="Search by....." (keyup.enter)="search()"  [(ngModel)] = 'searchText'>
      </div>
   <div style="display: flex; align-items: center;padding-right: 50px; gap: 3px;">
     <button class="approve" *ngIf="status == '&status=pending' && !checked" (click) = 'approve()'>Approve</button>
     <button class="decline" *ngIf="status == '&status=pending' && !checked" (click) = 'decline()' >Decline</button>
     <button class="approve" *ngIf="status == '&status=pending' && checked" (click) = 'approveAll()' >Approve All</button>
     <button class="decline" *ngIf="status == '&status=pending' && checked" (click) = 'declineAll()'>Decline All</button>
   </div>
   </div>
   <br>
   
  <div *ngIf="data.length !== 0">
    <div class="header-three">
      <div class="recent-transaction">
      <table>
       <thead>
          <tr>
              <th style="padding-left:15px;" class="order-type"><input type="checkbox" (change)="allChecked()" *ngIf="status == '&status=approved' || status == '&status=pending'">Name</th>
              <th>Status</th>
              <th></th>
          
          </tr>
       </thead>
      
         <tbody>
          <tr *ngFor="let transactions of data; let i = index">
              <td>
                <div class="order-type">
                  <input [checked] = 'checked' (change) = 'selectedBtn(i)' type="checkbox" [disabled]="checked || val.checked"   #val   *ngIf="status == '&status=approved' || status == '&status=pending'" >
                     <p (click) = 'viewDetail(i)'>{{transactions.full_name}}</p>
                  </div>
              </td>
              <td (click) = 'viewDetail(i)' *ngIf="transactions.status == 'approved'"><span class="completed">{{transactions.status | titlecase}}</span></td>
              <td (click) = 'viewDetail(i)' *ngIf="transactions.status == 'pending'"><span class="pending">{{transactions.status | titlecase}}</span></td>
         
                </tr>
         </tbody>
      </table>
     
      <br>
     
      </div>
      
      </div>
      <div style="display: flex; justify-content: center;">
       <p-paginator  (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
     </div>
     </div>
  </div>
   <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 50px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    <div style="display: flex;  justify-content: center;padding:80px;position:relative; right:100px;top: 20px;" *ngIf="data.length == 0 && !isLoading">
    
    <div>
     <img src="../../../assets/Empty state illustratio.png" style="margin: 0 auto;display: block;padding-bottom:10px;" width="80" alt="">
     <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">No records to view yet.</h5>
     <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Message Goes here</p>
    </div>
   </div>
    <p-dialog [header]="'Decline'" [(visible)]="visible" [modal]="true" [style]="{ width: '25vw' }" [draggable]="false" [resizable]="false">
      <app-bvn-decline-modal style="font-family: poppins;" [userId]="userId" [id]="id"></app-bvn-decline-modal>
         </p-dialog>
         