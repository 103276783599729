import { Component } from '@angular/core';

@Component({
  selector: 'app-settings-header',
  templateUrl: './settings-header.component.html',
  styleUrls: ['./settings-header.component.css']
})
export class SettingsHeaderComponent {
  visible: boolean = false;
  modalName = '';
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
}
