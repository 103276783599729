import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BillsDialogBoxComponent } from '../bills-dialog-box/bills-dialog-box.component';
import { HttpClient } from '@angular/common/http';
import { BillDialogService } from '../bills-dialog-box/bills-dialog.service';
import { MessageService } from 'primeng/api';
import { billsTransactionsService } from './bills-transactions.service';
import { catchError, exhaustMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';
interface transaction {
  id:number,
  user_id:string,
  amount:number,
  method:string,
  reference:string,
  status:string,
  currency:string,
  date:string,
  }
@Component({
  selector: 'app-bills-transactions',
  templateUrl: './bills-transactions.component.html',
  styleUrls: ['./bills-transactions.component.css'],
})
export class BillsTransactionsComponent implements OnInit,OnDestroy{
  modalName = ''
  searchText = ''
  isLoading = false;
  data:any[] = []
  visible: boolean = false;
  start_date = '';
  end_date = '';
  page = 1;
  first: number = 1;
  rows: number = 10;
  totalRecord = 20;
  status:string = '';
  order_type:string = '';
  start_amount = '';
  end_amount = '';
  private filterSubscription:any;
  private apiKey:string = '';
  constructor(private http:HttpClient, private dialogService:BillDialogService,private messageService:MessageService, private billService:billsTransactionsService) {
    // api key
    this.apiKey = environment.apiUrl;
  }
  openSnackBar(id:any) {
   this.showInfo(id)
   navigator.clipboard.writeText(id);
  }
  viewDetail(id:number){
  this.dialogService.id = this.data[id].id;
  this.showDialog('Bill Transactions')
  }
  onPageChange(event:any) {
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + 1;
    this.isLoading = true;
 this.getRequest().subscribe((res:any) => {
  this.data = res.data;
  this.isLoading = false;
 })
}
  showInfo(id:any) {
    this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
}
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
  filter(name:any){

   this.filterSubscription = this.billService.filter.pipe(tap((res:any) => {
    this.isLoading = true;
    this.status = res.status == '' ? '' : '&status=' + res.status;
    this.order_type = res.order_type == '' ? '' : '&method=' + res.order_type;
    this.start_date = res.start_date == '' ? '' : '&start_date' + res.start_date.toISOString();;
    this.end_date = res.end_date == '' ? '' : '&end_date' + res.end_date.toISOString();;
    this.start_amount = '&start_amount=' + res.start_amount;
    this.end_amount = '&end_amount=' + res.end_amount;
    console.log(res.start_amount + 'amount')
   }),exhaustMap((res:any) => {return this.getRequest(); 
  
  
  })).pipe(catchError(this.handleError)).subscribe({
    next:(res:any) => {
      this.isLoading = false;
      this.data = res.data;
      this.totalRecord = res.total;
    },
    error:(res) => {
      this.isLoading = false;
      setTimeout(() => {
        this.messageService.add({ severity: 'error', detail:res,life:2000 });
       }, 2000);
    }
  })
  }

  getRequest(){
    return this.http.get(this.apiKey + '/zap/admin/transaction/bills?page=' + this.page + '&' + 'limit' + '=' + this.rows + this.status + this.order_type + this.start_date + this.end_date + this.start_amount + this.end_amount + '&search_query=' + this.searchText)
  }
  
  search(){
    this.isLoading = true;
    this.getRequest().subscribe((res:any) => {
      this.data = res.data;
      this.totalRecord = res.total;
    this.isLoading = false
     })
    }

    handleError(error:any) { let errorMessage = 'No Internet (transactions)';

    if(error.error.message){
      errorMessage = error.error.message
    }
  
      console.log(errorMessage);
      return throwError(() => {
          return errorMessage;
      });
    }
  ngOnInit(): void { 
    this.isLoading = true; 
    this.filter('onInit')
  }

  ngOnDestroy(): void {
   
    console.log('ngOnDestroy called');
    // Unsubscribe from the filter subscription to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}
