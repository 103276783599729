<div class="header-one">
    <h4>Individual Card Transaction</h4>

   <nav>
      <div class="filter-group" (click)="showDialog('filter')">
          <p>Filter</p>
          <img src="../../assets/Stockholm-icons (9).png" alt="">
      </div>
<img src="../../../assets/Stockholm-icons (15).png" (click)="reload()" style="margin-left: 20px;" alt="">
  </nav>
</div>
<div *ngIf="!isLoading">
    <div class="payment-manager">
        <div class="card">
            <img src="../../assets/Widget icons (3).png" alt="">
            <p>Total Credits</p>
            
            <h4>${{overview.total_credits_sum | number}}</h4>
        </div>
        <div class="card">
            <img src="../../assets/Widget icons (3).png" alt="">
            <p>Total Debits</p>
            <h4>${{overview.total_debits_sum | number}}</h4>
        </div>
        <div class="card">
            <img src="../../assets/Widget icons (3).png" alt="">
            <p>Total Transactions</p>
            <h4>${{overview.total_transaction_sum | number}}</h4>
        </div>
        <div class="card">
            <img src="../../assets/Widget icons (3).png" alt="">
            <p>Total Transactions Count</p>
            <h4>{{overview.total_transaction_count | number}}</h4>
        </div>
    </div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    <p-dialog [header]="modalName" [(visible)]="visible" [modal]="true" [style]="{ width: '35vw',height:'100%' }" [draggable]="false" [resizable]="false">
        <app-ct-filter-modal *ngIf="visible" style="font-family: poppins;"></app-ct-filter-modal>
      </p-dialog>