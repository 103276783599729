import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {BehaviorSubject, catchError, throwError} from 'rxjs'
import {tap} from 'rxjs'
import { UserModel } from "./user.model";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment.development";
interface Auth  {
    message:string,
    code:number;
    status:string;
    data:{access_token:string,expires:string}
}
@Injectable({providedIn:'root'})
export class AuthPageService {
    private logOutTimer:any  = null;
   user = new BehaviorSubject<any>(null) 
   roleName = new BehaviorSubject<any>(null);
   private apiKey:any = '';
constructor(private http:HttpClient, private route:Router){
// api key
this.apiKey = environment.apiUrl
}
loginRequest(email:string,password:string){
return this.http.post<Auth>(this.apiKey + '/zap/admin/auth/login',{email:email,password:password}).pipe(tap(res => {
    let expired = new Date(res.data.expires);
    let user = new UserModel(res.message,res.code,res.status,res.data.access_token,expired)
    this.user.next(user);
    localStorage.setItem('userData', JSON.stringify(user));
    console.log(this.user)
}),catchError(this.handleError))

    }
    logOut(){
        this.user.next(null);
       this.route.navigate(['/login']);
       localStorage.clear();
       if(this.logOutTimer){
        clearTimeout(this.logOutTimer);
       }
    }
    autoLogin() {
let userDataInString:any = localStorage.getItem('userData');
let userData:{message:string,code:number,status:string,_access_token:string,_expires:Date} = JSON.parse(userDataInString)
if(!userData){
return;
}
let loadedUser = new UserModel(userData.message,userData.code,userData.status,userData._access_token,userData._expires)

if(loadedUser.token){
    this.user.next(loadedUser)
    const expiredDuration = new Date(userData._expires).getTime() - new Date().getTime();
    console.log(expiredDuration)
    this.autoLogOut(expiredDuration);
}

    }

    autoLogOut(expiredDuration:number){
      this.logOutTimer =  setTimeout(() => {
            this.logOut()
        }, expiredDuration);
    }
    handleError(error:any) { let errorMessage = 'internet connection';

      if(error.error.message){
        errorMessage = error.error.message
      }
    
        console.log(errorMessage);
        return throwError(() => {
            return errorMessage;
        });
      }

      getAdminstrator(){
        return this.http.get(this.apiKey + '/zap/admin/auth/user')
      }
}