<div *ngIf="!isLoading">
    <div class="header-two">
      <img src="../../../assets/search.png" alt="">
      <input type="text" placeholder="Search by....." (keyup.enter)="search()"  [(ngModel)]="searchText">
  </div>
  <br>
  <div *ngIf="data.length !== 0">
    
  <div class="header-four">
    <div class="recent-transaction">
    <table>
     <thead>
      <tr>
        <th>Customer Id</th>
            <th>Full Name</th>
            <th>Creation Date</th>
            <th>Email</th>
            <th>Status</th>
        </tr>
     </thead>
    
       <tbody>
        <tr *ngFor="let transactions of data; let i =index" >
            <td (click) = 'viewDetail(i)'>{{transactions.user_id}}</td>
                  <td (click) = 'viewDetail(i)'>{{transactions.full_name}}</td>
                  <td>{{transactions.created_at | date}}</td>
                  <td (click) = 'viewDetail(i)'>{{transactions.email}}</td>
                  <td *ngIf="transactions.status == 'completed'"><p><span class="completed">Completed</span></p></td>
                  <td *ngIf="transactions.status == 'pending'"><p><span class="pending">Pending</span></p></td>
             
      </tr>
       </tbody>
    </table>
    <br>
    <div style="display: flex; justify-content: space-between;align-items: center;">
      <p style="color: #151A20;font-weight: 500;font-size: 13px;">Showing 1-10 of {{this.page}} results</p>
      <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
    </div>
    </div>
    </div>
    </div>
  </div>
  <div style="display: flex;  justify-content: center;padding:80px;position:relative; right:100px;top: 0px;" *ngIf="data.length == 0 && !isLoading">
    
    <div>
     <img src="../../../assets/Empty state illustratio.png" style="margin: 0 auto;display: block;padding-bottom:10px;" width="80" alt="">
     <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">No records to view yet.</h5>
     <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Transaction records will appear here once customers make purchases.</p>
    </div>
   </div>
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 100px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    <p-toast></p-toast>