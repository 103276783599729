

<div class="header-one" *ngIf="!isLoading">
   <div class="card" *ngFor="let message of data">
      <img style="align-self: flex-start;" src="../../assets/Widget icons (5).png" width="30" alt="">
      <div>
          <h4>{{message.title | titlecase}}</h4>
      <p>{{message.body}}</p>
      </div>
   <div style="align-self:flex-end;">
      <p style="font-size: 11px;"><strong>{{message.created_at | date:'shortTime'}}</strong></p>
   </div>
  </div>
  <div style="display: flex; justify-content: space-between;align-items: center;">
   <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
  </div>
</div>

<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right:5px; top: 20px;" *ngIf = 'isLoading'>
   <app-spinner ></app-spinner>
   </div>