import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-analytics-card-transaction',
  templateUrl: './analytics-card-transaction.component.html',
  styleUrls: ['./analytics-card-transaction.component.css']
})
export class AnalyticsCardTransactionComponent implements OnInit{
  data: any;
 dropName = 'Success';
 dataTwo:{dates:string[],data_count:number[]}= {dates:[],data_count:[]}
 isLoading = false;
 private apiKey:string = '';
constructor(private http:HttpClient){
  this.apiKey = environment.apiUrl;
}
 success(){
    this.dropName = 'Success';
    this.fetchRequest();
 }

 failed(){
    this.dropName = 'Failed';
       this.fetchRequest();
 }
  options: any;

  fetchRequest(){
    this.isLoading = true;
    this.http.get(this.apiKey + '/zap/admin/analytics/card?status=' + this.dropName).subscribe((res:any) => {
        this.isLoading = false;
this.dataTwo = res.data
   console.log(this.dataTwo)
   const documentStyle = getComputedStyle(document.documentElement);
   const textColor = documentStyle.getPropertyValue('--text-color');
   const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
   const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

   this.data = {
       labels: this.dataTwo.dates.map((res:any) => {
        const timestampStr = res;
        const timestampObj = new Date(timestampStr);
        
        // Format time
        const timeOptions:any = { year: 'numeric', month: 'numeric', day: 'numeric' };
        const formattedTime = timestampObj.toLocaleString('en-US', timeOptions);
        return formattedTime
       }),
       datasets: [
           {
               label: 'Transactions',
               data: this.dataTwo.data_count,
               fill: true,
               borderColor:"#673AB7",
               backgroundColor: 'rgba(208,194,233,0.5)',
               borderWidth: 1,
           },
       ]
   };

   this.options = {
     responsive: true,
       maintainAspectRatio: false,
       aspectRatio: 0.9,
       plugins: {
           legend: {
             display: false,
               labels: {
               
                   color: textColor,
                   font: {
                     family: 'arial', // Reference the custom font here
                   },
               }
           }
       },
       scales: {
           x: {
               ticks: {
               
                   color: 'black'
               },
               grid: {
                   color: surfaceBorder,
                   drawBorder: false
               }
           },
           y: {
               ticks: {
                   color: 'black',
                   callback: (value: any) => {
                    return value + 'k';
                  },
               },
               grid: {
                   color: surfaceBorder,
                   drawBorder: true
               }
           }
       }
   };

 })
}
  ngOnInit() {
    this.isLoading = true;
   this.fetchRequest();
    
  }
}
