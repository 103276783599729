import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-vc-detail',
  templateUrl: './vc-detail.component.html',
  styleUrls: ['./vc-detail.component.css']
})
export class VcDetailComponent {
  constructor(private route:Router){}
active(url:string){
 return this.route.url == url;
  
}
}
