import { Component } from '@angular/core';

@Component({
  selector: 'app-vc-filter',
  templateUrl: './vc-filter.component.html',
  styleUrls: ['./vc-filter.component.css']
})
export class VcFilterComponent {

}
