import { Component, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogBoxComponent } from 'src/app/dialog-box/dialog-box.component';
import {OnInit} from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';
import { recentTransactionsService } from '../recent-transaction/recent-transactions.service';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';
import { environment } from 'src/environments/environment.development';
interface overviewTransaction {
total_active_users:number,total_balance:number,total_transaction_count:number,transfer_balance:number,usd_to_ngn_rate:number,total_transaction_sum:number,ngn_to_usd_rate:number
}
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})

export class OverviewComponent implements OnInit,OnDestroy{
 usdToNgn = true;
  isLoading = false;
overview:overviewTransaction = {total_active_users:0,total_balance:0,total_transaction_count:0,transfer_balance:0,usd_to_ngn_rate:0,total_transaction_sum:0,ngn_to_usd_rate:0}
visible: boolean = false;
modalName = '';
role:string = '';
filterSubscription:any;
private apiKey:string = '';
  constructor(public dialog:MatDialog, private http:HttpClient,private messageService:MessageService, private recentService:recentTransactionsService,private authService:AuthPageService){
    // api key
    this.apiKey = environment.apiUrl;
  }
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
  openDialog(){
  this.showDialog('Filter')
  }
switch(){
this.usdToNgn = !this.usdToNgn
}
  ngOnInit(): void {
    this.authService.roleName.subscribe((res:any) => {
      this.role = res.data.role.role_name
    })
   this.filterSubscription =  this.recentService.filter.subscribe(res => this.visible = false);
   if(this.role == 'Administrator'){this.isLoading = true}
    if(this.role == 'Administrator'){
    this.http.get(this.apiKey + '/zap/admin/home/stats').pipe(catchError(this.handleError)).subscribe(
      {
        next:(res:any) => {
          this.isLoading = false;
this.overview = res.data;
        },
        error:(res)=> {
           this.isLoading = false;
          if(this.role == 'Administrator'){ this.messageService.add({ severity: 'error', detail:res,life:2000 });}
        }
      }
    )
    }
  }
  handleError(error:any){ let errorMessage = 'No Internet (overview)';

  if(error.error.message && errorMessage){
    errorMessage = error.error.message
  }

    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }

  ngOnDestroy(): void {
       // Unsubscribe from the filter subscription to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}
