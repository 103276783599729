import { Component } from '@angular/core';

@Component({
  selector: 'app-c-airtime-to-cash',
  templateUrl: './c-airtime-to-cash.component.html',
  styleUrls: ['./c-airtime-to-cash.component.css']
})
export class CAirtimeToCashComponent {
  checked: boolean = false;
}
