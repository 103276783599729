
<div class="header-two">
  <h4>Overview</h4>

 <nav>
  <button class="btn-message" (click) = 'message()'>Message</button>
</nav>
</div>
<div *ngIf="data.length !== 0 && !isLoading">
<div class="header-four">
<div class="recent-transaction">
<table>
 <thead>
    <tr>
        <th>Title</th>
        <th>Message</th>
        <th>Type</th>
        <th>Date created</th>
      <th></th>
    </tr>
 </thead>
<tr *ngFor="let message of data; let i = index;">
    <td (click)="viewDetail(i)">{{message.title | titlecase}}</td>
    <td (click)="viewDetail(i)">{{ message.content | slice:0:10 }} {{ message.content.length > 10 ? '...' : '' }}</td>
    <td (click)="viewDetail(i)">{{message.type | titlecase }}</td>
    <td (click)="viewDetail(i)">{{message.date | date}}</td>
    <td  mat-icon-button [matMenuTriggerFor]="menu"><img src="../../assets/Title (1).png" width="50" alt="">
        <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <span style="font-family: poppins; font-size: 13px;" (click)="delete(i)">Delete</span>
            </button>
          </mat-menu>
    </td>
</tr>
   <tbody>
    </tbody>
</table>
<br>
<div style="display: flex; justify-content: space-between;align-items: center;">
  <p style="color: #151A20;font-weight: 500;font-size: 13px;">Showing 1-10 of {{this.page}} results</p>
  <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
</div>
</div>
</div>
</div>
<p-dialog [maximizable]="true" [header]="modalName" [(visible)]="visible" [modal]="true" [style]="{ width: '50vw', 'font-family':'poppins'}" [draggable]="false" [resizable]="false">
  <app-email-dialog-box *ngIf="visible" style="font-family: poppins;"></app-email-dialog-box>
</p-dialog>
<p-toast></p-toast>
    <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
      <app-spinner ></app-spinner>
      </div>
      <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 100px;" *ngIf="data.length == 0 && !isLoading">
    
          <div>
           <img src="../../../assets/Empty state illustratio (1).png" style="margin-left: 160px;" width="80" alt="">
           <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">No records to view yet.</h5>
           <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Transaction records will appear here once customers make purchases.</p>
          </div>
         </div>