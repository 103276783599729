import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BankTransferDialogService } from './bank-transfer-dialog.service';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-bank-transfer-dialog-box',
  templateUrl: './bank-transfer-dialog-box.component.html',
  styleUrls: ['./bank-transfer-dialog-box.component.css']
})
export class BankTransferDialogBoxComponent {
  isLoading = false;
  refundData:any = {status:''};
accessCode:any = '';
isLoadingTwo = false;
resolveTransaction = false;
  data = {id:0,user_id:'',email:'',level:'',session_id:'',note:'',amount:0,method:'',reference:'',status:'',fee:0,currency:'',balance_before:0,balance_after:0,created_at:'',transaction_id:'',name:'',order_type:'',receipient:'',
  phone_number:'',bank:'',account_name:''
}
private apiKey:string = '';
constructor(private dialogService:BankTransferDialogService,private messageService:MessageService, private http:HttpClient){
  this.apiKey = environment.apiUrl;
}
resolve(){
  this.isLoadingTwo = true;
  this.resolveTransaction = true;
  this.http.get(this.apiKey + '/zap/admin/transaction/bills/resolve/' + this.data.id).subscribe({
    next:(res:any) => {
this.isLoadingTwo = false;
this.refundData = res;
this.messageService.add({severity:'success', summary:'Resolve Message', detail:res.message})
    },error:(res:any) => {
this.isLoadingTwo = false;
this.messageService.add({severity:'error', summary:'Rejected', detail:res.message})
    }
  })
}
done(){
  this.resolveTransaction = false;
}
refund(){
  this.isLoadingTwo = true
this.http.post(this.apiKey + '/zap/admin/transaction/bills/refund',{admin_key:this.accessCode,bill_id:this.data.id}).subscribe({
  next:(res:any) => {
    this.messageService.add({ severity: 'success', summary: 'Refund', detail: res.message});
      this.done()
      this.isLoadingTwo = false;
  },
  error:(res:any) => {
    this.messageService.add({ severity: 'error', summary: 'Rejected', detail: res.message});
    this.isLoadingTwo = false;
  }
})
}
openSnackBar(id:any) {
  this.showInfo(id)
  navigator.clipboard.writeText(id);
 }
showInfo(id:any) {
  this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
}
ngOnInit(): void {
this.isLoading = true
  this.dialogService.getUser().subscribe({
    next:(res:any) => {
      this.isLoading = false;
      this.data = res.data
      console.log(res)
    },
    error:(res:any) => {
      this.isLoading = false;
    }
  })
}
}
