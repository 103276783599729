<div *ngIf="!isLoading">
  
<div class="header-two">
  <h5>Transaction Status</h5>


  <p *ngIf="data.status.toLocaleLowerCase() == 'success' || data.status.toLocaleLowerCase() == 'successful' || data.status.toLocaleLowerCase() == 'completed'"><span class="success">Successful</span></p>
  <p *ngIf="data.status.toLocaleLowerCase() == 'reversed'"><span class="failed">Reversed</span></p>
</div>
<table class="table-one" style="width: 500px;">
  <tr>
    <th>Wallet Holder</th>
    <th>Customer Id</th>
    <th>Email</th>
  </tr>
  <tr>
    <td>{{data.wallet_holder | titlecase}}</td>
    <td>
      <div style="display: flex; align-items: center;">
      <p>{{data.user_id | slice:0:8}} {{ data.user_id.length > 8 ? '...' : '' }}</p>
      <img (click)="openSnackBar(data.user_id)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
      </div>
    </td>
    <td>  <div style="display: flex; align-items: center;">
      <p>{{data.email}}</p>
      <img  (click)="openSnackBar(data.email)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
      </div></td>

  </tr>
  <tr>
    <th>Beneficiary</th>
    <th>Level</th>
    <th>Transaction ID</th>
  </tr>
  <tr>
    <td>{{data.beneficiary}}</td>
    <td>{{data.level}}</td>
    <td>{{data.transaction_id | slice:0:8}} {{ data.transaction_id.length > 8 ? '...' : '' }}</td>
  

  </tr>
  <tr>
    <th>Price</th>
    <th>Transaction Fee</th>
    <th>Date &Time</th>
  </tr>
  <tr>
    <td>{{data.currency == 'usd' ? '$' : '₦'}} {{data.amount | number}}</td>
    <td>{{data.currency == 'usd' ? '$' : '₦'}} {{data.fee | number}}</td>
    <td>{{data.date | date}} & {{data.date | date:'shortTime' }}</td>
  </tr>
  <tr>
    <th>Order Type</th>
    <th>Balance Before</th>
    <th>Balance After</th>
  </tr>
  <tr>
    <td>{{data.method | titlecase}}</td>
    <td>{{data.currency == 'usd' ? '$' : '₦'}} {{data.balance_before | number}}</td>
    <td>{{data.currency == 'usd' ? '$' : '₦'}} {{data.balance_after | number}}</td>
  </tr>
</table>

<div class="button-group" *ngIf="!resolveTransaction" (click) = 'resolve()'>
  <button> <img src="../../../../assets/Stockholm-icons (17).png" width="20" alt=""> Resolve Transaction</button>
</div>
<br>
<div class="container" *ngIf="resolveTransaction">
<header>
  <h4 style="font-size: 13px;margin: 0px;">Resolve Transaction</h4>
  <img src="../../../../assets/Group.png" width="20" style="cursor: pointer;" alt="" (click)="done()">
</header>
<div class="message success" *ngIf="refundData.status.toLocaleLowerCase() == 'success' && !isLoadingTwo">
  <img src="../../../../assets/success.png" width="20" alt="" style="margin: 0px;">
  <p style="margin: 0px;font-size: 12px;padding: 0px; font-weight: 600;" >This transaction was successful</p>
</div>
<div class="message pending" *ngIf="refundData.status.toLocaleLowerCase() == 'pending' && !isLoadingTwo">
  <img src="../../../../assets/message (2).png" width="20" alt="" style="margin: 0px;">
  <p style="margin: 0px;font-size: 12px;padding: 0px; font-weight: 600;" >This transaction is still pending. Further information will be communicated for success of refund.</p>
</div>
<div class="message failed" *ngIf="refundData.status.toLocaleLowerCase() == 'failed' && !isLoadingTwo">
  <img src="../../../../assets/failed.png" width="20" alt="" style="margin: 0px;">
  <p style="margin: 0px;font-size: 11px;padding: 0px; font-weight: 600;" >{{refundData.message}} Input access code to successfully refund customer.</p>
  
</div>
<br>
<div *ngIf="refundData.status.toLocaleLowerCase() == 'failed' && !isLoadingTwo">
  <h4 style="font-size: 13px;margin: 0px;padding-left: 10px;">Access Code</h4>
<input type="text" placeholder="Enter access Code" [(ngModel)] = 'accessCode'>
<p style="padding: 0px;margin: 2px;font-size: 11px;padding-left: 10px;">Input 6 digit admin access code to complete refund.</p>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoadingTwo'>
  <app-spinner ></app-spinner>
  </div>
</div>
<div class="button-group-two" *ngIf="resolveTransaction && refundData.status.toLocaleLowerCase() == 'failed'" (click)="refund()">
  <button [disabled]="accessCode == ''" >Refund</button>
</div>
<div class="button-group-two" *ngIf="resolveTransaction && refundData.status.toLocaleLowerCase() !== 'failed'" (click)="done()">
  <button>Cancel</button>
</div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>
