import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, exhaustMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { ReferralService } from '../../../referral-transaction/referral.service';

@Component({
  selector: 'app-referral-profile-transaction',
  templateUrl: './referral-profile-transaction.component.html',
  styleUrls: ['./referral-profile-transaction.component.css']
})
export class ReferralProfileTransactionComponent {
  searchText = ''
  isLoading:boolean = false;
  data:any = [];
  start_date = '';
  end_date = '';
  page = 1;
  first: number = 1;
rows: number = 10;
totalRecord = 20;
status = '';
start_amount = '';
end_amount = '';
private filterSubscription:any;
private apiKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService,private referralService:ReferralService){
    this.apiKey = environment.apiUrl;
  }
viewDetail(index:number){

}
openSnackBar(id:any) {
  this.showInfo(id)
  navigator.clipboard.writeText(id);
 }
showInfo(id:any) {
  this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
}


onPageChange(event:any) {
  this.first = event.first;
  this.rows = event.rows;
  this.page = event.page + 1;
  this.isLoading = true;
  this.getRequest().subscribe((res:any) => {
    this.data = res.data;
    this.isLoading = false;
   })
}
    filter(){
    this.filterSubscription = this.referralService.filter.pipe(tap((res:any) => {
        this.status = res.status == '' ? '' : '&status=' + res.status;
        this.start_date = res.start_date == '' ? '' : '&start_date' + res.start_date.toISOString();;
        this.end_date = res.end_date == '' ? '' : '&end_date' + res.end_date.toISOString();;
        this.start_amount = '&start_amount=' + res.start_amount;
        this.end_amount = '&end_amount=' + res.end_amount;
        this.isLoading = true;
        console.log(res.start_amount + 'amount')
       }),exhaustMap((res:any) => {return this.getRequest()})).pipe(catchError(this.handleError)).subscribe({
        next:(res:any) => {
          this.isLoading = false;
          this.data = res.data.data;
          this.totalRecord = res.total;
        },
        error:(res) => {
          this.isLoading = false;
          setTimeout(() => {
            this.messageService.add({ severity: 'error', detail:res,life:2000 });
           }, 2000);
        }
      })
    }
    handleError(error:any) { let errorMessage = 'No Internet (transactions)';
  
    if(error.error.message){
      errorMessage = error.error.message
    }
    
      console.log(errorMessage);
      return throwError(() => {
          return errorMessage;
      });
    }
    getRequest(){
      let dataInString:any = localStorage.getItem('userId');
   let userId = JSON.parse(dataInString)
      return  this.http.get(this.apiKey + '/zap/admin/lock/referrals/' +  userId + '?page='  + this.page + '&' + 'limit' + '=' + this.rows)
     }
     search(){
      this.isLoading = true;
      this.getRequest().subscribe((res:any) => {
        this.data = res.data.data;
        this.totalRecord = res.data.total;
      this.isLoading = false
       })
      }
ngOnInit(): void {
  this.isLoading = true;
  this.filter()
}
ngOnDestroy(): void {
   
  console.log('ngOnDestroy called');
  // Unsubscribe from the filter subscription to avoid memory leaks
  if (this.filterSubscription) {
    this.filterSubscription.unsubscribe();
  }
} 
}
