import { Component } from '@angular/core';
import { GcTransactionsService } from 'src/app/transactions/gift-card/gift-card-transactions/gc-transactions.service';
import { ReferralService } from '../referral-transaction/referral.service';

@Component({
  selector: 'app-referral-filter-modal',
  templateUrl: './referral-filter-modal.component.html',
  styleUrls: ['./referral-filter-modal.component.css']
})
export class ReferralFilterModalComponent {
  toggle = false
  date:any = '';
  dateTwo:any = '';
  amountOne:number = 0;
  amountTwo:number = 0;
  status = ''
  selectedItem: number | null = 0;
  selectedItemTwo: number | null = 0;
  orderType = 'visa';
  private filterSubscription:any;
constructor(private referralService:ReferralService){}

  isActive(index: number): boolean {
    return this.selectedItem === index;
  }
  isActiveTwo(index: number): boolean {
    return this.selectedItemTwo === index;
  }
  toggleItem(index:number){
  this.selectedItem = this.selectedItem === index ? null : index;
  if(index == 0){
   this.status = ''
        }
  if(index == 1){

this.status = 'success'
  }
  if(index == 2){

    this.status = 'reversed'
      }
}

toggleItemTwo(index:number){
  this.selectedItemTwo = this.selectedItemTwo === index ? null : index;
  if(index == 0){
   this.orderType = 'visa'
        }
  if(index == 1){

this.orderType = 'mastercard'
  }
  if(index == 2){

    this.orderType = 'verve'
      }
}
send(){
this.referralService.filter.next({start_date:this.date,end_date:this.dateTwo,start_amount:this.amountOne,end_amount:this.amountTwo,status:this.status});
}
clear(){
 this.referralService.filter.next({start_date:'',end_date:'',start_amount:0,end_amount:0,status:'',order_type:''});
}
  ngOnInit(): void {
this.filterSubscription =  this.referralService.filter.subscribe(res => {
  if(res.status == 'reversed'){
    this.selectedItem = 2;
    this.status = 'reversed';
  }
  if(res.status == 'success'){
    this.selectedItem = 1;
    this.status = 'success'
  }

  if(res.status == ''){
    this.selectedItem = 0;
    this.status = '';
  }
    this.date = res.start_date;
    this.dateTwo = res.end_date;
 if(res.start_amount !== undefined && res.end_amount !== undefined ){
  this.amountOne = res.start_amount;
  this.amountTwo = res.end_amount;
 }
 else {
  this.amountOne = 0;
  this.amountTwo = 0;
 }
  })

  }

  ngOnDestroy(): void {
   
    console.log('ngOnDestroy called');
    // Unsubscribe from the filter subscription to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}
