import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-data-plan-modal',
  templateUrl: './data-plan-modal.component.html',
  styleUrls: ['./data-plan-modal.component.css']
})
export class DataPlanModalComponent implements OnInit{
  name:string = '';
  price:number = 0;
  telcoPrice:number = 0;
  id:any = 0
  isLoading = false;
  code:string = '';
  @Input() data:any={};
  @Output() updated = new EventEmitter<any>
  private apiKey:string = '';
  constructor(private http:HttpClient,private messageService:MessageService){
    this.apiKey = environment.apiUrl;
  }
  update(){
    this.isLoading = true;
    
this.http.post(this.apiKey + '/zap/admin/configuration/update/data-plans',{id:this.id,price:this.price,telco_price:this.telcoPrice,name:this.name,code:this.code}).subscribe({
  next:(res:any) => {
    this.messageService.add({ severity: 'info', summary:'Id: ' + this.id, detail:res.message});
    this.isLoading = false;
    this.updated.emit();
    this.clear()
  },
  error:(res:any) => {
    this.messageService.add({ severity: 'error', summary:'Rejected', detail: res.message});
    this.isLoading = false;
  }
})
  }
  clear(){
    this.price = 0;
    this.telcoPrice = 0;
  }
ngOnInit(): void {
  this.id = this.data.id;
  this.price = this.data.price;
  this.telcoPrice = this.data.telco_price;
  this.name = this.data.name;
  this.code = this.data.code;
}
}

