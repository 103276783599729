import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-fx-overview',
  templateUrl: './fx-overview.component.html',
  styleUrls: ['./fx-overview.component.css']
})
export class FxOverviewComponent implements OnInit{
  isLoading = false;
  ngn_usd = false;
  data:any = {ngn_usd:0,usd_ngn:0}
  private apiKey:string = '';
constructor(private http:HttpClient){
  this.apiKey =  environment.apiUrl;
}
switch(){
this.ngn_usd = !this.ngn_usd;
}
  ngOnInit(): void {
    this.isLoading = true;
    this.http.get(this.apiKey + '/zap/admin/home/stats').subscribe({
      next:(res:any) => {
        this.isLoading = false;
this.data.ngn_usd = res.data.ngn_to_usd_rate;
this.data.usd_ngn = res.data.usd_to_ngn_rate
      },error:(res:any) => {
this.isLoading = false;
      }
    })
  }
}
