import { Component } from '@angular/core';

@Component({
  selector: 'app-vc-transaction-history',
  templateUrl: './vc-transaction-history.component.html',
  styleUrls: ['./vc-transaction-history.component.css']
})
export class VcTransactionHistoryComponent {

}
