import { Component } from '@angular/core';
import { vcTransactionService } from '../vc-transaction/vc-transaction.service';

@Component({
  selector: 'app-v-card-filter',
  templateUrl: './v-card-filter.component.html',
  styleUrls: ['./v-card-filter.component.css']
})
export class VCardFilterComponent {
  toggle = false
  date:any = '';
  dateTwo:any = '';
  amountOne:number = 0;
  amountTwo:number = 0;
  status = ''
 private filterSubscription:any;
constructor(private vcTransactionService:vcTransactionService){}
send(){
this.vcTransactionService.filter.next({start_date:this.date,end_date:this.dateTwo,start_amount:this.amountOne,end_amount:this.amountTwo,status:this.status});
}
clear(){
this.vcTransactionService.filter.next({start_date:'',end_date:'',start_amount:0,end_amount:0,status:'',order_type:''})
}
  ngOnInit(): void {
 this.filterSubscription =  this.vcTransactionService.filter.subscribe(res => {
      this.date = res.start_date;
      this.dateTwo = res.end_date;
   if(res.start_amount !== undefined && res.end_amount !== undefined ){
    this.amountOne = res.start_amount;
    this.amountTwo = res.end_amount;
   }
   else {
    this.amountOne = 0;
    this.amountTwo = 0;
   }
    })
  }
  ngOnDestroy(): void {
   
    console.log('ngOnDestroy called');
    // Unsubscribe from the filter subscription to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  } 
}
