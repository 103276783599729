import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AirtimeSwapDialogBoxComponent } from '../airtime-swap-dialog-box/airtime-swap-dialog-box.component';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { AirtimeSwapDialogBoxService } from '../airtime-swap-dialog-box/airtime-swap-dialog.service';
import { AsTransactionsService } from './as-transactions.service';
import { catchError, exhaustMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';
@Component({
  selector: 'app-airtime-swap-transactions',
  templateUrl: './airtime-swap-transactions.component.html',
  styleUrls: ['./airtime-swap-transactions.component.css']
})
export class AirtimeSwapTransactionsComponent implements OnInit,OnDestroy{
  modalName = '';
  visible: boolean = false;
    searchText = '';
  isLoading = false;
  clearCheckedBox = true;
  checked = false;
  headerState = 'pending';
  data:any[] = [];
  status = '';
  start_date = '';
  end_date = '';
  start_amount = '';
  order_type:string = '';
  end_amount = '';
  toggleActive = false;
  page = 1
  first: number = 1;
  rows: number = 10;
  totalRecord = 20;
  selectedAllArray:any = []
selectedArray:any = []
private filterSubscription:any;
private apiKey:string = '';
private adminKey:string = '';
  constructor(private dialog:MatDialog,private http:HttpClient, private messageService:MessageService,private dialogService:AirtimeSwapDialogBoxService, private asTransactionsService:AsTransactionsService){
    // api key
    this.apiKey = environment.apiUrl;
    this.adminKey = environment.admin_key;
  }

  viewDetail(index:number){
    this.dialogService.id = this.data[index].id;
    this.showDialog('Airtime Swap')
  }


  toggle(){
    this.toggleActive = !this.toggleActive;
    if(this.toggleActive == true){
this.headerState = 'approved';
this.isLoading = true;
    }
   if(this.toggleActive == false) {
      this.headerState = 'pending';
       this.isLoading = true;
    }
    this.checked = false;
    this.getRequest().subscribe((res:any) => {
      this.isLoading = false;
      this.data = res.data;
     })
  }


  onPageChange(event:any) {
      this.first = event.first;
      this.page = event.page + 1
      this.rows = event.rows;
      this.isLoading = true;
      this.getRequest().subscribe((res:any) => {
        this.data = res.data;
        this.isLoading = false;
       })
  }
    allChecked(){
      this.checked = !this.checked
      this.selectedArray = [];
     for (let index = 0; index < this.data.length; index++) {
      if(!this.selectedAllArray.includes(this.data[index].id)){
        this.selectedAllArray.push(this.data[index].id)
      }
        
      }
    }
  selected(i:number){

if (!this.selectedArray.includes(this.data[i].id)) {
this.selectedArray.push(this.data[i].id);

} 

console.log(this.data[i].id)
    }
    approve(){
     
     if(this.selectedArray.length !== 0){
      this.isLoading = true;
      console.log(this.selectedArray)
      if(this.selectedArray.length == 1){
this.http.post(this.apiKey + '/zap/admin/transaction/flips/approve',{admin_key:this.adminKey,flip_id:this.selectedArray[0]}).subscribe({
  next:(res:any) => {
    console.log(res)
    this.filter()
    this.messageService.add({ severity: 'success', detail:res.message,life:2000 });
    console.log(this.selectedArray[0])
    this.selectedArray = [];
  },error:(res:any) => {
    this.messageService.add({ severity: 'warn', detail:res.message,life:2000 });
    this.isLoading = false;
  }
})    }
    else {
      this.http.post(this.apiKey + '/zap/admin/transaction/flips/bulk',{admin_key:this.adminKey,flip_id:this.selectedArray,is_approved: true}).subscribe({
        next:(res:any) => {
          console.log(res)
          this.filter()
          this.messageService.add({ severity: 'success', detail:res.message,life:2000 });
          console.log(this.selectedArray)
          this.selectedArray = [];
        },error:(res:any) => {
          this.messageService.add({ severity: 'warn', detail:res.message,life:2000 });
          this.isLoading = false;
        }
      })
    }
  
     }

     else {
      this.messageService.add({ severity: 'error', summary: 'Rejected', detail:'No id selected'});
     }
    }
    approveAll(){
      if(this.selectedAllArray.length !== 0){
        this.isLoading = true;
      this.http.post(this.apiKey + '/zap/admin/transaction/flips/bulk',{admin_key:this.adminKey,flip_id:this.selectedAllArray,is_approved: true}).subscribe({
        next:(res:any) => {
          console.log(res)
          this.messageService.add({ severity: 'success', detail:res.message,life:2000 });
          console.log(this.selectedAllArray)
          this.selectedArray = [];
          this.filter()
        },error:(res:any) => {
          this.messageService.add({ severity: 'warn', detail:res.message,life:2000 });
          this.isLoading = false;
        }
      })
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail:'No id selected'});
       }
    }

    cancel(){
 this.clearCheckedBox = true;
      this.checked = false;
    }

    decline(){
      if(this.selectedArray.length !== 0){
        this.isLoading = true;
      if(this.selectedArray.length == 1){
this.http.post(this.apiKey + '/zap/admin/transaction/flips/decline',{admin_key:this.adminKey,flip_id:this.selectedArray[0]}).subscribe({
  next:(res:any) => {
    console.log(res)
    this.filter()
    this.messageService.add({ severity: 'success', detail:res.message,life:2000 });
    this.selectedArray = [];
  },error:(res:any) => {
    this.isLoading = false;
    this.messageService.add({ severity: 'warn', detail:res.message,life:2000 });
  }
})
    }
    else {
      this.http.post(this.apiKey + '/zap/admin/transaction/flips/bulk',{admin_key:this.adminKey,flip_id:this.selectedArray,is_approved: false}).subscribe({
        next:(res:any) => {
          console.log(res)
          this.filter()
          console.log(this.selectedArray)
          this.messageService.add({ severity: 'success', detail:res.message,life:2000 });
          this.selectedArray = [];
        },error:(res:any) => {
          this.isLoading = false
          this.messageService.add({ severity: 'warning', detail:res.message,life:2000 });
        }
      })
    }
  }
  else {
    this.messageService.add({ severity: 'error', summary: 'Rejected', detail:'No id selected'});
   }
    }
   declineAll(){
    if(this.selectedAllArray.length !== 0){
      this.isLoading = true;
      this.http.post(this.apiKey + '/zap/admin/transaction/flips/bulk',{admin_key:this.adminKey,flip_id:this.selectedAllArray,is_approved: false}).subscribe({
        next:(res:any) => {
          console.log(res)
          this.messageService.add({ severity: 'success', detail:res.message,life:2000 });
          console.log(this.selectedAllArray)
          this.selectedArray = [];
          this.filter();
          this.checked = false;
        },error:(res:any) => {
          this.messageService.add({ severity: 'warn', detail:res.message,life:2000 });
        }
      })
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Rejected', detail:'No id selected'});
     }
    }

    filter(){
   this.filterSubscription = this.asTransactionsService.filter.pipe(tap((res:any) => {
        this.status = res.status == '' ? '' : '&status=' + res.status;
        this.order_type = res.order_type == '' ? '' : '&network=' + res.order_type;
        this.start_date = res.start_date == '' ? '' : '&start_date' + res.start_date.toISOString();;
        this.end_date = res.end_date == '' ? '' : '&end_date' + res.end_date.toISOString();;
        this.start_amount = '&start_amount=' + res.start_amount;
        this.end_amount = '&end_amount=' + res.end_amount;
        this.isLoading = true;
        console.log(res.start_amount + 'amount')
       }),exhaustMap((res:any) => {return this.getRequest(); 
      })).pipe(catchError(this.handleError)).subscribe({
        next:(res:any) => {
          this.isLoading = false;
          this.data = res.data;
          this.totalRecord = res.total;
          console.log(res)
        },
        error:(res) => {
          this.isLoading = false;
          setTimeout(() => {
            this.messageService.add({ severity: 'error', detail:res,life:2000 });
           }, 2000);
        }
      })
    }
    showDialog(name:string) {
      this.modalName = name;
        this.visible = true;
    }
    handleError(error:any) { let errorMessage = 'No Internet (transactions)';

if(error.error.message){
  errorMessage = error.error.message
}

  console.log(errorMessage);
  return throwError(() => {
      return errorMessage;
  });

}
    getRequest(){
      return this.http.get(this.apiKey + '/zap/admin/transaction/flips?page=' + this.page + '&' + 'limit' + '=' + this.rows + '&sort_by' + '=' + this.headerState + this.start_date + this.end_date + this.order_type + this.start_amount + this.end_amount + '&search_query=' + this.searchText)
    }
    ngOnInit(): void {
      this.isLoading = true;
 this.filter() 
    }
    search(){
      this.isLoading = true;
      this.getRequest().subscribe((res:any) => {
        this.data = res.data;
        this.totalRecord = res.total;
      this.isLoading = false
       })
      }
clear(){
  this.clearCheckedBox = false
  this.selectedArray = [];
  this.selectedAllArray = [];
}
    ngOnDestroy(): void {
   
     
      // Unsubscribe from the filter subscription to avoid memory leaks
      if (this.filterSubscription) {
        this.filterSubscription.unsubscribe();
      }
    }
}


