<div *ngIf="!isLoading">
    <div class="container">
        <header>
           <span></span>
            <p [class.active]="isActive(0)" (click)="toggleItem(0)">All</p>
            <p [class.active]="isActive(1)" (click)="toggleItem(1)">Closed</p>
            <p [class.active]="isActive(2)" (click)="toggleItem(2)">Pending</p>
            <p [class.active]="isActive(3)" (click)="toggleItem(3)">Resloved</p>
        </header>
        <div style="display: flex;  justify-content: center;padding: 40px;padding-top: 80px;" *ngIf="data == ''">
                  
            <div>
             <img src="../../../assets/Empty state illustratio (1).png" style="margin-left: 20px;" width="80" alt="">
             <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">There are no queries</h5>
            </div>
           </div>
        
                    <div class="message" *ngFor="let message of data;let i = index"  (click) = 'viewDetail(i)'>
                        <div style="display: flex;align-items: center;gap: 10px;padding: 10px;">
                            <p-avatar label="{{message.email | slice:0:1}}" styleClass="mr-2" size="large" shape="circle"  [style]="{ 'zoom': '60%' , 'font-family':'poppins', 'background-color':'#B5DDC3', 'color':'#11923D'}"></p-avatar>
                            <div>
                                <h4 style="margin: 0px;font-size: 13px;color: #1D242D;">{{message.name}}</h4>
                               <strong style="font-size: 12px;color:#3D4C5E;">{{message.title | titlecase}}</strong>
                               <p style="color: #909090;font-size: 11px;">{{ message.message | slice:0:10 }} {{ message.message.length > 10 ? '...' : '' }}</p>
                            </div>
                        </div>
                        <p style="color: #909090;font-size: 11px;">{{message.created_at | date:'shortTime'}}</p>
                        </div>
                       
    </div>
  
      <br>
        <div style="display: flex; justify-content: center;">
        <p-paginator  (onPageChange)="onPageChange($event)" [first]="first"  [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
      </div>
</div>
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 50px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
   