import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-foreign-exchange',
  templateUrl: './foreign-exchange.component.html',
  styleUrls: ['./foreign-exchange.component.css']
})
export class ForeignExchangeComponent implements OnInit{
isLoading = false;
checked:boolean = false;
private apiUrl:string = '';
constructor(private http:HttpClient, private messageService:MessageService){
  this.apiUrl = environment.apiUrl
}
activateChecked(){
this.http.post(this.apiUrl + '/zap/admin/configuration/toggle',{service:'conversion',status:this.checked}).subscribe({
  next:(res:any) => {
this.messageService.add({severity:'success', summary:'Conversion Toggle', detail:'' + this.checked})
  }
})
}

ngOnInit(): void {
  this.http.get(this.apiUrl + '/zap/admin/configuration/appsettings').subscribe({
    next:(res:any) => {
      this.checked = res.data.conversion
    }, error:(res:any) => {
      this.messageService.add({severity:'error', summary:'Rejected', detail:'An error occured'})
    }
  })
}
}
