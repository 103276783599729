import { Component } from '@angular/core';
import { KycCustomerDetailsService } from '../../kyc-request/kyc-customer-details/kyc-customer-details.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';
import { BvnCustomerDetailsService } from './bvn-customer-details.service';

@Component({
  selector: 'app-bvn-customer-details',
  templateUrl: './bvn-customer-details.component.html',
  styleUrls: ['./bvn-customer-details.component.css']
})
export class BvnCustomerDetailsComponent {
  data:any = {}
  userId:any = '';
  id:any = [];
  modalName = ''
  visible:boolean = false;
  visibleTwo:boolean = false;
  loading = false;
  preview = false;
  private apiKey:string = '';
constructor(private bvnDetailService:BvnCustomerDetailsService, private http:HttpClient, private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
showDialog(name:string) {
  this.modalName = name;
    this.visible = true;
}
showDialogTwo(name:string) {
  this.modalName = name;
    this.visibleTwo = true;
}
approve(){
  this.loading = true;
  this.http.post(this.apiKey + '/zap/admin/kyc/bvn/approve',{user_id:this.data.user_id}).subscribe({
    next:(res:any) => {
      this.messageService.add({ severity: 'info', summary:this.data.name, detail:res.message });
      location.reload();
    },
    error:(res:any) => {
      this.loading = false;
      let errorMessage = 'An error occurred'; // Default error message
      if (res.error && res.error.message) {
          errorMessage = res.error.message; // Use the error message from the response body
      }
      this.messageService.add({ severity: 'error', summary: 'Error!', detail: errorMessage });
    }
  })
}
onPreview(){
  this.preview = !this.preview;
}
ngOnInit(): void {
  this.bvnDetailService.kycUsersSubject.subscribe(res => {
    this.data = res;
    this.userId = [res.user_id];
    this.id = [res.id];
    console.log(res)
  })

  this.bvnDetailService.loading.subscribe(res => {
    this.loading = res;
  })
}

}
