import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { SaqMessageService } from '../saq-message/saq-message.service';
import { SaqMessagesService } from './saq-messages.service';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-saq-messages',
  templateUrl: './saq-messages.component.html',
  styleUrls: ['./saq-messages.component.css']
})
export class SaqMessagesComponent implements OnInit {
  sort = ''
  sortPath = ''
  selected:boolean = false;
  messageUser:any = {};
  page:number = 1;
  isLoading:boolean = false;
  data:any = []
  selectedItem: number | null = 0;
  @Input() searchText = '';
  private apiKey:string = '';
  constructor(private http:HttpClient,private saqService:SaqMessageService, private saqMessageService:SaqMessagesService, private messageService:MessageService){
    this.apiKey = environment.apiUrl;
  }
  isActive(index: number): boolean {
    return this.selectedItem === index;
  }

  toggleItem(index: number): void {
    this.selectedItem = this.selectedItem === index ? null : index;
    if(index == 0){
      this.sortPath = '';
      this.sort = ''
      this.fetchRequest(this.page,this.rows)
          }
    if(index == 1){
this.sort = 'closed'
this.fetchRequest(this.page,this.rows)
    }

    if(index == 2){
      this.sort = 'pending'
      this.fetchRequest(this.page,this.rows)
          }
          if(index == 3){
            this.sort = 'resolved'
            this.fetchRequest(this.page,this.rows)
                }
  }
 
  viewDetail(index:number){
  this.messageUser = this.data[index];
this.saqService.messageUsersSubject.next(this.messageUser);
  }


  toggle(){
  
  }
  first: number = 1;
  rows: number = 5;
  totalRecord = 20;
  onPageChange(event:any) {
    console.log(event.page + 1)
    this.first = event.first;
    this.page = event.page + 1;
    this.rows = event.rows;
    console.log(this.rows)
   
    this.fetchRequest(event.page + 1,this.rows)
  }
  fetchRequest(page:number,limit:number){
    this.isLoading = true
    this.saqService.loading.next(this.isLoading)
   if(this.sort !== ''){ this.sortPath = '&filter_by' + '=' + this.sort;}
    this.http.get(this.apiKey + '/zap/admin/support/?page=' + page + '&' + 'limit' + '=' + limit + this.sortPath + '&search_query=' + this.searchText ).subscribe(
      {
        next:(res:any)=> {
          this.totalRecord = res.data.total
          this.data = res.data.data;
          this.isLoading = false;
          this.saqService.loading.next(this.isLoading)
          console.log(res)
        },
        error:(res:any) => {
          this.isLoading = false
          this.saqService.loading.next(this.isLoading)
          this.messageService.add({ severity: 'error',summary:'Rejected', detail:'An error ocurred',life:2000 });
        }
      }
    )
      }

      ngOnInit(): void {
        this.saqService.loading.next(this.isLoading)
        this.isLoading = true;
      this.fetchRequest(this.first,this.rows)

      this.saqMessageService.search.subscribe(res => { this.fetchRequest(this.first,this.rows);})
      }
}
