import { Component, OnInit } from '@angular/core';
import { AuthPageService } from '../auth-page/auth-page.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit{
  role:string = '';
constructor(private authService:AuthPageService){}
  ngOnInit(): void {
    this.authService.roleName.subscribe((res:any) => {
      this.role = res.data.role.role_name
    })
  }
}
