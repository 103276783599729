import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog'
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthPageService } from '../auth-page/auth-page.service';
import { ProductChartService } from './product-chart/product-chart.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  role:string = ''
  items:MenuItem[] = [];
products =  ['Bills', 'Giftcards', 'Zappins'];
period = ['Today','One-week', 'Two-weeks', 'One-month']
Index = 0;
toggleActive = true;
toggleName = 'product'
getProductIndex(index:number){
this.Index = index
}
itemClicked(val:string){
  this.pcService.fetched.next(val)
}
toggle(name:string){
  this.toggleActive = !this.toggleActive;
  this.toggleName = name;

  if(name == 'period') {
    this.products = this.period
    this.Index = 0;
  }
  else {
    this.products = ['Bills', 'Giftcards', 'zappins'];
    this.Index = 0;
  }
}
constructor(private messageService: MessageService,private authService:AuthPageService, private pcService:ProductChartService) {}
    
ngOnInit() {
    this.items = [
        {
            items: [
                {
                    label: 'Bill Payment',
                    command: () => {
                     
                    }
                },
                {
                    label: 'Gift Card',
                    command: () => {
                     
                    }
                },
                {
                  label: 'Zap pin',
                  command: () => {
                   
                  }
              }
            ]
        },
    ];
    this.authService.roleName.subscribe((res:any) => {
      this.role = res.data.role.role_name
    })
}


}
