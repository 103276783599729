<div *ngIf="!isLoading">
  
    <div class="header-two">
      <h5>Transaction Status</h5>
    
      <p *ngIf="data.status.toLocaleLowerCase() == 'success' || data.status.toLocaleLowerCase() == 'successful' || data.status.toLocaleLowerCase() == 'completed'"><span class="completed">Successful</span></p>
      <p *ngIf="data.status.toLocaleLowerCase() == 'decline' || data.status.toLocaleLowerCase() == 'declined'"><span class="pending">Declined</span></p>
      <p *ngIf="data.status.toLocaleLowerCase() == 'reversed'"><span class="reversed">Reversed</span></p>
      <p *ngIf="data.status.toLocaleLowerCase() == 'failed'"><span class="pending">Failed</span></p>
    </div>
    <table class="table-one" style="width: 500px;">
      <tr>
        <th>Wallet Holder</th>
        <th>Customer Id</th>
        <th>Email</th>
      </tr>
      <tr>
        <td>{{data.user_full_name | titlecase}}</td>
        <td>
          <div style="display: flex; align-items: center;">
          <p>{{data.user_id | slice:0:8}} {{ data.user_id.length > 8 ? '...' : '' }}</p>
          <img (click)="openSnackBar(data.user_id)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
          </div>
        </td>
        <td>  <div style="display: flex; align-items: center;">
          <p>{{data.email}}</p>
          <img  (click)="openSnackBar(data.email)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
          </div></td>
    
      </tr>
      <tr>
        <th>Card ID</th>
        <th>Level</th>
        <th>Transaction ID</th>
      </tr>
      <tr>
        <td>{{data.card_id | slice:0:8}} {{ data.card_id.length > 8 ? '...' : '' }}</td>
        <td>Tier {{data.level}}</td>
        <td>
            <div style="display: flex; align-items: center;">
            <p>{{data.reference | slice:0:8}} {{ data.reference.length > 8 ? '...' : '' }}</p>
            <img (click)="openSnackBar(data.reference)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
            </div>
          </td>
        
      
    
      </tr>
      <tr>
        <th>Order Type</th>
        <th>Card Type</th>
        <th>Merchant</th>
      </tr>
      <tr>
        <td><div class="data-group"><img src="../../../../assets/Icons (1).png" style="margin-right: 4px;" width="20" alt="">Card Transactions</div></td>
        <td>{{data.card_type | titlecase}}</td>
        <td>{{data.receipient | titlecase}}</td>
      </tr>
      <tr>
        <th>Amount</th>
        <th>Transaction Fee</th>
        <th>Date &Time</th>
      </tr>
      <tr>
        <td>${{data.amount | number}}</td>
        <td>${{data.fee | number}}</td>
        <td>{{data.date | date}} & {{data.date | date:'shortTime' }}</td>
      </tr>
      <tr>
        <th>Balance Before</th>
        <th>Balance After</th>
      </tr>
      <tr>
        <td>${{data.balance_before | number}}</td>
        <td>${{data.balance_after | number }}</td>
      </tr>
    </table>
    </div>
    <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoading'>
      <app-spinner ></app-spinner>
      </div>
    