import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';
interface City {
  name: string;
}
@Component({
  selector: 'app-email-dialog-box',
  templateUrl: './email-dialog-box.component.html',
  styleUrls: ['./email-dialog-box.component.css']
})
export class EmailDialogBoxComponent {
  isLoading = false;
  textBoxLogic = 'email';
  citiesTwo: City[] | any;
  citiesOne: City[] | any;
  selectedCityTwo: City | any = '';
  selectedCityOne: City | any = '';
title:string = '';
content:string = ''
private apiKey:string = ''
constructor(private http:HttpClient,private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
  cancel(){

  }

  sendMessage(){
    this.isLoading = true;
this.http.post(this.apiKey + '/zap/admin/notification/',{title:this.title,content:this.content,audience:this.selectedCityTwo.name,notification_type:this.selectedCityOne.name}).subscribe({
  next:(res:any) => {
    this.messageService.add({ severity: 'info', summary:'Successful', detail:res.message});
    this.clearMessage();
    this.isLoading = false;
  },
  error:(res:any) => {
    this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message });
    this.isLoading = false;
    this.clearMessage();
  }
})
  }

  clearMessage(){
this.content = '';
this.title = '';
this.selectedCityTwo = {name:''}
this.selectedCityOne = {name:''}
  }
  switchTextbox(val:any){
if(val.name == 'push' || val.name == 'email'){
this.textBoxLogic = val.name;
}
  }
  ngOnInit() {
      this.citiesTwo = [
        { name: 'all'},
        { name: 'verified',},
        {name:'unverified'},
        { name: 'tier 2'},
        { name: 'tier 3',},
        {name:'tier 4'},
    ];
    this.citiesOne = [
      { name: 'email'},
      {name:'push'}
  ];
  }
}
