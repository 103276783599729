<div *ngIf="!isLoading">
    <div class="header-two">
      <img src="../../../assets/search.png" alt="">
      <input type="text" placeholder="Search by....." (keyup.enter)="search()"  [(ngModel)]="searchText">
  </div>
  <br>
  <div *ngIf="data.length !== 0">
    
  <div class="header-four">
    <div class="recent-transaction">
    <table>
     <thead>
        <tr>
            <th>Lock Id</th>
            <th>Name </th>
            <th>Balance</th>
            <th>End Date</th>
            <th>Date Closed</th>
            <th>Lock Status</th>
            <th>Withdrawal Status</th>
        </tr>
     </thead>
    
       <tbody>
        <tr *ngFor="let transactions of data; let i =index" >
            <td (click) = 'viewDetail(i)'>{{transactions.lock_id}}</td>
                  <td (click) = 'viewDetail(i)'>{{transactions.name}}</td>
                  <td (click) = 'viewDetail(i)'>₦{{transactions.balance | number}}</td>
                  <td (click) = 'viewDetail(i)'>{{transactions.end_date | date}}</td>
                  <td (click) = 'viewDetail(i)'>{{transactions.date_closed | date}}</td>
                  <td (click) = 'viewDetail(i)'><p><span class="completed">{{transactions.is_closed ? 'Closed' : 'Not Closed'}}</span></p></td>
                  <td (click) = 'viewDetail(i)'><p><span class="completed">{{transactions.withdrawal_occurred ? 'Withdrawal' : 'No Withdrawal'}}</span></p></td>
             
      </tr>
       </tbody>
    </table>
    <br>
    <div style="display: flex; justify-content: space-between;align-items: center;">
      <p style="color: #151A20;font-weight: 500;font-size: 13px;">Showing 1-10 of {{this.page}} results</p>
      <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
    </div>
    </div>
    </div>
    </div>
  </div>
  <div style="display: flex;  justify-content: center;padding:80px;position:relative; right:100px;top: 0px;" *ngIf="data.length == 0 && !isLoading">
    
    <div>
     <img src="../../../assets/Empty state illustratio.png" style="margin: 0 auto;display: block;padding-bottom:10px;" width="80" alt="">
     <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">No records to view yet.</h5>
     <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Transaction records will appear here once customers make purchases.</p>
    </div>
   </div>
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 100px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    <p-toast></p-toast>
    <p-dialog [header]="modalName"  [(visible)]="visible" [modal]="true" [style]="{ width: '60vw' }"[position]="'topright'" [maximizable]="true" [draggable]="false" [resizable]="false">
      <app-lock-history [lockId]="lockId" *ngIf="visible"  style="font-family: poppins;"></app-lock-history>
    </p-dialog>
    