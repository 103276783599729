<div *ngIf="!isLoading">
    <div class="container">
        <h1 style="padding-bottom: 0px;margin: 0px; width:500px;"></h1>
        <div class="header-two">
                
    <p>USD TO NGN</p>
    <input  type="number" placeholder="USD" [(ngModel)] = 'usd_ngn'>
    <p>NGN TO USD</p>
    <input type="number" placeholder="NGN" [(ngModel)]="ngn_usd">

    <p>FX FEE</p>
    <input type="number" placeholder="NGN" [(ngModel)]="fx_fee">
    <p>MIN USD CONVERSION</p>
    <input type="number" placeholder="USD" [(ngModel)] = 'min_usd'>
    <p>MIN NGN CONVERSION</p>
    <input type="number" placeholder="NGN" [(ngModel)]="min_ngn">
    <p>FX USD CAP</p>
    <input type="number" placeholder="USD" [(ngModel)]="fx_usd">
    <p>FX NGN CAP</p>
    <input type="number" placeholder="NGN" [(ngModel)] = 'fx_ngn'>
    <p>FUNDING FEE</p>
    <input type="number" placeholder="NGN" [(ngModel)]="funding_fee">
    <div  class="header-eight">
     
        <button class="filter" >Clear</button>
        <button class="apply" (click)="update()" [disabled]="ngn_usd == data.ngn_usd && usd_ngn == data.usd_ngn && fx_fee == data.fx_fee && min_usd == data.min_usd && min_ngn == data.min_ngn && fx_usd == data.fx_usd && fx_ngn == data.fx_ngn && funding_fee == data.funding_fee">Update FX</button>
      </div>
</div>
</div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
  