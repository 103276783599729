import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthPageService } from './auth-page/auth-page.service';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit{

  login = false;
constructor(private route:Router, private authService:AuthPageService){
  console.log(process.env['API_KEY'])
}

ngOnInit(): void {
  this.authService.autoLogin();
  let dataInString:any = localStorage.getItem('roleData');
  let roleData = JSON.parse(dataInString)
  this.authService.roleName.next(roleData)
  if(this.route.url == '/'){
this.login = true;
  }
  else {
    this.login = false;
  }
  

}


}

