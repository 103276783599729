import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-ab-transactions',
  templateUrl: './ab-transactions.component.html',
  styleUrls: ['./ab-transactions.component.css']
})
export class AbTransactionsComponent {
  start_date= new Date();
  end_date = new Date();
  searchText = ''
  modalName = ''
  visible:boolean = false;
  isLoading:boolean = false;
  is_active:any[] = []
  data:any = [];
  state = false;
  stateView:boolean = false;
  subIndex = 0;
  singleData:any;
  private apiKey:string = '';
constructor(private http:HttpClient,private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
  viewDetail(id:number){
   this.singleData = this.data[id];
   this.visible = true;
  }
      getRequest(){
 this.http.get(this.apiKey + '/zap/admin/business/business').subscribe({
  next:(res:any) => {
    this.isLoading = false;
    this.data = res.data;
    for (let index = 0; index < res.data.length; index++) {
    if(res.data[index].sendzap_subscription){
      this.is_active.push(res.data[index].sendzap_subscription.is_active)
    }
    else {
      this.is_active.push('')
    }
      console.log(this.is_active)
    }
  }
 })
      }
delete(index:number){
this.http.post(this.apiKey + '/zap/admin/business/business/' + this.data[index].user_id,{user_id:this.data[index].user_id}).subscribe({
  next:(res:any) => {
location.reload()
  }
})
      }
      isActive(index:number){
        this.subIndex = index;
        this.state = true;
        this.stateView = this.is_active[index]
      }

      isActiveRequest(){
        let data = this.data[this.subIndex]
        this.http.patch(this.apiKey + '/zap/admin/business/business/subscription',{user_id:data.user_id,start_date:this.start_date.toISOString(),end_date:this.end_date.toISOString()
        ,is_active:this.stateView}).subscribe({
          next:(res:any) => {
     
          this.state = false;
          }
        })
    
      }
      close(){
        this.state = false;
        this.is_active[this.subIndex] = !this.is_active[this.subIndex]
      }
      ngOnInit(): void {
        this.isLoading = true;
        this.getRequest()
      }
   
}
