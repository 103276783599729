import { AuthPageService } from "./auth-page.service";
import {inject} from '@angular/core'
import { Router } from "@angular/router";
import {map} from 'rxjs'
import {tap} from 'rxjs'
export const AuthGuard = ()=> {
    const authService = inject(AuthPageService)
    const route = inject(Router)
    return authService.user.pipe(map(user => {
        return !!user
    }),tap(isAuth => {
        if(!isAuth){
            route.navigate(['/login'])
        }
    }))
}