<div *ngIf = '!isLoading'>
    <div class="header-two">
        <h4>Overview</h4>
    
       <nav>
          <div class="filter-group" (click) = 'openDialog()'>
              <p>Filter</p>
              <img src="../../assets/Stockholm-icons (9).png" alt="">
          </div>
    
          <div class="currency"  *ngIf="role == 'Administrator'">
      <img *ngIf="usdToNgn" src="../../assets/united states.png" alt="">
      <img *ngIf="!usdToNgn" src="../../assets/nigeria.png" alt="">
      <p *ngIf="usdToNgn">USD <strong>$1</strong></p>
      <p *ngIf="!usdToNgn">NGN <strong>₦1</strong></p>
      <img src="../../assets/Stockholm-icons (13).png" (click)="switch()" style="padding-left:12px;" alt="">
      <img *ngIf="usdToNgn" src="../../assets/nigeria.png" alt="">
      <p *ngIf="usdToNgn">NGN {{overview.usd_to_ngn_rate}}</p>
      <img *ngIf="!usdToNgn" src="../../assets/united states.png" alt="">
      <p *ngIf="!usdToNgn" >USD {{overview.ngn_to_usd_rate}}</p>
      <img src="../../assets/Line 3.png" style="padding-left: 10px;" width="2" height="25" alt="">
      <img src="../../assets/Stockholm-icons (7).png" style="padding-left: 0px;" alt="" routerLink="/configurations/foreign-exchange">
          </div>
      </nav>
    </div>
    <div class="payment-manager" *ngIf="role == 'Administrator'">
        <div class="card">
            <img src="../../assets/Widget icons (5).png" alt="">
            <p>Total Users</p>
            <h4> {{overview.total_active_users | number}}</h4>
        </div>
        <div class="card" >
            <img src="../../assets/Widget icons (7).png" alt="">
            <p>Total Balance</p>
            <h4>₦ {{overview.total_balance | number}}</h4>
        </div>
        <div class="card">
            <img src="../../assets/Widget icons.png" alt="">
            <p>Transfer Wallet Balance</p>
            <h4>₦ {{overview.transfer_balance | number}}</h4>
        </div>
        <div class="card">
            <img src="../../assets/Widget icons (3).png" alt="">
            <p>Total Transactions</p>
            <h4> {{overview.total_transaction_count | number}}</h4>
        </div>
       
        <div class="card">
            <img src="../../assets/Widget icons (7).png" alt="">
            <p>Transaction Volume</p>
            <h4>₦  {{overview.total_transaction_sum | number}}</h4>
        </div>
    </div>

</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
<p-toast *ngIf="role == 'Administrator'"></p-toast>

    <p-dialog [header]="modalName" position="center" [maximizable]="true" [(visible)]="visible" [modal]="true" [style]="{ width: '35vw', 'font-family':'poppins', height:'100%'}" [draggable]="false" [resizable]="false">
        <app-dialog-box *ngIf="visible" style="font-family: poppins;"></app-dialog-box>
    </p-dialog>