
<div>
    <div class="header-one" >
        <h4>Bills Transaction</h4>
    
       <nav>
          <div class="filter-group" (click) = 'openDialog()'>
              <p>Filter</p>
              <img src="../../assets/Stockholm-icons (9).png" alt="" >
          </div>
    <img src="../../../assets/Stockholm-icons (15).png" style="margin-left: 20px;" alt="" (click)="reload()">
      </nav>
    </div>
    <div *ngIf = '!isLoading'>
    <div class="payment-manager" *ngIf="role == 'Administrator'">
        <div class="card">
            <img src="../../assets/Widget icons (7).png" alt="">
            <p>Total Balance</p>
            
            <h4>₦{{overview.totalBalance | number}}</h4>
        </div>
        <div class="card">
            <img src="../../assets/Widget icons.png" alt="">
            <p>Total Transactions</p>
            <h4>{{overview.totalAmount | number}}</h4>
        </div>
    </div>
    </div>
</div>

<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    <p-dialog [header]="modalName" [maximizable]="true" [(visible)]="visible" [modal]="true" [style]="{ width: '35vw',height:'100%' }" [draggable]="false" [resizable]="false">
        <app-bills-filter-modal style="font-family: poppins;"></app-bills-filter-modal>
      </p-dialog>