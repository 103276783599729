<div class="container" *ngIf="!isLoading">
   <div style="display: flex;justify-content: space-between;align-items: center;">
    <h4>Social media links</h4>
    <button (click) = 'save()' class="btn-message" *ngIf="instagram !== data.instagram || facebook !== data.facebook || twitter !== data.twitter || email !== data.email || chaturl !== data.chat_url">Save</button>
   </div>
      <div class="inputWithIcon">
        <p>Instagram</p>
        <input type="text" [(ngModel)] = 'instagram'>
      </div>
      <div class="inputWithIcon">
        <p>Facebook</p>
        <input type="text" [(ngModel)] = 'facebook'>
      </div>
      <div class="inputWithIcon">
        <p>Twitter</p>
        <input type="text" [(ngModel)] = 'twitter'>
      </div>
      <h4>Account information</h4>
    <div>
        <div class="inputWithIcon">
            <p>Email Address</p>
            <input type="text" [(ngModel)] = 'email'>
          </div>
    </div>
      <div class="inputWithIcon">
        <p>Chat Url</p>
        <input type="text" [(ngModel)] = 'chaturl'>
      </div>
   
</div>

<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 100px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>