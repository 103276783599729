<div *ngIf="!isLoading">
    <div class="header-one">
      <div class="card">
          <div>
             
             <h5 style="color: #1D242D;margin-bottom: 2px;">Conversion</h5>
             <p style="color: #546881;margin-top: 0px;font-size: 12px;">Manage forex configuration, price, purchase fee.</p>
          </div>
                 <div style="display: flex;align-items: flex-start;gap: 10px;">
  <p-inputSwitch [(ngModel)]="checked" (click) = 'activateChecked()'></p-inputSwitch>
                 </div>
             </div>
  </div>
<app-fx-overview></app-fx-overview>
<app-fx-transactions></app-fx-transactions>
<div style="padding: 10px;width: 90%;border: 1px solid #E7E7E8;margin:20px;border-radius: 7px;">
    <div class="header-one">
        <h4>Total Transaction Volume
            Today
            NGN-USD</h4>
    </div>
    <app-fx-chart></app-fx-chart>
</div>