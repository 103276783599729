import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-fx-transactions',
  templateUrl: './fx-transactions.component.html',
  styleUrls: ['./fx-transactions.component.css']
})
export class FxTransactionsComponent implements OnInit{
visible:boolean = false;
modalName = '';
isLoading = false;
data:any = {ngn_usd:0,usd_ngn:0,fx_fee:0,min_usd:0,min_ngn:0,fx_usd:0,fx_ngn:0,funding_fee:0}
private apiKey:string = '';
  constructor(private http:HttpClient){
    this.apiKey = environment.apiUrl;
  }
showDialog(name:string) {
  this.modalName = name;
    this.visible = true;
}
fetchRequest(){
  this.isLoading = true;
  this.http.get(this.apiKey + '/zap/admin/configuration/fx').subscribe({
    next:(res:any) => {
      console.log(res)
      this.isLoading = false;
      this.data.ngn_usd = res.data.ngn_to_usd;
      this.data.usd_ngn = res.data.usd_to_ngn;
      this.data.fx_fee = res.data.fx_fee; 
      this.data.min_usd = res.data.min_usd_conversion;
      this.data.min_ngn = res.data.min_ngn_conversion;
      this.data.fx_usd = res.data.fx_usd_cap;
      this.data.fx_ngn = res.data.fx_ngn_cap;
      this.data.funding_fee = res.data.funding_fee;
    },error:(res:any) => {
      this.isLoading = false;
    }
  })
}
updated(){
  this.fetchRequest();
  this.visible = false;
}
ngOnInit(): void {
  this.isLoading = true;
this.fetchRequest()
}
}
