import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-data-plan',
  templateUrl: './data-plan.component.html',
  styleUrls: ['./data-plan.component.css']
})
export class DataPlanComponent implements OnInit{
  filterName:string = 'MTN';
  data:any = []
  isLoading = false;
  modalName = '';
  networkId:number = 0
  visible:boolean = false;
  visibleTwo:boolean = false;
  singleData:any ={}
  private apiKey:string = '';
  constructor(private http:HttpClient, private route:Router){
    this.apiKey = environment.apiUrl;
  }
viewDetail(index:number){
  this.showDialog(this.filterName + ' (Update Data Plan)')
  this.singleData = this.data[index];
}

mtn(){
  this.route.navigate(['/configurations/internet/data-plan/mtn']);
this.filterName = 'MTN'
this.fetchNetworkData()
}
glo(){
  this.route.navigate(['/configurations/internet/data-plan/glo']);
  this.filterName = 'GLO'
  this.fetchNetworkData()
}
airtel(){
  this.route.navigate(['/configurations/internet/data-plan/airtel']);
  this.filterName = 'AIRTEL'
  this.fetchNetworkData()
}
mobile(){
  this.route.navigate(['/configurations/internet/data-plan/9mobile']);
  this.filterName = '9MOBILE'
  this.fetchNetworkData()
}
fetchNetworkData(){
  this.isLoading = true;
  this.http.get(this.apiKey + '/zap/admin/configuration/data-plans?network=' + this.filterName).subscribe({
    next:(res:any) => {
      this.isLoading = false;
      this.data = res.data
      console.log(res)
    },error:(res:any) => {
this.isLoading = false;
    }
  })
}
showDialog(name:string) {
  this.modalName = name;
    this.visible = true;
}
showDialogTwo(){
  this.visibleTwo = true;
}
delete(index:number){
  this.http.post(this.apiKey + '/zap/admin/configuration/delete/data-plans',{id:this.data[index].id}).subscribe(res => {this.fetchNetworkData()})
}
upadated(){
  this.fetchNetworkData()
  this.visible = false;
}
Added(){
  this.fetchNetworkData()
}

active(url:string){
  if(this.route.url == url){
return true
  }
  else {
    return false
  }
}
ngOnInit(): void {
  this.isLoading = true;
this.fetchNetworkData()
}
}
