<div class="container">
    <h4 style="font-size: 15px;margin-bottom: 2px;color:#2E1A52;">Step 3</h4>
    <h4 style="font-size: 14px;margin-bottom: 2px;color:#2E1A52;margin-top: 4px;">Card Information</h4>
    <p style="font-size: 12px;margin-top: 0px;color: #546881;">Enter information about card</p>
      <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Minimum Balance</p>
      <div class="input">
      <input type="number" placeholder="0%" [(ngModel)] = "minimumBalance">
    </div> 
    <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Maximum Balance</p>
    <div class="input">
    <input type="number" placeholder="0%" [(ngModel)]="maximumBalance">
  </div> 
<p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Monthly Transaction Amount</p>
<div class="inputWithIcon">
<input type="number" placeholder="0" [(ngModel)]="monthlyTransactionAmount">
<img src="../../../../assets/united-state-2.png" width="60" alt="">
</div> 
<p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Initial Funding</p>
<div class="inputWithIcon">
<input type="number" placeholder="0" [(ngModel)]="initialFunding">
<img src="../../../../assets/united-state-2.png" width="60" alt="">
</div> 
<p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Maximum Funding</p>
<div class="inputWithIcon">
<input type="number" placeholder="0" [(ngModel)]="maximumFunding">
<img src="../../../../assets/united-state-2.png" width="60" alt="">
</div> 
  <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Minimum Funding</p>
        <div class="inputWithIcon">
        <input type="number" placeholder="0" [(ngModel)]="minimumFunding">
        <img src="../../../../assets/united-state-2.png" width="60" alt="">
      </div> 
      <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Secure Card</p>
      <p-inputSwitch [(ngModel)]="secure"></p-inputSwitch>
      <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Can Create Card</p>
      <p-inputSwitch [(ngModel)]="can_create"></p-inputSwitch>
      <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Can Fund Card</p>
      <p-inputSwitch [(ngModel)]="can_fund"></p-inputSwitch>
      <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Can Withdrwal Card</p>
      <p-inputSwitch [(ngModel)]="can_withdrawal"></p-inputSwitch>
      <div class="container-three">
        <button class="filter" routerLink="/configurations/virtual-card/step-two">Back</button>
        <button class="apply" (click)="save()">Save</button>
    </div>
   </div>