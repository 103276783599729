import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-message',
  templateUrl: './email-message.component.html',
  styleUrls: ['./email-message.component.css']
})
export class EmailMessageComponent implements OnInit {
  text = '';
  data:any = {}
  constructor(private http:HttpClient){}
ngOnInit(): void {
  let dataInString:any = localStorage.getItem('userId');
  this.data = JSON.parse(dataInString)
  console.log(this.data)
}
}
