<div  *ngIf="data.length !== 0 && !isLoading">
    

<div *ngIf="!isLoading">
    <!-- <div class="header-three">
    
      
        <table>
            <tr  class="boredred">
                <th>
                    <div style="display: flex; align-items: center;">
                        <img src="../../../../assets/Frame 40462.png" width="50" alt="">
                        <div style="padding-left: 7px;">
                            <p style="margin: 0px; font-size: 12px;color: #151A20;font-weight: 600;">Yussuf Ahmed</p>
                            <span style="font-weight: 400;margin: 0px;font-size: 12px;">Tier 1</span>
                        </div>
                    </div>
                </th>
                <th>
                    <div class="btn-group-approved">
                        <img src="../../../../../assets/approved.png" width="25" alt="">
    <p>KYC Approved</p>
                    </div>
                </th>
       
                <th>
                    <div class="btn-group-message">
                        <img src="../../../../../assets/message.png" width="25" alt="">
    <p>Message</p>
                    </div>
                </th>
                <th>
                    <div class="btn-group-upgrade">
                        <img src="../../../../../assets/upgrade.png" width="25" alt="">
    <p>Upgrade Customer</p>
                    </div>
                </th>
                <th>
                    <div class="btn-group-suspend">
                        <img src="../../../../../assets/suspend.png" width="25" alt="">
    <p>Suspend Customer</p>
                    </div>
                </th>
            </tr>
           
     
        </table>
    </div> -->
    
    <br>
    <div class="header-four">
        <div class="recent-transaction">
        <table>
         <thead>
            <tr>
                <th>Title</th>
                <th>Message</th>
                <th>Type</th>
                <th>Date created</th>
            </tr>
         </thead>
        <tr *ngFor="let message of data">
            <td>{{message.title | titlecase}}</td>
            <td>{{ message.content | slice:0:10 }} {{ message.content.length > 10 ? '...' : '' }}</td>
            <td>{{message.type | titlecase}}</td>
            <td>{{message.date | date}}</td>
    
        </tr>
           <tbody>
            </tbody>
        </table>
        <br>
    
        </div>
    </div>
</div>
<div style="display: flex; justify-content: right;">
    <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
  </div>
</div>
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 100px;" *ngIf="data.length == 0 && !isLoading">
  
        <div>
         <img src="../../../assets/Empty state illustratio.png" style="margin-left: 20px;" width="80" alt="">
         <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">There is no data here</h5>
         <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Message Goes here</p>
        </div>
       </div>