import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailDialogBoxComponent } from '../email-dialog-box/email-dialog-box.component';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';
@Component({
  selector: 'app-email-overview',
  templateUrl: './email-overview.component.html',
  styleUrls: ['./email-overview.component.css']
})
export class EmailOverviewComponent {
  data:any = [];
  isLoading = false;
  page:number = 1
  select = false;
  modalName = ''
  visible:boolean = false;
messageContent:any = '';
private apiKey:string = '';
  constructor(private http:HttpClient,private messageService:MessageService, private route:Router){
    this.apiKey = environment.apiUrl;
  }

first: number = 1;
rows: number = 10;
totalRecord = 20;
viewDetail(index:number) {
  localStorage.setItem('userId',JSON.stringify(this.data[index]));
  this.route.navigate(['/email/message'])
}
showDialog(name:string) {
  this.modalName = name;
    this.visible = true;
}
onPageChange(event:any) {

  console.log(event.page + 1)
  this.page = event.page + 1
  this.first = event.first;
  this.rows = event.rows;
  this.fetchRequest(event.page + 1,this.rows)
}
fetchRequest(page:number,limit:number){
  this.isLoading = true;
  this.http.get(this.apiKey + '/zap/admin/notification/?page=' + page + '&' + 'limit' + '=' + limit).pipe(catchError(this.handleError)).subscribe(
    {
      next:(res:any)=> {
        this.totalRecord = res.data.total
        this.data = res.data.data
        this.isLoading = false;

      },
      error:(res:any) => {
        this.isLoading = false
       this.messageService.add({ severity: 'error', detail:res,life:2000 })
      }
    }
  )
    }

    handleError(error:any){ let errorMessage = 'No Internet (email)';

    if(error.error.message){
      errorMessage = error.error.message
    }
  
      console.log(errorMessage);
      return throwError(() => {
          return errorMessage;
      });
    }
    ngOnInit(): void {
      this.isLoading = true;
    this.fetchRequest(this.first,this.rows)
    }
    message(){
      this.showDialog('Email')
      }

      delete(index:number){
this.http.post(this.apiKey + '/zap/admin/notification/delete',{id:this.data[index].id}).subscribe({
  next:(res:any) => {
    this.messageService.add({ severity: 'info', summary:'Deleted', detail:'Message Sent'});
    this.fetchRequest(this.page,this.rows)
  },
  error:(res:any) => {
    this.messageService.add({ severity: 'error', summary:'Could Not Delete', detail:res.message});
  }
})
      }
}
