import { Component } from '@angular/core';

@Component({
  selector: 'app-user-management-header',
  templateUrl: './user-management-header.component.html',
  styleUrls: ['./user-management-header.component.css']
})
export class UserManagementHeaderComponent {
  visible: boolean = false;
  modalName = '';
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
}
