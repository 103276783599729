<div class="container">
    <h4 style="font-size: 15px;margin-bottom: 2px;color:#2E1A52;">Step 2</h4>
    <h4 style="font-size: 14px;margin-bottom: 2px;color:#2E1A52;margin-top: 4px;">Card Fee</h4>
    <p style="font-size: 12px;margin-top: 0px;color: #546881;">Enter information about card</p>
    <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Creation Fee</p>
        <div class="inputWithIcon">
        <input type="number" placeholder="0" [(ngModel)]="creationFee">
        <img src="../../../../assets/united-state-2.png" width="60" alt="">
      </div> 
      <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Funding Fee</p>
      <div class="input">
      <input type="number" placeholder="0%" [(ngModel)]="fundingFee">
    </div> 
    <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Withdrawal Fee</p>
    <div class="input">
        <input type="number" placeholder="0%" [(ngModel)]="withdrawalFee">
      </div> 
  <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Decline Fee</p>
  <div class="inputWithIcon">
  <input type="number" placeholder="0" [(ngModel)]="declineFee">
  <img src="../../../../assets/united-state-2.png" width="60" alt="">
</div> 
<p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Maintenance Fee </p>
<div class="inputWithIcon">
<input type="number" placeholder="0" [(ngModel)]="maintenanceFee">
<img src="../../../../assets/united-state-2.png" width="60" alt="">
</div> 
<p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Transaction Fee</p>
<div class="inputWithIcon">
<input type="number" placeholder="0" [(ngModel)]="transactionFee">
<img src="../../../../assets/united-state-2.png" width="60" alt="">
</div> 
  <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Provider Withdrawal Fee</p>
        <div class="inputWithIcon">
        <input type="number" placeholder="0" [(ngModel)]="providerWithdrawalFee">
        <img src="../../../../assets/united-state-2.png" width="60" alt="">
      </div> 
      <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Provider Funding Fee</p>
      <div class="inputWithIcon">
      <input type="number" placeholder="0" [(ngModel)]="providerFundingFee">
      <img src="../../../../assets/united-state-2.png" width="60" alt="">
    </div> 
      <div class="container-three">
        <button class="filter" routerLink="/configurations/virtual-card/step-one">Back</button>
        <button class="apply" (click)="save()">Save and Continue</button>
    </div>
   </div>