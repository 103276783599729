<div class="header-one">
    <h4>Virtual Card</h4>

   <nav>
      <div class="filter-group" (click)="showDialog()">
          <p>Create Card</p>
      </div>
<img src="../../../assets/Stockholm-icons (15).png" style="margin-left: 20px;" alt="" (click)="reload()">
  </nav>
</div>
<div class="header-four" *ngIf="!isLoading">
<div class="recent-transaction">
<table>
 <thead>
    <tr>
        <th>Image</th>
        <th>Issuer</th>
        <th>Currency Type</th>
        <th>Brand Name</th>
        <th>Card Type</th>
        <th></th>
    </tr>
 </thead>

   <tbody>
    <tr *ngFor="let transactions of data; let i = index">
        <td (click)="viewDetail(i)"><img [src]="transactions.image" width="50" alt=""></td>
        <td (click)="viewDetail(i)">{{transactions.name | titlecase }}</td>
        <td (click)="viewDetail(i)">{{transactions.currency | titlecase }}</td>
              <td class="order-type" (click)="viewDetail(i)">
                <img *ngIf="transactions.brand == 'verve' || transactions.brand == 'VERVE'" src="../../../assets/VERVE 2.png" width="30" alt="">
                <img *ngIf="transactions.brand == 'mastercard' || transactions.brand == 'MASTERCARD' " src="../../../assets/MASTERCARD.png" width="30" alt="">
                <img *ngIf="transactions.brand == 'visa' || transactions.brand == 'VISA'" src="../../../assets/CARD.png" width="30" alt="">{{transactions.brand | titlecase }}</td>
             <td>{{transactions.type | titlecase }}</td>
        <td  [matMenuTriggerFor]="menu"><img src="../../../assets/Title (1).png" width="35" alt="">
        
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="delete(i)">
              <span style="font-family: poppins; font-size: 14px;">Delete</span>
            </button>
          </mat-menu></td>
          </tr>
   </tbody>
</table>

</div>
<p-dialog  header="Virtual Card Creation" [(visible)]="visible" [modal]="true" [style]="{ width: '60vw', 'font-family':'poppins' }" [draggable]="false" [resizable]="false" [maximizable]="true">
<div class="container">
    <div class="sidenav">
        <h4 style="color: #1D242D;margin-bottom: 2px;">Creation Steps</h4>
        <p style="color: #546881;margin-top: 0px;">Complete process to setup cards.</p>
        <div class="card" [class.active] = 'activeClass("/configurations/virtual-card/step-one")' [class.checked] = 'checkedToggle()'>
            <p>1. Card Information</p>
        </div>
        <div [class.active] = 'activeClass("/configurations/virtual-card/step-two")' [class.checked] = 'checkedToggleTwo()' class="card">
            <p>2. Card fee</p>
        </div>
        <div [class.active] = 'activeClass("/configurations/virtual-card/step-three")' [class.checked] = 'checkedToggleThree()'  class="card">
            <p>3. Card Limits</p>
        </div>
        <div [class.active] = 'activeClass("/configurations/virtual-card/preview")' class="card">
            <p>4. Preview</p>
        </div>
    </div>
   <router-outlet></router-outlet>
</div>
</p-dialog>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    <p-toast></p-toast>