<div class="container">  
    <div class="header-four">
     <div class="card">
         <h5 style="margin-top: 0px;">Start</h5>
         <div  style="zoom: 80%;"><p-calendar [(ngModel)]="date" [touchUI]="false" [readonlyInput]="true" [placeholder]="'dd/mm/yy'"></p-calendar> </div>   
 
     </div>
     <div class="card">
         <h5 style="margin-top: 0px;">End</h5>
 
        <div  style="zoom: 80%;"> <p-calendar [(ngModel)]="dateTwo" [touchUI]="false" [readonlyInput]="true" [placeholder]="'dd/mm/yy'"></p-calendar></div>
     </div>
     
       </div>
       <h5 style="font-weight: 600;font-size: 14px;margin-bottom: 0px;margin-top: 3px;">Amount</h5>
       <div class="header-four">
         <div>
           <h5>From</h5>
           <div  style="zoom: 80%;"> <p-inputNumber [(ngModel)]="amountOne" [style]="{'font-size':'5px'}" inputId="currency-ngn" mode="currency" currency="NGN" locale="en-US" [placeholder]="'NGN'"> </p-inputNumber></div>
         </div>
         <div>
           <h5>To</h5>
         
             <div  style="zoom: 80%;"><p-inputNumber  [(ngModel)]="amountTwo" inputId="currency-ngn" mode="currency" currency="NGN" locale="en-US" [placeholder]="'NGN'"> </p-inputNumber></div>
            </div>
         
           </div>
           <br>
           <div class="header-seven">
            <p style="font-weight: 600;font-size: 14px;margin: 0px;">Order Type</p>
            <div style="display: flex;gap: 5px;">
              <p><img width="40" [class.active]="isActiveTwo(0)" (click)="toggleItemTwo(0)"  class="active" src=".././../../../assets/MTN.png" alt=""></p>
              <p><img width="40"  [class.active]="isActiveTwo(1)" (click)="toggleItemTwo(1)"  class src=".././../../../assets/GLO.png" alt=""></p>
              <p><img width="40"  [class.active]="isActiveTwo(2)" (click)="toggleItemTwo(2)"  class src=".././../../../assets/9MOBILE.png" alt=""></p>
              <p><img width="40"  [class.active]="isActiveTwo(3)" (click)="toggleItemTwo(3)"  class src=".././../../../assets/AIRTEL.png" alt=""></p>
            </div>
            </div>
 
 <div  class="header-eight">
  
   <button class="filter" (click)="clear()">Clear Filter</button>
   <button class="apply" (click)="send()">Apply</button>
 </div>
     
    
       
 </div>
 
 
 
 
 