import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-bank-list',
  templateUrl: './bank-list.component.html',
  styleUrls: ['./bank-list.component.css']
})
export class BankListComponent {
  checked:boolean = false;
  value:any = '';
  accountNumber:any = '';
  accountName:string = '';
  naration:string = '';
  amount:string = '';
  pin:string = '';
  first: number = 1;
  bank:string = '';
  isLoading:boolean = false;
  isLoadingTwo:boolean = false;
  is_active:any[] = []
  data:any = [];
resolvedData:any;
  private apiKey:string = '';
constructor(private http:HttpClient,private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
 
resolveAccountNumber(index:string,indexTwo:string){
this.value = index.toLocaleLowerCase()
this.bank = indexTwo.toLocaleLowerCase()
this.accountName = '';
}



      getRequest(){
 this.http.get(this.apiKey + '/zap/admin/setting/transfer/banklist?page=1&limit=10').subscribe({
  next:(res:any) => {
    this.isLoading = false;
    this.data = res.data;
    console.log(res)
  }
 })
      }

       
    resolve(){
if(this.accountNumber.length == 10 ){
  this.isLoadingTwo = true;
  this.http.post(this.apiKey + '/zap/admin/setting/transfer/resolve',{accountNumber:this.accountNumber,bank:this.bank}).subscribe({
      next:(res:any) => {
        this.messageService.add({ severity: 'info', summary:res.data.accountName, detail:res.message});
        this.isLoadingTwo = false;
        this.accountName = res.data.accountName;
        this.resolvedData = res.data;
      },
      error:(res:any) => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message });
        this.isLoadingTwo = false;
      
      }
    })
}
    }

    transfer(){
      this.isLoading = true;
      let realAmount = parseInt(this.amount,10)
  this.http.post(this.apiKey + '/zap/admin/setting/transfer/transfer',{account_number:this.resolvedData.accountNumber,admin_pin:this.pin,amount:realAmount,naration:this.naration,account_name:this.resolvedData.accountName,bank_code:this.resolvedData.bankCode,session_id:this.resolvedData.sessionId}).subscribe({
    next:(res:any) => {
      location.reload()
      this.messageService.add({ severity: 'info', summary:'Successful', detail:res.message});
    },
    error:(res:any) => {
      this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message });

     
    }
  })
    }
 
      ngOnInit(): void {
        this.isLoading = true;
        this.getRequest()
      }
   
}
