import { Component } from '@angular/core';
import { AsTransactionsService } from '../airtime-swap-transactions/as-transactions.service';
@Component({
  selector: 'app-as-filter-modal',
  templateUrl: './as-filter-modal.component.html',
  styleUrls: ['./as-filter-modal.component.css']
})
export class AsFilterModalComponent {
  toggle = false
  date:any = '';
  dateTwo:any = '';
  amountOne:number = 0;
  amountTwo:number = 0;
  status = 'failed'
  selectedItem: number | null = 0;
  selectedItemTwo: any | null;
  orderType = '';
  filterSubscription:any;
constructor(private asTransactionsService:AsTransactionsService){}

  isActive(index: number): boolean {
    return this.selectedItem === index;
  }
  isActiveTwo(index: number): boolean {
    return this.selectedItemTwo === index;
  }
  toggleItem(index:number){
  this.selectedItem = this.selectedItem === index ? null : index;
  if(index == 0){
   this.status = 'failed'
        }
  if(index == 1){

this.status = 'success'
  }
}

toggleItemTwo(index:number){
  this.selectedItemTwo = this.selectedItemTwo === index ? null : index;
  if(index == 0){
   this.orderType = 'mtn'
        }
  if(index == 1){

this.orderType = 'glo'
  }
  if(index == 2){

    this.orderType = '9mobile'
      }
      if(index == 3){

        this.orderType = 'airtel'
          }
}
send(){
this.asTransactionsService.filter.next({start_date:this.date,end_date:this.dateTwo,start_amount:this.amountOne,end_amount:this.amountTwo,order_type:this.orderType});
}
clear(){
this.asTransactionsService.filter.next({start_date:'',end_date:'',start_amount:0,end_amount:0,status:'',order_type:''})
}
  ngOnInit(): void {
  this.filterSubscription = this.asTransactionsService.filter.subscribe(res => {
    if(res.order_type == 'airtel'){
      this.selectedItemTwo = 3;
      this.orderType = 'airtel';
    }
    if(res.order_type == '9mobile'){
      this.selectedItemTwo = 2;
      this.orderType = '9mobile'
    }
  
    if(res.order_type == 'glo'){
      this.selectedItemTwo = 1;
      this.orderType = 'glo';
    }
  if(res.order_type == 'mtn'){
    this.selectedItemTwo = 0;
    this.orderType = 'mtn';
  }
  if(res.order_type == ''){
    this,this.selectedItemTwo = '';
    this.orderType = ''
      }
      this.date = res.start_date;
      this.dateTwo = res.end_date;
   if(res.start_amount !== undefined && res.end_amount !== undefined ){
    this.amountOne = res.start_amount;
    this.amountTwo = res.end_amount;
   }
   else {
    this.amountOne = 0;
    this.amountTwo = 0;
   }
    })
  }
  ngOnDestroy(): void {
    // Unsubscribe from the filter subscription to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}
