import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-landing-teams',
  templateUrl: './landing-teams.component.html',
  styleUrls: ['./landing-teams.component.css']
})
export class LandingTeamsComponent {
  data:any = [];
  isLoading:boolean = false;
  visible: boolean = false;
  modalName = '';
  visibleTwo:boolean = false;
  private apiKey:string = '';
  singleData:any = {noData:true}
   constructor(private http:HttpClient){
    this.apiKey = environment.apiUrl
  }

  showDialog(name:string,id:number) {
    this.modalName = name;
      this.visible = true;
      this.singleData = this.data[id];
    console.log(this.singleData)
  }

 ngOnInit(): void {
  this.isLoading = true;
  this.http.get(this.apiKey + "/zap/admin/landing/teams").subscribe({
    next:(res:any) => {
this.data = res.data;
this.isLoading = false;
    },
    error:(res:any) => {
      this.isLoading = false;
    }
  })
 }
}
