import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { HeaderComponent } from './home/header/header.component';
import { OverviewComponent } from './home/overview/overview.component';
import { ProductChartComponent } from './home/product-chart/product-chart.component';
import { QuickActionComponent } from './home/quick-action/quick-action.component';
import { PeriodComponent } from './home/period/period.component';
import { RecentTransactionComponent } from './home/recent-transaction/recent-transaction.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { BillsTransactionComponent } from './transactions/bills-transaction/bills-transaction.component';
import { TransactionHeaderComponent } from './transactions/transaction-header/transaction-header.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { BillsDialogBoxComponent } from './transactions/bills-transaction/bills-dialog-box/bills-dialog-box.component';
import { BillsOverviewComponent } from './transactions/bills-transaction/bills-overview/bills-overview.component';
import { BillsTransactionsComponent } from './transactions/bills-transaction/bills-transactions/bills-transactions.component';
import { AirtimeSwapComponent } from './transactions/airtime-swap/airtime-swap.component';
import { AirtimeSwapOverviewComponent } from './transactions/airtime-swap/airtime-swap-overview/airtime-swap-overview.component';
import { AirtimeSwapTransactionsComponent } from './transactions/airtime-swap/airtime-swap-transactions/airtime-swap-transactions.component';
import { AirtimeSwapDialogBoxComponent } from './transactions/airtime-swap/airtime-swap-dialog-box/airtime-swap-dialog-box.component';
import { CardTransactionsComponent } from './transactions/card-transactions/card-transactions.component';
import { CardTransactionsOverviewComponent } from './transactions/card-transactions/card-transactions-overview/card-transactions-overview.component';
import { CardTransactionsTransactionsComponent } from './transactions/card-transactions/card-transactions-transactions/card-transactions-transactions.component';
import { CardTransactionsDialogBoxComponent } from './transactions/card-transactions/card-transactions-dialog-box/card-transactions-dialog-box.component';
import { ZapPinComponent } from './transactions/zap-pin/zap-pin.component';
import { ZapPinOverviewComponent } from './transactions/zap-pin/zap-pin-overview/zap-pin-overview.component';
import { ZapPinTransactionsComponent } from './transactions/zap-pin/zap-pin-transactions/zap-pin-transactions.component';
import { ZapPinDialogBoxComponent } from './transactions/zap-pin/zap-pin-dialog-box/zap-pin-dialog-box.component';
import { GiftCardComponent } from './transactions/gift-card/gift-card.component';
import { GiftCardOverviewComponent } from './transactions/gift-card/gift-card-overview/gift-card-overview.component';
import { GiftCardTransactionsComponent } from './transactions/gift-card/gift-card-transactions/gift-card-transactions.component';
import { GiftCardDialogBoxComponent } from './transactions/gift-card/gift-card-dialog-box/gift-card-dialog-box.component';
import { BankTransferComponent } from './transactions/bank-transfer/bank-transfer.component';
import { BankTransferOverviewComponent } from './transactions/bank-transfer/bank-transfer-overview/bank-transfer-overview.component';
import { BankTransferTransactionsComponent } from './transactions/bank-transfer/bank-transfer-transactions/bank-transfer-transactions.component';
import { BankTransferDialogBoxComponent } from './transactions/bank-transfer/bank-transfer-dialog-box/bank-transfer-dialog-box.component';
import { EmailComponent } from './email/email.component';
import { EmailHeaderComponent } from './email/email-header/email-header.component';
import { EmailDialogBoxComponent } from './email/email-dialog-box/email-dialog-box.component';
import { EmailMessageComponent } from './email/email-message/email-message.component';
import { EmailOverviewComponent } from './email/email-overview/email-overview.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { CustomersComponent } from './user-management/customers/customers.component';
import { UserManagementHeaderComponent } from './user-management/user-management-header/user-management-header.component';
import { CustomersOverviewComponent } from './user-management/customers/customers-overview/customers-overview.component';
import { CustomersTransactionsComponent } from './user-management/customers/customers-transactions/customers-transactions.component';
import { CustomerDetailsComponent } from './user-management/customers/customer-details/customer-details.component';
import { AuthPageComponent } from './auth-page/auth-page.component';
import { ProjectContainerComponent } from './project-container/project-container.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SpinnerComponent } from './spinner/spinner.component';
import { AuthInterceptorService } from './auth-page/auth-interceptor.service';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PaginatorModule } from 'primeng/paginator';
import { ChartModule } from 'primeng/chart';
import { MenuModule } from 'primeng/menu';
import { FilterPipe } from './filter.pipe';
import { CustomerProfileComponent } from './user-management/customers/customer-details/customer-profile/customer-profile.component';
import { CustomerTransactionHistoryComponent } from './user-management/customers/customer-details/customer-transaction-history/customer-transaction-history.component';
import { CustomerMessageHistoryComponent } from './user-management/customers/customer-details/customer-message-history/customer-message-history.component';
import { CthOverviewComponent } from './user-management/customers/customer-details/customer-transaction-history/cth-overview/cth-overview.component';
import { CthTransactionsComponent } from './user-management/customers/customer-details/customer-transaction-history/cth-transactions/cth-transactions.component';
import { KycRequestComponent } from './user-management/kyc-request/kyc-request.component';
import { KycOverviewComponent } from './user-management/kyc-request/kyc-overview/kyc-overview.component';
import { KycCustomersComponent } from './user-management/kyc-request/kyc-customers/kyc-customers.component';
import { KycCustomerDetailsComponent } from './user-management/kyc-request/kyc-customer-details/kyc-customer-details.component';
import { CardFundingComponent } from './user-management/card-funding/card-funding.component';
import { CfOverviewComponent } from './user-management/card-funding/cf-overview/cf-overview.component';
import { CfTransactionComponent } from './user-management/card-funding/cf-transaction/cf-transaction.component';
import { VirtualCardComponent } from './user-management/virtual-card/virtual-card.component';
import { VcOverviewComponent } from './user-management/virtual-card/vc-overview/vc-overview.component';
import { VcTransactionComponent } from './user-management/virtual-card/vc-transaction/vc-transaction.component';
import { SupportAndQueriesComponent } from './support-and-queries/support-and-queries.component';
import { SaqHeaderComponent } from './support-and-queries/saq-header/saq-header.component';
import { SaqMessagesComponent } from './support-and-queries/saq-messages/saq-messages.component';
import { SaqMessageComponent } from './support-and-queries/saq-message/saq-message.component';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { BillsPaymentComponent } from './configurations/bills-payment/bills-payment.component';
import { ConfigurationsHeaderComponent } from './configurations/configurations-header/configurations-header.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DialogModule } from 'primeng/dialog';
import { BankingTransferComponent } from './configurations/banking-transfer/banking-transfer.component';
import { CGiftCardComponent } from './configurations/c-gift-card/c-gift-card.component';
import { CAirtimeToCashComponent } from './configurations/c-airtime-to-cash/c-airtime-to-cash.component';
import { CZapPinComponent } from './configurations/c-zap-pin/c-zap-pin.component';
import { CVirtualCardComponent } from './configurations/c-virtual-card/c-virtual-card.component';
import { StepOneComponent } from './configurations/c-virtual-card/step-one/step-one.component';
import { DropdownModule } from 'primeng/dropdown';
import { StepTwoComponent } from './configurations/c-virtual-card/step-two/step-two.component';
import { StepThreeComponent } from './configurations/c-virtual-card/step-three/step-three.component';
import { PreviewComponent } from './configurations/c-virtual-card/preview/preview.component';
import { FxOverviewComponent } from './configurations/foreign-exchange/fx-overview/fx-overview.component';
import { FxTransactionsComponent } from './configurations/foreign-exchange/fx-transactions/fx-transactions.component';
import { FxChartComponent } from './configurations/foreign-exchange/fx-chart/fx-chart.component';
import { ForeignExchangeComponent } from './configurations/foreign-exchange/foreign-exchange.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AnalyticsHeaderComponent } from './analytics/analytics-header/analytics-header.component';
import { AnalyticsOverviewComponent } from './analytics/analytics-overview/analytics-overview.component';
import { AnalyticsTotalTransactionsComponent } from './analytics/analytics-total-transactions/analytics-total-transactions.component';
import { AnalyticsUserManagementComponent } from './analytics/analytics-user-management/analytics-user-management.component';
import { AnalyticsBillPaymentComponent } from './analytics/analytics-bill-payment/analytics-bill-payment.component';
import { AnalyticsAirtimeSwapComponent } from './analytics/analytics-airtime-swap/analytics-airtime-swap.component';
import { AnalyticsCardTransactionComponent } from './analytics/analytics-card-transaction/analytics-card-transaction.component';
import { AnalyticsGiftCardComponent } from './analytics/analytics-gift-card/analytics-gift-card.component';
import { AnalyticsZapPinComponent } from './analytics/analytics-zap-pin/analytics-zap-pin.component';
import { AnalyticsBankTransferComponent } from './analytics/analytics-bank-transfer/analytics-bank-transfer.component';
import { NotificationComponent } from './notification/notification.component';
import { SettingsComponent } from './settings/settings.component';
import { SettingsHeaderComponent } from './settings/settings-header/settings-header.component';
import { SettingsHeaderTwoComponent } from './settings/settings-header-two/settings-header-two.component';
import { GeneralSettingsComponent } from './settings/general-settings/general-settings.component';
import { SystemSettingsComponent } from './settings/system-settings/system-settings.component';
import { PushNotificationsComponent } from './settings/push-notifications/push-notifications.component';
import { SecurityAuditComponent } from './settings/security-audit/security-audit.component';
import { WebhookLogsComponent } from './settings/webhook-logs/webhook-logs.component';
import { NotificationTemplatesComponent } from './settings/notification-templates/notification-templates.component';
import { EmailMangementComponent } from './settings/email-mangement/email-mangement.component';
import { FilterTwoPipe } from './filter-two.pipe';
import { FilterThreePipe } from './filter-three.pipe';
import { FilterFourPipe } from './filter-four.pipe';
import { FilterFivePipe } from './filter-five.pipe';
import { FilterSixPipe } from './filter-six.pipe';
import { UpgradeComponent } from './user-management/customers/customer-details/upgrade/upgrade.component';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ImageModule } from 'primeng/image';
import { DeclineModalComponent } from './user-management/customers/customer-details/decline-modal/decline-modal.component';
import { CfDialogComponent } from './user-management/card-funding/cf-dialog/cf-dialog.component';
import { CfModalComponent } from './user-management/card-funding/cf-modal/cf-modal.component';

import { AvatarModule } from 'primeng/avatar';
import { CpMessageComponent } from './user-management/customers/customer-details/customer-profile/cp-message/cp-message.component';
import { SupportQueriesPipe } from './support-queries.pipe';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { AsFilterModalComponent } from './transactions/airtime-swap/as-filter-modal/as-filter-modal.component';
import { CtFilterModalComponent } from './transactions/card-transactions/ct-filter-modal/ct-filter-modal.component';
import { ZpFilterModalComponent } from './transactions/zap-pin/zp-filter-modal/zp-filter-modal.component';
import { BtFilterModalComponent } from './transactions/bank-transfer/bt-filter-modal/bt-filter-modal.component';
import { GcFilterModalComponent } from './transactions/gift-card/gc-filter-modal/gc-filter-modal.component';
import { BillsFilterModalComponent } from './transactions/bills-transaction/bills-filter-modal/bills-filter-modal.component';
import { CustomerFilterModalComponent } from './user-management/customers/customers-transactions/customer-filter-modal/customer-filter-modal.component';
import { FilterSevenPipe } from './filter-seven.pipe';
import { KycFilterComponent } from './user-management/kyc-request/kyc-filter/kyc-filter.component';
import { EditorModule } from 'primeng/editor';
import { CardFilterComponent } from './user-management/card-funding/card-filter/card-filter.component';
import { VcFilterComponent } from './user-management/virtual-card/vc-filter/vc-filter.component';
import { CAirtimeComponent } from './configurations/c-airtime/c-airtime.component';
import { AirtimeToCashComponent } from './configurations/c-airtime/airtime-to-cash/airtime-to-cash.component';
import { AirtimeToCashAutoComponent } from './configurations/c-airtime/airtime-to-cash-auto/airtime-to-cash-auto.component';
import { AirtimeToCashManualComponent } from './configurations/c-airtime/airtime-to-cash-manual/airtime-to-cash-manual.component';
import { DataPlanComponent } from './configurations/data-plan/data-plan.component';
import { DataPlanModalComponent } from './configurations/data-plan/data-plan-modal/data-plan-modal.component';
import { DataPlanModalTwoComponent } from './configurations/data-plan/data-plan-modal-two/data-plan-modal-two.component';
import { FxUpdateModalComponent } from './configurations/foreign-exchange/fx-transactions/fx-update-modal/fx-update-modal.component';
import { ServiceProviderComponent } from './configurations/service-provider/service-provider.component';
import { SpModalComponent } from './configurations/service-provider/sp-modal/sp-modal.component';
import { CvcModalComponent } from './configurations/c-virtual-card/cvc-modal/cvc-modal.component';
import { AccountManagerComponent } from './settings/account-manager/account-manager.component';
import { WebhookCardComponent } from './settings/webhook-card/webhook-card.component';
import { CthFilterComponent } from './user-management/customers/customer-details/customer-transaction-history/cth-transactions/cth-filter/cth-filter.component';
import { VCardFilterComponent } from './user-management/virtual-card/v-card-filter/v-card-filter.component';
import { VcDetailComponent } from './user-management/virtual-card/vc-detail/vc-detail.component';
import { VcProfileComponent } from './user-management/virtual-card/vc-detail/vc-profile/vc-profile.component';
import { VcTransactionHistoryComponent } from './user-management/virtual-card/vc-detail/vc-transaction-history/vc-transaction-history.component';
import { VctOverviewComponent } from './user-management/virtual-card/vc-detail/vc-transaction-history/vct-overview/vct-overview.component';
import { VctTransactionsComponent } from './user-management/virtual-card/vc-detail/vc-transaction-history/vct-transactions/vct-transactions.component';
import { VctDialogBoxComponent } from './user-management/virtual-card/vc-detail/vc-transaction-history/vct-transactions/vct-dialog-box/vct-dialog-box.component';
import { CthtDialogBoxComponent } from './user-management/customers/customer-details/customer-transaction-history/cth-transactions/ctht-dialog-box/ctht-dialog-box.component';
import { ReferralComponent } from './user-management/referral/referral.component';
import { ReferralOverviewComponent } from './user-management/referral/referral-overview/referral-overview.component';
import { ReferralTransactionComponent } from './user-management/referral/referral-transaction/referral-transaction.component';
import { ReferralFilterModalComponent } from './user-management/referral/referral-filter-modal/referral-filter-modal.component';
import { ReferralDetailComponent } from './user-management/referral/referral-detail/referral-detail.component';
import { ReferralProfileComponent } from './user-management/referral/referral-detail/referral-profile/referral-profile.component';
import { ReferralProfileOverviewComponent } from './user-management/referral/referral-detail/referral-profile/referral-profile-overview/referral-profile-overview.component';
import { ReferralProfileTransactionComponent } from './user-management/referral/referral-detail/referral-profile/referral-profile-transaction/referral-profile-transaction.component';
import { SavingComponent } from './user-management/saving/saving.component';
import { SavingOverviewComponent } from './user-management/saving/saving-overview/saving-overview.component';
import { SavingTransactionComponent } from './user-management/saving/saving-transaction/saving-transaction.component';
import { SavingsFilterModalComponent } from './user-management/saving/savings-filter-modal/savings-filter-modal.component';
import { SavingsDetailsComponent } from './user-management/saving/savings-details/savings-details.component';
import { SavingsProfileComponent } from './user-management/saving/savings-details/savings-profile/savings-profile.component';
import { SavingsProfileOverviewComponent } from './user-management/saving/savings-details/savings-profile/savings-profile-overview/savings-profile-overview.component';
import { SavingsProfileTransactionComponent } from './user-management/saving/savings-details/savings-profile/savings-profile-transaction/savings-profile-transaction.component';
import { LockHistoryComponent } from './user-management/saving/savings-details/savings-profile/savings-profile-transaction/lock-history/lock-history.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LandingSectionsComponent } from './landing-page/landing-sections/landing-sections.component';
import { LandingProductsComponent } from './landing-page/landing-products/landing-products.component';
import { LandingTeamsComponent } from './landing-page/landing-teams/landing-teams.component';
import { LandingHeaderComponent } from './landing-page/landing-header/landing-header.component';
import { LandingHeaderTwoComponent } from './landing-page/landing-header-two/landing-header-two.component';
import { LandingProductsDialogComponent } from './landing-page/landing-products/landing-products-dialog/landing-products-dialog.component';
import { LandingTeamsDialogComponent } from './landing-page/landing-teams/landing-teams-dialog/landing-teams-dialog.component';
import { LandingSectionDialogComponent } from './landing-page/landing-sections/landing-section-dialog/landing-section-dialog.component';
import { LandingSectionDialogTwoComponent } from './landing-page/landing-sections/landing-section-dialog-two/landing-section-dialog-two.component';
import { LandingProductsDialogTwoComponent } from './landing-page/landing-products/landing-products-dialog-two/landing-products-dialog-two.component';
import { LandingTeamsDialogTwoComponent } from './landing-page/landing-teams/landing-teams-dialog-two/landing-teams-dialog-two.component';
import { BvnRequestComponent } from './user-management/bvn-request/bvn-request.component';
import { BvnOverviewComponent } from './user-management/bvn-request/bvn-overview/bvn-overview.component';
import { BvnCustomersComponent } from './user-management/bvn-request/bvn-customers/bvn-customers.component';
import { BvnCustomerDetailsComponent } from './user-management/bvn-request/bvn-customer-details/bvn-customer-details.component';
import { BvnFilterComponent } from './user-management/bvn-request/bvn-filter/bvn-filter.component';
import { BvnDeclineModalComponent } from './user-management/bvn-request/bvn-decline-modal/bvn-decline-modal.component';
import { LandingFaqComponent } from './landing-page/landing-faq/landing-faq.component';
import { LandingFaqDialogComponent } from './landing-page/landing-faq/landing-faq-dialog/landing-faq-dialog.component';
import { LandingFaqDialogTwoComponent } from './landing-page/landing-faq/landing-faq-dialog-two/landing-faq-dialog-two.component';
import { RecentTransactionDialogComponent } from './home/recent-transaction/recent-transaction-dialog/recent-transaction-dialog.component';
import { BusinessComponent } from './business/business.component';
import { BusinessHeaderComponent } from './business/business-header/business-header.component';
import { BusinessHeaderTwoComponent } from './business/business-header-two/business-header-two.component';
import { BusinessPlanComponent } from './business/business-plan/business-plan.component';
import { AvailableBusinessComponent } from './business/available-business/available-business.component';
import { BusinessPlanDialogComponent } from './business/business-plan/business-plan-dialog/business-plan-dialog.component';
import { BusinessPlanDialogTwoComponent } from './business/business-plan/business-plan-dialog-two/business-plan-dialog-two.component';
import { AbDialogComponent } from './business/available-business/ab-dialog/ab-dialog.component';
import { AbDialogTwoComponent } from './business/available-business/ab-dialog-two/ab-dialog-two.component';
import { AbOverviewComponent } from './business/available-business/ab-overview/ab-overview.component';
import { AbTransactionsComponent } from './business/available-business/ab-transactions/ab-transactions.component';
import { PayoutComponent } from './settings/payout/payout.component';
import { BankListComponent } from './settings/payout/bank-list/bank-list.component';
import { PayoutDialogComponent } from './settings/payout/payout-dialog/payout-dialog.component';
import { PayoutDialogTwoComponent } from './settings/payout/payout-dialog-two/payout-dialog-two.component';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { WithdrawComponent } from './user-management/withdraw/withdraw.component';
import { WithdrawTransactionComponent } from './user-management/withdraw/withdraw-transaction/withdraw-transaction.component';
import { WithdrawOverviewComponent } from './user-management/withdraw/withdraw-overview/withdraw-overview.component';
import { WithdrawDialogComponent } from './user-management/withdraw/withdraw-dialog/withdraw-dialog.component';
import { WithdrawModalComponent } from './user-management/withdraw/withdraw-modal/withdraw-modal.component';
import { WithdrawFilterComponent } from './user-management/withdraw/withdraw-filter/withdraw-filter.component';

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    HomeComponent,
    HeaderComponent,
    OverviewComponent,
    ProductChartComponent,
    QuickActionComponent,
    PeriodComponent,
    RecentTransactionComponent,
    TransactionsComponent,
    BillsTransactionComponent,
    TransactionHeaderComponent,
    DialogBoxComponent,
    BillsDialogBoxComponent,
    BillsOverviewComponent,
    BillsTransactionsComponent,
    AirtimeSwapComponent,
    AirtimeSwapOverviewComponent,
    AirtimeSwapTransactionsComponent,
    AirtimeSwapDialogBoxComponent,
    CardTransactionsComponent,
    CardTransactionsOverviewComponent,
    CardTransactionsTransactionsComponent,
    CardTransactionsDialogBoxComponent,
    ZapPinComponent,
    ZapPinOverviewComponent,
    ZapPinTransactionsComponent,
    ZapPinDialogBoxComponent,
    GiftCardComponent,
    GiftCardOverviewComponent,
    GiftCardTransactionsComponent,
    GiftCardDialogBoxComponent,
    BankTransferComponent,
    BankTransferOverviewComponent,
    BankTransferTransactionsComponent,
    BankTransferDialogBoxComponent,
    EmailComponent,
    EmailHeaderComponent,
    EmailDialogBoxComponent,
    EmailMessageComponent,
    EmailOverviewComponent,
    UserManagementComponent,
    CustomersComponent,
    UserManagementHeaderComponent,
    CustomersOverviewComponent,
    CustomersTransactionsComponent,
    CustomerDetailsComponent,
    AuthPageComponent,
    ProjectContainerComponent,
    SpinnerComponent,
    FilterPipe,
    CustomerProfileComponent,
    CustomerTransactionHistoryComponent,
    CustomerMessageHistoryComponent,
    CthOverviewComponent,
    CthTransactionsComponent,
    KycRequestComponent,
    KycOverviewComponent,
    KycCustomersComponent,
    KycCustomerDetailsComponent,
    CardFundingComponent,
    CfOverviewComponent,
    CfTransactionComponent,
    VirtualCardComponent,
    VcOverviewComponent,
    VcTransactionComponent,
    SupportAndQueriesComponent,
    SaqHeaderComponent,
    SaqMessagesComponent,
    SaqMessageComponent,
    ConfigurationsComponent,
    BillsPaymentComponent,
    ConfigurationsHeaderComponent,
    BankingTransferComponent,
    CGiftCardComponent,
    CAirtimeToCashComponent,
    CZapPinComponent,
    CVirtualCardComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    PreviewComponent,
    ForeignExchangeComponent,
    FxOverviewComponent,
    FxTransactionsComponent,
    FxChartComponent,
    AnalyticsComponent,
    AnalyticsHeaderComponent,
    AnalyticsOverviewComponent,
    AnalyticsTotalTransactionsComponent,
    AnalyticsUserManagementComponent,
    AnalyticsBillPaymentComponent,
    AnalyticsAirtimeSwapComponent,
    AnalyticsCardTransactionComponent,
    AnalyticsGiftCardComponent,
    AnalyticsZapPinComponent,
    AnalyticsBankTransferComponent,
    NotificationComponent,
    SettingsComponent,
    SettingsHeaderComponent,
    SettingsHeaderTwoComponent,
    GeneralSettingsComponent,
    SystemSettingsComponent,
    PushNotificationsComponent,
    SecurityAuditComponent,
    WebhookLogsComponent,
    NotificationTemplatesComponent,
    EmailMangementComponent,
    FilterTwoPipe,
    FilterThreePipe,
    FilterFourPipe,
    FilterFivePipe,
    FilterSixPipe,
UpgradeComponent,
DeclineModalComponent,
CfDialogComponent,
CfModalComponent,
CpMessageComponent,
SupportQueriesPipe,
AsFilterModalComponent,
CtFilterModalComponent,
ZpFilterModalComponent,
BtFilterModalComponent,
GcFilterModalComponent,
BillsFilterModalComponent,
CustomerFilterModalComponent,
FilterSevenPipe,
KycFilterComponent,
CardFilterComponent,
VcFilterComponent,
CAirtimeComponent,
AirtimeToCashComponent,
AirtimeToCashAutoComponent,
AirtimeToCashManualComponent,
DataPlanComponent,
DataPlanModalComponent,
DataPlanModalTwoComponent,
FxUpdateModalComponent,
ServiceProviderComponent,
SpModalComponent,
CvcModalComponent,
AccountManagerComponent,
WebhookCardComponent,
CthFilterComponent,
VCardFilterComponent,
VcDetailComponent,
VcProfileComponent,
VcTransactionHistoryComponent,
VctOverviewComponent,
VctTransactionsComponent,
VctDialogBoxComponent,
CthtDialogBoxComponent,
ReferralComponent,
ReferralOverviewComponent,
ReferralTransactionComponent,
ReferralFilterModalComponent,
ReferralDetailComponent,
ReferralProfileComponent,
ReferralProfileOverviewComponent,
ReferralProfileTransactionComponent,
SavingComponent,
SavingOverviewComponent,
SavingTransactionComponent,
SavingsFilterModalComponent,
SavingsDetailsComponent,
SavingsProfileComponent,
SavingsProfileOverviewComponent,
SavingsProfileTransactionComponent,
LockHistoryComponent,
LandingPageComponent,
LandingSectionsComponent,
LandingProductsComponent,
LandingTeamsComponent,
LandingHeaderComponent,
LandingHeaderTwoComponent,
LandingProductsDialogComponent,
LandingTeamsDialogComponent,
LandingSectionDialogComponent,
LandingSectionDialogTwoComponent,
LandingProductsDialogTwoComponent,
LandingTeamsDialogTwoComponent,
BvnRequestComponent,
BvnOverviewComponent,
BvnCustomersComponent,
BvnCustomerDetailsComponent,
BvnFilterComponent,
BvnDeclineModalComponent,
LandingFaqComponent,
LandingFaqDialogComponent,
LandingFaqDialogTwoComponent,
RecentTransactionDialogComponent,
BusinessComponent,
BusinessHeaderComponent,
BusinessHeaderTwoComponent,
BusinessPlanComponent,
AvailableBusinessComponent,
BusinessPlanDialogComponent,
BusinessPlanDialogTwoComponent,
AbDialogComponent,
AbDialogTwoComponent,
AbOverviewComponent,
AbTransactionsComponent,
PayoutComponent,
BankListComponent,
PayoutDialogComponent,
PayoutDialogTwoComponent,
WithdrawComponent,
WithdrawTransactionComponent,
WithdrawOverviewComponent,
WithdrawDialogComponent,
WithdrawModalComponent,
WithdrawFilterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,FormsModule,HttpClientModule,ButtonModule,ToastModule,PaginatorModule,ChartModule,MenuModule,InputSwitchModule,DialogModule,DropdownModule,ConfirmPopupModule,ImageModule,AvatarModule,CalendarModule,InputNumberModule,EditorModule,InputTextModule,CardModule,CheckboxModule
  ],
  providers: [{provide:HTTP_INTERCEPTORS,useClass:AuthInterceptorService,multi:true},MessageService,ConfirmationService,],
  bootstrap: [AppComponent]
})
export class AppModule { }
