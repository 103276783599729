<div class="container-one" *ngIf="!isLoading">
  
  <h1 style="padding-bottom: 0px;margin: 0px; width:500px;"></h1>
  <div class="header-two">
    <h5>Transaction Status</h5>
  
    <p *ngIf="data.status.toLocaleLowerCase() == 'success' || data.status.toLocaleLowerCase() == 'successful' || data.status.toLocaleLowerCase() == 'completed'"><span class="success">Successful</span></p>
    <p *ngIf="data.status.toLocaleLowerCase() == 'reversed'"><span class="failed">Reversed</span></p>
  </div>
  <table class="table-one">
    <tr>
      <th>Wallet Holder</th>
      <th>Customer Id</th>
      <th>Email</th>
    </tr>
    <tr>
        <td>{{data.name | titlecase}}</td>
      <td>
  <div class="data-group">
    <p>{{data.user_id | slice:0:8}} {{ data.user_id.length > 8 ? '...' : '' }}</p>
        <img (click)="openSnackBar(data.user_id)"  src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
  </div>
      </td>
      <td>
          <div class="data-group">
              <p>{{data.email}}</p>
              <img (click)="openSnackBar(data.email)"  src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
          </div>
      </td>
    </tr>
   
    <tr>
      <th>Phone number</th>
      <th>Level</th>
      <th>Transaction id</th>
    </tr>
    <tr>
        <td>{{data.phone_number}}</td>
      <td>Tier {{data.level}}
      </td>
      <td>  <div class="data-group">
        <p>{{data.reference | slice:0:8}} {{ data.reference.length > 8 ? '...' : '' }}</p>
        <img (click)="openSnackBar(data.reference)"  src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
    </div></td>
    </tr>
    <tr>
      <th>Order type</th>
      <th>Bank Name</th>
      <th>Price</th>
    </tr>
    <tr>
        <td>Bank Transfer</td>
      <td>{{data.bank | titlecase}}</td>
      <td>₦{{data.amount | number}}</td>
    </tr>
    <tr>
      <th>Recipient Name</th>
      <th>Account Number</th>
      <th>Transaction ID</th>
    </tr>
    <tr>
      <td>{{data.receipient | titlecase}}</td>
      <td>{{data.account_name }}</td>
      <td>{{data.reference | slice:0:8}} {{ data.reference.length > 8 ? '...' : '' }}</td>
    </tr>
    <tr>
      <th>Date &Time</th>
      <th>Balance Before</th>
      <th>Balance After</th>
    </tr>
    <tr>
      <td>{{data.created_at | date}} & {{data.created_at | date:'shortTime'}}</td>
      <td>₦{{data.balance_before | number}}</td>
      <td>₦{{data.balance_after | number}}</td>
    </tr>
<tr>
  <th>Description</th>
  <th>Transaction Fee</th>
  <th>Session Id</th>
</tr>
<td>{{data.note}}</td>
<td>₦{{data.fee}}</td>
<td>{{data.session_id}}</td>
  </table>
  
  <div class="button-group" *ngIf="!resolveTransaction" (click) = 'resolve()'>
  <button> <img src="../../../../assets/Stockholm-icons (17).png" width="20" alt=""> Resolve Transaction</button>
</div>
<br>
<div class="container" *ngIf="resolveTransaction">
<header>
  <h4 style="font-size: 13px;margin: 0px;">Resolve Transaction</h4>
  <img src="../../../../assets/Group.png" width="20" style="cursor: pointer;" alt="" (click)="done()">
</header>
<div class="message success" *ngIf="refundData.status.toLocaleLowerCase() == 'success' && !isLoadingTwo">
  <img src="../../../../assets/success.png" width="20" alt="" style="margin: 0px;">
  <p style="margin: 0px;font-size: 12px;padding: 0px; font-weight: 600;" >This transaction was successful</p>
</div>
<div class="message pending" *ngIf="refundData.status.toLocaleLowerCase() == 'pending' && !isLoadingTwo">
  <img src="../../../../assets/message (2).png" width="20" alt="" style="margin: 0px;">
  <p style="margin: 0px;font-size: 12px;padding: 0px; font-weight: 600;" >This transaction is still pending. Further information will be communicated for success of refund.</p>
</div>
<div class="message failed" *ngIf="refundData.status.toLocaleLowerCase() == 'failed' && !isLoadingTwo">
  <img src="../../../../assets/failed.png" width="20" alt="" style="margin: 0px;">
  <p style="margin: 0px;font-size: 11px;padding: 0px; font-weight: 600;" >{{refundData.message}} Input access code to successfully refund customer.</p>
  
</div>
<br>
<div *ngIf="refundData.status == 'failed' && !isLoadingTwo">
  <h4 style="font-size: 13px;margin: 0px;padding-left: 10px;">Access Code</h4>
<input type="text" placeholder="Enter access Code" [(ngModel)] = 'accessCode'>
<p style="padding: 0px;margin: 2px;font-size: 11px;padding-left: 10px;">Input 6 digit admin access code to complete refund.</p>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoadingTwo'>
  <app-spinner ></app-spinner>
  </div>
</div>
<div class="button-group-two" *ngIf="resolveTransaction && refundData.status.toLocaleLowerCase() == 'failed'" (click)="refund()">
  <button [disabled]="accessCode == ''" >Refund</button>
</div>
<div class="button-group-two" *ngIf="resolveTransaction && refundData.status.toLocaleLowerCase() !== 'failed'" (click)="done()">
  <button>Cancel</button>
</div>
  </div>
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
  