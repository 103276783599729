<div *ngIf="!loading">
    <div *ngIf="data !==''">
    
        <div class="header-three">
        
          
            <table>
                <tr >
                    <th>
                            <img src="../../../../assets/id.png"  width="100" alt="">
                    </th>
                    <th *ngIf="data.status == 'approved'">
                        <div class="btn-group-approved">
                            <img src="../../../../../assets/approved.png" width="20" alt="">
        <p>KYC {{data.status | titlecase}}</p>
                        </div>
                    </th>
                    <th *ngIf="data.status == 'pending'">
                        <div class="btn-group-pending">
                            <img src="../../../../assets/message (2).png" width="20" alt="">
        <p>KYC {{data.status | titlecase}}</p>
                        </div>
                    </th>
           
                </tr>
                <tr>
                    <th>Name</th>
                    <th>Email Address</th> 
                     
            </tr>
            <tr >
                <td>{{data.name}}</td>
                <td>{{data.email}}</td>
                <td></td>
            </tr>
            <br>
            <tr>
                <th>Phone Number</th>
                <th>Document Number</th>
                 
        </tr>
        <tr>
            <td>{{data.phone_number}}</td>
            <td>{{data.document_number}}</td>
           
        </tr>
        <br>
        <tr>
            <th>Date of Birth</th>
            <th>City</th>
        </tr>
        <tr>
            <td>{{data.date_of_birth | titlecase}}</td>
            <td>{{data.city | titlecase}}</td>
        </tr>
        <br>
        <tr>
            <th>State</th>
            <th>Zip</th>
        </tr>
        <tr>
            <td>{{data.state | titlecase}}</td>
            <td>{{data.zip}}</td>
        </tr>
        <br>
        <tr>
            <th>Address</th>
        </tr>
        <tr  class="boredred">
            <td>{{data.address}}</td>
            <td></td>
        </tr>
        
         
            </table>
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <p style="font-size: 12px;color: #546881;padding-left:5px;font-weight: 600;">Documents</p>
         <img src="../../../../assets/approved.png" width="20" alt="">
        </div>
            <div class="document">
                <div style="display: flex;align-items: center;gap: 10px;">
                    <img src="../../../../assets/passport.png" width="30" alt="">
                    <p style="color:#151A20;font-size: 12px;">{{data.document_type}}</p>
                </div>
                <p style="color:#673AB7;font-size: 11px;cursor: pointer;" (click)="showDialog('Preview')" (click)="onPreview()">{{preview ? 'Close' : 'Preview'}}</p>
               
            </div>
            <p *ngIf="preview" style="font-size: 12px;color: #546881;padding-left:5px;font-weight: 600;">Documents Images</p>
           <div style="margin: 10px; display: flex;gap: 30px;" *ngIf="preview"> 
      <p-image [src]="data.face"   alt="Image" width="30" [preview]="true"></p-image>
            <p-image [src]="data.front_of_document"   alt="Image" width="30" [preview]="true"></p-image>
            <p-image [src]="data.back_of_document"   alt="Image" width="30" [preview]="true"></p-image>

        </div>
            <div style="display: flex;justify-content: right;">
                <br>
            <button class="approved" *ngIf="data.status == 'pending'" (click) = 'approve()'>Approve</button>
            <button class="decline" *ngIf="data.status == 'pending'" (click) = 'showDialogTwo("Decline Kyc")'>Decline</button>
            </div>
        
        </div>
        </div>
        
        <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 50px;top: 120px;" *ngIf="data == ''">
          
            <div>
             <img src="../../../assets/Empty state illustratio.png" style="margin-left: 50px;" width="80" alt="">
             <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">Select a File</h5>
             <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">You have not selected any file</p>
            </div>
           </div>
            
           <p-dialog [header]="modalName" [(visible)]="visibleTwo" [modal]="true" [style]="{ width: '25vw' }" [draggable]="false" [resizable]="false">
            <app-decline-modal style="font-family: poppins;" [userId]="userId" [id]="id"></app-decline-modal>
               </p-dialog>
</div>
   <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 100px;" *ngIf = 'loading'>
    <app-spinner ></app-spinner>
    </div>