<div *ngIf="!isLoading">
    <div class="header-one">
        <h4>Bvn Request</h4>
    
       <nav>
          <div class="filter-group" (click)="showDialog('Filter')">
              <p>Filter</p>
              <img src="../../assets/Stockholm-icons (9).png" alt="">
          </div>
    <img src="../../../assets/Stockholm-icons (15).png" (click)="reload()" style="margin-left: 20px;" alt="">
      </nav>
    </div>
    <div class="payment-manager">
        <div class="card">
            <img src="../../../../assets/Icon.png" alt="">
            <p>Total Request</p>
            
            <h4>{{overview.all}}</h4>
        </div>
        <div class="card">
            <img src="../../../../assets/Icon.png" alt="">
            <p>Approved</p>
            <h4>{{overview.approved}}</h4>
        </div>
        <div class="card">
            <img src="../../../../assets/Icon.png" alt="">
            <p>Pending</p>
            <h4>{{overview.pending}}</h4>
        </div>
        <div class="card">
            <img src="../../../../assets/Icon.png" alt="">
            <p>Declined</p>
            <h4>{{overview.declined}}</h4>
        </div>
    </div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    <p-dialog [header]="modalName" [(visible)]="visible" [maximizable]="true" [modal]="true" [style]="{ width: '35vw',height:'100%' }" [draggable]="false" [resizable]="false">
        <app-bvn-filter *ngIf="visible" style="font-family: poppins;"></app-bvn-filter>
      </p-dialog>