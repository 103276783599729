import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {MatDialog} from '@angular/material/dialog'
import { recentTransactionsService } from '../home/recent-transaction/recent-transactions.service';
interface City {
  name: string;
}
@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.css']

})
export class DialogBoxComponent implements OnInit,OnDestroy{
  toggle = false
  date:any = null;
  dateTwo:any = null;
  amountOne:number = 0;
  amountTwo:number = 0;
  selectedCityOne: City | any = '';
  citiesOne: City[] | any;
  status = ''
  selectedItem: number | null = 0;
  private filterSubscription:any;
constructor(public dialog:MatDialog,private recentTransactionsService:recentTransactionsService){}

  isActive(index: number): boolean {
    return this.selectedItem === index;
  }
  toggleItem(index:number){
  this.selectedItem = this.selectedItem === index ? null : index;
  if(index == 0){
   this.status = ''
        }
  if(index == 1){

this.status = 'success'
  }
  if(index == 2){

    this.status = 'reversed'
      }
}

send(){
this.recentTransactionsService.filter.next({sort_by:this.selectedCityOne.name,start_date:this.date,end_date:this.dateTwo,start_amount:this.amountOne,end_amount:this.amountTwo,status:this.status});
}
clear(){
this.recentTransactionsService.filter.next('')
}
  ngOnInit(): void {
    this.citiesOne = [
      { name: 'card-transaction'},
      {name:'user-transaction'}
  ];

  this.filterSubscription = this.recentTransactionsService.filter.subscribe(res => {
  if(res.status == 'reversed'){
    this.selectedItem = 2;
    this.status = 'reversed';
  }
  if(res.status == 'success'){
    this.selectedItem = 1;
    this.status = 'success'
  }

  if(res.status == ''){
    this.selectedItem = 0;
    this.status = '';
  }

  if(res.status == undefined){
this.selectedItem = 0;
this.status = ''
  }
    this.date = res.start_date;
    this.dateTwo = res.end_date;
    this.selectedCityOne = {name:res.sort_by};
 if(res.start_amount !== undefined && res.end_amount !== undefined ){
  this.amountOne = res.start_amount;
  this.amountTwo = res.end_amount;
 }
 else {
  this.amountOne = 0;
  this.amountTwo = 0;
 }
  })
  }

  ngOnDestroy(): void {
    // Unsubscribe from the filter subscription to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}
