import { Component, OnDestroy, OnInit } from '@angular/core';
import { btTransactionsService } from '../bank-transfer-transactions/bt-transactions.service';
interface City {
  name: string;
}
@Component({
  selector: 'app-bt-filter-modal',
  templateUrl: './bt-filter-modal.component.html',
  styleUrls: ['./bt-filter-modal.component.css']
})
export class BtFilterModalComponent implements OnInit,OnDestroy {
  toggle = false
  date:any = '';
  dateTwo:any = '';
  amountOne:number = 0;
  amountTwo:number = 0;
  selectedCityOne: City | any = '';
  citiesOne: City[] | any;
  status = ''
  selectedItem: number | null = 0;
  selectedItemTwo: number | null = 0;
  orderType = 'visa';
 private filterSubscription:any;
constructor(private btTransactionsService:btTransactionsService){}

  isActive(index: number): boolean {
    return this.selectedItem === index;
  }
  isActiveTwo(index: number): boolean {
    return this.selectedItemTwo === index;
  }
  toggleItem(index:number){
  this.selectedItem = this.selectedItem === index ? null : index;
  if(index == 0){
   this.status = ''
        }
  if(index == 1){

this.status = 'success'
  }
  if(index == 2){

    this.status = 'reversed'
      }
}

toggleItemTwo(index:number){
  this.selectedItemTwo = this.selectedItemTwo === index ? null : index;
  if(index == 0){
   this.orderType = 'visa'
        }
  if(index == 1){

this.orderType = 'mastercard'
  }
  if(index == 2){

    this.orderType = 'verve'
      }
}
send(){
this.btTransactionsService.filter.next({sort_by:this.selectedCityOne.name,start_date:this.date,end_date:this.dateTwo,start_amount:this.amountOne,end_amount:this.amountTwo,status:this.status,order_type:this.orderType});

}
clear(){
 this.btTransactionsService.filter.next({start_date:'',end_date:'',start_amount:0,end_amount:0,status:'',order_type:''})
}
  ngOnInit(): void {
    this.citiesOne = [
      { name: 'card-transaction'},
      {name:'user-transaction'}
  ];

  this.btTransactionsService.filter.subscribe(res => {
    if(res.status == 'reversed'){
      this.selectedItem = 2;
      this.status = 'reversed';
    }
    if(res.status == 'success'){
      this.selectedItem = 1;
      this.status = 'success'
    }
  
    if(res.status == ''){
      this.selectedItem = 0;
      this.status = '';
    }
  
    if(res.status == undefined){
  this.selectedItem = 0;
  this.status = ''
    }
      this.date = res.start_date;
      this.dateTwo = res.end_date;
   if(res.start_amount !== undefined && res.end_amount !== undefined ){
    this.amountOne = res.start_amount;
    this.amountTwo = res.end_amount;
   }
   else {
    this.amountOne = 0;
    this.amountTwo = 0;
   }
    })
  }

  ngOnDestroy(): void {
   
    console.log('ngOnDestroy called');
    // Unsubscribe from the filter subscription to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}
