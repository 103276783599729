import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-service-provider',
  templateUrl: './service-provider.component.html',
  styleUrls: ['./service-provider.component.css']
})
export class ServiceProviderComponent implements OnInit{
  data:any = [];
  isLoading = false;
  isLoadingTwo = false;
  modalName = '';
  name:string = '';
  airtimeToCashPhone:string = '';
  airtimeToCash:number = 0;
  swapInstruction:string = '';
  swapCode:string = 'sa';
enabledSwap:boolean = false;
autoSwap:boolean = false;
enableCheapAirtime:boolean = false;
sharePin:string = '';
cheapAirime:string = '';
cheapAirtimeToken:string = '';
  visible: boolean = false;
  private apiKey:string = '';
  constructor(private http:HttpClient,private messageService:MessageService){
    this.apiKey = environment.apiUrl;
  }
showDialog(name:string){
this.modalName = name;
this.visible = true;
console.log(this.enabledSwap)
if(this.modalName == 'MTN'){
this.name = this.data[0].name
this.airtimeToCashPhone = this.data[0].airtime_to_cash_phone;
this.airtimeToCash = this.data[0].airtime_to_cash_percent;
this.swapInstruction  = this.data[0].swap_instructions;
this.swapCode = this.data[0].swap_code;
this.enabledSwap = this.data[0].swap_enabled;
this.autoSwap = this.data[0].auto_swap_enabled;
this.sharePin = this.data[0].share_pin;
this.enableCheapAirtime = this.data[0].cheap_airtime_enabled;
this.cheapAirime = this.data[0].cheap_airtime_percent;
this.cheapAirtimeToken = this.data[0].cheap_airtime_token
}
if(this.modalName == 'AIRTEL'){
  this.name = this.data[1].name
  this.airtimeToCashPhone = this.data[1].airtime_to_cash_phone;
  this.airtimeToCash = this.data[1].airtime_to_cash_percent;
  this.swapInstruction  = this.data[1].swap_instructions;
  this.swapCode = this.data[1].swap_code;
  this.enabledSwap = this.data[1].swap_enabled;
  this.autoSwap = this.data[1].auto_swap_enabled;
  this.sharePin = this.data[1].share_pin;
  this.enableCheapAirtime = this.data[1].cheap_airtime_enabled;
  this.cheapAirime = this.data[1].cheap_airtime_percent;
  this.cheapAirtimeToken = this.data[1].cheap_airtime_token
  }

  if(this.modalName == '9MOBILE'){
    this.name = this.data[2].name
    this.airtimeToCashPhone = this.data[2].airtime_to_cash_phone;
    this.airtimeToCash = this.data[2].airtime_to_cash_percent;
    this.swapInstruction  = this.data[2].swap_instructions;
    this.swapCode = this.data[2].swap_code;
    this.enabledSwap = this.data[2].swap_enabled;
    this.autoSwap = this.data[2].auto_swap_enabled;
    this.sharePin = this.data[2].share_pin;
    this.enableCheapAirtime = this.data[2].cheap_airtime_enabled;
    this.cheapAirime = this.data[2].cheap_airtime_percent;
    this.cheapAirtimeToken = this.data[2].cheap_airtime_token
    }
    if(this.modalName == 'GLO'){
      this.name = this.data[3].name
      this.airtimeToCashPhone = this.data[3].airtime_to_cash_phone;
      this.airtimeToCash = this.data[3].airtime_to_cash_percent;
      this.swapInstruction  = this.data[3].swap_instructions;
      this.swapCode = this.data[3].swap_code;
      this.enabledSwap = this.data[3].swap_enabled;
      this.autoSwap = this.data[3].auto_swap_enabled;
      this.sharePin = this.data[3].share_pin;
      this.enableCheapAirtime = this.data[3].cheap_airtime_enabled;
      this.cheapAirime = this.data[3].cheap_airtime_percent;
      this.cheapAirtimeToken = this.data[3].cheap_airtime_token
      }
}

update(){
if(this.modalName == 'MTN'){
this.http.post(this.apiKey + '/zap/admin/configuration/flip',{id:1,name:this.name,airtime_to_cash_phone:this.airtimeToCashPhone,
airtime_to_cash_percent:this.airtimeToCash,swap_instructions:this.swapInstruction,swap_code:this.swapCode,auto_swap_enabled:this.autoSwap,
cheap_airtime_enabled:this.enableCheapAirtime,cheap_airtime_percent:this.cheapAirime,swap_enabled:this.enabledSwap,share_pin:this.sharePin,cheap_airtime_token:this.cheapAirtimeToken
}).subscribe({
  next:(res:any) => {
    this.visible = false;
    this.fetchRequest();
  }
})
}
if(this.modalName == 'AIRTEL'){
  this.http.post(this.apiKey + '/zap/admin/configuration/flip',{id:2,name:this.name,airtime_to_cash_phone:this.airtimeToCashPhone,
  airtime_to_cash_percent:this.airtimeToCash,swap_instructions:this.swapInstruction,swap_code:this.swapCode,auto_swap_enabled:this.autoSwap,
  cheap_airtime_enabled:this.enableCheapAirtime,cheap_airtime_percent:this.cheapAirime,swap_enabled:this.enabledSwap,share_pin:this.sharePin,cheap_airtime_token:this.cheapAirtimeToken
  }).subscribe({
    next:(res:any) => {
      this.visible = false;
      this.fetchRequest();
    }
  })
  }

  if(this.modalName == '9MOBILE'){
    this.http.post(this.apiKey + '/zap/admin/configuration/flip',{id:3,name:this.name,airtime_to_cash_phone:this.airtimeToCashPhone,
    airtime_to_cash_percent:this.airtimeToCash,swap_instructions:this.swapInstruction,swap_code:this.swapCode,auto_swap_enabled:this.autoSwap,
    cheap_airtime_enabled:this.enableCheapAirtime,cheap_airtime_percent:this.cheapAirime,swap_enabled:this.enabledSwap,share_pin:this.sharePin,cheap_airtime_token:this.cheapAirtimeToken
    }).subscribe({
      next:(res:any) => {
        this.visible = false;
        this.fetchRequest();
      }
    })
    }

    if(this.modalName == 'GLO'){
      this.http.post(this.apiKey + '/zap/admin/configuration/flip',{id:4,name:this.name,airtime_to_cash_phone:this.airtimeToCashPhone,
      airtime_to_cash_percent:this.airtimeToCash,swap_instructions:this.swapInstruction,swap_code:this.swapCode,auto_swap_enabled:this.autoSwap,
      cheap_airtime_enabled:this.enableCheapAirtime,cheap_airtime_percent:this.cheapAirime,swap_enabled:this.enabledSwap,share_pin:this.sharePin,cheap_airtime_token:this.cheapAirtimeToken
      }).subscribe({
        next:(res:any) => {
          this.visible = false;
          this.fetchRequest();
        }
      })
      }
}
fetchRequest(){
  this.http.get(this.apiKey + '/zap/admin/configuration/flip').subscribe({
    next:(res:any) => {
      this.data = res.data
    },error:(res:any) => {
      this.isLoading = false;
      this.messageService.add({severity:'error', summary:'Rejected', detail:res.message})
    }
  })
}
cancel(){
  this.visible = false;
}
ngOnInit(): void {
this.fetchRequest()
}
}
