import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { VcService } from '../c-virtual-card/c-virtual-card.service';

@Component({
  selector: 'app-configurations-header',
  templateUrl: './configurations-header.component.html',
  styleUrls: ['./configurations-header.component.css']
})
export class ConfigurationsHeaderComponent {
  constructor(private route:Router,private vcService:VcService){}
  visible: boolean = false;
  modalName = '';
  visibleTwo = false;
  showDialog(name:string) {
    if(this.route.url == '/configurations/internet/data-plan/mtn' || this.route.url == '/configurations/internet/data-plan/airtel' || this.route.url == '/configurations/internet/data-plan/glo' || this.route.url == '/configurations/internet/data-plan/9mobile' ){
this.visibleTwo  =true
    }
if(this.route.url == '/configurations/virtual-card'){
this.vcService.showDialog.next('');
}
  }
  
  cancel(){
    
  }
}
