import { Component, OnInit } from '@angular/core';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';

@Component({
  selector: 'app-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.css']
})
export class GiftCardComponent implements OnInit{
role:string = '';
constructor(private authService:AuthPageService){}
ngOnInit(): void {
  this.authService.roleName.subscribe((res:any) => {
    this.role = res.data.role.role_name
  })
}
}