<div class="header-three" *ngIf="!isLoading">
    <table>
        <tr>
            <th>
                <div style="display: flex; align-items: center;">
                    <img *ngIf = 'data.profile_image == ""' src="../../../../assets/Frame 40462.png" width="50" alt="">
                    <img *ngIf = 'data.profile_image !== ""' [src]="data.profile_image" width="50" alt="">
                    <div style="padding-left: 7px;">
                        <p-inputSwitch [(ngModel)]="checked" (click) = 'isActive()'></p-inputSwitch>
                        <p (click)="showDialogThree('Update User Profile')" style="margin: 0px; font-size: 12px;color: #151A20;font-weight: 600;cursor: pointer;">{{data.full_name}}</p>
                        <span style="font-weight: 400;margin: 0px;font-size: 12px;">Tier {{data.account_level}}</span>
                    </div>
                </div>
            </th>
            <th>
                <div class="btn-group-approved">
                    <img src="../../../../../assets/approved.png" width="20" alt="">
    <p>KYC Approved</p>
                </div>
            </th>
            <th *ngIf="data.kyc_status == 'pending'">
                <div class="btn-group-pending">
    <p>KYC {{data.kyc_status | titlecase}}</p>
                </div>
            </th>
            <th>
                <div class="btn-group-message" (click)="showDialog('Message Customer')">
                    <img src="../../../../../assets/message.png" width="20" alt="">
    <p>Message</p>
                </div>
            </th>
            <th>
                <div class="btn-group-upgrade" (click)="showDialogTwo('Upgrade User')">
                    <img src="../../../../../assets/upgrade.png" width="20" alt="">    
    <p>Upgrade Customer</p>
                </div>
            </th>
            <th>
                <div class="btn-group-suspend"(click)="confirm($event)"  >
                    <img src="../../../../../assets/suspend.png" width="20" alt="">     
    <p>Suspend Customer</p>
                </div>
            </th>
            <th *ngIf="data.is_suspended == true">
                <div class="btn-group-suspend"(click)="confirm($event)"  >
                    <img src="../../../../../assets/suspend.png" width="20" alt="">     
    <p>Unsuspend Customer</p>
                </div>
            </th>
        </tr>
        </table>
        </div>
        <p-dialog [header]="modalName" [maximizable]="true" [(visible)]="visible" [modal]="true" [style]="{ width: '35vw', 'font-family':'poppins'}" [draggable]="false" [resizable]="false">
            <app-cp-message *ngIf="visible" style="font-family: poppins;"></app-cp-message>
          </p-dialog>
          <p-dialog [header]="modalName" [(visible)]="visibleTwo" [modal]="true" [style]="{ width: '35vw', 'font-family':'poppins'}" [draggable]="false" [resizable]="false">
           <app-upgrade  *ngIf="visibleTwo"  style="font-family: poppins;" [tier]="accountLevel"></app-upgrade>
          </p-dialog>
        
          <p-dialog [header]="modalName" [(visible)]="visibleThree" [modal]="true" [style]="{ width: '35vw', 'font-family':'poppins'}" [draggable]="false" [resizable]="false">
           <div class="user-profile" *ngIf="!isLoadingTwo">
            <input type="text" [(ngModel)] = 'firstName' placeholder="firstName">
            <br>
            <input type="text" [(ngModel)] = 'lastName' placeholder="lastName">
            <br>
            <button (click)="saveProfile()" [disabled]="firstName + ' ' + lastName == data.full_name">Save Profile</button>
           </div>
           <div style="display: flex;  justify-content: center;padding: 40px;position:relative; left: 10px;" *ngIf = 'isLoadingTwo'>
            <app-spinner ></app-spinner>
            </div>
           </p-dialog>
        <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
            <app-spinner ></app-spinner>
            </div>
        
            <div class="card flex justify-content-center gap-2">
                <p-toast></p-toast>
                <p-confirmPopup [style]="{'font-family':'poppins','font-size':'12px'}"></p-confirmPopup>
            </div>
        <app-referral-profile-overview></app-referral-profile-overview>
        <app-referral-profile-transaction></app-referral-profile-transaction>