import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-ab-overview',
  templateUrl: './ab-overview.component.html',
  styleUrls: ['./ab-overview.component.css']
})
export class AbOverviewComponent {
  isLoading = false;
  overview:any= {ngn:0,usd:0}
  modalName = '';
  visible: boolean = false;
  role:string = '';
  private apiKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService,){
    this.apiKey = environment.apiUrl;
  }
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
  
  reload(){
    location.reload()
  }
  ngOnInit(): void {

    this.http.get(this.apiKey + '/zap/admin/business/business').pipe(catchError(this.handleError)).subscribe({
      next:(res:any) => {
        this.isLoading = false
        console.log(res.data)
        this.overview.ngn = res.data.ngn_wallet_balance
        this.overview.usd = res.data.usd_wallet_balance;
      },
      error:(res:any) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'error', detail:res,life:2000 });
      }
    })
  }
  handleError(error:any) { let errorMessage = 'No Internet (overview)';
  
  if(error.error.message){
    errorMessage = error.error.message
  }
  
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
