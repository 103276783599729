<div *ngIf="!isLoading">
   <h4 style="font-size: 14px; color: #546881; padding-left: 25px; padding-bottom: 10px;">Bills Payment</h4>
   <div class="header-one">
       <div class="card">
           <div>
              <img src="../../assets/Data.png" width="30" alt="">
              <h4 style="color: #1D242D;margin-bottom: 2px;">Internet</h4>
              <p style="color: #546881;margin-top: 0px;font-size: 12px;">Manage Data configuration, price, purchase fee.</p>
           </div>
                  <div style="display: flex;align-items: flex-start;gap: 10px;">
                      <img src="../../assets/Stockholm-icons (14).png" style="cursor: pointer;" routerLink="/configurations/internet/data-plan/mtn" width="30" alt="">
   <p-inputSwitch [(ngModel)]="checked" (click) = 'activateChecked()'></p-inputSwitch>
                  </div>
              </div>
              <div class="card">
               <div>
                  <img src="../../assets/airtime.png" width="30" alt="">
                  <h4 style="color: #1D242D;margin-bottom: 2px;">Airtime</h4>
                  <p style="color: #546881;margin-top: 0px;font-size: 12px;">Manage Airtime configuration, price, purchase fee.</p>
               </div>
               <div style="display: flex;align-items: flex-start;gap: 10px;">
   <p-inputSwitch [(ngModel)]="checkedTwo" (click) = 'activateCheckedTwo()'></p-inputSwitch>
               </div>
                  </div>
                  <div class="card">
                   <div>
                      <img src="../../assets/electricity.png" width="30" alt="">
                      <h4 style="color: #1D242D;margin-bottom: 2px;">Electricity</h4>
                      <p style="color: #546881;margin-top: 0px;font-size: 12px;">Manage Electricity configuration, price, purchase fee.</p>
                   </div>
                   <div style="display: flex;align-items: flex-start;gap: 10px;">
                       <img src="../../assets/Stockholm-icons (14).png" style="cursor: pointer;" (click)="showDialog('electricity')" width="30" alt="">
    <p-inputSwitch [(ngModel)]="checkedThree" (click) = 'activateCheckedThree()'></p-inputSwitch>
                   </div>
                      </div>
                      <p-toast></p-toast>
              
      
   </div>
   
   <div class="header-two">
       <div class="card">
           <div>
              <img src="../../assets/cabletv.png" width="30" alt="">
              <h4 style="color: #1D242D;margin-bottom: 2px;">Cable Networks</h4>
              <p style="color: #546881;margin-top: 0px;font-size: 12px;">Manage Cable Networks configuration, price, purchase fee.</p>
           </div>
           <div style="display: flex;align-items: flex-start;gap: 10px;">
               <img src="../../assets/Stockholm-icons (14).png" style="cursor: pointer;" (click)="showDialog('cable')" width="30" alt="">
   <p-inputSwitch [(ngModel)]="checkedFour" (click) = 'activateCheckedFour()'></p-inputSwitch>
           </div>
              </div>
              <div class="card">
               <div>
                  <img src="../../assets/betting.png" width="30" alt="">
                  <h4 style="color: #1D242D;margin-bottom: 2px;">Betting</h4>
                  <p style="color: #546881;margin-top: 0px;font-size: 12px;">Manage Betting configuration, price, purchase fee.</p>
               </div>
               <div style="display: flex;align-items: flex-start;gap: 10px;">
                   <img src="../../assets/Stockholm-icons (14).png" style="cursor: pointer;" (click)="showDialog('betting')" width="30" alt="">
       <p-inputSwitch [(ngModel)]="checkedFive" (click) = 'activateCheckedFive()'></p-inputSwitch>
               </div>
                  </div>
                  <div class="card" style="opacity: 0;cursor:default;">
                   <div>
                      <img src="../../assets/betting.png" width="30" alt="">
                      <h4 style="color: #1D242D;margin-bottom: 2px;">Betting</h4>
                      <p style="color: #546881;margin-top: 0px;font-size: 12px;">Manage Data configuration, price, purchase fee.</p>
                   </div>
                   <div style="display: flex;align-items: flex-start;gap: 10px;">
                       <img src="../../assets/Stockholm-icons (14).png" width="30" alt="">
           <p-inputSwitch [(ngModel)]="checkedThree"></p-inputSwitch>
                   </div>
                      </div>
                  
   </div>
</div>
<p-dialog [header]="modalName" *ngIf="visible" [(visible)]="visible" [modal]="true" [style]="{ width: '30vw' }" [draggable]="false" [resizable]="false">
  <div *ngIf="!isLoadingTwo">
   <p style="font-size: 14px; color: #546881;font-family: poppins;">Manage your package transaction fee.</p>
   <p style="font-size: 14px;color: #111014;font-family: poppins;">Package</p>
   <input type="text" class="package" [placeholder]="modalName" disabled>
   <p style="font-size: 14px;color: #111014;font-family: poppins;">Transaction Fee</p>
   <div class="inputWithIcon">
       <input type="number" placeholder="0" #input [(ngModel)]="fees">
       <img src="../../assets/Frame 40550.png" width="60" alt="">
     </div> 
     <div class="container-three">
       <button class="filter" (click)="cancel()">Cancel</button>
       <button class="apply" (click)="updateFee(modalName,input)">Save</button>
   </div>
  </div>
  <div style="display: flex;justify-content: center;padding: 40px;position:relative; right:5px;top: 20px;" *ngIf = 'isLoadingTwo'>
   <app-spinner ></app-spinner>
   </div>
</p-dialog>
<div style="display: flex;justify-content: center;padding: 40px;position:relative; right: 80px;top: 100px;" *ngIf = 'isLoading'>
   <app-spinner ></app-spinner>
   </div>