import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-referral-profile-overview',
  templateUrl: './referral-profile-overview.component.html',
  styleUrls: ['./referral-profile-overview.component.css']
})
export class ReferralProfileOverviewComponent {
  isLoading = true;
  overview:any = {total_users:0,ngn_balance:0,usd_balance:0,total_transactions:0}
  modalName = '';
  role:any = '';
  referralCode:any = '';
  visible: boolean = false;
  private apiKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService){
    this.apiKey = environment.apiUrl
  }
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
  copy(val:any){
    this.messageService.add({ severity: 'info', summary: 'Copied', detail: val })
    navigator.clipboard.writeText(val);
  }
  ngOnInit(): void {
    
   let dataInString:any = localStorage.getItem('userId');
   let userId = JSON.parse(dataInString)
    this.isLoading = true;

    this.http.get(this.apiKey + '/zap/admin/lock/referrals/' + userId + '?page=1&limit=1').pipe(catchError(this.handleError)).subscribe(
      {
        next:(res:any) => {
          this.isLoading = false;
this.overview = res.data.stats;
this.referralCode = res.data.data.referral_code
        },
        error:(res)=> {
           this.isLoading = false;
           this.messageService.add({ severity: 'error', detail:res,life:2000 });
        }
      }
    )
   
  }
  handleError(error:any) { let errorMessage = 'No internet (overview)';

  if(error.error.message){
    errorMessage = error.error.message
  }

    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });

  
  }
}
