import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-bills-payment',
  templateUrl: './bills-payment.component.html',
  styleUrls: ['./bills-payment.component.css']
})
export class BillsPaymentComponent implements OnInit{
  isLoading = false;
  isLoadingTwo = false;
fees:any = 0;
  modalName = '';
  checked: boolean = false;
  checkedTwo:boolean = false;
  checkedThree:boolean = false;
  checkedFour:boolean = false;
  checkedFive:boolean = false;
  visible: boolean = false;
data:any = {};
private apiKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService){
    this.apiKey = environment.apiUrl;
  }
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
if(this.modalName == 'electricity'){
  this.fees = this.data.airtime
}
if(this.modalName == 'cable'){
  this.fees = this.data.cable;

}
if(this.modalName == 'betting'){
this.fees = this.data.betting;
}
  }
  cancel(){
    this.visible = false;
  }
  activateChecked(){
    this.http.post(this.apiKey + '/zap/admin/configuration/toggle',{service:'internet',status:this.checked}).subscribe({
      next:(res:any) => {
        this.messageService.add({ severity: 'info', summary:'Internet', detail:res.message});
      },
      error:(res:any) => {
        this.messageService.add({ severity: 'error', summary:'Rejected', detail:res.message});
      }
    })
  }

  activateCheckedTwo(){
    this.http.post(this.apiKey + '/zap/admin/configuration/toggle',{service:'airtime',status:this.checkedTwo}).subscribe({
      next:(res:any) => {
        this.messageService.add({ severity: 'info', summary:'airtime', detail:res.message});
      },
      error:(res:any) => {
        this.messageService.add({ severity: 'error', summary:'Rejected', detail:res.message});
      }
    })
  }

  activateCheckedThree(){
    this.http.post(this.apiKey + '/zap/admin/configuration/toggle',{service:'electricity',status:this.checkedThree}).subscribe({
      next:(res:any) => {
        this.messageService.add({ severity: 'info', summary:'Electricity', detail:res.message});
      },
      error:(res:any) => {
        this.messageService.add({ severity: 'error', summary:'Rejected', detail:res.message});
      }
    })
  }

  activateCheckedFour(){
    this.http.post(this.apiKey + '/zap/admin/configuration/toggle',{service:'cable',status:this.checkedFour}).subscribe({
      next:(res:any) => {
        this.messageService.add({ severity: 'info', summary:'Cable', detail:res.message});
      },
      error:(res:any) => {
        this.messageService.add({ severity: 'error', summary:'Rejected', detail:res.message});
      }
    })
  }

  activateCheckedFive(){
    this.http.post(this.apiKey + '/zap/admin/configuration/toggle',{service:'betting',status:this.checkedFive}).subscribe({
      next:(res:any) => {
        this.messageService.add({ severity: 'info', summary:'Betting', detail:res.message});
      },
      error:(res:any) => {
        this.messageService.add({ severity: 'error', summary:'Rejected', detail:res.message});
      }
    })
  }
  updateFee(name:string,val:any){
   this.isLoadingTwo = true;
this.http.post(this.apiKey + '/zap/admin/configuration/fees',{service:name,fee:val.value}).subscribe(
  {
    next:(res:any) => {
      this.messageService.add({ severity: 'info', summary:this.modalName, detail:res.message});
      this.fetchFees();
    },
    error:(res:any) => {
      this.isLoadingTwo = false;
      this.messageService.add({ severity: 'error', summary:'Rejected', detail:res.message});
    }
  }
)
  }
  fetchFees(){
    this.http.get(this.apiKey + '/zap/admin/configuration/fees').subscribe({
      next:(res:any) => {
        this.isLoadingTwo = false;
        console.log(res)
this.data = res.data;
      }
    })
  }

  fetchStatus(){
    this.isLoading = true;
    this.http.get(this.apiKey + '/zap/admin/configuration/appsettings').subscribe({
      next:(res:any) => {
        this.isLoading = false;
        console.log(res)
let status = res.data;
this.checked = status.internet;
this.checkedTwo = status.airtime;
this.checkedThree = status.electricity;
this.checkedFour = status.cable;
this.checkedFive = status.betting;
      },error:(res:any) => {
        this.isLoading = false;
        this.messageService.add({severity:'error', summary:'Rejected', detail:res.message})
      }
    })
  }
  ngOnInit(): void {
    this.isLoading = true;
  this.fetchFees() 
this.fetchStatus()
    
  }

}
