import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-fx-update-modal',
  templateUrl: './fx-update-modal.component.html',
  styleUrls: ['./fx-update-modal.component.css']
})
export class FxUpdateModalComponent implements OnInit{
  isLoading = false;
  @Input() data:any = {ngn_usd:0,usd_ngn:0,fx_fee:0,min_usd:0,min_ngn:0,fx_usd:0,fx_ngn:0,funding_fee:0}
  @Output() updated = new EventEmitter<any>
  private apiKey:string = '';
  constructor(private http:HttpClient,private messageService:MessageService){
    this.apiKey = environment.apiUrl;
  }
ngn_usd = 0;
usd_ngn = 0;
fx_fee = 0;
min_usd = 0;
min_ngn = 0;
fx_usd = 0;
fx_ngn = 0;
funding_fee = 0;
update(){
  this.isLoading  = true;
  this.http.post(this.apiKey + '/zap/admin/configuration/fx',{
    ngn_to_usd: this.ngn_usd,
    usd_to_ngn: this.usd_ngn,
    fx_fee: this.fx_fee,
    min_usd_conversion: this.min_usd,
    min_ngn_conversion: this.min_ngn,
    fx_usd_cap: this.fx_usd,
    fx_ngn_cap: this.fx_ngn,
    funding_fee: this.funding_fee
  }).subscribe({
    next:(res:any) => {
      this.messageService.add({ severity: 'info', summary:'Forex Settings', detail:res.message});
      this.isLoading = false;
this.updated.emit()
    },error:(res:any) => {
      this.isLoading = false;
      this.messageService.add({ severity: 'error', summary:'Rejected!', detail:res.message});
    }
  })
}
ngOnInit(): void {
  this.ngn_usd = this.data.ngn_usd;
  this.usd_ngn = this.data.usd_ngn;
  this.fx_fee = this.data.fx_fee; 
  this.min_usd = this.data.min_usd;
  this.min_ngn = this.data.min_ngn;
  this.fx_usd = this.data.fx_usd;
  this.fx_ngn = this.data.fx_ngn;
  this.funding_fee = this.data.funding_fee;
}
}
