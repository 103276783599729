import { Component } from '@angular/core';

@Component({
  selector: 'app-transaction-header',
  templateUrl: './transaction-header.component.html',
  styleUrls: ['./transaction-header.component.css']
})
export class TransactionHeaderComponent {
  visible: boolean = false;
  modalName = '';
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
}
