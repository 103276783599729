import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings-header-two',
  templateUrl: './settings-header-two.component.html',
  styleUrls: ['./settings-header-two.component.css']
})
export class SettingsHeaderTwoComponent {
constructor(private route:Router){

}

active(url:string){
if(this.route.url == url){
return true
}
else {
  return false
}
}
}
