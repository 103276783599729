import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterThree'
})
export class FilterThreePipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();


    return items.filter(item => {
      // Assuming each item has a 'name' property
      const timestampStr = item.created_at;
      const timestampObj = new Date(timestampStr);
      
      // Format date with month, day, and year
      const dateOptions:any = { month: 'short', day: 'numeric', year: 'numeric' };
      const formattedDate = timestampObj.toLocaleString('en-US', dateOptions);
      
      // Format time
      const timeOptions:any = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
      const formattedTime = timestampObj.toLocaleString('en-US', timeOptions);
      return item.merchant.toLowerCase().includes(searchText) || item.user_id.toLowerCase().includes(searchText) || item.amount.toString().toLowerCase().includes(searchText)
      || item.user_id.toLowerCase().includes(searchText) || item.card_type.toLowerCase().includes(searchText) || item.reference.toLowerCase().includes(searchText)  || formattedTime.toLowerCase().includes(searchText) || formattedDate.toLowerCase().includes(searchText)
    });
  }

}
