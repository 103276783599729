<div class="container">
    <h4 style="font-size: 15px;margin-bottom: 2px;color:#2E1A52;">Step 1</h4>
    <h4 style="font-size: 14px;margin-bottom: 2px;color:#2E1A52;margin-top: 4px;">Card Information</h4>
    <p style="font-size: 12px;margin-top: 0px;color: #546881;">Enter information about card</p>
    <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Issuer</p>
    <input type="text" placeholder="Zappy custom cards" [(ngModel)]="name">
    <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Brand Name</p>
    <p style="color:#909DAD;margin-top: 2px;font-size: 13px;">Select Service Provider</p>
    <div class="service-provider">
        <div class="card" [class.active]="isActive(0)" (click)="toggleItem(0)">
            <img src="../../../../assets/CARD.png" width="20" alt="">
            <p>Visa</p>
        </div>
        <div class="card" [class.active]="isActive(1)" (click)="toggleItem(1)">
            <img src="../../../../assets/VERVE 2.png" width="20" alt="">
            <p>Verve</p>
        </div>
        <div class="card" [class.active]="isActive(2)" (click)="toggleItem(2)">
            <img src="../../../../assets/MASTERCARD.png" width="20" alt="">
            <p>Mastercard</p>
        </div>
    </div>
    <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Currency Type</p>
 
    <div class="select">
        <p-dropdown [options]="cities" [style]="{width:'470px','font-family':'poppins'}" [(ngModel)]="selectedCity" optionLabel="name" [showClear]="false" placeholder="Select Currency Type"></p-dropdown>
    </div>
   
    <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Card Type</p>
 
    <div class="select">
        <p-dropdown [options]="citiesTwo" [style]="{width:'470px'}" [(ngModel)]="selectedCityTwo" optionLabel="name" [showClear]="false" placeholder="Select Card Type"></p-dropdown>
    </div>
    <img [src]="imgFile" width="200" style="margin: 0px;" alt="">
<div *ngIf="imgFile ==''">
    <p style="font-size: 12px;color: #111014;margin-bottom: 2px;">Card Image</p>
<div style="display: flex;align-items: center;gap:7px;">
    <img src="../../../../assets/preview-warning.png" width="15" alt="">
    <p style="font-size: 9px;color: #546881;">Upload image from your device, (PNG, JPG). Image size 5MB.</p>
</div>
</div>
<div class="file-upload">
    <div class="file-select">
      <div class="file-select-button" id="fileName">Choose Image</div>
      <div class="file-select-name" id="noFile">No file chosen...</div> 
      <input type="file" name="chooseFile" id="chooseFile" (change)="inputFile($event)">
    </div>
  </div>
<br>
<div class="container-three">
  
    <button class="apply" (click)="save()" [disabled]="selectedCity == '' || selectedCityTwo == '' || imgFile == '' || name == ''">Save and Continue</button>
</div>
</div>