<div *ngIf="role == 'Administrator'">
    <app-analytics-header></app-analytics-header>
<app-analytics-overview></app-analytics-overview>
<div style="padding: 10px;border: 1px solid #E7E7E8;margin:20px;border-radius: 7px;width: 90%;">
<app-analytics-total-transactions></app-analytics-total-transactions>
</div>
<div style="display: flex;width: 94%;">
    <div style="padding: 10px;width: 100%;border: 1px solid #E7E7E8;margin:20px;border-radius: 7px;">
        <app-analytics-user-management></app-analytics-user-management>
    </div>
    <div style="padding: 10px;width: 100%;border: 1px solid #E7E7E8;margin:20px;border-radius: 7px;">
    <app-analytics-bill-payment></app-analytics-bill-payment>
    </div>
</div>
<div style="display: flex;width: 94%;">
    <div style="padding: 10px;width: 100%;border: 1px solid #E7E7E8;margin:20px;border-radius: 7px;">
        <app-analytics-airtime-swap></app-analytics-airtime-swap>
    </div>
    <div style="padding: 10px;width: 100%;border: 1px solid #E7E7E8;margin:20px;border-radius: 7px;">
        <app-analytics-card-transaction></app-analytics-card-transaction>
    </div>
</div>
<div style="display: flex;width: 94%;">
    <div style="padding: 10px;width: 100%;border: 1px solid #E7E7E8;margin:20px;border-radius: 7px;">
        <div class="header-one">
            <h4>Gift Card</h4>
        </div>
    <app-analytics-gift-card></app-analytics-gift-card>
    </div>
    <div style="padding: 10px;width: 100%;border: 1px solid #E7E7E8;margin:20px;border-radius: 7px;">
    <app-analytics-zap-pin></app-analytics-zap-pin>
    </div>
</div>
<div style="display: flex;width: 94%;">
    <div style="padding: 10px;width:47%;border: 1px solid #E7E7E8;margin:20px;border-radius: 7px;">
    <app-analytics-bank-transfer></app-analytics-bank-transfer>
    </div>
</div>
</div>