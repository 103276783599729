<div class="container">
    <div class="header-four">
     <div class="card">
         <h5 style="margin-top: 0px;">Start</h5>
         <div  style="zoom: 80%;"><p-calendar [(ngModel)]="date" [touchUI]="false" [readonlyInput]="true" [placeholder]="'dd/mm/yy'"></p-calendar> </div>   
 
     </div>
     <div class="card">
         <h5 style="margin-top: 0px;">End</h5>
 
        <div  style="zoom: 80%;"> <p-calendar [(ngModel)]="dateTwo" [touchUI]="false" [readonlyInput]="true" [placeholder]="'dd/mm/yy'"></p-calendar></div>
     </div>
     
       </div>
       <h5 style="font-weight: 600;font-size: 14px;margin-bottom: 0px;margin-top: 3px;">Amount</h5>
       <div class="header-four">
         <div>
           <h5>From</h5>
           <div  style="zoom: 80%;"> <p-inputNumber [(ngModel)]="amountOne" [style]="{'font-size':'5px'}" inputId="currency-ngn" mode="currency" currency="NGN" locale="en-US" [placeholder]="'NGN'"> </p-inputNumber></div>
         </div>
         <div>
           <h5>To</h5>
         
             <div  style="zoom: 80%;"><p-inputNumber  [(ngModel)]="amountTwo" inputId="currency-ngn" mode="currency" currency="NGN" locale="en-US" [placeholder]="'NGN'"> </p-inputNumber></div>
            </div>
         
           </div>
       
 <div class="header-seven">
 <p style="font-weight: 600;font-size: 14px;">Status</p>
 <div style="display: flex;gap: 5px;">
<p><span style="font-size: 11px;" [class.active]="isActive(0)" (click)="toggleItem(0)">All</span></p>
    <p><span style="font-size: 11px;" [class.active]="isActive(1)" (click)="toggleItem(1)">Locked</span></p>
   <p><span [class.active]="isActive(2)" (click)="toggleItem(2)" style="font-size: 11px;" >Unlocked</span></p>
 </div>
 </div>
 
 <div  class="header-eight">
  
   <button class="filter" (click)="clear()">Clear Filter</button>
   <button class="apply" (click)="send()">Apply</button>
 </div>
     
    
       
 </div>
 
 
 
 
 