import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GiftCardDialogBoxComponent } from '../gift-card-dialog-box/gift-card-dialog-box.component';
import { HttpClient } from '@angular/common/http';
import { GiftCardDialogService } from '../gift-card-dialog-box/gift-card-dialog.service';
import { MessageService } from 'primeng/api';
import { GcTransactionsService } from './gc-transactions.service';
import { catchError, exhaustMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';
interface transaction {
  id:number,
  user_id:string,
  amount:number,
  status:string,
  method:string,
  card_type:string,
  created_at:string // typo error
  }
@Component({
  selector: 'app-gift-card-transactions',
  templateUrl: './gift-card-transactions.component.html',
  styleUrls: ['./gift-card-transactions.component.css']
})
export class GiftCardTransactionsComponent implements OnInit,OnDestroy{
  modalName = ''
  visible:boolean = false;
  searchText = ''
  isLoading:boolean = false;
  data:any = [];
  start_date = '';
  end_date = '';
  page = 1;
  first: number = 1;
rows: number = 10;
totalRecord = 20;
status = '';
start_amount = '';
end_amount = '';
private filterSubscription:any;
private apiKey:string = '';
  constructor(private dialogService:GiftCardDialogService,private http:HttpClient, private messageService:MessageService,private gcTransactionService:GcTransactionsService){
    this.apiKey = environment.apiUrl;
  }
viewDetail(id:number){
  this.dialogService.id = this.data[id].id;
  this.showDialog('Giftcard Transactions')
}
openSnackBar(id:any) {
  this.showInfo(id)
  navigator.clipboard.writeText(id);
 }
showInfo(id:any) {
  this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
}


onPageChange(event:any) {
  this.first = event.first;
  this.rows = event.rows;
  this.page = event.page + 1;
  this.isLoading = true;
  this.getRequest().subscribe((res:any) => {
    this.data = res.data;
    this.isLoading = false;
   })
}
    showDialog(name:string) {
      this.modalName = name;
        this.visible = true;
    }
    filter(){
    this.filterSubscription = this.gcTransactionService.filter.pipe(tap((res:any) => {
        this.status = res.status == '' ? '' : '&status=' + res.status;
        this.start_date = res.start_date == '' ? '' : '&start_date' + res.start_date.toISOString();;
        this.end_date = res.end_date == '' ? '' : '&end_date' + res.end_date.toISOString();;
        this.start_amount = '&start_amount=' + res.start_amount;
        this.end_amount = '&end_amount=' + res.end_amount;
        this.isLoading = true;
        console.log(res.start_amount + 'amount')
       }),exhaustMap((res:any) => {return this.getRequest()})).pipe(catchError(this.handleError)).subscribe({
        next:(res:any) => {
          this.isLoading = false;
          this.data = res.data;
          this.totalRecord = res.total;
        },
        error:(res) => {
          this.isLoading = false;
          setTimeout(() => {
            this.messageService.add({ severity: 'error', detail:res,life:2000 });
           }, 2000);
        }
      })
    }
    handleError(error:any) { let errorMessage = 'No Internet (transactions)';
  
    if(error.error.message){
      errorMessage = error.error.message
    }
    
      console.log(errorMessage);
      return throwError(() => {
          return errorMessage;
      });
    }
    getRequest(){
      return  this.http.get(this.apiKey + '/zap/admin/transaction/giftcard?page=' + this.page + '&' + 'limit' + '=' + this.rows + this.start_amount +this. end_amount + this.status + this.start_date + this.end_date + '&search_query=' + this.searchText)
     }
     search(){
      this.isLoading = true;
      this.getRequest().subscribe((res:any) => {
        this.data = res.data;
        this.totalRecord = res.total;
      this.isLoading = false
       })
      }
ngOnInit(): void {
  this.isLoading = true;
  this.filter()
}
ngOnDestroy(): void {
   
  console.log('ngOnDestroy called');
  // Unsubscribe from the filter subscription to avoid memory leaks
  if (this.filterSubscription) {
    this.filterSubscription.unsubscribe();
  }
} 
}
