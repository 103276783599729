import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SaqMessageService } from './saq-message.service';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-saq-message',
  templateUrl: './saq-message.component.html',
  styleUrls: ['./saq-message.component.css']
})
export class SaqMessageComponent implements OnInit{
  message:string = '';
  data:any = {};
  isLoading = false;
  private apiKey:string = '';
  constructor(private http:HttpClient, private saqService:SaqMessageService,private messageService:MessageService){
    this.apiKey = environment.apiUrl
  }
  ngOnInit(): void {
    this.saqService.messageUsersSubject.subscribe(res => {
      this.data = res;
      console.log(res)
    })
    this.saqService.loading.subscribe(res => {
      this.isLoading = res;
    })
  }

  delete(){
    this.http.post(this.apiKey + '/zap/admin/support/delete',{id:this.data.id}).subscribe({
      next:(res:any) => {
        console.log(res)
        this.messageService.add({ severity: 'success', summary: 'Deleted', detail:res.message});
      },error:(res:any) => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message});
        console.log(res)
      }
    })
  }

  send(){
    this.http.post(this.apiKey + '/zap/admin/support/reply',{id:this.data.id,message:this.message,status:this.data.status}).subscribe({
      next:(res:any) => {
        console.log(res)
        this.message = '';
        this.messageService.add({ severity: 'success', summary: 'Approved', detail:res.message});
      },error:(res:any) => {
        this.message = '';
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message});
        console.log(res)
      }
    })
   
  }
  clear(){
    this.message = '';
  }
}
