import { Component } from '@angular/core';
import { cthTransactionService } from '../cth-transactions.service';

@Component({
  selector: 'app-cth-filter',
  templateUrl: './cth-filter.component.html',
  styleUrls: ['./cth-filter.component.css']
})
export class CthFilterComponent {
  toggle = false
  date:any = '';
  dateTwo:any = '';
  amountOne:number = 0;
  amountTwo:number = 0;
  status = ''
  selectedItem: number | null = 0;
  selectedItemTwo: number | null = 0;
  orderType = 'visa';
  private filterSubscription:any;
constructor(private cthTransactionsService:cthTransactionService){}

  isActive(index: number): boolean {
    return this.selectedItem === index;
  }
  isActiveTwo(index: number): boolean {
    return this.selectedItemTwo === index;
  }
  toggleItem(index:number){
  this.selectedItem = this.selectedItem === index ? null : index;
  if(index == 0){
   this.status = ''
        }
  if(index == 1){

this.status = 'success'
  }
  if(index == 2){

    this.status = 'reversed'
      }
}

send(){
this.cthTransactionsService.filter.next({start_date:this.date,end_date:this.dateTwo,start_amount:this.amountOne,end_amount:this.amountTwo,status:this.status,order_type:this.orderType});
}
clear(){
 this.cthTransactionsService.filter.next({start_date:'',end_date:'',start_amount:0,end_amount:0,status:'',order_type:''})
}
  ngOnInit(): void {
 this.filterSubscription =  this.cthTransactionsService.filter.subscribe(res => {
    if(res.status == 'reversed'){
      this.selectedItem = 2;
      this.status = 'reversed';
    }
    if(res.status == 'success'){
      this.selectedItem = 1;
      this.status = 'success'
    }
  
    if(res.status == ''){
      this.selectedItem = 0;
      this.status = '';
    }
      this.date = res.start_date;
      this.dateTwo = res.end_date;
   if(res.start_amount !== undefined && res.end_amount !== undefined ){
    this.amountOne = res.start_amount;
    this.amountTwo = res.end_amount;
   }
   else {
    this.amountOne = 0;
    this.amountTwo = 0;
   }
    })
  }

  ngOnDestroy(): void {
   
    console.log('ngOnDestroy called');
    // Unsubscribe from the filter subscription to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  } 
}
