<div class="header-two">
  <img src="../../../assets/search.png" alt="">
  <input type="text" placeholder="Search by....." (keyup.enter)="search()"  [(ngModel)]="searchText">
  <div class="filter-group" *ngIf="searchText !== ''" (click)="search()">
    <p>search</p>
  </div>
</div>
<div *ngIf="data.length !== 0">
  <div *ngIf="!isLoading">
    <br>
    
    <div class="header-four">
    <div class="recent-transaction">
    <table>
     <thead>
        <tr>
            <th>Name</th>
            <th>Card Type</th>
            <th>Amount</th>
            <th>Customer ID</th>
            <th></th>
            <th>Time</th>
            <th>Date</th>
            <th>Status</th>
        </tr>
     </thead>
    
       <tbody>
        <tr *ngFor="let transactions of data;let i = index">
            <td (click)="viewDetail(i)">{{transactions.name}}</td>
            <td (click)="viewDetail(i)">{{transactions.card_type}}</td>
            <td (click)="viewDetail(i)">${{transactions.amount | number}}</td>
              <td (click)="viewDetail(i)">{{ transactions.user_id | slice:0:10 }} {{ transactions.user_id.length > 10 ? '...' : '' }}</td>
                  <td><img (click)="openSnackBar(transactions.user_id)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt=""></td>
                  <td (click)="viewDetail(i)">{{transactions.created_at | date}}</td>
                  <td (click)="viewDetail(i)">{{transactions.created_at | date:'shortTime'}}</td>
                      <td *ngIf="transactions.status.toLowerCase() == 'success' || transactions.status.toLowerCase() == 'successful' || transactions.status.toLowerCase() == 'completed'"><p><span class="success">Successful</span></p></td>
                  <td  (click)="viewDetail(i)" *ngIf="transactions.status.toLowerCase() == 'reversed'"><p><span class="failed">Reversed</span></p></td>
              </tr>
       </tbody>
    </table>
    <br>
    <div style="display: flex; justify-content: space-between;align-items: center;">
      <p style="color: #151A20;font-weight: 500;font-size: 13px;">Showing 1-10 of {{this.page}} results</p>
      <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
    </div>
    </div>
</div>
    <p-dialog [header]="modalName" [(visible)]="visible" [modal]="true" [style]="{ width: '35vw' }" [draggable]="false" [resizable]="false">
        <app-gift-card-dialog-box *ngIf="visible" style="font-family: poppins;"></app-gift-card-dialog-box>
      </p-dialog>
</div>
</div>
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 50px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    <div style="display: flex;  justify-content: center;padding:80px;position:relative; right:100px;top: 0px;" *ngIf="data.length == 0 && !isLoading">
  
      <div>
       <img src="../../../assets/Empty state illustratio.png" style="margin: 0 auto;display: block;padding-bottom:10px;" width="80" alt="">
       <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">No records to view yet.</h5>
       <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Transaction records will appear here once customers make purchases.</p>
      </div>
     </div>