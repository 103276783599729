<div class="container">
    <div style="display: flex;justify-content: space-between;align-items: center;">
     <h4>Update Password</h4>
     <button (click)="updatePassword()" class="btn-message" [disabled]="oldPassword == '' || newPassword == '' || confirmPassword !== newPassword">Save New Password</button>
    </div>
       <div class="inputWithIcon">
        <img  (click)="toggle()" src="../../../assets/Trailing icons.png" width="40" alt="">
         <p>Old Password</p>
         <input [type]="isPassword ? 'text' : 'password'" placeholder="Enter Password" [(ngModel)]="oldPassword">
       </div>
       <div class="inputWithIcon">       
         <p>New Password</p>
         <img (click)="toggle()"   src="../../../assets/Trailing icons.png" width="40" alt="">
         <input  [type]="isPassword ? 'text' : 'password'" placeholder="Enter Password" [(ngModel)]="newPassword">
       </div>
       <div class="inputWithIcon">
        <p>Confirm Password</p>
        <img (click)="toggle()"  src="../../../assets/Trailing icons.png" width="40" alt="">
        <input [type]="isPassword ? 'text' : 'password'" type="password" placeholder="Enter Password" [(ngModel)]="confirmPassword">
      </div>
 </div>

 <div class="container">
    <div style="display: flex;justify-content: space-between;align-items: center;">
     <h4>Update Account Email</h4>
    </div>
       <div class="inputWithIcon">
         <p>Email</p>
         <input type="text" placeholder="Enter Email" [(ngModel)] = 'email'>
       </div>
      <button class="btn-message" [disabled]="email == ''" (click)="emailPassword()">Save Email</button>
 </div>
 <p-toast></p-toast>