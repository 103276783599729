<div *ngIf = 'role == "Administrator"'>
    <div *ngIf="!isLoading">
        <div class="header-one">
            <h4>Virtual Card Management</h4>
        
           <nav>
        <img src="../../../assets/Stockholm-icons (15).png" (click)="reload()" style="margin-left: 20px;" alt="">
          </nav>
        </div>
        <div class="payment-manager">
            <div class="card">
                <img src="../../../../assets/Icon.png" alt="">
                <p>Total Cards</p>
                
                <h4>{{overview.total_cards | number}}</h4>
            </div>
            <div class="card">
                <img src="../../assets/Widget icons.png" alt="">
                <p>Total Cards Balance</p>
                <h4>${{overview.total_balance | number}}</h4>
            </div>
        </div>
    </div>
    <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
        <app-spinner ></app-spinner>
        </div>
</div>
<p-dialog [header]="modalName" [(visible)]="visible" [modal]="true" [style]="{ width: '30vw' }" [draggable]="false" [resizable]="false">
    <app-v-card-filter *ngIf="visible" style="font-family: poppins;"></app-v-card-filter>
  </p-dialog>
  <app-vc-transaction></app-vc-transaction>
