import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-withdraw-dialog',
  templateUrl: './withdraw-dialog.component.html',
  styleUrls: ['./withdraw-dialog.component.css']
})
export class WithdrawDialogComponent {
  private apiKey:string = '';
  private adminKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService){
    this.apiKey = environment.apiUrl;
    this.adminKey = environment.admin_key
  }
  @Input() data:any = {user_id:'vkjv',transaction_id:' vhgch'}
isLoading:boolean = false
openSnackBar(id:any) {
  this.showInfo(id)
  navigator.clipboard.writeText(id);
 }
showInfo(id:any) {
  this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
}
approve(){
  this.isLoading = true
  this.http.post(this.apiKey + '/zap/admin/funding/withdraw/change-status',{id:[this.data.id],admin_key:this.adminKey,status:'approve'}).subscribe({
    next:(res:any) => {
      console.log(res)
this.isLoading = false;
location.reload()
      this.messageService.add({ severity: 'success', summary: 'Approved', detail:res.message});
    },error:(res:any) => {
      this.isLoading = false;
      this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message});
    }
  })
   
}
}
