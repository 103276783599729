import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-referral-profile',
  templateUrl: './referral-profile.component.html',
  styleUrls: ['./referral-profile.component.css']
})
export class ReferralProfileComponent implements OnInit {
  isLoading:boolean = false;
  isLoadingTwo:boolean = false;
  modalName = ''
  visible:boolean = false;
  visibleTwo:boolean = false;
  visibleThree:boolean = false;
  data:any = {user_id:''}
  virtualCard:any = []
  accountLevel:number = 0;
  firstName:string = '';
  lastName:string = '';
  private apiKey:string = ''
  checked = false;
  constructor(private route:Router , private http:HttpClient,private confirmationService: ConfirmationService, private messageService: MessageService){
    this.apiKey = environment.apiUrl;
  }
  isActive(){
    this.isLoading = true;
    this.http.post(this.apiKey + '/zap/admin/users/restriction',
    {user_id:this.data.user_id,is_soft_restricted:this.checked}).subscribe({
      next:(res:any) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'info', summary: 'Successful', detail:res.message, life: 3000 });
        this.fetchRequest()
      },error:(res:any) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: res.message, life: 3000 }); 
      }
    })
  }
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
  showDialogTwo(name:string) {
    this.modalName = name;
      this.visibleTwo = true;
  }
  showDialogThree(name:string) {
    this.modalName = name;
      this.visibleThree = true;
  }
  confirm(event: Event) {
    let dataInString:any = localStorage.getItem('userId');
   let userId = JSON.parse(dataInString)
      this.confirmationService.confirm({
          target: event.target as EventTarget,
          message: 'Are you sure that you want to proceed?',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
           
            if(this.data.is_suspended == false){
              this.http.post(this.apiKey + '/zap/admin/users/activate',{user_id:[userId],is_active:true}).subscribe({
                next:(res:any) => {
   this.messageService.add({ severity: 'info', summary:this.data.full_name , detail:res.message });
   this.fetchRequest()
                },
                error: (res:any) => {
                  this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message });
                  console.log(res)
                }
              })
            }

            else {
              this.http.post(this.apiKey + '/zap/admin/users/activate',{user_id:[userId],is_active:false}).subscribe({
                next:(res:any) => {
   this.messageService.add({ severity: 'info', summary:this.data.full_name , detail: res.message });
   this.fetchRequest()
                },
                error: (res:any) => {
                  this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message});
                  console.log(res)
                }
              })
            }
          },
          reject: () => {
              this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
          }
      });
  }
 processQueue(){
    this.isLoading = true;
    this.http.post('/zap/admin/users/webhooks',{user_id:this.data.user_id}).subscribe({
      next:(res:any) => {
        this.messageService.add({ severity: 'info', summary:this.data.full_name , detail:res.message });
   this.fetchRequest()
      },error:(res:any) => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message });  
      }
    })
  }

  saveProfile(){
    this.isLoadingTwo = true;
    this.http.post(this.apiKey + '/zap/admin/users/update-profile',{user_id:this.data.user_id,first_name:this.firstName,last_name:this.lastName}).subscribe({
      next:(res:any) => {
        this.messageService.add({ severity: 'info', summary:this.data.full_name , detail:res.message });
   this.fetchRequest()
   this.visibleThree = false;
   this.isLoadingTwo = false;
      },error:(res:any) => {
        this.isLoadingTwo = false;
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message });  
      }
    })
  }

  fetchRequest(){
    this.isLoading = true
  
   let dataInString:any = localStorage.getItem('userId');
   let userId = JSON.parse(dataInString)
    this.http.get(this.apiKey + "/zap/admin/users/particular?user_id=" + userId).subscribe(
      {
        next:(res:any) => {
          this.isLoading = false;
          this.data = res.data;
          this.accountLevel = res.data.account_level
          this.virtualCard = res.data.virtual_cards
          this.checked = res.data.is_soft_restricted
          console.log(res)
          const nameArray = res.data.full_name.split(' ');
          this.lastName = nameArray.pop();
          this.firstName = nameArray.join(' ');
        },
        error:(res:any) => {
          this.isLoading = false
        }
      }
    )
  }
ngOnInit(): void {
  this.fetchRequest()
}  
}
