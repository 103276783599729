import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
interface City {
  name: string;
}
@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.css']
})
export class StepOneComponent implements OnInit {
  constructor(private route:Router){}
  cities: City[] | undefined;
  citiesTwo: City[] | undefined;
  selectedCity: City | any;
  selectedCityTwo: City | any;
  selectedItem: number | null = 0;
  brand = 'VISA';
  imgFile:any = '';
  name:string = '';

  cancel(){

  }

  onUpload(img:any){
console.log(img)
  }
  isActive(index: number): boolean {
    return this.selectedItem === index;
  }
  toggleItem(index:number){
  this.selectedItem = this.selectedItem === index ? null : index;
  if(index == 0){
   this.brand = 'VISA'
        }
  if(index == 1){

this.brand = 'VERVE'
  }
  if(index == 2){

    this.brand = 'MASTERCARD'
      }
}

inputFile(event:any){
  let fileEvent = event.target.files[0];
  let img = fileEvent;
  console.log(fileEvent)
  let fileReader = new FileReader();
  fileReader.readAsDataURL(fileEvent);
  fileReader.onload = () => {
     if(fileEvent.type == 'image/jpeg' || fileEvent.type == 'image/png' || fileEvent.type == 'image/gif'){
  if(fileEvent.size <= 10485760){
    this.imgFile = fileReader.result;
    console.log(img)
    console.log(this.imgFile)
  }
    }
  }
}  

save(){
  let stepOne = {name:this.name,brand:this.brand,currency:this.selectedCity?.name,card_type:this.selectedCityTwo?.name,image:this.imgFile}
  localStorage.setItem('stepOne', JSON.stringify(stepOne))
  this.route.navigate(["/configurations/virtual-card/step-two"])
  
}
  ngOnInit() {
    let dataInString:any = localStorage.getItem('stepOne');
    let stepOneData = JSON.parse(dataInString);
   if(stepOneData !== null){
    if(stepOneData.brand == 'VISA'){
this.selectedItem = 0;
this.brand = 'VISA'
    }
    if(stepOneData.brand == 'VERVE'){
      this.selectedItem = 1;
      this.brand = 'VERVE';
          }
          if(stepOneData.brand == 'MASTERCARD'){
            this.selectedItem = 2;
            this.brand = 'MASTERCARD';
                }
    this.selectedCity = {name:stepOneData.currency};
    this.selectedCityTwo = {name:stepOneData.card_type};
    this.imgFile = stepOneData.image;
    this.name=stepOneData.name
   }
      this.cities = [
          { name: 'USD'},
          { name: 'NGN'},
         
      ];
      this.citiesTwo = [
        { name: 'VIRTUAL'},
        { name: 'PHYSICAL' },
    ];
  }
}
