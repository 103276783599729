

<div class="header-four" *ngIf="!isLoading">
<div class="recent-transaction">
<table>
 <thead>
    <tr>
        <th>USD TO NGN</th>
        <th>NGN TO USD</th>
        <th>Fx Fee</th>
        <th>MIN USD CONVERSION</th>
        <th>MIN NGN CONVERSION</th>
        <th>FX USD CAP</th>
        <th>FX NGN CAP</th>
        <th>FUNDING FEE</th>
        <th></th>
    </tr>
 </thead>

   <tbody>
    <tr>
        <td (click)="showDialog('Forex Settings')">{{data.usd_ngn}}</td>
        <td (click)="showDialog('Forex Settings')">{{data.ngn_usd}}</td>
        <td (click)="showDialog('Forex Settings')">{{data.fx_fee}}</td>
        <td (click)="showDialog('Forex Settings')">{{data.min_usd}}</td>
         <td (click)="showDialog('Forex Settings')">{{data.min_ngn}}</td>
        <td (click)="showDialog('Forex Settings')">{{data.fx_usd}}</td>
        <td (click)="showDialog('Forex Settings')">{{data.fx_ngn}}</td>
        <td>{{data.funding_fee}}</td>
        <td [matMenuTriggerFor]="menu"><img src="./../../../../assets/Title (1).png" width="50" alt="">
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <span style="font-family: poppins; font-size: 14px;" (click)="showDialog('Forex Settings')">Edit</span>
            </button>
          </mat-menu>
          
        </td>
          </tr>
   </tbody>
</table>
</div>
</div>
<p-dialog [header]="modalName" *ngIf="visible" [(visible)]="visible" [modal]="true" [style]="{ width: '35vw', 'font-family':'poppins'}" [draggable]="false" [resizable]="false">
  <app-fx-update-modal [data]="data" (updated)="updated()"></app-fx-update-modal>
</p-dialog>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>
  <p-toast></p-toast>