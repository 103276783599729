<div *ngIf="!isLoading">
    <div class="container">
        <h1 style="padding-bottom: 0px;margin: 0px; width:500px;"></h1>
        <div class="header-two">
    <p>Reasons</p>
    <input type="text" placeholder="Reasons" [(ngModel)]="reasons">
        </div>
    
        <div  class="header-eight">
     
            <button class="filter" (click)="clearMessage()">Clear</button>
            <button class="apply" (click)="sendMessage()" [disabled]="reasons == ''">Send</button>
          </div>
    </div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right:5px;top: 5px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>