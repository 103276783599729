<div *ngIf = '!isloading'>
    <div class="header-one">
        <img src="../../assets/zappy logo 1@2x.png" width="140" alt="">
        <div style="display: flex;align-items: center;gap: 7px;">
            <a href="https://zappy.ng/" style="padding-top: 5px;"><img src="../../assets/Stockholm-icons (29).png"  width="30" alt=""></a>
            <p>Back To Website</p>
        </div>
    </div>
    
    <div class="header-two">
        <form #form = 'ngForm' (ngSubmit) = 'login(form)'>
            <h4>Sign into your account</h4>
            <p style="color: #546881;">Let’s get started!</p>
            <br>
            <p *ngIf = 'errorMessage' style="font-size: 12px;padding-bottom: 10px; font-weight: 600;color: #546881;">{{errorMessage}}</p>
            <div>
                <p>Email</p>
                <input type="text" placeholder="Enter email" name="email" ngModel required email>
            </div>
            <div>
                <p>Password</p>
                <input [type]="!viewPassword ? 'password' : 'text'" placeholder="Enter password" name="password" ngModel required>
                <img (click) = 'ActivateViewPassword()' style="position: relative;left: -30px;top: 5px; cursor: pointer;" src="../../assets/Trailing icons.png" width="20" alt="">
            </div>
            <p style="color: #909DAD;font-size: 12px;">Forgot Password?<strong style="color: #673AB7;margin-left:3px;">Reset Password</strong></p>
            <br>
            <button type="submit" [disabled] = 'form.invalid'>Sign In</button>
         
        </form>
        <br>
        <div style="display: flex; justify-content:space-between; align-items: center;border: 1px solid #D8D8D8;width: 440px;border-radius: 7px;">
            <strong style="font-size: 12px;padding: 10px;">Having problems logging in?</strong>
            <a href="#" style="padding: 10px; font-size: 12px;">Contact us</a>
        </div>
    </div>
</div>


<app-spinner *ngIf = 'isloading' style="display: flex;justify-content: center;align-items: center;position: relative; top:300px;"></app-spinner>


    <p-toast></p-toast>
