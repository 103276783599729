import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-vct-dialog-box',
  templateUrl: './vct-dialog-box.component.html',
  styleUrls: ['./vct-dialog-box.component.css']
})
export class VctDialogBoxComponent {
  isLoading = false;
  @Input()  data:any = {}

constructor(private messageService:MessageService,private http:HttpClient){}
openSnackBar(id:any) {
  this.showInfo(id)
  navigator.clipboard.writeText(id);
 }
showInfo(id:any) {
  this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
}
ngOnInit(): void {

}
}
