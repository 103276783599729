import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ZapPinDialogBoxComponent } from '../zap-pin-dialog-box/zap-pin-dialog-box.component';
import { HttpClient } from '@angular/common/http';
import { ZapPinDialogservice } from '../zap-pin-dialog-box/zap-pin-dialog.service';
import { zapinTransactionsService } from './zapin-transactions.service';
import { catchError, exhaustMap, tap, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';
@Component({
  selector: 'app-zap-pin-transactions',
  templateUrl: './zap-pin-transactions.component.html',
  styleUrls: ['./zap-pin-transactions.component.css']
})
export class ZapPinTransactionsComponent  implements OnInit{
  modalName = ''
  visible:boolean = false;
  searchText = ''
  isLoading:boolean = false;
  filtered = false;
  page = 1;
start_date = '';
end_date = '';
  data:any = [];
  first: number = 1;
  rows: number = 10;
  status = '';
  start_amount = '';
  end_amount = '';
  totalRecord = 20;
  private filterSubscription:any;
  private apiKey:string = '';
constructor( private http:HttpClient,private dialogService:ZapPinDialogservice, private zapinTransactionService:zapinTransactionsService,private messageService:MessageService){
  this.apiKey = environment.apiUrl
}
  viewDetail(id:number){
    this.dialogService.id = this.data[id].id;
    this.showDialog('Zap Pin Transactions')
  }

  onPageChange(event:any) {
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + 1;
    this.isLoading = true;
    this.getRequest().subscribe((res:any) => {
      this.data = res.data;
      this.isLoading = false;
     })
  }


      showDialog(name:string) {
        this.modalName = name;
          this.visible = true;
      }
      filter(){
       this.filterSubscription =  this.zapinTransactionService.filter.pipe(tap((res:any) => {
          this.status = res.status == '' ? '' : '&status=' + res.status;
          this.start_date = res.start_date == '' ? '' : '&start_date' + res.start_date.toISOString();;
          this.end_date = res.end_date == '' ? '' : '&end_date' + res.end_date.toISOString();;
          this.start_amount = '&start_amount=' + res.start_amount;
          this.end_amount = '&end_amount=' + res.end_amount;
          console.log(res.start_amount + 'amount')
          this.isLoading = true;
         }),exhaustMap((res:any) => {return this.getRequest()})).pipe(catchError(this.handleError)).subscribe({
          next:(res:any) => {
            this.isLoading = false;
            this.data = res.data;
            this.totalRecord = res.total;
          },
          error:(res) => {
            this.isLoading = false;
            setTimeout(() => {
              this.messageService.add({ severity: 'error', detail:res,life:2000 });
             }, 2000);
          }
        })
      }
      handleError(error:any) { let errorMessage = 'No Internet (transactions)';

if(error.error.message){
  errorMessage = error.error.message
}

  console.log(errorMessage);
  return throwError(() => {
      return errorMessage;
  });
}
      getRequest(){
        return  this.http.get(this.apiKey + '/zap/admin/transaction/zappin?page=' + this.page + '&' + 'limit' + '=' + this.rows + this.start_amount + this.end_amount + this.status + this.start_date + this.end_date + '&search_query=' + this.searchText)
       }
       search(){
        this.isLoading = true;
        this.getRequest().subscribe((res:any) => {
          this.data = res.data;
          this.totalRecord = res.total;
        this.isLoading = false
         })
        }
ngOnInit(): void {
  this.isLoading = true;
  this.filter()
}
ngOnDestroy(): void {
   
  console.log('ngOnDestroy called');
  // Unsubscribe from the filter subscription to avoid memory leaks
  if (this.filterSubscription) {
    this.filterSubscription.unsubscribe();
  }
} 
 
}
