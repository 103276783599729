import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { KycCustomerDetailsService } from '../../kyc-request/kyc-customer-details/kyc-customer-details.service';
import { kycCustomerService } from '../../kyc-request/kyc-customers/kyc-customers.service';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';
import { catchError, exhaustMap, tap, throwError } from 'rxjs';
import { BvnCustomerDetailsService } from '../bvn-customer-details/bvn-customer-details.service';
import { BvnCustomerService } from './bvn-customers.service';

@Component({
  selector: 'app-bvn-customers',
  templateUrl: './bvn-customers.component.html',
  styleUrls: ['./bvn-customers.component.css']
})
export class BvnCustomersComponent {
  searchText = ''
  selected:boolean = false;
  kycUser:any = {};
  page:number = 1;
  isLoading:boolean = false;
  data:any = []
  first: number = 1;
  rows: number = 8;
  totalRecord = 20;
  status = '';
  start_date = '';
  end_date = '';
  selectedArray:any = []
  selectedAllArray:any = []
  selectedAllId:any = []
  checked = false;
  selectedId:any = [];
  modalName = '';
  userId:any = '';
  id:any;
  visible: boolean = false;
  private apiKey:string = '';
  constructor(private http:HttpClient,private bvnDetailService:BvnCustomerDetailsService,private bvnCustomerService:BvnCustomerService, private messageService:MessageService){
    this.apiKey = environment.apiUrl
  }
  viewDetail(index:number){
  this.kycUser = this.data[index];
this.bvnDetailService.kycUsersSubject.next(this.kycUser);
  }


  toggle(){
  
  }
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
  allChecked(){
    this.checked = !this.checked
    this.selectedArray = [];
   for (let index = 0; index < this.data.length; index++) {
    if(!this.selectedAllArray.includes(this.data[index].user_id)){
      this.selectedAllArray.push(this.data[index].user_id)
    }
      
    }
    for (let index = 0; index < this.data.length; index++) {
      if(!this.selectedId.includes(this.data[index].id)){
        this.selectedId.push(this.data[index].id)
      }
        
      }
  }
  selectedBtn(i:number){

    if (!this.selectedArray.includes(this.data[i].user_id)) {
    this.selectedArray.push(this.data[i].user_id);
    }

    if (!this.selectedId.includes(this.data[i].id)) {
      this.selectedId.push(this.data[i].id);
      }
    console.log(this.selectedArray)
  }
  onPageChange(event:any) {
    console.log(event.page + 1)
    this.page = event.page + 1;
    this.rows = event.rows;
    this.first = event.first;
    this.isLoading = true;
    this.getRequest().subscribe((res:any) => {
      this.data = res.data.data;
      this.isLoading = false;
     })
  }
filter(){
this.bvnCustomerService.filter.pipe(tap((res:any) => {
    this.status = res.status == '' ? '' : '&status=' + res.status;
    this.start_date = res.start_date == '' ? '' : '&start_date' + res.start_date.toISOString();;
    this.end_date = res.end_date == '' ? '' : '&end_date' + res.end_date.toISOString();;
    console.log(res.start_amount + 'amount')
    this.isLoading = true;
   }),exhaustMap((res:any) => {return this.getRequest()})).pipe(catchError(this.handleError)).subscribe({
    next:(res:any) => {
      this.isLoading = false;
      this.data = res.data.data;
      this.totalRecord = res.data.total;
      this.bvnDetailService.loading.next(this.isLoading);
    },
    error:(res) => {
      this.isLoading = false;
      this.bvnDetailService.loading.next(this.isLoading);
      setTimeout(() => {
        this.messageService.add({ severity: 'error', detail:res,life:2000 });
       }, 2000);
    }
  })
}
handleError(error:any) { let errorMessage = 'No internet (kyc-customer)';

if(error.error.message){
  errorMessage = error.error.message
}

  console.log(errorMessage);
  return throwError(() => {
      return errorMessage;
  });
}
      getRequest(){
      
        return this.http.get( this.apiKey + '/zap/admin/kyc/bvn/all?page=' + this.page + '&' + 'limit' + '=' + this.rows + this.status + this.start_date + this.end_date + '&search_query=' + this.searchText)
       }

       approve(){
        this.isLoading = true;
        if(this.selectedArray.length !== 0){
        this.http.post(this.apiKey + '/zap/admin/kyc/bvn/bulk/approve',{user_id:this.selectedArray}).subscribe({
          next:(res:any) => {
            this.messageService.add({ severity: 'info', summary:this.data.name, detail:res.message });
            this.selectedArray = [];
            location.reload()
          },
          error:(res:any) => {
            this.isLoading = false;
            let errorMessage = 'An error occurred'; // Default error message
            if (res.error && res.error.message) {
                errorMessage = res.error.message; // Use the error message from the response body
            }
            this.messageService.add({ severity: 'error', summary: 'Error!', detail: errorMessage });
            this.selectedArray = [];
          }
        })
      }
      else {
        this.messageService.add({ severity: 'error', summary:'Rejected!', detail: 'No selected id' });
      }
       }
       approveAll(){
        this.isLoading = true;
        if(this.selectedAllArray.length !== 0){
        this.http.post(this.apiKey + '/zap/admin/kyc/bvn/bulk/approve',{user_id:this.selectedAllArray}).subscribe({
          next:(res:any) => {
            this.messageService.add({ severity: 'info', summary:this.data.name, detail:res.message });
            this.selectedAllArray = [];
            location.reload()
          },
          error:(res:any) => {
            this.isLoading = false;
            let errorMessage = 'An error occurred'; // Default error message
            if (res.error && res.error.message) {
                errorMessage = res.error.message; // Use the error message from the response body
            }
            this.messageService.add({ severity: 'error', summary: 'Error!', detail: errorMessage });
            this.selectedAllArray = [];

          }
        })
      }
      else {
        this.messageService.add({ severity: 'error', summary:'Rejected!', detail: 'No selected id' });
      }
       }
       decline(){
        if(this.selectedArray.length !== 0){
        this.userId = this.selectedArray;
        this.id = this.selectedId;
        this.visible = true;
        }
        else {
          this.messageService.add({ severity: 'error', summary:'Rejected!', detail: 'No selected id' });
        }
       }
       declineAll(){
        if(this.selectedArray.length !== 0){
        this.userId = this.selectedAllArray;
        this.id = this.selectedAllId;
        this.visible = true;
        }   else {
          this.messageService.add({ severity: 'error', summary:'Rejected!', detail: 'No selected id' });
        }
       }

       search(){
        this.isLoading = true;
        this.getRequest().subscribe((res:any) => {
          this.data = res.data.data;
          this.isLoading = false;
          this.totalRecord = res.data.total;
        
         })
        }
      ngOnInit(): void {
        this.isLoading = true;
        this.bvnDetailService.loading.next(this.isLoading);
        this.filter()
      }
}
