import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-decline-modal',
  templateUrl: './decline-modal.component.html',
  styleUrls: ['./decline-modal.component.css']
})
export class DeclineModalComponent {
  reasons:string = '';
 @Input() userId:string = ''
 @Input() id:number = 0;
 isLoading = false;
 private apiKey:string = '';
 constructor(private http:HttpClient, private messageService:MessageService){
  this.apiKey = environment.apiUrl;
 }
sendMessage(){
  this.isLoading = true;
this.http.post(this.apiKey + '/zap/admin/kyc/bulk/decline',{user_id:this.userId,id:this.id,reason:this.reasons}).subscribe({
  next:(res:any) => {
    this.messageService.add({ severity: 'info', summary:'Declined', detail: res.message });
    this.isLoading = false;
    this.clearMessage()
    location.reload();
  },error:(res:any) => {
    this.isLoading = false;
    let errorMessage = 'An error occurred'; // Default error message
    if (res.error && res.error.message) {
        errorMessage = res.error.message; // Use the error message from the response body
    }
    this.messageService.add({ severity: 'error', summary: 'Error!', detail: errorMessage });
  }
})
}

clearMessage(){
  this.reasons = '';
}
}
