<div *ngIf="!isLoading">
  
  <div class="header-two">
    <h5>Transaction Status</h5>
  
    <p *ngIf="data.status == true"><span class="completed">Active</span></p>
    <p *ngIf="data.status == false"><span class="pending">Redeemed</span></p>
  </div>
  <table class="table-one" style="width: 500px;">
    <tr>
      <th>Wallet Holder</th>
      <th>Customer Id</th>
      <th>Email</th>
    </tr>
    <tr>
      <td>{{data.name | titlecase}}</td>
      <td>
        <div style="display: flex; align-items: center;">
        <p>{{data.user_id | slice:0:8}} {{ data.user_id.length > 8 ? '...' : '' }}</p>
        <img (click)="openSnackBar(data.user_id)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
        </div>
      </td>
      <td>  <div style="display: flex; align-items: center;">
        <p>{{data.email}}</p>
        <img  (click)="openSnackBar(data.email)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
        </div></td>
  
    </tr>
    <tr>
      <th>PIN Number</th>
      <th>Level</th>
      <th>Order Type</th>
    </tr>
    <tr>
      <td>{{data.pin}}</td>
      <td>Tier {{data.level}}</td>
      <td>{{data.order_type | titlecase}}</td>
      
    
  
    </tr>
    <tr>
      <th>Redeem By</th>
      <th>Amount</th>
      <th>Date &Time</th>
    </tr>
    <tr>
   
      <td>{{data.used_by}}</td>
      <td>{{data.currency == 'usd' ? '$' : '₦'}} {{data.amount | number}}</td>
      <td>{{data.created_at |date}} & {{data.created_at | date:'shortTime'}}</td>
    </tr>
    <tr>
      <th>Transaction Fee</th>
    </tr>
<tr>
  <td>₦{{data.fee | number}}</td>
</tr>
  </table>
  </div>
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
  