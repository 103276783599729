<div *ngIf="!isLoading">
    <div class="header-two">
        <h4>Foreign Exchange (FX)</h4>
    </div>
    <div class="currency-two">
        <div class="card">
            <div class="message">
                <img src="../../../../assets/message (2).png" width="20" alt="">
                <p>We use the mid-market rate for our Converter. This is for informational purposes only.</p>
            </div>
            <div style="display: flex;align-items: center;gap:5px;font-size: 13px;color:#546881;font-weight:600;">
                <p>1.00</p>
                <img src="../../../../assets/united states.png" width="20" alt="">
                <p>US Dollar</p>
                <p>=</p>
                <p>{{data.usd_ngn}}</p>
                <img src="../../../../assets/nigeria.png" width="20" alt="">
                <p>Nigerian Nairas</p>
            </div>
            <div>
                <p style="color: #546881;margin: 0px;font-size: 13px;padding: 0px;">1 NGN = {{data.ngn_usd}} USD</p>
            </div>
        </div>
        <div class="card" *ngIf="!ngn_usd">
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>
               
                <h5 style="margin: 4px;font-size: 12px;color:#111014;">From</h5>
                <div class="inputWithIcon">
                <input type="number" placeholder="1" disabled>
                <img src="../../../../assets/ngn-two.png" width="65" alt="" >
                </div>
              </div>
              <img src="../../../../assets/conversion.png" style="margin: 10px;padding-top: 25px;" (click)="switch()" width="30" alt="">
              <div>
                <h5 style="margin: 4px;font-size: 12px;color:#111014;">To</h5>
                <div class="inputWithIcon">
                <input type="number" [placeholder] = 'data.ngn_usd' disabled>
                <img src="../../../../assets/united-two.png" width="65" alt="">
             </div>
              </div>
            </div>
            <p style="color: #546881;font-size: 13px;">  1 NGN  = {{data.ngn_usd}} USD </p>
        </div>
        <div class="card" *ngIf="ngn_usd">
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>
               
                <h5 style="margin: 4px;font-size: 12px;color:#111014;">From</h5>
                <div class="inputWithIcon">
                <input type="number" placeholder="1" disabled>
                <img src="../../../../assets/united-two.png" width="65" alt="">
                </div>
              </div>
              <img  src="../../../../assets/conversion.png" style="margin: 10px;padding-top: 25px;cursor: pointer;" (click)="switch()" width="30" alt="">
              <div>
                <h5 style="margin: 4px;font-size: 12px;color:#111014;">To</h5>
                <div class="inputWithIcon">
                <input type="number" [placeholder] = 'data.usd_ngn' disabled>
                <img src="../../../../assets/ngn-two.png" width="65" alt="" >
             </div>
              </div>
            </div>
            <p style="color: #546881;font-size: 13px;">1 USD  = {{data.usd_ngn}}  NGN</p>
        </div>
    </div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
