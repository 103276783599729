
<div class="container">
    <div class="header-four">
     <div class="card">
         <h5 style="margin-top: 0px;">Start</h5>
         <div  style="zoom: 80%;"><p-calendar [(ngModel)]="date" [touchUI]="false" [readonlyInput]="true" [placeholder]="'dd/mm/yy'"></p-calendar> </div>   
 
     </div>
     <div class="card">
         <h5 style="margin-top: 0px;">End</h5>
 
        <div  style="zoom: 80%;"> <p-calendar [(ngModel)]="dateTwo" [touchUI]="false" [readonlyInput]="true" [placeholder]="'dd/mm/yy'"></p-calendar></div>
     </div>
     
       </div>

       
 <div class="header-seven">
 <p style="font-weight: 600;font-size: 14px;">Status</p>
 <div style="display: flex;gap: 5px;">
    <p><span [class.active]="isActive(0)" (click)="toggleItem(0)" style="font-size: 11px;" >All</span></p>
    <p><span [class.active]="isActive(1)" (click)="toggleItem(1)" style="font-size: 11px;" >Active</span></p>
<p><span [class.active]="isActive(2)" (click)="toggleItem(2)" style="font-size: 11px;" >Suspended</span></p>
 </div>
 </div>
 
 <div class="header-seven">
  <div style="display: flex;gap: 5px;">
    <p><span [class.active]="isActive(3)" (click)="toggleItem(3)" style="font-size: 11px;" >Tier 1</span></p>
 <p><span [class.active]="isActive(4)" (click)="toggleItem(4)" style="font-size: 11px;" >Tier 2</span></p>
 <p><span [class.active]="isActive(5)" (click)="toggleItem(5)" style="font-size: 11px;" >Tier 3</span></p>
 <p><span [class.active]="isActive(8)" (click)="toggleItem(8)" style="font-size: 11px;" >Tier 4</span></p>
 <p><span [class.active]="isActive(6)" (click)="toggleItem(6)" style="font-size: 11px;" >Verified</span></p>
 <p><span [class.active]="isActive(7)" (click)="toggleItem(7)" style="font-size: 11px;" >Unverified</span></p>
  </div>
  </div>
 <div  class="header-eight">
  
   <button class="filter" (click)="clear()">Clear Filter</button>
   <button class="apply" (click)="send()">Apply</button>
 </div>
     
    
       
 </div>
 
 
 
 
 
