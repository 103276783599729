<main>
  <div class="side-nav">
<h4>Data Plan</h4>
<p>Manage Internet configurations, price, purchase fee</p>
<div [class.active] = 'active("/configurations/internet/data-plan/mtn")' class="data-plan" (click)="mtn()">
  <img src="../../../assets/MTN.png" width="30" alt="">
  <p>MTN Internet Configurations</p>
</div>
<div [class.active] = 'active("/configurations/internet/data-plan/glo")' class="data-plan" (click)="glo()">
  <img src="../../../assets/GLO.png" width="30" alt="">
  <p>Glo Internet Configurations</p>
</div>
<div [class.active] = 'active("/configurations/internet/data-plan/airtel")' class="data-plan" (click)="airtel()">
  <img src="../../../assets/AIRTEL.png" width="30" alt="">
  <p>Airtel Internet Configurations</p>
</div>
<div [class.active] = 'active("/configurations/internet/data-plan/9mobile")' class="data-plan" (click)="mobile()">
  <img src="../../../assets/9MOBILE.png" width="30" alt="">
  <p>9Mobile Internet Configurations</p>
</div>
  </div>
  <div class="header-four">
    
    <div style="display: flex;justify-content: center;padding: 40px;position:relative; right: 50px;top: 100px;" *ngIf = 'isLoading'>
      <app-spinner ></app-spinner>
      </div>
    <div class="recent-transaction" *ngIf="!isLoading">
    <table>
     <thead>
        <tr>
    <th>Package</th>
            <th>Api Price</th>
            <th>Our Price</th>
            <th>Date</th>
            <th></th>
        </tr>
     </thead>
    
       <tbody>
        <tr *ngFor="let dataPlan of data; let i = index">
          <td (click) = 'viewDetail(i)' >{{dataPlan.name | titlecase }}</td>
            <td (click) = 'viewDetail(i)' >₦{{dataPlan.price | number}}</td>
            <td (click) = 'viewDetail(i)' >₦{{dataPlan.telco_price | number}}</td>
             <td (click) = 'viewDetail(i)' >{{dataPlan.created_at | date}}</td>
             <td [matMenuTriggerFor]="menu"><img src="./../../../../assets/Title (1).png" width="50" alt="">
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="delete(i)">
                      <span style="font-family: poppins; font-size: 14px;">Delete</span>
                    </button>
                  </mat-menu>
            </td>
              </tr>
       </tbody>
    </table>
    </div>
</div>
</main>
<br>
    

        <p-dialog [header]="modalName" *ngIf="visible" [(visible)]="visible" [modal]="true" [style]="{ width: '30vw', 'font-family':'poppins'}" [draggable]="false" [resizable]="false">
            <app-data-plan-modal [data]="singleData" (updated)="upadated()"></app-data-plan-modal>
        </p-dialog>
        <p-dialog [header]="'Add Data Plan'" *ngIf="visibleTwo" [(visible)]="visibleTwo" [modal]="true" [style]="{ width: '30vw', 'font-family':'poppins'}" [draggable]="false" [resizable]="false">
            <app-data-plan-modal-two (added)="Added()"></app-data-plan-modal-two>
        </p-dialog>
        <p-toast></p-toast>