<div *ngIf="!isLoading">
  <div class="header-two">
    <img src="../../../assets/search.png" alt="">
    <input type="text" placeholder="Search by....." (keyup.enter)="search()"  [(ngModel)]="searchText">
    <div *ngIf="searchText !== ''" class="filter-group" (click)="search()">
      <p>search</p>
    </div>
<button class="suspend" *ngIf="status == '&sort_by=active' && !checked" (click) = 'deactivate()'>Suspend</button>
<button class="active" *ngIf="status == '&sort_by=inactive' && !checked" (click) = 'activate()'>Activate</button>
<button class="suspend" (click) = 'deactivateAll()' *ngIf="status == '&sort_by=active' && checked">Suspend All</button>
<button class="active"  (click) = 'activateAll()' *ngIf="status == '&sort_by=inactive' && checked">Activate All</button>
<img *ngIf="select" (click)="select = false" src="../../../../assets/Stockholm-icons (18).png"  alt="">
</div>
<br>
<div>
  
<div class="header-four" *ngIf="data.length !== 0">
  <div class="recent-transaction">
  <table>
   <thead>
      <tr>
          <th style="padding-left:15px;"><div class="order-type"><input (change)="allChecked()" type="checkbox" *ngIf="status == '&sort_by=active' || status == '&sort_by=inactive'"><p>Customers ID</p></div></th>
          <th>Email</th>
          <th></th>
         <th>Joined</th>
         <th>Wallet Balance</th>
         <th>Money In</th>
         <th>Money Out</th>
         <th>Status</th>
      </tr>
   </thead>
  
     <tbody>
      <tr *ngFor="let transactions of data;let i =index">
         <td ><div class="order-type"><input type="checkbox" (change) = 'selected(i)' [checked]="checked" [disabled]="checked || val.checked"  #val *ngIf="status == '&sort_by=active' || status == '&sort_by=inactive'"><p (click)="viewDetail(i)">{{ transactions.user_id | slice:0:10 }} {{ transactions.user_id.length > 10 ? '...' : '' }}</p></div></td>
         <td (click)="viewDetail(i)">{{transactions.email}}</td>
         <td ><img (click)="openSnackBar(transactions.email)" src="../../../../assets/Stockholm-icons (16).png" width="25" alt=""></td>
         <td (click)="viewDetail(i)">{{transactions.created_at | date}}</td>
         <td (click)="viewDetail(i)">₦{{transactions.balance | number }}</td>
         <td (click)="viewDetail(i)" *ngIf="transactions.money_in">₦{{transactions.money_in | number}}</td>
         <td (click)="viewDetail(i)" *ngIf="!transactions.money_in">₦0</td>
         <td (click)="viewDetail(i)" *ngIf="transactions.money_out">₦{{transactions.money_out | number}}</td>
         <td (click)="viewDetail(i)" *ngIf="!transactions.money_out">₦0</td>
         <td (click)="viewDetail(i)" *ngIf="transactions.is_suspended == false"> <span class="redeemed" >Active</span></td>
         <td (click)="viewDetail(i)" *ngIf="transactions.is_suspended == true"><span class="active">Suspended</span></td>
       </tr>
     </tbody>
  </table>
  <br>
  <div style="display: flex; justify-content: space-between;align-items: center;">
    <p style="color: #151A20;font-weight: 500;font-size: 13px;">Showing 1-10 of {{this.page}} results</p>
    <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
  </div>
  </div>
  </div>
  </div>
</div>
<div style="display: flex;  justify-content: center;padding:80px;position:relative; right:100px;top: 0px;" *ngIf="data.length == 0 && !isLoading">
  
  <div>
   <img src="../../../assets/Empty state illustratio.png" style="margin: 0 auto;display: block;padding-bottom:10px;" width="80" alt="">
   <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">No records to view yet.</h5>
   <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Transaction records will appear here once customers make purchases.</p>
  </div>
 </div>
<p-toast></p-toast>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>