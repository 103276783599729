import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.css']
})
export class GeneralSettingsComponent implements OnInit{
instagram:string = '';
facebook:string = '';
twitter:string = '';
chaturl:string = '';
email:string = '';
data:any = {instagram:'',facebook:'',twitter:'',chatUrl:'',email:'',chat_url:''};
isLoading = false;
private apiKey:string = '';
constructor(private http:HttpClient,private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
save(){
  this.isLoading = true;
  this.http.post(this.apiKey + '/zap/admin/setting/socials',
  {chat_url:this.chaturl,email:this.email,facebook:this.facebook,twitter:this.twitter,instagram:this.instagram}).subscribe({
    next:(res:any) => {
this.fetchRequest();
    }
  })
}
fetchRequest(){
  this.http.get(this.apiKey + '/zap/admin/setting/socials').subscribe({
    next:(res:any) => {
      this.data  = res.data;
      this.instagram = res.data.instagram;
      this.facebook = res.data.facebook;
      this.twitter = res.data.twitter;
      this.chaturl  = res.data.chat_url
      this.email = res.data.email
      this.isLoading = false;
    },error:(res:any) => {
      this.isLoading = false;
      this.messageService.add({severity:'error',summary:'Rejected', detail:'An error ocurred'})
    }
  })
}
ngOnInit(): void {
  this.isLoading = true;
this.fetchRequest();
}
}
