import { Component, OnInit } from '@angular/core';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';

@Component({
  selector: 'app-airtime-swap',
  templateUrl: './airtime-swap.component.html',
  styleUrls: ['./airtime-swap.component.css']
})
export class AirtimeSwapComponent implements OnInit{
role:string = '';
constructor(private authService:AuthPageService){}
ngOnInit(): void {
  this.authService.roleName.subscribe((res:any) => {
    this.role = res.data.role.role_name
  })
}
}
