<div class="side-nav">
 
    <nav>
  <div class="logo">
    <img src="../assets/zappy logo 1@2x.png"  alt="no image">
  </div>
      <ul>
        <li (click)="navigate('/home')" *ngIf="role == 'Administrator' || role == 'Customer Support'"  [class.active] = 'activeClass("/home")'><img src="../assets/Stockholm-icons (10).png" alt=""><a>Home</a></li>
        <li [class.active] = 'transactionActive()' *ngIf="role == 'Administrator' || role == 'Customer Support'"   class="dropdown" (click) = 'transactionActivateDrop()' ><img src="../assets/Stockholm-icons (1).png" alt=""><a >Transactions</a></li>
        <ul class="dropdown-menu" *ngIf = 'transactionDropdown'>
          <li (click)="navigate('/transactions/bills-transaction')" [class.activeTwo] = 'activeClass("/transactions/bills-transaction")'><a>Bills Transactions</a></li>
          <li (click)="navigate('/transactions/airtime-swap')" [class.activeTwo] = 'activeClass("/transactions/airtime-swap")'><a>Airtime Swap</a></li>
          <li (click)="navigate('/transactions/card-transaction')" [class.activeTwo] = 'activeClass("/transactions/card-transaction")'><a>Card Transactions</a></li>
          <li (click)="navigate('/transactions/zap-pin')" [class.activeTwo] = 'activeClass("/transactions/zap-pin")'><a>Zap Pin</a></li>
          <li (click)="navigate('/transactions/gift-card')" [class.activeTwo] = 'activeClass("/transactions/gift-card")'><a>Gift Card</a></li>
          <li (click)="navigate('/transactions/bank-transfer')" [class.activeTwo] = 'activeClass("/transactions/bank-transfer")'><a >Bank Transfer</a></li>
        </ul>
        <li *ngIf="role == 'Administrator' || role == 'Customer Support' || role == 'Communications' "  [class.active] = 'emailActive()'><img src="../assets/Stockholm-icons (2).png" alt=""><a routerLink = '/email'>Messaging</a></li>
        <li *ngIf="role == 'Administrator' || role == 'Customer Support' "  class="dropdown" (click) = 'customerActivateDrop()'  [class.active] = 'userManagement()'><img src="../assets/Stockholm-icons (3).png" alt=""><a>User Management</a></li>
        <ul class="dropdown-menu" *ngIf = 'customerDropdown'>
          <li (click)="navigate('/user-management/customers')" [class.activeTwo] = 'customers()'><a>Customers</a></li>
          <li (click)="navigate('/user-management/kyc-request')" [class.activeTwo] = 'activeClass("/user-management/kyc-request")'><a>KYC Request</a></li>
          <li (click)="navigate('/user-management/bvn-request')" [class.activeTwo] = 'activeClass("/user-management/bvn-request")'><a>Bvn Request</a></li>
          <li (click)="navigate('/user-management/card-funding')" [class.activeTwo] = 'activeClass("/user-management/card-funding")'><a>Card Funding</a></li>
          <li (click)="navigate('/user-management/withdrawal')" [class.activeTwo] = 'activeClass("/user-management/withdrawal")'><a>Withdrawal</a></li>
          <li (click)="navigate('/user-management/virtual-card-management')" [class.activeTwo] = 'virtualCardActive()' ><a>Virtual Card Management</a></li>
          <li (click)="navigate('/user-management/referral')" [class.activeTwo] = 'referral()'><a>Referral</a></li>
          <li (click)="navigate('/user-management/savings')" [class.activeTwo] = 'savings()'><a>Savings</a></li>
        </ul>
        <li *ngIf="role == 'Administrator'" [class.active] = 'activeClass("/analytics")'><img src="../assets/Stockholm-icons (4).png" alt=""><a routerLink="/analytics">Analytics</a></li>
        <li *ngIf="role == 'Administrator' || role == 'Customer Support'"  [class.active] = 'activeClass("/support-and-query")'><img src="../assets/Stockholm-icons (5).png" alt=""><a  routerLink="/support-and-query">Support and Queries</a></li>
        <li *ngIf="role == 'Administrator'" [class.active] = 'landingActive()'><img src="../../assets/landing-page-svgrepo-com.svg" alt=""><a routerLink="/landing-page/sections">Landing Page</a></li>
        <li *ngIf="role == 'Administrator'" [class.active] = 'businessActive()'><img src="../../assets/business.png" alt=""><a routerLink="/business/plan">Business</a></li>
        <li *ngIf="role == 'Administrator'" [class.active] = 'configurations()' class="dropdown" (click) = 'configurationsActivateDrop()'><img src="../assets/Stockholm-icons (6).png" alt=""><a routerLink="/configurations/bills-payment">Configurations</a> </li>
        <ul class="dropdown-menu" *ngIf = 'configurationsDropdown'>
          <li (click)="navigate('/configurations/bills-payment')" [class.activeTwo] = 'BillActive()'><a>Bills Payment</a></li>
          <li (click)="navigate('/configurations/banking-transfer')" [class.activeTwo] = 'activeClass("/configurations/banking-transfer")'><a>Banking Transfer</a></li>
          <li (click)="navigate('/configurations/gift-card')" [class.activeTwo] = 'activeClass("/configurations/gift-card")'><a>Gift Card</a></li>
          <li (click)="navigate('/configurations/airtime-to-cash')" [class.activeTwo] = 'airtimeActive()'><a>Airtime To Cash</a></li>
          <li (click)="navigate('/configurations/service-provider')" [class.activeTwo] = 'activeClass("/configurations/service-provider")'><a>Service Provider</a></li>
          <li (click)="navigate('/configurations/virtual-card')" [class.activeTwo] = 'activeClass("/configurations/virtual-card")'><a>Virtual Card</a></li>
          <li (click)="navigate('/configurations/foreign-exchange')" [class.activeTwo] = 'activeClass("/configurations/foreign-exchange")'><a>Foreign Exchange(Fx)</a></li>
</ul>
      </ul>
    </nav>
  
    <div class="footer">
      <p *ngIf="role == 'Administrator'" ><img src="../assets/Stockholm-icons (7).png" alt=""><a class="active" [class.active] = 'settings()' routerLink="/settings/general-settings">Settings</a></p>
      <p *ngIf="role !== ''"><img src="../assets/Stockholm-icons (8).png" alt="">{{role}} <img mat-icon-button [matMenuTriggerFor]="menu" style="margin-left: 10px;" src="../../assets/Stockholm-icons (30).png" alt=""> 
      </p>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <span style="font-family: poppins; font-size: 15px;margin: 0px;padding: 0px;" (click) = 'logOut()'>Log-out</span>
        </button>
      </mat-menu>
    </div>
   </div>
