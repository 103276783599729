import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerProfileService } from './customer-profile/customer-profile.service';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})
export class CustomerDetailsComponent implements OnInit{
  userId:any = ''
  constructor(private route:Router, private router:ActivatedRoute, private customerService:CustomerProfileService){}
active(url:string){
 return this.route.url == url;
  
}
ngOnInit(): void {

}

}
