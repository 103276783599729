
    <div class="header-four">
      <div>
          <h4>Recent Transaction </h4>
      </div>
      <p *ngIf="recentTransaction.length !== 0" style="cursor: pointer;" (click) = 'viewMore()'>View More <img src="../../assets/Path 94 (2).png" width="5" alt=""></p>
  </div>
  <div *ngIf="recentTransaction.length !== 0">
    <div *ngIf = '!isLoading'>
  <div class="recent-transaction">
      <table>
       <thead>
          <tr>
              <th>Order Type</th>
              <th>Customer ID</th>
              <th>Amount</th>
              <th>Transaction ID</th>
              <th>Date</th>
              <th>Time</th>
              <th>Status</th>
          </tr>
       </thead>
  
         <tbody >
          <tr *ngFor = 'let transactions of recentTransaction;let i =index' (click) = 'showdialog(i)'>
              <td>
                <div class="order-type">
                 <img *ngIf="transactions.order_type.toLowerCase() == 'betting'" width="22" src="../../../assets/betting.png" alt="">
                 <img *ngIf="transactions.order_type.toLowerCase() == 'data'" width="22" src="../../../assets/Data.png" alt="">
                 <img *ngIf="transactions.order_type.toLowerCase() == 'electricity'" width="22" src="../../../assets/electricity.png" alt="">
                 <img *ngIf="transactions.order_type.toLowerCase() == 'airtime'" width="22" src="../../../assets/airtime.png" alt="">
                 <img *ngIf="transactions.order_type.toLowerCase() == 'cabletv'" width="22" src="../../../assets/cabletv.png" alt="">
                 <img *ngIf="transactions.order_type.toLowerCase() == 'usd'" width="22" src="../../../assets/Icons (1).png" alt="">
                    <p>{{transactions.order_type | titlecase }}</p>
                  </div>
              </td>
                    <td>{{ transactions.user_id | slice:0:10 }} {{ transactions.user_id.length > 10 ? '...' : '' }}</td>
                    <td><strong>{{transactions.order_type == 'usd' ? '$' : '₦'}}</strong>{{transactions.amount | number}}</td>
                    <td>{{transactions.transaction_id | slice:0:10}} {{ transactions.transaction_id.length > 10 ? '...' : '' }}</td>
                    <td>{{transactions.date | date}}</td>
                    <td>{{transactions.date | date:'shortTime' }}</td>
                    <td *ngIf="transactions.status.toLowerCase() == 'success' || transactions.status.toLowerCase() == 'successful' || transactions.status.toLowerCase() == 'completed'"><span class="success">Successful</span></td>
                    <td *ngIf="transactions.status.toLowerCase() == 'reversed' "><span class="failed">Reversed</span></td>
                    <td *ngIf="transactions.status.toLowerCase() == 'decline' || transactions.status.toLowerCase()== 'declined' "><span class="decline">Declined</span></td>
                    <td *ngIf="transactions.status.toLowerCase() == 'failed'"><span class="decline">Failed</span></td>
                </tr>
               
         </tbody>
         <p-toast></p-toast>
         
      </table>
  
  </div> 
  
  
  </div>
</div>

<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px" *ngIf="recentTransaction.length == 0 && !isLoading">
  
 <div>
  <img src="../../../assets/Empty state illustratio.png" style="margin-left: 20px;" width="80" alt="">
  <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">There is no data here</h5>
  <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Message Goes here</p>
 </div>
</div>

<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>

  <p-dialog *ngIf="visible" [header]="modalName"  [(visible)]="visible" [modal]="true" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false">
    <app-bills-dialog-box *ngIf="dialogDisplay == 'bills'" style="font-family: poppins;"></app-bills-dialog-box>
    <app-bank-transfer-dialog-box *ngIf="dialogDisplay == 'transfer'" style="font-family: poppins;" ></app-bank-transfer-dialog-box>
    <app-card-transactions-dialog-box *ngIf="dialogDisplay == 'card'" style="font-family: poppins;"></app-card-transactions-dialog-box>
  </p-dialog>