import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-vc-transaction',
  templateUrl: './vc-transaction.component.html',
  styleUrls: ['./vc-transaction.component.css']
})
export class VcTransactionComponent {
  data:any = [];
  saerchText:string = ''
  isLoading = false;
  page:number = 1
  select = false;
  private apiKey:string = '';
  constructor(private http:HttpClient,private messageService:MessageService,private route:Router){
    this.apiKey = environment.apiUrl;
  }
viewDetail(id:number){
  localStorage.setItem('userId',JSON.stringify(this.data[id].card_id))
this.route.navigate(['/user-management/virtual-card-management/virtual-card-detail/profile'])
}

first: number = 1;
rows: number = 10;
totalRecord = 20;
openSnackBar(id:any) {
  this.showInfo(id)
  navigator.clipboard.writeText(id);
 }
showInfo(id:any) {
  this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
}
onPageChange(event:any) {
  console.log(event.page + 1)
  this.page = event.page
  this.rows = event.rows;
  this.first = event.first
  console.log(this.rows)
  this.fetchRequest(event.page + 1,this.rows)
}
fetchRequest(page:number,limit:number){
  this.isLoading = true;
  this.http.get(this.apiKey + '/zap/admin/funding/cards?page=' + page + '&' + 'limit' + '=' + limit + '&search_query' + '=' + this.saerchText ).pipe(catchError(this.handleError)).subscribe(
    {
      next:(res:any)=> {
        this.totalRecord = res.data.total
        this.data = res.data
        this.isLoading = false;
        console.log(res)
      },
      error:(res:any) => {
        this.isLoading = false
        setTimeout(() => {
          this.messageService.add({ severity: 'error', detail:res,life:2000 });
         }, 2000);
      }
    }
  )
    }
    handleError(error:any) { let errorMessage = 'No internet (transactionss)';
  
    if(error.error.message){
      errorMessage = error.error.message
    }
    
      console.log(errorMessage);
      return throwError(() => {
          return errorMessage;
      });
    
    }
    
    search(){
      this.fetchRequest(this.page,this.rows)
    }
    ngOnInit(): void {
      this.isLoading = true;
    this.fetchRequest(this.first,this.rows)
    }
}
