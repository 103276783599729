import { Component } from '@angular/core';
import { kycCustomerService } from '../kyc-customers/kyc-customers.service';

@Component({
  selector: 'app-kyc-filter',
  templateUrl: './kyc-filter.component.html',
  styleUrls: ['./kyc-filter.component.css']
})
export class KycFilterComponent {
  toggle = false
  date:any = '';
  dateTwo:any = '';
  amountOne:number = 0;
  amountTwo:number = 0;
  status = ''
  selectedItem: number | null = 0;
  selectedItemTwo: number | null = 0;
  orderType = 'visa';
  private filterSubscription:any;
constructor(private kycCustomerService:kycCustomerService){}

  isActive(index: number): boolean {
    return this.selectedItem === index;
  }
  isActiveTwo(index: number): boolean {
    return this.selectedItemTwo === index;
  }
  toggleItem(index:number){
  this.selectedItem = this.selectedItem === index ? null : index;
  if(index == 0){
   this.status = ''
        }
  if(index == 1){

this.status = 'approved'
  }
  if(index == 2){

    this.status = 'pending'
      }
}

send(){
  
  this.kycCustomerService.filter.next({start_date:this.date,end_date:this.dateTwo,start_amount:this.amountOne,end_amount:this.amountTwo,status:this.status,order_type:this.orderType});
}
clear(){
  this.kycCustomerService.filter.next({start_date:'',end_date:'',status:'',})
}
  ngOnInit(): void {
 this.filterSubscription =  this.kycCustomerService.filter.subscribe(res => {
    if(res.status == 'pending'){
      this.selectedItem = 2;
      this.status = 'pending';
    }
    if(res.status == 'approved'){
      this.selectedItem = 1;
      this.status = 'approved'
    }
  
    if(res.status == ''){
      this.selectedItem = 0;
      this.status = '';
    }
      this.date = res.start_date;
      this.dateTwo = res.end_date;
    })
  }
  ngOnDestroy(): void {
   
    console.log('ngOnDestroy called');
    // Unsubscribe from the filter subscription to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}
