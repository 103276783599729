import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';
interface City {
  name: string;
}
@Component({
  selector: 'app-landing-section-dialog-two',
  templateUrl: './landing-section-dialog-two.component.html',
  styleUrls: ['./landing-section-dialog-two.component.css']
})
export class LandingSectionDialogTwoComponent implements OnInit{
  isLoading = false;
  desc:string = '';
  title:string = '';
  text:string = '';
  section:string = '';
  private apiKey:string = '';
  imgFile:any = '';
  isLoadingTwo = false;
  citiesOne: City[] | any;
  selectedCityOne: City | any = '';
constructor(private http:HttpClient,private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
save(){
  this.isLoading = true;
this.http.post(this.apiKey + '/zap/admin/landing/sections',{title:this.title,description:this.desc,image:this.imgFile,section_name:this.selectedCityOne.name}).subscribe({
next:(res:any) => {
  this.messageService.add({severity:'success',summary:'Successful', detail:res.message})
  this.isLoading = false;
  location.reload()
}, error:(res:any) => {
  this.messageService.add({severity:'error',summary:'Rejected', detail:res.message})
  this.isLoading = false;
}
})
}

  inputFile(event:any){
    this.isLoadingTwo = true;
    const formData = new FormData();
    let fileEvent = event.target.files[0];
    formData.append('file',fileEvent);
    let img = fileEvent;
    console.log(fileEvent)
    let fileReader = new FileReader();
    fileReader.readAsDataURL(fileEvent);
    fileReader.onload = () => {
       if(fileEvent.type == 'image/jpeg' || fileEvent.type == 'image/png' || fileEvent.type == 'image/gif'){
    if(fileEvent.size <= 10485760){
      this.http.post( 'https://t-admin.zappy.ng/v2/file/upload/' + fileEvent.name,formData).subscribe({
        next:(res:any) => {
          console.log(res)
          this.imgFile = res.public_url
          this.isLoadingTwo = false;
        },
        error:(res:any) => {
          this.isLoadingTwo = false;
        }
      })
    }
      }
    }
  } 
  ngOnInit(): void {
    this.citiesOne = [
      { name: 'hero'},
      {name:'transaction'},
      {name:'features'},
      {name:'get_started'},
      {name:'app_download'},
      {name:'footer'},
      {name:'about'},
      {name:'team'},
  ];
  }
  
}
