import { Component, OnDestroy, OnInit } from '@angular/core';
import { customerTransactionsService } from '../customers-transactions.service';
interface City {
  name: string;
}
@Component({
  selector: 'app-customer-filter-modal',
  templateUrl: './customer-filter-modal.component.html',
  styleUrls: ['./customer-filter-modal.component.css']
})
export class CustomerFilterModalComponent implements OnInit,OnDestroy{
  toggle = false
  date:any = '';
  dateTwo:any = '';
  amountOne:number = 0;
  amountTwo:number = 0;
  selectedCityOne: City | any = '';
  citiesOne: City[] | any;
  status = ''
  selectedItem: number | null = 0;
  selectedItemTwo: number | null = 0;
  orderType = 'visa';
  private filterSubscription:any;
constructor(private customerService:customerTransactionsService){}

  isActive(index: number): boolean {
    return this.selectedItem === index;
  }
  isActiveTwo(index: number): boolean {
    return this.selectedItemTwo === index;
  }
  toggleItem(index:number){
  this.selectedItem = this.selectedItem === index ? null : index;
  if(index == 0){
   this.status = ''
        }
  if(index == 1){

this.status = 'active'
  }
  if(index == 2){

    this.status = 'inactive'
      }

      if(index == 3){

        this.status = 'tier-1'
          }
          if(index == 4){

            this.status = 'tier-2'
              }
              if(index == 5){

                this.status = 'tier-3'
                  }
                  if(index == 6){

                    this.status = 'verified'
                      }
                      if(index == 7){

                        this.status = 'unverified'
                          }

                          if(index == 8){

                            this.status = 'tier-4'
                              }
}

toggleItemTwo(index:number){
  this.selectedItemTwo = this.selectedItemTwo === index ? null : index;
  if(index == 0){
   this.orderType = 'visa'
        }
  if(index == 1){

this.orderType = 'mastercard'
  }
  if(index == 2){

    this.orderType = 'verve'
      }
}
send(){
this.customerService.filter.next({start_date:this.date,end_date:this.dateTwo,start_amount:this.amountOne,end_amount:this.amountTwo,status:this.status,order_type:this.orderType});
}
clear(){
this.customerService.filter.next({start_date:'',end_date:'',start_amount:0,end_amount:0,status:'',order_type:''})
}
  ngOnInit(): void {
    this.citiesOne = [
      { name: 'card-transaction'},
      {name:'user-transaction'}
  ];
 this.filterSubscription =  this.customerService.filter.subscribe(res => {
  if(res.status == 'unverified'){
    this.selectedItem = 7;
    this.status = 'unverified';
  }
  if(res.status == 'verified'){
    this.selectedItem = 6;
    this.status = 'verified';
  }
  if(res.status == 'tier-3'){
    this.selectedItem = 5;
    this.status = 'tier-3';
  }
  if(res.status == 'tier-2'){
    this.selectedItem = 4;
    this.status = 'tier-2';
  }
  if(res.status == 'tier-1'){
    this.selectedItem = 3;
    this.status = 'tier-1';
  }
  if(res.status == 'tier-4'){
    this.selectedItem = 8;
    this.status = 'tier-4';
  }
    if(res.status == 'inactive'){
      this.selectedItem = 2;
      this.status = 'inactive';
    }
    if(res.status == 'active'){
      this.selectedItem = 1;
      this.status = 'active'
    }
  
    if(res.status == ''){
      this.selectedItem = 0;
      this.status = '';
    }
      this.date = res.start_date;
      this.dateTwo = res.end_date;
   if(res.start_amount !== undefined && res.end_amount !== undefined ){
    this.amountOne = res.start_amount;
    this.amountTwo = res.end_amount;
   }
   else {
    this.amountOne = 0;
    this.amountTwo = 0;
   }
    })
  }
  ngOnDestroy(): void {
   
    console.log('ngOnDestroy called');
    // Unsubscribe from the filter subscription to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}
