import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-cth-overview',
  templateUrl: './cth-overview.component.html',
  styleUrls: ['./cth-overview.component.css']
})
export class CthOverviewComponent {
  isLoading = false
  overview:any = {}
private apiKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService){
    this.apiKey = environment.apiUrl
  }

  ngOnInit(): void {
    this.isLoading = true;
    let dataInString:any = localStorage.getItem('userId');
    let userId = JSON.parse(dataInString)
    this.http.get(this.apiKey + '/zap/admin/users/transaction/' + userId + '/?page=1&limit=1').pipe(catchError(this.handleError)).subscribe({
      next:(res:any) => {
        this.isLoading = false
     this.overview = res.data.header_statistics;
      },
      error:(res:any) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'warn', detail:res,life:2000 });
      }
    })
  }
  handleError(error:any) { let errorMessage = 'internet connection';
  
  if(error.error.message){
    errorMessage = error.error.message
  }
  
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
