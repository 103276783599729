import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-header-two',
  templateUrl: './landing-header-two.component.html',
  styleUrls: ['./landing-header-two.component.css']
})
export class LandingHeaderTwoComponent {
  constructor(private route:Router){

  }
  
  active(url:string){
  if(this.route.url == url){
  return true
  }
  else {
    return false
  }
  }
}
