import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-c-airtime',
  templateUrl: './c-airtime.component.html',
  styleUrls: ['./c-airtime.component.css']
})
export class CAirtimeComponent {
  constructor(private route:Router){

  }
  
  active(url:string){
  if(this.route.url == url){
  return true
  }
  else {
    return false
  }
  }
}
