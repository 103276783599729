
<div class="header-one">
  <h5  [class.active] = '!toggleActive' (click) = 'toggle()'>Pending</h5>
  <h5 [class.active] = 'toggleActive' (click) = 'toggle()'>Approved</h5>
</div>
<div *ngIf="!isLoading">
<div class="header-two">
   <div style="display: flex;">
    <img src="../../../assets/search.png" alt="">
    <input type="text" placeholder="Search by....." (keyup.enter)="search()"  [(ngModel)]="searchText">
   </div>
<div style="display: flex; align-items: center;padding-right: 50px; gap: 3px;" *ngIf="headerState == 'queued' && !checked && !clearCheckBox">
  <button class="approve" (click)="approve()">Approve</button>
  <button class="decline" (click)="decline()">Decline</button>
  <img src="../../../../assets/Stockholm-icons (18).png" (click)="clear()" alt="">
</div>
<div style="display: flex; align-items: center;padding-right: 50px; gap: 3px;" *ngIf="headerState == 'queued' && checked && !clearCheckBox">
  <button class="approve" (click)="approveAll()">Approve All</button>
  <button class="decline" (click)="declineAll()">Decline All</button>
  <img src="../../../../assets/Stockholm-icons (18).png" (click)="clear()" alt="">
</div>
<div style="display: flex; align-items: center;padding-right: 50px; gap: 3px;" *ngIf="headerState == 'queued' && clearCheckBox">
  <button class="approve" (click)="clearCheckBox = !clearCheckBox">Select</button>
</div>
</div>
<br>

<div *ngIf="data.length !== 0">
  <div class="header-three">
    <div class="recent-transaction">
    <table>
     <thead>
        <tr>
    
            <th style="padding-left:15px;" class="order-type">
               <input type="checkbox" *ngIf="headerState == 'queued' && !clearCheckBox" (change)="allChecked()"  [checked] = 'checked' >Card Type</th>
            <th>Amount</th>
            <th>Card ID</th>
            <th></th>
            <th>Customer ID</th>
            <th>Date</th>
            <th>Status</th>
        </tr>
     </thead>
    
       <tbody>
        <tr *ngFor="let transactions of data; let i = index" >
            <td><div class="order-type"><input type="checkbox" (change)="IndividalSelect(i,$event)" [checked] = 'checked' *ngIf="headerState == 'queued' && !clearCheckBox" [disabled]="checked || val.checked"  #val ><p (click)="viewDetail(i)">{{transactions.card_type | titlecase}}</p></div></td>
            <td (click) = 'viewDetail(i)'>${{transactions.amount | number}}</td>
                  <td (click) = 'viewDetail(i)'>{{ transactions.card_id | slice:0:10 }} {{ transactions.card_id.length > 10 ? '...' : '' }}</td>
                  <td></td>
                  <td (click) = 'viewDetail(i)'>{{ transactions.user_id | slice:0:10 }} {{ transactions.user_id.length > 10 ? '...' : '' }}</td>
                  <td (click) = 'viewDetail(i)'>{{transactions.date | date}}</td>
            <td (click) = 'viewDetail(i)' (click) = 'viewDetail(i)' *ngIf="headerState == 'queued'"><p><span class="pending">{{transactions.status | titlecase}}</span></p></td>
            <td (click) = 'viewDetail(i)' *ngIf="headerState == 'processed'"><p><span class="completed">{{transactions.status | titlecase}}</span></p></td>
              </tr>       
       </tbody>
    </table>
    <br>
    <div style="display: flex; justify-content: space-between;align-items: center;">
      <p style="color: #151A20;font-weight: 500;font-size: 13px;">Showing 1-10 of {{this.page}} results</p>
      <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
    </div>
    </div>
    
    </div>
</div>
<p-dialog [header]="modalName" [(visible)]="visible" [modal]="true" [style]="{ width: '30vw' }" [draggable]="false" [resizable]="false">
  <app-cf-modal style="font-family: poppins;" *ngIf="visible" [id]="selectedArray" [allId]="selectedAllArray"></app-cf-modal>
     </p-dialog>
     <p-dialog [header]="modalName" [(visible)]="visibleTwo" [modal]="true" [style]="{ width: '35vw' }" [draggable]="false" [resizable]="false">
      <app-cf-dialog style="font-family: poppins;"  [data]="dataObject"></app-cf-dialog>
         </p-dialog>
</div>
<div style="display: flex;  justify-content: center;padding:80px;position:relative; right:100px;top: 0px;" *ngIf="data.length == 0 && !isLoading">
  
  <div>
   <img src="../../../assets/Empty state illustratio.png" style="margin: 0 auto;display: block;padding-bottom:10px;" width="80" alt="">
   <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">No records to view yet.</h5>
   <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Transaction records will appear here once customers make purchases.</p>
  </div>
 </div>
     <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 100px;" *ngIf = 'isLoading'>
      <app-spinner ></app-spinner>
      </div>