<header class="mat-typography">
    <h4 class="mat-typography">Email Management</h4>
    <nav>
        <img class="api" src="../../assets/_master.png" alt="">
        <img (click)="showDialog('Notification')" src="../../assets/bell.png" alt="">
        <img src="../../assets/plus.png" alt="">
    </nav>
</header>
<p-dialog [header]="modalName" position="topright" [(visible)]="visible" [modal]="true" [style]="{ width: '30vw' }" [draggable]="false" [resizable]="false">
    <app-notification></app-notification>
</p-dialog>