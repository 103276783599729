import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { recentTransactionsService } from './recent-transactions.service';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { BillDialogService } from 'src/app/transactions/bills-transaction/bills-dialog-box/bills-dialog.service';
import { CardTransactionsDialogService } from 'src/app/transactions/card-transactions/card-transactions-dialog-box/card-transactions-dialog.service';
import { BankTransferDialogService } from 'src/app/transactions/bank-transfer/bank-transfer-dialog-box/bank-transfer-dialog.service';

interface transaction {
  amount:number,
  date:string,
  id:number,
  order_type:string,
  status:string,
  transaction_id:string,
  user_id:string}
@Component({
  selector: 'app-recent-transaction',
  templateUrl: './recent-transaction.component.html',
  styleUrls: ['./recent-transaction.component.css']
})
export class RecentTransactionComponent implements OnInit,OnDestroy{
  modalName = '';
  visible = false;
  isLoading = false;
  recentTransaction:transaction[] = [];
  id:any;
  sort_by = ''
  status = ''
  start_date = '';
  end_date = ''
  private filterSubscription:any;
  private apiKey:string = '';
  dialogDisplay = '';
constructor(private billsDialog:BillDialogService,private cardDialog:CardTransactionsDialogService,private bankDialog:BankTransferDialogService,private http:HttpClient,private route:Router,private recentTransactionsService:recentTransactionsService,private messageService:MessageService){
  // api key
  this.apiKey = environment.apiUrl;
}
showdialog(id:any){
  this.visible = true;
  if(this.recentTransaction[id].order_type.toLocaleLowerCase() == 'usd'){
this.cardDialog.id = this.recentTransaction[id].id;
this.dialogDisplay = 'card';


  }
  if(this.recentTransaction[id].order_type.toLocaleLowerCase() == 'trf'){
    this.visible = true;
this.bankDialog.id = this.recentTransaction[id].id;
this.dialogDisplay = 'transfer'
  }
  if(this.recentTransaction[id].order_type.toLocaleLowerCase() == 'betting'){
    this.visible = true;
this.billsDialog.id = this.recentTransaction[id].id;
this.dialogDisplay = 'bills'
  }
 if(this.recentTransaction[id].order_type.toLocaleLowerCase() == 'electricity'){
    this.visible = true;
this.billsDialog.id = this.recentTransaction[id].id;
this.dialogDisplay = 'bills'
  }

  if(this.recentTransaction[id].order_type.toLocaleLowerCase() == 'data'){
    this.visible = true;
this.billsDialog.id = this.recentTransaction[id].id;
this.dialogDisplay = 'bills'
  }
  if(this.recentTransaction[id].order_type.toLocaleLowerCase() == 'cheap_data'){
    this.visible = true;
this.billsDialog.id = this.recentTransaction[id].id;
this.dialogDisplay = 'bills'
  }

  if(this.recentTransaction[id].order_type.toLocaleLowerCase() == 'normal_data'){
    this.visible = true;
this.billsDialog.id = this.recentTransaction[id].id;
this.dialogDisplay = 'bills'
  }
  if(this.recentTransaction[id].order_type.toLocaleLowerCase() == 'airtime'){
    this.visible = true;
this.billsDialog.id = this.recentTransaction[id].id;
this.dialogDisplay = 'bills'
  }
  if(this.recentTransaction[id].order_type.toLocaleLowerCase() == 'cheap_airtime'){
    this.visible = true;
this.billsDialog.id = this.recentTransaction[id].id;
this.dialogDisplay = 'bills'
  }

  if(this.recentTransaction[id].order_type.toLocaleLowerCase() == 'normal_airtime'){
    this.visible = true;
this.billsDialog.id = this.recentTransaction[id].id;
this.dialogDisplay = 'bills'
  }

  if(this.recentTransaction[id].order_type.toLocaleLowerCase() == 'cabletv'){
    this.visible = true;
this.billsDialog.id = this.recentTransaction[id].id;
this.dialogDisplay = 'bills'
  }

  if(this.recentTransaction[id].order_type.toLocaleLowerCase() == 'cable'){
    this.visible = true;
this.billsDialog.id = this.recentTransaction[id].id;
this.dialogDisplay = 'bills'
  }

  if(this.recentTransaction[id].order_type.toLocaleLowerCase() == 'cashback'){
    this.visible = true;
this.billsDialog.id = this.recentTransaction[id].id;
this.dialogDisplay = 'bills'
  }
console.log(this.recentTransaction[id].order_type.toLocaleLowerCase())
}



viewMore(){
if(this.sort_by == '' || this.sort_by == '&sort_by=user-transaction'){
  this.route.navigate(['/transactions/bills-transaction'])
}
else {
  this.route.navigate(['/transactions/card-transaction'])
}
}
filter(){
this.filterSubscription = this.recentTransactionsService.filter.subscribe(res => {
  this.isLoading = true;
   let start_amount = res.start_amount == undefined ? '': '&start_amount=' + res.start_amount;
   let end_amount = res.end_amount == undefined ? '' : '&end_amount=' + res.end_amount;
   this.status = res.status == undefined ? '' : '&status=' + res.status;
 this.sort_by = res.sort_by == undefined ? '' : '&sort_by=' + res.sort_by;
 this.start_date = res.start_date == undefined ? '' : '&start_date=' + res.start_date.toISOString();
 this.end_date = res.end_date == undefined ? '':'&end_date=' + res.end_date.toISOString();
   this.http.get(this.apiKey + '/zap/admin/home/recent?page=1&limit=15' + this.sort_by + start_amount + end_amount + this.status + this.start_date + this.end_date).pipe(catchError(this.handleError)).subscribe({
     next:(res:any) => {
       this.isLoading = false;
 this.recentTransaction = res.data;
     },
     error:(res:any) => {
       this.isLoading = false;
   setTimeout(() => {
    this.messageService.add({ severity: 'error', detail:res,life:2000 });
   }, 2000);
     }
   })
 })
}
handleError(error:any){ let errorMessage = 'No Internet  (transactions)';

if(error.error.message){
  errorMessage = error.error.message
}
  console.log(errorMessage);
  return throwError(() => {
      return errorMessage;
  });
}
ngOnInit(): void {
this.filter();
}

ngOnDestroy(): void {
  // Unsubscribe from the filter subscription to avoid memory leaks
  if (this.filterSubscription) {
    this.filterSubscription.unsubscribe();
  }
}

}
