import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-business-header',
  templateUrl: './business-header.component.html',
  styleUrls: ['./business-header.component.css']
})
export class BusinessHeaderComponent {
  pageUrl = '';
  constructor(private route:Router){}
  visible: boolean = false;
  modalName = '';
  visibleTwo:boolean = false;
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
  showDialogTwo(){
   if(this.route.url == '/business/plan') {
    this.modalName = 'Plan'
    this.visibleTwo = true;
   }
   if(this.route.url == '/business/available-business'){
    this.modalName = 'Available Business';
    this.visibleTwo = true;
   }
  }
}
