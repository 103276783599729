import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, exhaustMap, tap, throwError } from 'rxjs';
import { CardTransactionsDialogService } from 'src/app/transactions/card-transactions/card-transactions-dialog-box/card-transactions-dialog.service';
import { cardTransactionsService } from 'src/app/transactions/card-transactions/card-transactions-transactions/card-transactions.service';
import { environment } from 'src/environments/environment.development';
interface transaction {
  id:number,
  user_id:string,
  amount:number,
  status:string,
  created_at:string,
  reference:string,
  name:string,
  card_type:string,
  merchant:string
  }
@Component({
  selector: 'app-vct-transactions',
  templateUrl: './vct-transactions.component.html',
  styleUrls: ['./vct-transactions.component.css']
})
export class VctTransactionsComponent {
  modalName = ''
  visible:boolean = false;
  searchText = ''
  isLoading:boolean = false;
  data:any[] = []
  start_date = '';
  start_amount = '';
  end_amount = '';
  status = '';
  order_type = '';
  end_date = '';
  page = 1;
  id:string = '';
  private filterSubscription:any;
  first: number = 1;
rows: number = 10;
totalRecord = 20;
private apiKey:string = '';
  constructor(private http:HttpClient,private dialogService:CardTransactionsDialogService,private messageService:MessageService,private cardTransactionService:cardTransactionsService){
    this.apiKey = environment.apiUrl;
  }
  viewDetail(id:number){
    this.id = this.data[id];
    this.showDialog('Individual Transaction Modal')
  }

openSnackBar(id:any) {
  this.showInfo(id)
  navigator.clipboard.writeText(id);
 }
showInfo(id:any) {
  this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
}
  onPageChange(event:any) {
      this.first = event.first;
      this.rows = event.rows;
      this.page = event.page + 1;
      this.isLoading = true;
      this.getRequest().subscribe((res:any) => {
        this.data = res.data.data;
        this.isLoading = false;
       })
  }
      showDialog(name:string) {
        this.modalName = name;
          this.visible = true;
      }
      cancel(){
        this.visible = false;
      }
      filter(){
   this.filterSubscription = this.cardTransactionService.filter.pipe(tap((res:any) => {
          this.status = res.status == '' ? '' : '&status=' + res.status;
          this.start_date = res.start_date == '' ? '' : '&start_date' + res.start_date.toISOString();;
          this.end_date = res.end_date == '' ? '' : '&end_date' + res.end_date.toISOString();;
          this.start_amount = '&start_amount=' + res.start_amount;
          this.end_amount = '&end_amount=' + res.end_amount;
          this.isLoading = true;
          console.log(res.start_amount + 'amount')
         }),exhaustMap((res:any) => {return this.getRequest()})).pipe(catchError(this.handleError)).subscribe({
          next:(res:any) => {
            this.isLoading = false;
            this.data = res.data.data;
            this.totalRecord = res.data.total;
          },
          error:(res) => {
            this.isLoading = false;
            setTimeout(() => {
              this.messageService.add({ severity: 'error', detail:res,life:2000 });
             }, 2000);
          }
        })
      }
      search(){
        this.isLoading = true;
        this.getRequest().subscribe((res:any) => {
          this.data = res.data.data;
          this.totalRecord = res.data.total;
        this.isLoading = false
         })
        }
        handleError(error:any) { let errorMessage = 'No Internet (transactions)';

if(error.error.message){
  errorMessage = error.error.message
}

  console.log(errorMessage);
  return throwError(() => {
      return errorMessage;
  });
}
  getRequest(){
    let dataInString:any = localStorage.getItem('userId');
    let data = JSON.parse(dataInString);
   return  this.http.get(this.apiKey + '/zap/admin/funding/card-transactions/' + data + '/?page=' + this.page + '&' + 'limit' + '=' + this.rows + this.start_amount + this.end_amount + this.status + this.start_date + this.end_date + '&search_query=' + this.searchText)
  }
      ngOnInit(): void {
        this.isLoading = true;
       this.filter()
     
      }
   
  ngOnDestroy(): void {
   
    console.log('ngOnDestroy called');
    // Unsubscribe from the filter subscription to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  } 
}
