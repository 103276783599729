
<div class="container" *ngIf="!isLoading">
  <div class="card" *ngFor="let data of data; let i = index">
  <div>
 
    <p-image [src]="data.image"   alt="Image" width="50" [preview]="true"></p-image>
    <h4 (click)="showDialog('',i)">{{data.section_name | titlecase}}</h4>
    <p (click)="showDialog('',i)">{{data.description | titlecase}}</p>
  </div>
  </div>
</div>
<p-dialog *ngIf="visible" [header]="modalName"[maximizable]="true"  [(visible)]="visible" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
  <app-landing-section-dialog [data]="singleData"></app-landing-section-dialog>
</p-dialog>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 100px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>