
<div *ngIf="!isLoading">
    <div class="payment-manager">
        <div class="card">
            <img src="../../assets/Widget icons.png" alt="">
            <p>Total Lock Amount</p>
            <h4>₦{{overview.total_locks_amount | number}}</h4>
        </div>
        <div class="card">
            <img src="../../assets/Widget icons.png" alt="">
            <p>Total Locks Account</p>
            <h4>{{overview.total_locks_acount}}</h4>
        </div>
        <div class="card">
            <img src="../../assets/Widget icons.png" alt="">
            <p>Total Withdraw Amount</p>
            <h4>₦{{overview.total_withdraw_amount | number}}</h4>
        </div>
</div>
<!-- <div class="referral-code">
    <img src="../../../../../../assets/Calendar Mark.png" style="cursor: pointer;" width="24" alt="">
    <p>Next Unlock</p>
    <strong>April 3, 2024</strong>
</div> -->
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    
