import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailDialogBoxComponent } from './email-dialog-box/email-dialog-box.component';
import { AuthPageService } from '../auth-page/auth-page.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit{
  role:string = '';
  constructor(private dialog:MatDialog,private authService:AuthPageService){}
 
message(){
this.dialog.open(EmailDialogBoxComponent)
}

pageEvent(val:any){

}
ngOnInit(): void {
  this.authService.roleName.subscribe((res:any) => {
    this.role = res.data.role.role_name
  })
}
}
