import { Component, OnDestroy, OnInit } from '@angular/core';
import { billsTransactionsService } from '../bills-transactions/bills-transactions.service';
interface City {
  name: string;
}
@Component({
  selector: 'app-bills-filter-modal',
  templateUrl: './bills-filter-modal.component.html',
  styleUrls: ['./bills-filter-modal.component.css']
})
export class BillsFilterModalComponent implements OnInit,OnDestroy {
  toggle = false
  date:any = '';
  dateTwo:any = '';
  amountOne:number = 0;
  amountTwo:number = 0;
  status = ''
  selectedItem: any | null;
  selectedItemTwo:any| null;
  orderType = '';
  private filterSubscription:any;
constructor(private billsTransactionsService:billsTransactionsService){}

  isActive(index: number): boolean {
    return this.selectedItem === index;
  }
  isActiveTwo(index: number): boolean {
    return this.selectedItemTwo === index;
  }
  toggleItem(index:number){
  this.selectedItem = this.selectedItem === index ? null : index;
  if(index == 0){
   this.status = ''
        }
  if(index == 1){

this.status = 'success'
  }
  if(index == 2){

    this.status = 'reversed'
      }
}

toggleItemTwo(index:number){
  this.selectedItemTwo = this.selectedItemTwo === index ? null : index;
  if(index == 0){
   this.orderType = 'betting'
        }
  if(index == 1){

this.orderType = 'electricity'
  }
  if(index == 2){

    this.orderType = 'data'
      }
      if(index == 3){

        this.orderType = 'airtime'
          }
}
send(){
this.billsTransactionsService.filter.next({start_date:this.date,end_date:this.dateTwo,start_amount:this.amountOne,end_amount:this.amountTwo,status:this.status,order_type:this.orderType});
}
clear(){
  this.billsTransactionsService.filter.next({start_date:'',end_date:'',start_amount:0,end_amount:0,status:'',order_type:''})
}
  ngOnInit(): void {
    this.filterSubscription = this.billsTransactionsService.filter.subscribe(res => {
      if(res.status == 'reversed'){
        this.selectedItem = 2;
        this.status = ' reversed';
      }
      if(res.status == 'success'){
        this.selectedItem = 1;
        this.status = 'success'
      }
    
      if(res.status == ''){
        this.selectedItem = 0;
        this.status = '';
      }
    
      if(res.status == undefined){
    this.selectedItem = 0;
    this.status = ''
      }

      if(res.order_type == 'airtime'){
        this.selectedItemTwo = 3;
        this.orderType = 'airtime';
      }
      if(res.order_type == 'data'){
        this.selectedItemTwo = 2;
        this.orderType = 'data'
      }
    
      if(res.order_type == 'electricity'){
        this.selectedItemTwo = 1;
        this.orderType = 'electricity';
      }
    if(res.order_type == 'betting'){
      this.selectedItemTwo = 0;
      this.orderType = 'betting';
    }
    if(res.order_type == ''){
      this,this.selectedItemTwo = '';
      this.orderType = ''
        }
        this.date = res.start_date;
        this.dateTwo = res.end_date;
     if(res.start_amount !== undefined && res.end_amount !== undefined ){
      this.amountOne = res.start_amount;
      this.amountTwo = res.end_amount;
     }
     else {
      this.amountOne = 0;
      this.amountTwo = 0;
     }
      })
  }

  ngOnDestroy(): void {
    // Unsubscribe from the filter subscription to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}
