<div class="header-one">
    <h4>Airtime Swap</h4>

   <nav>
      <div class="filter-group" (click)="showDialog('Filter')">
          <p>Filter</p>
          <img src="../../assets/Stockholm-icons (9).png" alt="">
      </div>
<img src="../../../assets/Stockholm-icons (15).png" style="margin-left: 20px;" alt="" (click)="reload()">
  </nav>
</div>
<div *ngIf="!isLoading">
<div class="payment-manager" *ngIf="role == 'Administrator'">
    <div class="card">
        <img src="../../assets/Widget icons (7).png" alt="">
        <p>Total Airtime Swap</p>
        
        <h4>₦{{overview.totalAirtimeSwap | number}}</h4>
    </div>
    <div class="card">
        <img src="../../assets/Widget icons.png" alt="">
        <p>Total Swap</p>
        <h4>{{overview.transferTransaction | number}}</h4>
    </div>
</div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    <p-dialog [header]="modalName" [(visible)]="visible" [modal]="true" [maximizable]="true" [responsive]="true" [style]="{ width: '35vw',height:'100%' }" [draggable]="false" [resizable]="false">
        <app-as-filter-modal *ngIf="visible" style="font-family: poppins;"></app-as-filter-modal>
      </p-dialog>