import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DialogBoxComponent } from 'src/app/dialog-box/dialog-box.component';
import { BillsDialogBoxComponent } from './bills-dialog-box/bills-dialog-box.component';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';
@Component({
  selector: 'app-bills-transaction',
  templateUrl: './bills-transaction.component.html',
  styleUrls: ['./bills-transaction.component.css']
})
export class BillsTransactionComponent implements OnInit{
  role:string = ''
  constructor(private authService:AuthPageService){}
  ngOnInit(): void {
    this.authService.roleName.subscribe((res:any) => {
      this.role = res.data.role.role_name
    })
  }

}
