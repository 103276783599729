import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.css']
})
export class StepTwoComponent implements OnInit{
  creationFee:number = 0;
  fundingFee:number = 0;
  withdrawalFee:number = 0;
  declineFee:number = 0;
  maintenanceFee:number = 0;
  transactionFee:number = 0;
  providerWithdrawalFee:number = 0;
  providerFundingFee:number = 0;
constructor(private route:Router){}
save(){
let stepTwo = {creationFee:this.creationFee,fundingFee:this.fundingFee,widthdrawalFee:this.withdrawalFee,declineFee:this.declineFee
,maintenanceFee:this.maintenanceFee,transactionFee:this.transactionFee,providerWithdrawalFee:this.providerWithdrawalFee,providerFundingFee:this.providerFundingFee
}
localStorage.setItem('stepTwo', JSON.stringify(stepTwo))
this.route.navigate(["/configurations/virtual-card/step-three"])
}

ngOnInit(): void {
  let dataInString:any = localStorage.getItem('stepTwo');
  let stepTwoData = JSON.parse(dataInString);

if(stepTwoData !== null){
  this.creationFee = stepTwoData.creationFee;
  this.fundingFee = stepTwoData.fundingFee;
  this.withdrawalFee = stepTwoData.widthdrawalFee;
  this.declineFee = stepTwoData.declineFee;
  this.maintenanceFee = stepTwoData.maintenanceFee;
  this.transactionFee = stepTwoData.transactionFee;
  this.providerWithdrawalFee = stepTwoData.providerWithdrawalFee;
  this.providerFundingFee = stepTwoData.providerFundingFee;
}
}
}
