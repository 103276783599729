import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ProductChartService } from '../product-chart/product-chart.service';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.css']
})
export class PeriodComponent {
    data: any;
    options: any;
    name = 'today'
     dataTwo:{dates:string[],amounts:number[]} = {dates:[],amounts:[0]}
   isLoading = false;
  private apiKey:string = '';
    constructor(private http:HttpClient,private pcService:ProductChartService,private messageService:MessageService){
      // api key
this.apiKey = environment.apiUrl;
    }
    fetchRequest(){
      this.isLoading = true;
        this.http.get(this.apiKey + '/zap/admin/home/plots/period?period=' + this.name).pipe(catchError(this.handleError)).subscribe({
        next:(res:any) => {
                this.isLoading = false;
        this.dataTwo = res.data
           console.log(this.dataTwo)
           const documentStyle = getComputedStyle(document.documentElement);
           const textColor = documentStyle.getPropertyValue('--text-color');
           const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
           const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        
           this.data = {
               labels: this.dataTwo.dates.map((res:any) => {
                const timestampStr = res;
                const timestampObj = new Date(timestampStr);
                
                // Format time
                const timeOptions:any = { year: 'numeric', month: 'numeric', day: 'numeric' };
                const formattedTime = timestampObj.toLocaleString('en-US', timeOptions);
                return formattedTime
               }),
               datasets: [
                   {
                       label: 'Transactions',
                       data: this.dataTwo.amounts,
                       fill: true,
                       borderColor:'#673AB7',
                       borderWidth: 1,
                       backgroundColor: 'transparent'
                   },
               ]
           };
        
           this.options = {
             responsive: true,
               maintainAspectRatio: false,
               aspectRatio: 0.9,
               plugins: {
                   legend: {
                     display: false,
                       labels: {
                       
                           color: textColor,
                           font: {
                             family: 'arial', // Reference the custom font here
                           },
                       }
                   }
               },
               scales: {
                   x: {
                       ticks: {
                       
                           color: 'black'
                       },
                       grid: {
                           color: surfaceBorder,
                           drawBorder: false
                       }
                   },
                   y: {
                       ticks: {
                           color: 'black',
                           callback: (value: any) => {
                            return value + 'k';
                          },
                       },
                       grid: {
                           color: surfaceBorder,
                           drawBorder: true
                       }
                   }
               }
           };
        
         },error:(res:any) => {
                this.messageService.add({ severity: 'error', detail:res,life:2000 });  
       
            this.isLoading = false;
            const documentStyle = getComputedStyle(document.documentElement);
           const textColor = documentStyle.getPropertyValue('--text-color');
           const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
           const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        
           this.data = {
               labels: this.dataTwo.dates.map((res:any) => {
                const timestampStr = res;
                const timestampObj = new Date(timestampStr);
                
                // Format time
                const timeOptions:any = { year: 'numeric', month: 'numeric', day: 'numeric' };
                const formattedTime = timestampObj.toLocaleString('en-US', timeOptions);
                return formattedTime
               }),
               datasets: [
                   {
                       label: 'Transactions',
                       data: [],
                       fill: true,
                       borderColor:'#673AB7',
                       borderWidth: 1,
                       backgroundColor: 'transparent'
                   },
               ]
           };
        
           this.options = {
             responsive: true,
               maintainAspectRatio: false,
               aspectRatio: 0.9,
               plugins: {
                   legend: {
                     display: false,
                       labels: {
                       
                           color: textColor,
                           font: {
                             family: 'arial', // Reference the custom font here
                           },
                       }
                   }
               },
               scales: {
                   x: {
                       ticks: {
                       
                           color: 'black'
                       },
                       grid: {
                           color: surfaceBorder,
                           drawBorder: false
                       }
                   },
                   y: {
                       ticks: {
                           color: 'black',
                       },
                       grid: {
                           color: surfaceBorder,
                           drawBorder: true
                       }
                   }
               }
           };
         }
        }
        )
    }

    handleError(error:any){ let errorMessage = 'No Internet  (period chart)';

if(error.error.message){
  errorMessage = error.error.message
}
  console.log(errorMessage);
  return throwError(() => {
      return errorMessage;
  });
}
      ngOnInit() {
        this.isLoading = true;
       this.fetchRequest();
       this.pcService.fetched.subscribe(res => { this.name = res;
        if(res == 'Today'){
          this.fetchRequest()
        
        }
        if(res == 'One-week'){
            this.fetchRequest()
          
          }
          if(res == 'Two-weeks'){
            this.fetchRequest()
          
          }
          if(res == 'One-month'){
            this.fetchRequest()
          
          }
    })
        
      }

}


