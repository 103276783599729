<div style="display: flex;justify-content: space-between;align-items: center;">
    <h4>{{data.title}}</h4>
    <button class="btn-message" (click)="save()">Save</button>
  </div>
  <div class="container" *ngIf="!isLoading">
         <div class="inputWithIcon"> 
          <p>Title</p>
          <input type="text" placeholder="Title" [(ngModel)] ='title'>
        </div>
        <div class="inputWithIcon"> 
          <p>Content</p>
     <textarea name=""  id="" cols="30" rows="10" placeholder="Content" [(ngModel)] = 'content' ></textarea>
        </div>
      <div>
  </div>
  <button class="btn-delete" (click)="delete()">Delete Section</button>
  </div>
  
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;top: 3px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    