import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-savings-profile-overview',
  templateUrl: './savings-profile-overview.component.html',
  styleUrls: ['./savings-profile-overview.component.css']
})
export class SavingsProfileOverviewComponent {
  isLoading = true;
  overview:any = {}
  modalName = '';
  role:any = '';
  visible: boolean = false;
  private apiKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService){
    this.apiKey = environment.apiUrl
  }
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
  ngOnInit(): void {
    this.isLoading = true;
    let dataInString:any = localStorage.getItem('userId');
    let userId = JSON.parse(dataInString)
    this.http.get( this.apiKey + '/zap/admin/lock/specific/' + userId + '/?page=1&limit=10').pipe(catchError(this.handleError)).subscribe(
      {
        next:(res:any) => {
          this.isLoading = false;
this.overview = res.data.stats;
        },
        error:(res)=> {
           this.isLoading = false;
           this.messageService.add({ severity: 'error', detail:res,life:2000 });
        }
      }
    )
   
  }
  handleError(error:any) { let errorMessage = 'No internet (overview)';

  if(error.error.message){
    errorMessage = error.error.message
  }

    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });

  
  }
}
