<h4>Banks</h4>
<input [disabled]="checked" [style]="{'width':'1100px'}" type="text" pInputText placeholder="Search for any bank" [(ngModel)]="value" />
<div class="container">
    <div class="card" *ngFor="let bank of data | filter:value">
        <div>
            <h4>{{bank.bankName | titlecase}}  <span *ngIf="accountName !== ''" style="font-size: 12px;"><strong>({{accountName | titlecase}})</strong></span></h4>
        </div>
        <p-checkbox (click) = 'resolveAccountNumber(bank.bankName,bank.bankCode)' [binary]="true" [(ngModel)]="checked" inputId="binary"></p-checkbox>
    </div>
</div>
<p *ngIf="checked && !isLoadingTwo" style="font-size: 12px;" >Account Number</p>
<p *ngIf="checked && isLoadingTwo" style="font-size: 12px;color: purple;" ><strong>Loading...</strong></p>
<input [disabled]="accountName !== ''" *ngIf="checked" (input) = 'resolve()' [style]="{'width':'1100px'}" type="text" pInputText placeholder="Enter Bank Account Number here" [(ngModel)]="accountNumber" />
<div *ngIf="accountNumber.length == 10 && !isLoadingTwo">
    <p style="font-size: 12px;" >Narration</p>
<input [style]="{'width':'1100px'}" type="text" pInputText placeholder="Note" [(ngModel)] = 'naration' />

<p style="font-size: 12px;" >Admin Pin</p>
<input [style]="{'width':'1100px'}" type="text" pInputText placeholder="Pin" [(ngModel)] = 'pin' />

<p style="font-size: 12px;" >Amount</p>
<input [style]="{'width':'1100px'}" type="text" pInputText placeholder="Enter Amount" [(ngModel)] = 'amount' />
<br>
<button class="apply" (click) = 'transfer()'  *ngIf="naration !== '' && amount !== '' && pin !== ''">Send Money</button>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
  