<div class="header-one">
    <h4>Zap PIN</h4>

    <nav>
        <div class="filter-group" [matMenuTriggerFor]="menu">
            <p>{{dropName}}</p>
            <img src="../../assets/Stockholm-icons (9).png" alt="">
        </div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="used()">
            <span style="font-family: poppins; font-size: 14px;">Used</span>
          </button>
          <button mat-menu-item (click)="unUsed()">
            <span style="font-family: poppins; font-size: 14px;">Unused</span>
          </button>
        </mat-menu>
    </nav>
</div>
<div class="card" *ngIf="!isLoading">
    <p-chart type="line" [data]="data" [options]="options"></p-chart>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 45px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>