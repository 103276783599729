import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  visible: boolean = false;
  modalName = '';
  visibleTwo:boolean = false
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }

  showDialogTwo() {
      this.visibleTwo = true;
  }
  cancel(){
    this.visibleTwo = false;
  }
}
