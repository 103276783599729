import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-customer-message-history',
  templateUrl: './customer-message-history.component.html',
  styleUrls: ['./customer-message-history.component.css']
})
export class CustomerMessageHistoryComponent implements OnInit {
  isLoading:boolean = false;
  page:number = 1;
  data:any = [];
  private apiKey:string = '';
  constructor(private http:HttpClient){
    this.apiKey = environment.apiUrl
  }

  first: number = 1;
  rows: number = 10;
  totalRecord = 20;
  
  onPageChange(event:any) {
  this.first = event.first;
  this.rows = event.rows;
  this.page = event.page
  console.log(event.page + 1)
  this.rows = event.rows;
  this.fetchRequest(event.page + 1,this.rows)
  }

  fetchRequest(page:number,limit:number){
    let dataInString:any = localStorage.getItem('userId');
    let userId = JSON.parse(dataInString)
    this.http.get(this.apiKey + '/zap/admin/users/notification?user_id=' + userId + '&page=' + page + '&limit=' + limit).subscribe(
      {
        next:(res:any)=> {
          this.totalRecord = res.data.total
          this.data = res.data.data
          this.isLoading = false;
          console.log(res)
        },
        error:(res:any) => {
          this.isLoading = false
        }
      }
    )
      }
      
      ngOnInit(): void {
        this.isLoading = true;
        this.fetchRequest(this.first,this.rows)
      }
}
