import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';
interface City {
  name: string;
}
@Component({
  selector: 'app-cp-message',
  templateUrl: './cp-message.component.html',
  styleUrls: ['./cp-message.component.css']
})
export class CpMessageComponent {
  isLoading = false;
  citiesTwo: City[] | any;
  textBoxLogic = 'email';
  selectedCityTwo: City | any = '';
title:string = '';
content:string = '';
private apiKey:string = '';
constructor(private http:HttpClient,private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
  cancel(){

  }

  sendMessage(){
    this.isLoading = true;
    let dataInString:any = localStorage.getItem('userId');
    let userId = JSON.parse(dataInString)
this.http.post(this.apiKey + '/zap/admin/users/notification',{title:this.title,content:this.content,user_id:userId,notification_type:this.selectedCityTwo.name}).subscribe({
  next:(res:any) => {
    this.messageService.add({ severity: 'info', summary:'Successful', detail:res.message});
    this.clearMessage();
    this.isLoading = false;
  },
  error:(res:any) => {
    let errorMessage = 'An error occurred'; // Default error message
    if (res.error && res.error.message) {
        errorMessage = res.error.message; // Use the error message from the response body
    }
    this.messageService.add({ severity: 'error', summary: 'Error!', detail: errorMessage });
    this.isLoading = false;
    this.clearMessage();
  }
})
  }

  clearMessage(){
this.content = '';
this.title = '';
this.selectedCityTwo = {name:''}
  }

  switchTextbox(val:any){
    if(val.name == 'push' || val.name == 'email' || val.name == 'sms'){
    this.textBoxLogic = val.name;
    }
  }
  ngOnInit() {
      this.citiesTwo = [
        { name: 'email'},
        { name: 'sms',},
        {name:'push'}
    ];
  }
}
