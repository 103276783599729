import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthPageService } from './auth-page.service';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.css']
})
export class AuthPageComponent {
  isloading = false;
  errorMessage = null
  constructor(private route:Router, private authService:AuthPageService, private messageService:MessageService){}
viewPassword = false;

ActivateViewPassword(){
  this.viewPassword = !this.viewPassword;
}

login(form:NgForm){
  this.isloading = true;
console.log(form)
const email = form.value.email;
const password = form.value.password
this.authService.loginRequest(email,password).subscribe(
  {
      next:(res) => {
       
       this.show();
console.log(res)
this.authService.getAdminstrator().subscribe((res:any) => {
  if(res.data.role.role_name == 'Communications'){
    this.route.navigate(['/email']);
  }
else {
  this.route.navigate(['/home']);
}
 
  this.isloading = false;
  this.authService.roleName.next(res);
  localStorage.setItem('roleData', JSON.stringify(res))
})
      },
      error:(res)=> {
        this.isloading = false;
        this.showError(res)
      }
  }
)

form.reset();
}
show() {
  this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Welcome Back!' });
}
showError(message:string) {
  this.messageService.add({ severity: 'error', summary: 'Error', detail: message });
}

}
