<div *ngIf="!isLoading">
  
    <div class="header-two">
      <h5>Transaction Status</h5>
    
      <p *ngIf="data.status.toLocaleLowerCase() == 'success' || data.status.toLocaleLowerCase() == 'successful' || data.status.toLocaleLowerCase() == 'completed'"><span class="completed">Successful</span></p>
      <p *ngIf="data.status.toLocaleLowerCase() == 'decline' || data.status.toLocaleLowerCase() == 'declined'"><span class="pending">Declined</span></p>
    </div>
    <table class="table-one" style="width: 500px;">
      <tr>
        <th>Beneficiary</th>
        <th>Transaction Id</th>
        <th>Currency</th>
      </tr>
      <tr>
        <td>{{data.beneficiary | titlecase}}</td>
        <td>
          <div style="display: flex; align-items: center;">
          <p>{{data.reference}}</p>
          <img (click)="openSnackBar(data.user_id)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
          </div>
        </td>
        <td>{{data.currency}}</td>
    
      </tr>
      <tr>
        <th> ID</th>
        <th>Memo</th>
        <th>Order Type</th>
      </tr>
      <tr>
        <td>{{data.id}}</td>
        <td>{{data.memo | titlecase}}</td>
        <td>{{data.order_type | titlecase}}</td>
          
        
      
    
      </tr>
      <tr>
        <th>Amount</th>
        <th>Date</th>
        <th>Time</th>
      </tr>
      <tr>
        <td *ngIf="data.currency == 'USD'">${{data.amount | number}}</td>
        <td *ngIf="data.currency == 'NGN'">₦{{data.amount | number}}</td>
    <td>{{data.date | date}}</td>
    <td>{{data.date | date:'shortTime' }}</td>
    <td></td>
      </tr>

      <tr>
        <th>Sender</th>
        <th>Balance Before</th>
        <th>Balance After</th>
        
      </tr>
      <tr>
        <td>{{data.sender | titlecase}}</td>
        <td>₦{{data.balance_before | number}}</td>
        <td>₦{{data.balance_after | number}}</td>
    <td></td>
      </tr>
    </table>
    </div>
    <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoading'>
      <app-spinner ></app-spinner>
      </div>
    