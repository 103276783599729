<div *ngIf="!isLoading">
    <div class="container">
        <h1 style="padding-bottom: 0px;margin: 0px; width:500px;"></h1>
        <div class="header-two">
    <p>Title</p>
    <input type="text" placeholder="Title" [(ngModel)]="title">
    <p>Type</p>
    <div class="select">
        <div class="select">
            <p-dropdown [options]="citiesTwo" (click) = 'switchTextbox(selectedCityTwo)' [style]="{width:'100%'}" [(ngModel)]="selectedCityTwo" optionLabel="name" [showClear]="true" placeholder="Select Type"></p-dropdown>
        </div>
    </div>
    <p>Content</p>
    <p-editor [(ngModel)]="content" [style]="{ height: '400px' }" *ngIf="textBoxLogic == 'email'"></p-editor>
    <textarea [(ngModel)] = 'content' *ngIf="textBoxLogic == 'sms' || textBoxLogic == 'push'"  cols="30" rows="10"></textarea>
        </div>
    
        <div  class="header-eight">
     
            <button class="filter" (click)="clearMessage()">Clear</button>
            <button class="apply" (click)="sendMessage()" [disabled]="title == '' || content == '' || selectedCityTwo == ''">Send</button>
          </div>
    </div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>