<div *ngIf="!isLoading">
    <div class="container">
        <h1 style="padding-bottom: 0px;margin: 0px; width:500px;"></h1>
        <div class="header-two">
    <p>Price</p>
    <input [(ngModel)] = 'price' type="number" placeholder="₦0">
    <p>Telco Price</p>
    <input [(ngModel)] = 'telcoPrice' type="number" placeholder="₦0">
    <p>Name</p>
    <input [(ngModel)] = 'name' type="text" placeholder="Name">
    <p>Code</p>
    <input [(ngModel)] = 'code' type="text" placeholder="Code">
    <p>Network Id</p>
    <input [(ngModel)] = 'networkId' type="number" placeholder="₦0">
    <div  class="header-eight">
     
        <button class="filter" (click)="clear()">Clear</button>
        <button class="apply" (click)="Add()" [disabled]="name == '' || code == ''  ">Add Plan</button>
      </div>
</div>
</div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
  