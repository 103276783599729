

<div class="header-two">
    <h4></h4>
</div>
<div *ngIf="!isLoading">
    <div class="header-one">
        <div class="card">
            <div>
               <img src="../../../assets/Icons (5).png" width="30" alt="">
               <h4 style="color: #1D242D;margin-bottom: 2px;">Airtime To Cash Manual</h4>
               <p style="color: #546881;margin-top: 0px;font-size: 12px;">Manage Airtime To Cash Manual configuration, price, purchase fee.</p>
            </div>
                   <div style="display: flex;align-items: flex-start;gap: 10px;">
    <p-inputSwitch [(ngModel)]="checked" (click) = 'activateChecked()'></p-inputSwitch>
                   </div>
               </div>
    </div>
</div>
<div style="display: flex;justify-content: center;padding: 40px;position:relative; right: 80px;top: 100px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>