import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { DialogBoxComponent } from 'src/app/dialog-box/dialog-box.component';
import { billsTransactionsService } from '../bills-transactions/bills-transactions.service';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';
import { environment } from 'src/environments/environment.development';
@Component({
  selector: 'app-bills-overview',
  templateUrl: './bills-overview.component.html',
  styleUrls: ['./bills-overview.component.css']
})
export class BillsOverviewComponent implements OnInit{
  isLoading = false;
  overview = {totalBalance:'0',totalAmount:'0'}
  modalName = ''
  role:string = '';
  visible:boolean = false;
  private apiKey:string = '';
  constructor(private dialog:MatDialog,private http:HttpClient, private messageService:MessageService,private billService:billsTransactionsService,private authService:AuthPageService) {
    // api key
    this.apiKey = environment.apiUrl
  }
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
  openDialog(){
   this.showDialog('Filter')
  }

  handleError(error:any) { let errorMessage = 'No Internet (overview)';

  if(error.error.message){
    errorMessage = error.error.message
  }

    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
  reload(){
    location.reload();
  }

  ngOnInit(): void {
    this.authService.roleName.subscribe(res => {
      this.role = res.data.role.role_name
    })
    this.billService.filter.subscribe(res => {if(res !== '') {this.visible = false}})
   if(this.role == 'Administrator'){ this.isLoading = true;}
   if(this.role == 'Administrator'){
    this.http.get(this.apiKey + '/zap/admin/transaction/stats').pipe(catchError(this.handleError)).subscribe({
      next:(res:any) => {
        this.isLoading = false
        this.overview.totalBalance = res.data.total_balance;
        this.overview.totalAmount = res.data.total_amount;
      },
      error:(res:any) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'error', detail:res,life:2000 });
      }
    })

   }

  
  }
}
