import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthPageService } from '../auth-page/auth-page.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit{
  role:string = '';
constructor(private route:Router, private authService:AuthPageService){}

activeClass(url:string){
if(this.route.url == url){
return true
}

else {
  return false;
}
}

navigate(url:string){
this.route.navigate([url]);
}

transactionActive(){
 
  if(this.route.url == '/transactions/bills-transaction'|| 
  this.route.url == '/transactions/airtime-swap' || 
  this.route.url == "/transactions/card-transaction" 
  ||   this.route.url ==  "/transactions/zap-pin"
  || 
  this.route.url == '/transactions/gift-card'
  || this.route.url == "/transactions/bank-transfer"
  ){
    return true
    }
    else {
      return false;
    }
}

emailActive(){
if(this.route.url == '/email' || this.route.url == '/email/message'){
return true
}

else {
  return false
}
}


customers(){
  if(this.route.url == '/user-management/customers' ||   this.route.url == '/user-management/customers/customer-details/profile' ||  this.route.url == '/user-management/customers/customer-details/message-history'||  this.route.url == '/user-management/customers/customer-details/transaction-history'||  this.route.url == '/user-management/customers/customer-details/message-history'){
return true
  }
  else {
    return false
  }
}

referral(){
  if(this.route.url == '/user-management/referral' || this.route.url == '/user-management/referral/referral-detail'){
return true
  }
  else {
    return false;
  }
}
savings(){
  if(this.route.url == '/user-management/savings' || this.route.url == '/user-management/savings/savings-detail'){
return true
  }
  else {
    return false
  }
}
configurations(){
  if(this.route.url == '/configurations/bills-payment' || this.route.url == '/configurations/internet/data-plan/mtn' || this.route.url == '/configurations/internet/data-plan/glo' || this.route.url == '/configurations/internet/data-plan/airtel' || this.route.url == '/configurations/internet/data-plan/9mobile'   || this.route.url == '/configurations/banking-transfer' || this.route.url == '/configurations/gift-card' || this.route.url == '/configurations/airtime-to-cash' || this.route.url == '/configurations/zap-pin'|| this.route.url == '/configurations/virtual-card' || this.route.url == '/configurations/virtual-card/step-one' || this.route.url == '/configurations/virtual-card/step-two' || this.route.url == '/configurations/virtual-card/step-three' || this.route.url == '/configurations/virtual-card/preview' || this.route.url == '/configurations/foreign-exchange'  || this.route.url == '/configurations/airtime-to-cash-auto'  || this.route.url == '/configurations/airtime-to-cash-manual' || this.route.url == '/configurations/service-provider'){
return true
  }
  else {
    return false
  }
}
businessActive(){
  if(this.route.url == '/business/plan' || this.route.url == '/business/available-business'){
  return true
  }
  
  else {
    return false
  }
  }
landingActive(){
  if(this.route.url == '/landing-page/sections' || this.route.url == '/landing-page/products' || this.route.url == '/landing-page/teams' || this.route.url == '/landing-page/faq'){
  return true
  }
  
  else {
    return false
  }
  }
settings(){
  if(this.route.url == '/settings/general-settings' || this.route.url == '/settings/system-settings' || this.route.url == '/settings/security-audit' || this.route.url == '/settings/webhook-logs'  || this.route.url == '/settings/account-manager' || this.route.url == '/settings/payout'){
return true
  }
  else {
    return false
  }
}
userManagement(){
  if(this.route.url == '/user-management/customers' || this.route.url == '/user-management/referral' || this.route.url == '/user-management/savings' || this.route.url == '/user-management/savings/savings-detail' || this.route.url == '/user-management/referral/referral-detail' || this.route.url == '/user-management/customers/customer-details/profile' ||  this.route.url == '/user-management/customers/customer-details/message-history'||  this.route.url == '/user-management/customers/customer-details/transaction-history'||  this.route.url == '/user-management/customers/customer-details/message-history'||  this.route.url == '/user-management/kyc-request' ||  this.route.url == '/user-management/bvn-request' ||  this.route.url == '/user-management/card-funding' || this.route.url == '/user-management/withdrawal' ||  this.route.url == '/user-management/virtual-card-management' ||  this.route.url == '/user-management/virtual-card-management/virtual-card-detail/profile' ||  this.route.url == '/user-management/virtual-card-management/virtual-card-detail/transaction-history'){
  return true
  }
  
  else {
    return false
  }
  }
 BillActive(){
    if( this.route.url == '/configurations/bills-payment' || this.route.url == '/configurations/internet/data-plan/mtn' || this.route.url == '/configurations/internet/data-plan/glo' || this.route.url == '/configurations/internet/data-plan/airtel' || this.route.url == '/configurations/internet/data-plan/9mobile'){
return true
    }
    else {
      return false
    }
  }
virtualCardActive(){
  if(this.route.url == '/user-management/virtual-card-management' || this.route.url == '/user-management/virtual-card-management/virtual-card-detail/profile' ||  this.route.url == '/user-management/virtual-card-management/virtual-card-detail/transaction-history'){
return true
  }else {
    return false
  }
}
  airtimeActive(){
    if(this.route.url == '/configurations/airtime-to-cash' || this.route.url == '/configurations/airtime-to-cash-auto' || this.route.url == '/configurations/airtime-to-cash-manual'){
return true
    }
    else {
      return false;
    }
  }
  transactionDropdown = false;
  customerDropdown = false;
  configurationsDropdown = false;
  transactionActivateDrop(){
    this.route.navigate(['transactions/bills-transaction'])
    this.transactionDropdown = !this.transactionDropdown;
  }
  
  customerActivateDrop(){
    this.route.navigate(['user-management/customers'])
    this.customerDropdown = !this.customerDropdown;
  }
  configurationsActivateDrop(){
  
    this.configurationsDropdown = !this.configurationsDropdown;
  }

  logOut(){
    this.authService.logOut()
  }
  ngOnInit(): void {
    this.authService.roleName.subscribe(res => {
      this.role = res.data.role.role_name
    })
  }
}
