import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-webhook-card',
  templateUrl: './webhook-card.component.html',
  styleUrls: ['./webhook-card.component.css']
})
export class WebhookCardComponent implements OnInit {
  data:any =  [];
  page = 1
  first: number = 1;
  rows: number = 10;
  totalRecord = 20;
  isLoading = false;
  searchText:string = '';
  private apiKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService){
    this.apiKey = environment.apiUrl;
  }
  onPageChange(event:any) {
    this.isLoading = true;
    this.first = event.first;
    this.page = event.page + 1
    this.rows = event.rows;
    this.isLoading = true;
    this.fetchRequest()
  }
  openSnackBar(id:any) {
    this.showInfo(id)
    navigator.clipboard.writeText(id);
   }
  showInfo(id:any) {
    this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
  }
  fetchRequest(){
    this.http.get(this.apiKey + '/zap/admin/setting/card-webhooks/?page=' + this.page + '&limit=' + this.rows + '&search_query=' + this.searchText).subscribe({
      next:(res:any) => {
  this.data = res.data.data
  this.totalRecord = res.data.total
  this.isLoading = false;
      },error:(res:any) => {
        this.isLoading = false;
        this.messageService.add({severity:'error',summary:'Rejected', detail:'An error ocurred'})
      }
    })
  }
  search(){
    this.isLoading = true;
    this.fetchRequest();
  }
    ngOnInit(): void {
      this.isLoading = true;
  this.fetchRequest()
    }
}
