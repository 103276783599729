<div *ngIf="!isLoading">
    
<div class="payment-manager">
    <div class="card">
        <img src="../../assets/Widget icons.png" alt="">
        <p>Wallet Balance</p>
        <h4>₦{{overview.wallet_balance | number}}</h4>
    </div>
    <div class="card">
        <img src="../../assets/Widget icons.png" alt="">
        <p>USD Wallet Balance</p>
        <h4>${{overview.usd_wallet_balance | number}}</h4>
    </div>
    <div class="card">
        <img src="../../assets/Widget icons.png" alt="">
        <p>Total Money In</p>
        <h4>₦{{overview.total_money_in | number}}</h4>
    </div>
    <div class="card">
        <img src="../../assets/Widget icons.png" alt="">
        <p>Total Money Out</p>
        <h4>₦{{overview.total_money_out | number}}</h4>
      
    </div>
</div>

<div class="payment-manager-two">
    <div class="card">
        <img src="../../assets/Widget icons.png" alt="">
        <div>
            <p>Total Transaction</p>
        <h4>{{overview.total_transactions | number}}</h4>
        </div>
    </div>
    <div class="card">
        <img src="../../assets/Widget icons.png" alt="">
        <div>
            <p>Total Refunds</p>
        <h4>{{overview.total_refund | number}}</h4>
        </div>
    </div>
    <div class="card">
        <img src="../../assets/Widget icons.png" alt="">
       <div>
        <p>Total Money In</p>
        <h4>{{overview.total_in | number}}</h4>
       </div>
    </div>
    <div class="card">
        <img src="../../assets/Widget icons.png" alt="">
       <div>
        <p>Total Virtual Cards</p>
        <h4>{{overview.total_virtual_cards | number }}</h4>
       </div>
    </div>
</div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
