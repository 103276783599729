import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-ctht-dialog-box',
  templateUrl: './ctht-dialog-box.component.html',
  styleUrls: ['./ctht-dialog-box.component.css']
})
export class CthtDialogBoxComponent {
  isLoading = false;
  @Input()  data:any = {reference:'',status:''}

constructor(private messageService:MessageService,private http:HttpClient){}
openSnackBar(id:any) {
  this.showInfo(id)
  navigator.clipboard.writeText(id);
 }
showInfo(id:any) {
  this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
}
ngOnInit(): void {
console.log(this.data)
}
}
