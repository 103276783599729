<div *ngIf="!isLoading">
  
  <div class="header-two">
    <h5>Transaction Status</h5>
  
    <p *ngIf="data.status == true"><span class="completed">Approved</span></p>
    <p *ngIf="data.status == false"><span class="pending">Pending</span></p>
  </div>
  <table class="table-one" style="width: 500px;">
    <tr>
      <th>Wallet Holder</th>
      <th>Customer Id</th>
      <th>Email</th>
    </tr>
    <tr>
      <td>{{data.name}}</td>
      <td>
        <div style="display: flex; align-items: center;">
        <p>{{data.user_id | slice:0:8}} {{ data.user_id.length > 8 ? '...' : '' }}</p>
        <img (click)="openSnackBar(data.user_id)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
        </div>
      </td>
      <td>  <div style="display: flex; align-items: center;">
        <p>{{data.email}}</p>
        <img  (click)="openSnackBar(data.email)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
        </div></td>
  
    </tr>
    <tr>
      <th>Phone number</th>
      <th>Level</th>
      <th>Transaction ID</th>
    </tr>
    <tr>
      <td>{{data.phone}}</td>
      <td>Tier {{data.level}}</td>
      <td>
          <div style="display: flex; align-items: center;">
          <p>{{data.reference | slice:0:8}} {{ data.reference.length > 8 ? '...' : '' }}</p>
          <img (click)="openSnackBar(data.reference)" src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
          </div>
        </td>
      
    
  
    </tr>
    <tr>
      <th>Order Type</th>
      <th>Service Provider</th>
      <th>Percentage Conversion</th>
    </tr>
    <tr>
      <td><div class="data-group"><img src="../../../../assets/Icons (5).png" style="margin-right: 4px;" width="20" alt="">Airtime Swap</div></td>
      <td><div class="data-group"> 
        <img src="../../../../assets/MTN.png" *ngIf="data.order_type == 'mtn'" width="22" alt="" style="margin-right: 3px;">
        <img src="../../../../assets/GLO.png" *ngIf="data.order_type == 'glo'" width="22" alt="" style="margin-right: 3px;">
        <img src="../../../../assets/AIRTEL.png" *ngIf="data.order_type == 'airtel'" width="22" alt="" style="margin-right: 3px;">
        <img src="../../../../assets/9MOBILE.png" *ngIf="data.order_type == '9mobile'" width="22" alt="" style="margin-right: 3px;">
        {{data.order_type | titlecase}}</div></td>
      <td>{{data.percentage}}%</td>
    </tr>
    <tr>
      <th>Amount</th>
      <th>Received</th>
      <th>Date &Time</th>
    </tr>
    <tr>
      <td>{{data.currency == 'usd' ? '$' : '₦'}} {{data.amount | number}}</td>
      <td>{{data.currency == 'usd' ? '$' : '₦'}} {{data.received | number}}</td>
      <td>{{data.created_at | date}} & {{data.created_at | date:'shortTime' }}</td>
    </tr>
  
  </table>
  
  <div class="button-group" *ngIf="data.status == false">
    <button class="approve" (click)="approve()"> Approve</button>
    <button class="decline" (click)="decline()"> Decline</button>
  </div>
  </div>
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
  