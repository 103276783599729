import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Console } from 'console';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';
interface City {
  number:number;
}
@Component({
  selector: 'app-ab-dialog',
  templateUrl: './ab-dialog.component.html',
  styleUrls: ['./ab-dialog.component.css']
})
export class AbDialogComponent {
  @Input() data:any;
  date:any;
  isLoading = false;
  userId:string = '';
  title:string = '';
    citiesOne: City[] | any;
  text:string = '';
  private apiKey:string = '';
  duration:string = '';
  isLoadingTwo = false;
  selectedCityOne: City | any = '';
constructor(private http:HttpClient,private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
  save(){
    this.isLoading = true;
this.http.post(this.apiKey + '/zap/admin/business/business',{business_name:this.title,user_id:this.userId,status:this.selectedCityOne}).subscribe({
  next:(res:any) => {
    this.messageService.add({severity:'success',summary:'Successful', detail:res.message})
    this.isLoading = false;
  this.userId = '';
    this.title = '';
    this.selectedCityOne = '';
    location.reload()
  }, error:(res:any) => {
    this.messageService.add({severity:'error',summary:'Rejected', detail:res.message})
    this.isLoading = false;
  }
})
  }

 
  ngOnInit(): void {
  this.citiesOne = ['approved','pending'];
  this.userId = this.data.user_id;
  this.selectedCityOne = this.data.status;
  this.title = this.data.business_name
  console.log(this.data)
  }
}
