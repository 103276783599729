import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { AsTransactionsService } from '../airtime-swap-transactions/as-transactions.service';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-airtime-swap-overview',
  templateUrl: './airtime-swap-overview.component.html',
  styleUrls: ['./airtime-swap-overview.component.css']
})
export class AirtimeSwapOverviewComponent implements OnInit{
  overview = {totalAirtimeSwap:'0',transferTransaction:'0'}
isLoading:boolean = false;
modalName = '';
visible: boolean = false;
role:string = '';
private apiKey:string = '';
constructor(private messageService:MessageService,private http:HttpClient, private asTransactionsService:AsTransactionsService, private authService:AuthPageService){
  this.apiKey = environment.apiUrl;
}

handleError(error:any) { let errorMessage = 'No Internet (overview)';

if(error.error.message){
  errorMessage = error.error.message
}

  console.log(errorMessage);
  return throwError(() => {
      return errorMessage;
  });

}
// setTimeout(() => {
//   this.messageService.add({ severity: 'error', detail:res,life:2000 });
//  }, 2000);
reload(){
  location.reload()
}

showDialog(name:string) {
  this.modalName = name;
    this.visible = true;
}
ngOnInit(): void {
  this.authService.roleName.subscribe(res => {
    this.role = res.data.role.role_name
  })
this.asTransactionsService.filter.subscribe(res => {this.visible = false});
if(this.role == 'Administrator'){  this.isLoading = true;}

  if(this.role == 'Administrator'){
    this.http.get(this.apiKey + '/zap/admin/transaction/flips/stats').pipe(catchError(this.handleError)).subscribe({
    next:(res:any) => {
      this.isLoading = false
      this.overview.totalAirtimeSwap= res.data.total_amount;
      this.overview.transferTransaction = res.data.count;
    },
    error:(res:any) => {
      this.isLoading = false;
      this.messageService.add({ severity: 'error', detail:res,life:2000 });
    }
  })
  }
}
}
