import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { cthTransactionService } from './cth-transactions.service';
import { exhaustMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-cth-transactions',
  templateUrl: './cth-transactions.component.html',
  styleUrls: ['./cth-transactions.component.css']
})
export class CthTransactionsComponent implements OnInit {
  isLoading:boolean = false;
  searchText:string = '';
  page:number = 1;
  data:any = []
  modalName = '';
  start_amount = '';
end_amount = '';
start_date = '';
end_date = '';
status:string = '';
id:any = {};
  visible: boolean = false;
  visibleTwo: boolean = false;
  filterSubscription:any;
  private apiKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService,private cthService:cthTransactionService){
    this.apiKey  =environment.apiUrl;
  }
  openSnackBar(id:any) {
    this.showInfo(id)
    navigator.clipboard.writeText(id);
   }
  showInfo(id:any) {
    this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
  }
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }

  showDialogTwo(name:string) {
    this.modalName = name;
      this.visibleTwo = true;
  }
  viewDetail(index:number){
    this.id = this.data[index];
    this.showDialogTwo('Customers')
  }
  first: number = 1;
  rows: number = 10;
  totalRecord = 20;
  
  onPageChange(event:any) {
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + 1;
    this.isLoading = true;
    this.getRequest().subscribe((res:any) => {
      this.data = res.data.transactions;
      this.isLoading = false;
     })
  }
  filter(){
    this.filterSubscription = this.cthService.filter.pipe(tap((res:any) => {
      this.status = res.status == '' ? '' : '&status=' + res.status;
        this.start_date = res.start_date == '' ? '' : '&start_date' + res.start_date.toISOString();;
        this.end_date = res.end_date == '' ? '' : '&end_date' + res.end_date.toISOString();;
        this.start_amount = '&start_amount=' + res.start_amount;
        this.end_amount = '&end_amount=' + res.end_amount;
        console.log(res.start_amount + 'amount')
        this.isLoading = true;
       }),exhaustMap((res:any) => {return this.getRequest()})).subscribe({
        next:(res:any) => {
          this.isLoading = false;
          this.data = res.data.transactions;
          this.totalRecord = res.data.total;
        },
        error:(res) => {
          this.isLoading = false;
        }
      })
    }
  getRequest(){
    let dataInString:any = localStorage.getItem('userId');
    let userId = JSON.parse(dataInString)
    return  this.http.get(this.apiKey + '/zap/admin/users/transaction/' + userId + '/?page=' + this.page + '&' + 'limit' + '=' + this.rows + '&search_query' + '=' + this.searchText +  this.start_amount + this.end_amount + this.start_date + this.end_date + this.status) 
   }

      search(){
      }
      ngOnInit(): void {
        this.isLoading = true;
        this.filter()
      }

     
}

 