

<div class="container">
    <h4>Templates</h4>
<p>Message goes here</p>
</div>
<div class="header-four">
<div class="recent-transaction">
<table>
 <thead>
    <tr>
        <th>Title</th>
        <th>Message</th>
        <th>Type</th>
        <th>Date created</th>
      <th></th>
      <th></th>
    </tr>
 </thead>
<tr>
    <td>System Maintenance</td>
    <td>Lorem ipsum dolor sit amet consectetur. Elementum aliquamec lacus duis nu...</td>
    <td>SMS</td>
    <td>Aug 3, 2023</td>
    <td mat-icon-button [matMenuTriggerFor]="menu"><img src="../../../assets/edit.png" width="25" alt="">
    <td mat-icon-button [matMenuTriggerFor]="menu"><img src="../../../assets/Stockholm-icons (23).png" width="25" alt="">
        <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <span style="font-family: poppins; font-size: 13px;">Delete</span>
            </button>
            <button mat-menu-item>
                <span style="font-family: poppins; font-size: 13px;">Message</span>
              </button>
          </mat-menu>
    </td>
</tr>
<tr>
    <td>System Maintenance</td>
    <td>Lorem ipsum dolor sit amet consectetur. Elementum aliquamec lacus duis nu...</td>
    <td>SMS</td>
    <td>Aug 3, 2023</td>
    <td mat-icon-button [matMenuTriggerFor]="menu"><img src="../../../assets/edit.png" width="25" alt="">
    <td mat-icon-button [matMenuTriggerFor]="menu"><img src="../../../assets/Stockholm-icons (23).png" width="25" alt="">
        <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <span style="font-family: poppins; font-size: 13px;">Delete</span>
            </button>
            <button mat-menu-item>
                <span style="font-family: poppins; font-size: 13px;">Message</span>
              </button>
          </mat-menu>
    </td>
</tr>
<tr>
    <td>System Maintenance</td>
    <td>Lorem ipsum dolor sit amet consectetur. Elementum aliquamec lacus duis nu...</td>
    <td>SMS</td>
    <td>Aug 3, 2023</td>
    <td mat-icon-button [matMenuTriggerFor]="menu"><img src="../../../assets/edit.png" width="25" alt="">
    <td mat-icon-button [matMenuTriggerFor]="menu"><img src="../../../assets/Stockholm-icons (23).png" width="25" alt="">
        <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <span style="font-family: poppins; font-size: 13px;">Delete</span>
            </button>
            <button mat-menu-item>
                <span style="font-family: poppins; font-size: 13px;">Message</span>
              </button>
          </mat-menu>
    </td>
</tr>
   <tbody>
    </tbody>
</table>