import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';
interface City {
  name: string;
}
@Component({
  selector: 'app-payout-dialog-two',
  templateUrl: './payout-dialog-two.component.html',
  styleUrls: ['./payout-dialog-two.component.css']
})
export class PayoutDialogTwoComponent {
  isLoading = false;
accountNumber:string = '';
adminPin:string = '';
amount:number = 0;
narration:string = '';
accountName:string = '';
bank_code:string = '';
sessionId:string = '';
content:string = ''
private apiKey:string = ''
constructor(private http:HttpClient,private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
  cancel(){

  }

  sendMessage(){
    this.isLoading = true;
this.http.post(this.apiKey + '/zap/admin/setting/transfer/transfer',{account_number:this.accountNumber,admin_pin:this.adminPin,amount:this.amount,naration:this.narration,account_name:this.accountName,bank_code:this.bank_code,session_id:this.sessionId}).subscribe({
  next:(res:any) => {
    this.messageService.add({ severity: 'info', summary:'Successful', detail:res.message});
    this.clearMessage();
    this.isLoading = false;
  },
  error:(res:any) => {
    this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message });
    this.isLoading = false;
   
  }
})
  }

  clearMessage(){
this.content = '';
this.accountNumber = '';
  }
  
  ngOnInit() {
  }
}
