<div *ngIf="role == 'Administrator' || role == 'Customer Support'" >
    <app-header></app-header>

<div class="main-content">
<app-overview></app-overview>
<main *ngIf = 'role == "Administrator"'>
<div class="visual-representation" >
<div class="header-three">
    <h4>Visual Representation</h4>
    <nav>
        <div class="card">
            <p [class.active] = 'toggleActive' (click) = 'toggle("product")'>Product</p>
            <p [class.active] = '!toggleActive' (click) = 'toggle("period")'>Period</p>
        </div>

        <div class="card" mat-icon-button [matMenuTriggerFor]="menu">
            <p style="margin: 10px;">{{products[Index]}}</p>
            <img src="../../assets/Stockholm-icons (9).png" alt="">
            <mat-menu #menu="matMenu">
                <button mat-menu-item *ngFor="let product of products;let i = index" (click) = 'getProductIndex(i)' (click) = 'itemClicked(product)'>
                  <span style="font-family: poppins; font-size: 14px;" >{{product}}</span>
                </button>
              </mat-menu>
              

        </div>
    
    </nav>
</div>
</div>
<h4>Quick Action</h4>
</main>

<section *ngIf = 'role == "Administrator"'>
    <div style="padding: 10px;width: 65%;border: 1px solid #E7E7E8;margin:10px;border-radius: 7px;" >
        <app-period *ngIf="toggleName == 'period'"></app-period>
        <app-product-chart *ngIf="toggleName == 'product'"></app-product-chart>
    </div>
    <div class="quick-action">
        <br>
        <app-quick-action></app-quick-action>
    </div>
</section>

<app-recent-transaction></app-recent-transaction>
</div>
<div>
   
</div>



</div>