import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-vc-profile',
  templateUrl: './vc-profile.component.html',
  styleUrls: ['./vc-profile.component.css']
})
export class VcProfileComponent implements OnInit{
 
  isLoading:boolean = false
  modalName = ''
  visible:boolean = false;
  visibleTwo:boolean = false;
  checked:boolean = false;
  data:any = {}
  virtualCard:any = []
  accountLevel:number = 0;
  private apiKey:string = '';
  private adminKey:string = '';
  constructor(private confirmationService:ConfirmationService,private messageService:MessageService,private http:HttpClient){
    this.apiKey = environment.apiUrl;
    this.adminKey = environment.admin_key
  }
  confirm1(event: Event) {
    this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Are you sure you want to freeze this user?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.freezeAccount()
        },
        reject: () => {
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
    });
  }
  confirm2(event: Event) {
    this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Are you sure you want to unfreeze this user?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
      this.unFreezeAccount()
        },
        reject: () => {
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
    });
  }

  confirm3(event: Event) {
    this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'this user account balance will be updated',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
      this.updateAccount()
        },
        reject: () => {
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
    });
  }
  isActive(){
    this.isLoading = true;
    this.http.post(this.apiKey + '/zap/admin/funding/toggle-card',
    {card_id:this.data.card_id,is_active:this.checked,admin_key:this.adminKey}).subscribe({
      next:(res:any) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'info', summary: 'Successful', detail:res.message, life: 3000 });
        this.fetchRequest()
      },error:(res:any) => {
        this.isLoading = false;
        let errorMessage = 'An error occurred'; // Default error message
        if (res.error && res.error.message) {
            errorMessage = res.error.message; // Use the error message from the response body
        }
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: errorMessage }); 
      }
    })
  }

  freezeAccount(){
    this.isLoading = true;
    this.http.post(this.apiKey + '/zap/admin/funding/toggle-freeze',
    {card_id:this.data.card_id,status:'disabled',admin_key:this.adminKey}).subscribe({
      next:(res:any) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'info', summary:this.data.name, detail:res.message, life: 3000 });
        this.fetchRequest()
      },error:(res:any) => {
        this.isLoading = false;
        let errorMessage = 'An error occurred'; // Default error message
        if (res.error && res.error.message) {
            errorMessage = res.error.message; // Use the error message from the response body
        }
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: errorMessage });
      }
    })
  }

  unFreezeAccount(){
    this.isLoading = true;
    this.http.post(this.apiKey + '/zap/admin/funding/toggle-freeze',
    {card_id:this.data.card_id,status:'active',admin_key:this.adminKey}).subscribe({
      next:(res:any) => {
        this.isLoading = false;
        this.fetchRequest()
        this.messageService.add({ severity: 'info', summary:this.data.name, detail:res.message, life: 3000 });
      },error:(res:any) => {
        this.isLoading = false;
        let errorMessage = 'An error occurred'; // Default error message
    if (res.error && res.error.message) {
        errorMessage = res.error.message; // Use the error message from the response body
    }
    this.messageService.add({ severity: 'error', summary: 'Error!', detail: errorMessage }); 
      }
    })
  }

updateAccount(){
  this.http.post(this.apiKey + '/zap/admin/funding/update-details',{admin_key:this.adminKey,card_id:this.data.card_id}).subscribe({
    next:(res:any) => {
      this.isLoading = false;
      this.fetchRequest()
      this.messageService.add({ severity: 'info', summary:this.data.name, detail:res.message, life: 3000 });
    },error:(res:any) => {
      this.isLoading = false;
      let errorMessage = 'An error occurred'; // Default error message
      if (res.error && res.error.message) {
          errorMessage = res.error.message; // Use the error message from the response body
      }
      this.messageService.add({ severity: 'error', summary: 'Error!', detail: errorMessage });
    }
  })
}

fetchRequest(){
  let dataInString:any = localStorage.getItem('userId');
  let data = JSON.parse(dataInString);
  this.http.get(this.apiKey + '/zap/admin/funding/cards?page=1&limit=1&search_query=' + data).subscribe({
next:(res:any) => {
  this.data = res.data[0]
  localStorage.setItem('userId',JSON.stringify(res.data[0].card_id))
  console.log(res.data[0])
  this.checked = res.data[0].is_active;
  this.isLoading = false;
},error:(res:any) => 
this.isLoading = false
})
}
  ngOnInit(): void {
    this.isLoading = true;
this.fetchRequest()
   
  }
}
