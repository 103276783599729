import { Component, OnInit } from '@angular/core';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';

@Component({
  selector: 'app-bank-transfer',
  templateUrl: './bank-transfer.component.html',
  styleUrls: ['./bank-transfer.component.css']
})
export class BankTransferComponent implements OnInit{
role:string = '';
constructor(private authService:AuthPageService){}
ngOnInit(): void {
  this.authService.roleName.subscribe((res:any) => {
    this.role = res.data.role.role_name
  })
}

}
