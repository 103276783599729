<div class="header-two">
  <img src="../../../assets/search.png" alt="">
  <input type="text" placeholder="Search by....." (keyup.enter)="search()" [(ngModel)] = 'searchText'>
  <div class="filter-group" *ngIf="searchText !== ''" (click)="search()">
    <p>search</p>
  </div>
</div>
<div *ngIf="data.length !== 0">
<br>
<div *ngIf="!isLoading">
<div class="header-four">
<div class="recent-transaction">
<table>
 <thead>
  <tr>
    <th>Order Type</th>
    <th>Amount</th>
<th>Transaction ID</th>
    <th></th>
    <th>Customer ID</th>
    <th>Date</th>
    <th>Time</th>
    <th>Status</th>

</tr>
 </thead>

   <tbody>
    <tr *ngFor = 'let transactions of data; let i = index'>
      <td>
        <div class="order-type" (click) = 'viewDetail(i)' >
          <img *ngIf="transactions.method !== 'atc'" [src]="'../../../../assets/' + transactions.method + '.png'" width="22" alt="">
            <p>{{transactions.method | titlecase}}</p>
          </div>
      </td>
      <td (click) = 'viewDetail(i)'><strong>{{transactions.order_type == 'usd' ? '$' : '₦'}}</strong>{{transactions.amount | number}}</td>
      <td (click) = 'viewDetail(i)'>{{transactions.transaction_id}}</td>
            <td (click)="openSnackBar(transactions.transaction_id)"><img src="../../../../assets/Stockholm-icons (16).png" width="20" alt=""></td>
            <td (click) = 'viewDetail(i)'>{{ transactions.user_id | slice:0:10 }} {{ transactions.user_id.length > 10 ? '...' : '' }}</td>
            <td (click) = 'viewDetail(i)'>{{transactions.date | date}}</td>
            <td>{{transactions.date | date:'shortTime' }}</td>
            <td (click) = 'viewDetail(i)' *ngIf="transactions.status.toLowerCase() == 'success' || transactions.status.toLowerCase() == 'successful' || transactions.status.toLowerCase() == 'completed'"><span class="success">Successful</span></td>
            <td (click) = 'viewDetail(i)' *ngIf="transactions.status.toLowerCase() == 'reversed' "><span class="failed">Reversed</span></td>
        </tr>
   </tbody>
</table>
<br>

<div style="display: flex; justify-content: space-between;align-items: center;">
  <p style="color: #151A20;font-weight: 500;font-size: 13px;">Showing 1-10 of {{this.page}} results</p>
  <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
</div>
</div>
</div>
</div>
</div>
<div style="display: flex;  justify-content: center;padding:80px;position:relative; right:100px;top: 0px;" *ngIf="data.length == 0 && !isLoading">
  
  <div>
   <img src="../../../assets/Empty state illustratio.png" style="margin: 0 auto;display: block;padding-bottom:10px;" width="80" alt="">
   <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">No records to view yet.</h5>
   <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Transaction records will appear here once customers make purchases.</p>
  </div>
 </div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>
  <p-dialog [header]="modalName"  [(visible)]="visible" [modal]="true" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false">
    <app-bills-dialog-box *ngIf="visible"  style="font-family: poppins;"></app-bills-dialog-box>
  </p-dialog>