<div *ngIf="!isLoading">
    <div class="container">
        <h4>Manage your customer level</h4>
        <br>
        <div style="display: flex;justify-content: space-between;align-items: center;">
                <p>Tier 1</p>
    
            <p-inputSwitch [(ngModel)]="checked" (click) = 'onChecked()' ></p-inputSwitch>
        </div>
        <br>
        <div style="display: flex;justify-content: space-between;align-items: center;">
                <p>Tier 2</p>
            
            <p-inputSwitch [(ngModel)]="checkedTwo" (click) = 'onCheckedTwo()'></p-inputSwitch>
        </div>
        <br>
        <div style="display: flex;justify-content: space-between;align-items: center;">
                <p>Tier 3</p>
      
            <p-inputSwitch [(ngModel)]="checkedThree" (click) = 'onCheckedThree()'></p-inputSwitch>
        </div>
        <br>
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <p>Tier 4</p>
  
        <p-inputSwitch [(ngModel)]="checkedFour" (click) = 'onCheckedFour()'></p-inputSwitch>
    </div>
    </div>
    <div  class="header-eight">
        <button class="apply" (click)="upgrade()">Upgrade</button>
      </div>
</div>
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 5px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>