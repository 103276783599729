
<div class="header-one">
    <img src="../../../assets/Stockholm-icons (19).png" routerLink = '../'  alt="">
    <p>Message</p>
</div>

<div class="header-two">
  
    <div class="container-one">
        <div style="display: flex;align-items: center;">
            <img src="../../../assets/Stockholm-icons (20).png" width="20" alt="">
            <div>
                <h4 style="margin: 2px;font-size: 13px;padding: 10px;padding-left: 0px;">{{data.title | titlecase}} </h4>
            </div>
        </div>
        <div>
     <p style="padding: 10px;font-size: 12px;color: #909090;">{{data.date | date}}</p>
        </div>
    </div>
    <div class="container-two">
 <div [innerHTML]="data.content"></div>
    </div>
</div>