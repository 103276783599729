
<div  class="header-eight">
    <button class="apply" (click)="resolve()">Save</button>
  </div>
<div *ngIf="!isLoading">
    <div class="container">
        <h1 style="padding-bottom: 0px;margin: 0px; width:500px;"></h1>
        <div class="header-two">
    <p>Account Number</p>
    <input type="text" placeholder="Account Number" [(ngModel)]="accountNumber">
    <p>Bank List</p>
    <input type="text" placeholder="Bank Name" [(ngModel)]="bankName">
        </div>
    </div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
  