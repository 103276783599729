import { Component } from '@angular/core';
interface City {
  name: string;
}
@Component({
  selector: 'app-cvc-modal',
  templateUrl: './cvc-modal.component.html',
  styleUrls: ['./cvc-modal.component.css']
})
export class CvcModalComponent {
  cities: City[] | undefined;
  citiesTwo: City[] | undefined;
  selectedCity: City | any;
  selectedCityTwo: City | any;
  selectedItem: number | null = 0;
  brand = 'visa';
  imgFile:any = '';
  name:string = '';

  onUpload(img:any){
    console.log(img)
      }
      isActive(index: number): boolean {
        return this.selectedItem === index;
      }
      toggleItem(index:number){
      this.selectedItem = this.selectedItem === index ? null : index;
      if(index == 0){
       this.brand = 'visa'
            }
      if(index == 1){
    
    this.brand = 'verve'
      }
      if(index == 2){
    
        this.brand = 'mastercard'
          }
    }
    
    inputFile(event:any){
      let fileEvent = event.target.files[0];
      let img = fileEvent;
      console.log(fileEvent)
      let fileReader = new FileReader();
      fileReader.readAsDataURL(fileEvent);
      fileReader.onload = () => {
         if(fileEvent.type == 'image/jpeg' || fileEvent.type == 'image/png' || fileEvent.type == 'image/gif'){
      if(fileEvent.size <= 10485760){
        this.imgFile = fileReader.result;
        console.log(img)
        console.log(this.imgFile)
      }
        }
      }
    }  
}
