
<div *ngIf="!isLoading">
    <div class="payment-manager">
        <div class="card">
            <img src="../../assets/Widget icons (5).png" alt="">
            <p>Total Referrals</p>
            <h4>{{overview.total_referrals | number}}</h4>
        </div>
        <div class="card">
            <img src="../../assets/Widget icons.png" alt="">
            <p>Pending Referrals</p>
            <h4>{{overview.pending_referrals | number}}</h4>
        </div>
        <div class="card">
            <img src="../../assets/Widget icons.png" alt="">
            <p>Total Amount</p>
            <h4>₦ {{overview.total_amount | number}}</h4>
        </div>
</div>
<div class="referral-code">
    <p>Referral Code</p>
    <strong>{{referralCode}}</strong>
    <img src="../../../../../../assets/clipboard.png" (click)="copy(referralCode)" style="cursor: pointer;" width="24" alt="">
</div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    <p-toast></p-toast>