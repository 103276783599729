<div *ngIf="role == 'Administrator' || role == 'Customer Support'">
<app-saq-header></app-saq-header>
<div class="header-two">
    <img src="../../../assets/search.png" alt="">
    <input type="text" placeholder="Search by....." (keyup.enter)="search()"  [(ngModel)]="searchText">
  <div style="display: flex;align-items: center;">
    <div class="filter-group" (click)="search()">
        <p>search</p>
    </div>
    <img src="../../../../../../assets/Stockholm-icons (15).png" (click)="reload()" alt="">
  </div>
    
</div>
<main>
    <app-saq-messages style="width: 30%;" [searchText]="searchText"></app-saq-messages>
    <app-saq-message></app-saq-message>
</main>
<p-toast></p-toast>
</div>