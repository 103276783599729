import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-c-gift-card',
  templateUrl: './c-gift-card.component.html',
  styleUrls: ['./c-gift-card.component.css']
})
export class CGiftCardComponent implements OnInit{
  checked: boolean = false;
isLoading = false;
fees:number = 0;
private apiKey:string = '';
constructor(private http:HttpClient, private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
activateChecked(){
  this.http.post(this.apiKey + '/zap/admin/configuration/toggle',{service:'gift_card',status:this.checked}).subscribe({
    next:(res:any) => {
      this.messageService.add({ severity: 'info', summary:'Gift Card', detail:res.message});
    },
    error:(res:any) => {
      this.messageService.add({ severity: 'error', summary:'Rejected', detail: res.message});
    }
  })
}
updateFee(){
  this.isLoading = true
this.http.post(this.apiKey + '/zap/admin/configuration/fees',{service:'gift_card',fee:this.fees}).subscribe(
 {
   next:(res:any) => {
     this.messageService.add({ severity: 'info', summary:'Gift Card', detail:res.message});
     this.fetchFees();
   },
   error:(res:any) => {
     this.messageService.add({ severity: 'error', summary:'Rejected', detail: res.message});
     this.isLoading = false;
   }
 })
}
fetchStatus(){
  this.http.get(this.apiKey + '/zap/admin/configuration/appsettings').subscribe({
    next:(res:any) => {
      this.isLoading = false;
      console.log(res)
let status = res.data;
this.checked = status.bank_transfer;
    }
  })
}
fetchFees(){
  this.http.get(this.apiKey + '/zap/admin/configuration/fees').subscribe({
    next:(res:any) => {
      console.log(res)
      this.isLoading = false;
this.fees = res.data.gift_card;
    },error:(res:any) => {
      this.isLoading = false;
      this.messageService.add({severity:'error', summary:'Rejected', detail: res.message})
    }
  })
}
  ngOnInit(): void {
this.isLoading = true;
this.fetchStatus();
this.fetchFees()
  }
}
