import { Component } from '@angular/core';

@Component({
  selector: 'app-email-mangement',
  templateUrl: './email-mangement.component.html',
  styleUrls: ['./email-mangement.component.css']
})
export class EmailMangementComponent {

}
