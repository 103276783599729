import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.css']
})
export class UpgradeComponent implements OnInit {
  @Input() tier:number = 0;
  isLoading = false;
  checked:boolean = false;
  checkedTwo:boolean = false;
  checkedThree:boolean = false;
  checkedFour:boolean = false;
  private apiKey:string = ''
  constructor(private http:HttpClient, private messageService:MessageService){
    this.apiKey = environment.apiUrl;
  }
  onChecked(){
    this.checked = true
    this.checkedTwo = false;
    this.checkedThree = false;
    this.checkedFour = false;
  }

  onCheckedTwo(){
    this.checked = false
    this.checkedTwo = true;
    this.checkedThree = false;
    this.checkedFour = false;
  }

  onCheckedThree(){
    this.checked = false;
    this.checkedTwo = false;
    this.checkedThree = true;
    this.checkedFour = false;
  }

  onCheckedFour(){
    this.checkedFour = true;
    this.checked = false;
    this.checkedTwo = false;
    this.checkedThree = false;
  }

  upgrade(){
    this.isLoading = true;
    let dataInString:any = localStorage.getItem('userId');
    let userId = JSON.parse(dataInString)
    let levelNumber = 0;
    if(this.checked){
levelNumber = 1;
    }
    if(this.checkedTwo){
levelNumber = 2
    }
    if(this.checkedThree){
levelNumber = 3;
    }
    if(this.checkedFour){
      levelNumber = 4;
          }
this.http.post(this.apiKey + '/zap/admin/users/level',{user_id:userId,level:levelNumber}).subscribe({
  next:(res:any) => {
    this.messageService.add({ severity: 'info', summary:'Upgraded', detail:res.message });
    this.isLoading = false
  },
  error:(res:any) => {
    this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message });
    this.isLoading = false;
  }
})
  }
  ngOnInit(): void {
    if(this.tier == 1){
this.checked = true
    }
    if(this.tier == 2){
      this.checkedTwo = true
          }
          if(this.tier == 3){
            this.checkedThree = true
                }

                if(this.tier == 4){
                  this.checkedFour = true
                      }
  }
}
