import { Component, OnInit } from '@angular/core';
import { AuthPageService } from '../auth-page/auth-page.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {
role:string = '';
constructor(private authService:AuthPageService){}
ngOnInit(): void {
  this.authService.roleName.subscribe(res => {
    this.role = res.data.role.role_name
  })
}
}
