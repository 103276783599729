import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { kycCustomerService } from '../kyc-customers/kyc-customers.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-kyc-overview',
  templateUrl: './kyc-overview.component.html',
  styleUrls: ['./kyc-overview.component.css']
})
export class KycOverviewComponent {
  isLoading = false;
  modalName = '';
  visible: boolean = false;
  overview:any = {all:0,approved:0,declined:0}
  private apiKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService,private kycCustomerService:kycCustomerService) {
    this.apiKey = environment.apiUrl
  }
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }

  reload(){
    location.reload()
  }
  ngOnInit(): void {
    this.isLoading = true;
this.kycCustomerService.filter.subscribe(res => this.visible = false)
    this.http.get(this.apiKey + '/zap/admin/kyc/all?page=1&limit=10').pipe(catchError(this.handleError)).subscribe({
      next:(res:any) => {
        this.isLoading = false
        this.overview = res.data.stats;
      },
      error:(res:any) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'error', detail:res,life:2000 });
      }
    })
  }
  handleError(error:any) { let errorMessage = 'No internet (overview)';

  if(error.error.message){
    errorMessage = error.error.message
  }

    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
