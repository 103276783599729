import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { environment } from "src/environments/environment.development";
@Injectable({providedIn:'root'})
export class GiftCardDialogService {
    id:number = 0;
    private apiKey:string = '';
    constructor(private http:HttpClient){
        this.apiKey = environment.apiUrl;
    }
    getUser(){
    return this.http.get(this.apiKey + "/zap/admin/transaction/giftcard/particular/" + this.id)
    }
}