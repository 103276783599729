import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'supportQueries'
})
export class SupportQueriesPipe implements PipeTransform {


  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();


    return items.filter(item => {
      // Assuming each item has a 'name' property
      return item.message.toLowerCase().includes(searchText) || item.name.toLowerCase().includes(searchText) || item.title.toLowerCase().includes(searchText)
    });
  }

}
