import { Component } from '@angular/core';

@Component({
  selector: 'app-c-zap-pin',
  templateUrl: './c-zap-pin.component.html',
  styleUrls: ['./c-zap-pin.component.css']
})
export class CZapPinComponent {
  checked: boolean = false;
}
