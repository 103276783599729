import { Component } from '@angular/core';
import { SaqMessagesService } from './saq-messages/saq-messages.service';
import { AuthPageService } from '../auth-page/auth-page.service';

@Component({
  selector: 'app-support-and-queries',
  templateUrl: './support-and-queries.component.html',
  styleUrls: ['./support-and-queries.component.css']
})
export class SupportAndQueriesComponent {
searchText = ''
role:string = '';
constructor(private saqMessagesService:SaqMessagesService,private authService:AuthPageService){}
reload(){
  location.reload()
}
search(){
  this.saqMessagesService.search.next('')
}
  ngOnInit(): void {
    this.authService.roleName.subscribe((res:any) => {
      this.role = res.data.role.role_name
    })
  }
}
