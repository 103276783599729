<div *ngIf="!isLoading">
    <h4 style="text-align: center;">Lock History</h4>
    <div class="header-two">
      <img src="../../../assets/search.png" alt="">
      <input type="text" placeholder="Search by....." (keyup.enter)="search()"  [(ngModel)]="searchText">
  </div>
  <br>
  <div *ngIf="data.length !== 0">
    
  <div class="header-four">
    <div class="recent-transaction">
    <table>
     <thead>
        <tr>
            <th>Amount</th>
            <th>Method</th>
            <th>Type</th>
            <th>Title</th>
            <th>Balance Before</th>
            <th>Balance After</th>
            <th>Creation Date</th>
        </tr>
     </thead>
    
       <tbody>
        <tr *ngFor="let transaction of data">
             <td>₦{{transaction.amount | number}}</td>
             <td>{{transaction.method | titlecase}}</td>
             <td>{{transaction.type | titlecase}}</td>
             <td>{{transaction.title | titlecase}}</td>
             <td>₦{{transaction.balance_before | number}}</td>
             <td>₦{{transaction.balance_after | number}}</td>
             <td><p>{{transaction.created_at | date}}</p></td>
      </tr>
       </tbody>
    </table>
    <br>
    <div style="display: flex; justify-content: space-between;align-items: center;">
      <p style="color: #151A20;font-weight: 500;font-size: 13px;">Showing 1-10 of {{this.page}} results</p>
      <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"></p-paginator>
    </div>
    </div>
    </div>
    </div>
  </div>
  <div style="display: flex;  justify-content: center;padding:80px;position:relative; right:100px;top: 0px;" *ngIf="data.length == 0 && !isLoading">
    
    <div>
     <img src="../../../assets/Empty state illustratio.png" style="margin: 0 auto;display: block;padding-bottom:10px;" width="80" alt="">
     <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">No records to view yet.</h5>
     <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Transaction records will appear here once customers make purchases.</p>
    </div>
   </div>
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 40px;top: 20px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    <p-toast></p-toast>
    