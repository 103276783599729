<div class="container" *ngIf="!isLoading">
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <h4>Social media links</h4>
        <button (click) = 'save()' class="btn-message" *ngIf="businessName !== data.business_name ||  phoneNumber !== data.phone_number || promoUrl !== data.promo_url || maintenanceMode !== data.maintenance_mode || promo_image !== data.promo_banner || message !== data.maintenance_message|| version !==data.app_version || address !== data.business_address || welcomeMessage !== data.welcome_message">Save</button>
       </div>
    <br>
     <img [src]="data.promo_banner" style="max-width: 50%;">
    <div class="inputWithIcon"> 
        <p>Promo Banner</p>
        <input type="text" [(ngModel)] = 'promo_image'>
      </div>
    <div class="inputWithIcon">
        <p>Phone number</p>
        <input type="text" [(ngModel)] = 'phoneNumber'>
      </div> 
      <div class="inputWithIcon">
        <p>Business Name</p>
        <input type="text" [(ngModel)] = 'businessName'>
      </div>
      <div class="inputWithIcon">
        <p>Promo Url</p>
        <input type="text" [(ngModel)] = 'promoUrl'>
      </div>
      <div class="inputWithIcon">
        <p>Address</p>
        <input type="text" [(ngModel)] = 'address'>
      </div>
      <div class="inputWithIcon">
        <p>Welcome Message</p>
        <input type="text" [(ngModel)] = 'welcomeMessage'>
      </div>

      <h4>Maintenance message</h4>
    <div>
        <div class="inputWithIcon">
            <p style="padding: 4px;">Maintenance Message</p>
            <textarea  id="" cols="50" rows="9" [(ngModel)] = 'message'></textarea>
          </div>
    </div>
    <div>
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <div>
            <h4>Maintenance Mode Status</h4>
        </div>
        <p-inputSwitch [(ngModel)]="maintenanceMode"></p-inputSwitch>
    </div>
    <div>
        <h4 style="color:#546881;">App version</h4>
        <div class="inputWithIcon">
            <input type="text" [(ngModel)] = 'version'>
          </div>
    </div>
</div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;top: 100px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>