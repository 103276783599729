<div class="container">
   <div class="header-four">
    <div class="card">
        <h5>Start</h5>
        <div  style="zoom: 80%;"><p-calendar [(ngModel)]="date" [touchUI]="false" [readonlyInput]="true" [placeholder]="'dd/mm/yy'"></p-calendar> </div>   

    </div>
    <div class="card">
        <h5>End</h5>

       <div  style="zoom: 80%;"> <p-calendar [(ngModel)]="dateTwo" [touchUI]="false" [readonlyInput]="true" [placeholder]="'dd/mm/yy'"></p-calendar></div>
    </div>
    
      </div>
      <h5 style="font-weight: 600;font-size: 14px;margin-bottom: 0px;">Amount</h5>
      <div class="header-four">
        <div>
          <h5>From</h5>
          <div  style="zoom: 80%;"> <p-inputNumber [(ngModel)]="amountOne" [style]="{'font-size':'5px'}" inputId="currency" mode="currency" [currency]="selectedCityOne.name == 'card-transaction' ? 'USD' :'NGN'" locale="en-US" [placeholder]="'NGN'"> </p-inputNumber></div>
        </div>
        <div>
          <h5>To</h5>
        
            <div  style="zoom: 80%;"><p-inputNumber  [(ngModel)]="amountTwo" inputId="currency" mode="currency" [currency]="selectedCityOne.name == 'card-transaction' ? 'USD' :'NGN'" locale="en-US" [placeholder]="'NGN'"> </p-inputNumber></div>
           </div>
        
          </div>
      <div class="header-six">
<h5 style="font-weight: 600;font-size: 14px;">Sort By</h5>
<div>
  <div  style="zoom: 80%;"><p-dropdown [options]="citiesOne" [style]="{width:'510px'}" [(ngModel)]="selectedCityOne" optionLabel="name" [showClear]="false" placeholder="Select Type"></p-dropdown></div>

      </div>
      </div>
      
<div class="header-seven">
<p style="font-weight: 600;font-size: 14px;">Status</p>
<div style="display: flex;gap: 5px;">
  <p><span style="font-size: 11px;" [class.active]="isActive(0)" (click)="toggleItem(0)">All</span></p>
  <p><span [class.active]="isActive(1)" (click)="toggleItem(1)" style="font-size: 11px;" >Success</span></p>
  <p><span style="font-size: 11px;" [class.active]="isActive(2)" (click)="toggleItem(2)">Reversed</span></p>
</div>
</div>

<div  class="header-eight">
 
  <button class="filter" (click)="clear()">Clear Filter</button>
  <button class="apply" (click)="send()" [disabled]="selectedCityOne == ''">Apply</button>
</div>
    
   
      
</div>




