import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';
interface City {
  number:number;
}
@Component({
  selector: 'app-business-plan-dialog',
  templateUrl: './business-plan-dialog.component.html',
  styleUrls: ['./business-plan-dialog.component.css']
})
export class BusinessPlanDialogComponent implements OnInit{
  date:any;
  isLoading = false;
  cost:number = 0;
  title:string = '';
    citiesOne: City[] | any;
  text:string = '';
  private apiKey:string = '';
  duration:string = '';
  isLoadingTwo = false;
  selectedCityOne: City | any = '';
  @Input() data:any = {};
constructor(private http:HttpClient,private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
  save(){
    this.isLoading = true;
this.http.post(this.apiKey + '/zap/admin/business/plans/' + this.data.plan_id,{plan_name:this.title,cost:this.cost,duration:this.selectedCityOne}).subscribe({
  next:(res:any) => {
    this.messageService.add({severity:'success',summary:'Successful', detail:res.message})
    this.isLoading = false;
    this.cost = 0;
    this.title = '';
    location.reload()
  }, error:(res:any) => {
    this.messageService.add({severity:'error',summary:'Rejected', detail:res.message})
    this.isLoading = false;
  }
})
  }
  
  delete(){
    this.isLoading = true;
    this.http.post(this.apiKey + '/zap/admin/business/plans/delete/' + this.data.plan_id,{plan_id:this.data.plan_id}).subscribe({
      next:(res:any) => {
        this.messageService.add({severity:'success',summary:'Successful', detail:res.message})
        location.reload()
      }, error:(res:any) => {
        this.messageService.add({severity:'error',summary:'Rejected', detail:res.message})
        this.isLoading = false;
      }
    })
  }

 
  ngOnInit(): void {
  this.citiesOne = [1,2,3,4,5,6,7,8,9,10,11,12];

    this.title = this.data.plan_name;
    this.cost = this.data.cost
    this.selectedCityOne = this.data.duration
    console.log(this.data)
  }
}
