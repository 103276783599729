import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CustomerProfileService } from '../customer-details/customer-profile/customer-profile.service';
import { Router } from '@angular/router';
import { customerTransactionsService } from './customers-transactions.service';
import { catchError, exhaustMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-customers-transactions',
  templateUrl: './customers-transactions.component.html',
  styleUrls: ['./customers-transactions.component.css']
})
export class CustomersTransactionsComponent implements OnInit,OnDestroy{
  data:any = [];
  searchText:string = ''
  isLoading = false;
  page:number = 1
  select = false;
  start_date = '';
  end_date = '';
  start_amount = '';
  end_amount = '';
  status = '';
  checked = false;
  selectedAllArray:any = []
  selectedArray:any = []
  first: number = 1;
rows: number = 10;
totalRecord = 20;
clearCheckedBox = false;
private filterSubscription:any;
private apiKey:string = '';
  constructor(private http:HttpClient,private messageService:MessageService, private dialogService:CustomerProfileService,private route:Router, private customerService:customerTransactionsService){
    this.apiKey = environment.apiUrl;
  }
viewDetail(id:any){
  this.route.navigate(['/user-management/customers/customer-details/profile'])
  localStorage.setItem('userId',JSON.stringify(this.data[id].user_id))
}
allChecked(){
  this.checked = !this.checked;
  this.selectedArray = [];
 for (let index = 0; index < this.data.length; index++) {
  if(!this.selectedAllArray.includes(this.data[index].user_id)){
    this.selectedAllArray.push(this.data[index].user_id)
  }
    
  }
}

selected(i:number){

  if (!this.selectedArray.includes(this.data[i].user_id)) {
  this.selectedArray.push(this.data[i].user_id);
  }
  console.log(this.selectedArray)
}

openSnackBar(id:any) {
  this.showInfo(id)
  navigator.clipboard.writeText(id);
 }
showInfo(id:any) {
  this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
}
onPageChange(event:any) {
  this.first = event.first;
  this.rows = event.rows;
  this.page = event.page + 1;
  this.isLoading = true;
  this.getRequest().subscribe((res:any) => {
    this.data = res.data.users;
    this.isLoading = false;
   })
 
}
    filter(){
     this.filterSubscription =  this.customerService.filter.pipe(tap((res:any) => {
        this.status = res.status == '' ? '' : '&sort_by=' + res.status;
        this.start_date = res.start_date == '' ? '' : '&start_date' + res.start_date.toISOString();;
        this.end_date = res.end_date == '' ? '' : '&end_date' + res.end_date.toISOString();;
        this.start_amount = '&start_amount=' + res.start_amount;
        this.end_amount = '&end_amount=' + res.end_amount;
        console.log(res.start_amount + 'amount')
        this.isLoading = true;
       }),exhaustMap((res:any) => {return this.getRequest()})).pipe(catchError(this.handleError)).subscribe({
        next:(res:any) => {
          this.isLoading = false;
          this.data = res.data.users;
          this.totalRecord = res.data.total;
        },
        error:(res) => {
          this.isLoading = false;
          setTimeout(() => {
            this.messageService.add({ severity: 'error', detail:res,life:2000 });
           }, 2000);
        }
      })
    }

    activate(){
      if(this.selectedArray.length !==0 ) {
this.http.post(this.apiKey + '/zap/admin/users/activate',{user_id:this.selectedArray,is_active:false}).subscribe({
  next:(res:any) => {
    this.selectedArray = [];
    this.messageService.add({ severity: 'info', summary:this.data.full_name , detail:res.message });
    location.reload()
  },error:(res:any) => {
    this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message });
    this.selectedArray = [];
    console.log(res)
  }
})
      }

      else {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'No selected id' });
      }
    }

    deactivate(){
      if(this.selectedArray.length !==0 ) {
      this.http.post(this.apiKey + '/zap/admin/users/activate',{user_id:this.selectedArray,is_active:true}).subscribe({
        next:(res:any) => {
          this.selectedArray = [];
          this.messageService.add({ severity: 'info', summary:this.data.full_name , detail:res.message });
          location.reload()
        },error:(res:any) => {
          this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message });
          this.selectedArray = [];
        }
        
      })
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'No selected id' });
    }
          }
          activateAll(){
            if(this.selectedAllArray.length !==0 ) {
            this.http.post(this.apiKey + '/zap/admin/users/activate',{user_id:this.selectedAllArray,is_active:false}).subscribe({
              next:(res:any) => {
                this.selectedArray = [];
                this.messageService.add({ severity: 'info', summary:this.data.full_name , detail:res.message });
              },error:(res:any) => {
                this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message });
                this.selectedArray = [];
                console.log(res)
              }
            })
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'No selected id' });
          }
          }
          deactivateAll(){
            if(this.selectedAllArray.length !==0 ) {
            this.http.post(this.apiKey + '/zap/admin/users/activate',{user_id:this.selectedAllArray,is_active:true}).subscribe({
              next:(res:any) => {
                this.selectedArray = [];
                this.messageService.add({ severity: 'info', summary:this.data.full_name , detail:res.message });
                location.reload()
              },error:(res:any) => {
                this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message });
                this.selectedArray = [];
              }
            })
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'No selected id' });
          }
          }
           handleError(error:any) { let errorMessage = 'No internet (transactions)';

  if(error.error.message){
    errorMessage = error.error.message
  }

    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });

  
  }
    getRequest(){
      return this.http.get(this.apiKey + '/zap/admin/users/all?page=' + this.page + '&' + 'limit' + '=' + this.rows + this.status + this.start_date + this.end_date + '&search_query=' + this.searchText)
     }
     search(){
      this.isLoading = true;
      this.getRequest().subscribe({
        next:(res:any) => {
          this.data = res.data.users;
        this.totalRecord = res.data.total;
        this.isLoading = false;
        },error:(res) => {
          this.isLoading = false;
          this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'An Error Ocurred' })
        }
      })
    }
 
clear(){

}
    ngOnInit(): void {
      this.isLoading = true
this.filter()
    }
    ngOnDestroy(): void {
   
      console.log('ngOnDestroy called');
      // Unsubscribe from the filter subscription to avoid memory leaks
      if (this.filterSubscription) {
        this.filterSubscription.unsubscribe();
      }
    } 
}
