<div *ngIf="!isLoading">
    
<br>
<button class="queue" (click)="processQueue()">Process Queue</button>

<br>
<br>
<div class="header-three">

  
    <table>
        <tr  class="boredred">
            <th>
                <div style="display: flex; align-items: center;">
                    <img *ngIf = 'data.profile_image == ""' src="../../../../assets/Frame 40462.png" width="50" alt="">
                    <img *ngIf = 'data.profile_image !== ""' [src]="data.profile_image" width="50" alt="">
                    <div style="padding-left: 7px;">
                        <p-inputSwitch [(ngModel)]="checked" (click) = 'isActive()'></p-inputSwitch>
                        <p (click)="showDialogThree('Update User Profile')" style="margin: 0px; font-size: 12px;color: #151A20;font-weight: 600;cursor: pointer;">{{data.full_name}}</p>
                        <span style="font-weight: 400;margin: 0px;font-size: 12px;">Tier {{data.account_level}}</span>
                    </div>
                </div>
            </th>
            <th *ngIf="data.kyc_status == 'approved'">
                <div class="btn-group-approved">
                    <img src="../../../../../assets/approved.png" width="20" alt="">
<p>KYC {{data.kyc_status | titlecase}}</p>
                </div>
            </th>
            <th *ngIf="data.kyc_status == 'pending'">
                <div class="btn-group-pending">
<p>KYC {{data.kyc_status | titlecase}}</p>
                </div>
            </th>
            <th>
                <div class="btn-group-message" (click)="showDialog('Message Customer')">
                    <img src="../../../../../assets/message.png" width="20" alt="">
<p>Message</p>
                </div>
            </th>
            <th>
                <div class="btn-group-upgrade" (click)="showDialogTwo('Upgrade User')">
                    <img src="../../../../../assets/upgrade.png" width="20" alt="">    
<p>Upgrade Customer</p>
                </div>
            </th>
            <th *ngIf="data.is_suspended == false">
                <div class="btn-group-suspend"(click)="confirm($event)"  >
                    <img src="../../../../../assets/suspend.png" width="20" alt="">     
<p>Suspend Customer</p>
                </div>
            </th>
            <th *ngIf="data.is_suspended == true">
                <div class="btn-group-suspend"(click)="confirm($event)"  >
                    <img src="../../../../../assets/suspend.png" width="20" alt="">     
<p>Unsuspend Customer</p>
                </div>
            </th>
        </tr>
        <tr>
            <th>User ID</th>
            <th>Email Address</th>
            <th>Phone Number</th>
            <th>Joined</th> 
             
    </tr>
    <tr >
        <td style="cursor: pointer;" (click)="openSnackBar(data.user_id)">{{data.user_id | slice:0:10 }} {{ data.user_id.length > 10 ? '...' : '' }}</td>
        <td>{{data.email}}</td>
        <td>{{data.phone_number}}</td>
        <td>{{data.created_at | date}}</td>
        <td></td>
    </tr>
    <br>
    <tr>
        <th>BVN</th>
        <th>Address</th>
        <th>Last Login</th>
        <th>Date of Birth</th> 
        <th></th>
         
</tr>
<tr class="boredred">
    <td>{{data.bvn}}</td>
    <td>{{data.address}}</td>
    <td>{{data.last_login | date:'shortTime'}}</td>
    <td>{{data.date_of_birth}}</td>
    <td></td>
</tr>
<br>
<tr>
    <th>Account number</th>
    <th>Bank Name</th>
    <th>Account Name</th>
    <th>Delete Account </th> 
    <th></th>
     
</tr>
<tr class="boredred">
<td>{{data.account_number}}</td>
<td>{{data.bank_name | titlecase}}</td>
<td>{{data.account_name | titlecase}}</td>
<td style="padding-left: 13px;"><p-inputSwitch [(ngModel)]="checkedTwo" (click) = 'confirmDelete($event)'></p-inputSwitch></td>
<td></td>
</tr>
<tr>
  
    <th>NGN Wallet Balance</th>
    <th>USD Wallet Balance</th>
    <th>Cashback Balance</th>
    <th></th>
    <th></th>
     
</tr>
<tr>
    <td>₦{{data.ngn_wallet_balance | number}}</td>
    <td>${{data.usd_wallet_balance | number}}</td>
    <td>{{data.cashback_balance | number}}</td>
    <td></td>
    <td></td>
    </tr>
    </table>
</div>
<div style="display: flex;" (click)="toCardProfile()">
    <div class="card-group" *ngFor="let card of virtualCard">
        <img style="cursor: pointer;" src="../../../../../assets/mVisa (1).png" *ngIf="card.card_type == 'visa' && card.is_active == true" alt="">
        <img style="cursor: pointer;" src="../../../../../assets/mVisa inactive (2).png" *ngIf="card.card_type == 'visa' && card.is_active == false" alt="">
        <img style="cursor: pointer;" src="../../../../../assets/mVerve (1).png (1).png" *ngIf="card.card_type == 'verve' && card.is_active == true" alt="">
        <img style="cursor: pointer;" src="../../../../../assets/mVerve inactive (2).png" *ngIf="card.card_type == 'verve' && card.is_active == false" alt="">
        <img style="cursor: pointer;" src="../../../../../assets/mCard (2).png" *ngIf="card.card_type == 'mastercard' && card.is_active == true" alt="">
        <img style="cursor: pointer;" src="../../../../../assets/mMastercard inactive.png" *ngIf="card.card_type == 'mastercard' && card.is_active == false" alt="">
      
        <div class="card">
           <div>
               <h4>Card Number</h4>
               <p>{{card.card_number}}</p>
           </div>
           <div>
               <h4>Holder name</h4>
               <p>{{card.card_name}}</p>
           </div>
        </div>
       <div style="padding-left: 10px;">
           <h4>Exp Date</h4>
           <p>{{card.expiry}}</p>
       </div>
       <br>
       </div>
</div>
</div>

<p-dialog [header]="modalName" [maximizable]="true" [(visible)]="visible" [modal]="true" [style]="{ width: '35vw', 'font-family':'poppins'}" [draggable]="false" [resizable]="false">
    <app-cp-message *ngIf="visible" style="font-family: poppins;"></app-cp-message>
  </p-dialog>
  <p-dialog [header]="modalName" [(visible)]="visibleTwo" [modal]="true" [style]="{ width: '35vw', 'font-family':'poppins'}" [draggable]="false" [resizable]="false">
   <app-upgrade  *ngIf="visibleTwo"  style="font-family: poppins;" [tier]="accountLevel"></app-upgrade>
  </p-dialog>

  <p-dialog [header]="modalName" [(visible)]="visibleThree" [modal]="true" [style]="{ width: '35vw', 'font-family':'poppins'}" [draggable]="false" [resizable]="false">
   <div class="user-profile" *ngIf="!isLoadingTwo">
    <input type="text" [(ngModel)] = 'firstName' placeholder="firstName">
    <br>
    <input type="text" [(ngModel)] = 'lastName' placeholder="lastName">
    <br>
    <button (click)="saveProfile()" [disabled]="firstName + ' ' + lastName == data.full_name">Save Profile</button>
   </div>
   <div style="display: flex;  justify-content: center;padding: 40px;position:relative; left: 10px;" *ngIf = 'isLoadingTwo'>
    <app-spinner ></app-spinner>
    </div>
   </p-dialog>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>

    <div class="card flex justify-content-center gap-2">
        <p-toast></p-toast>
        <p-confirmPopup [style]="{'font-family':'poppins','font-size':'12px'}"></p-confirmPopup>
    </div>
