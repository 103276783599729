<div *ngIf="!isLoading">
    <div *ngIf="data !== ''">
    

        <div class="header-two">
            <div class="container-one">
                <div style="display: flex;align-items: center;">
                    <img src="../../../assets/Stockholm-icons (20).png" width="20" alt="">
                    <h4 style="font-size: 15px;">{{data.title | titlecase}}</h4>
        
                </div>
                <div style="display: flex;align-items: center;">
                    <p *ngIf="data.status == 'pending'"><span class="pending">{{data.status}}</span></p>
                    <p *ngIf="data.status !== 'pending'"><span class="completed">{{data.status}}</span></p>
                    <img src="../../../assets/Stockholm-icons (23).png" width="20" alt="" (click)="delete()">
                </div>
            </div>
            <div class="container-one">
                <div style="display: flex;align-items: center;padding: 10px;">
         <p-avatar label="{{data.email | slice:0:1}}" styleClass="mr-2" size="large" shape="circle"  [style]="{ 'zoom': '60%' , 'font-family':'poppins', 'background-color':'#B5DDC3', 'color':'#11923D','padding':'5px'}"></p-avatar>
                    <div>
                        <h4 style="margin: 2px;font-size: 13px;">{{data.name | titlecase}} <span  style="margin:0px;font-size: 12px;color: #909090;font-weight: 400;">{{data.email}}</span></h4>
                    <h4 style="margin: 2px;font-size: 13px;">To <span style="font-size: 12px;color: #909090;font-weight: 400;margin: 0px;">support@zappy.com
                    </span></h4>
                    </div>
                </div>
                <div>
                <p></p>
             <p style="padding: 10px;font-size: 12px;color: #909090;">{{data.created_at | date}} {{data.created_at | date:'shortTime'}}</p>
                </div>
            </div>
            <div class="container-two">
                <p-editor [(ngModel)]="message" [style]="{ height: '390px','border':'none','width':'100vh' }"></p-editor>
        </div>
        <br>
        <div class="container-three">
            <button class="filter" (click)="clear()">Clear</button>
            <button class="apply" [disabled]="message == ''" (click)="send()">Reply</button>
        </div>
        </div>
        </div>
        <div style="display: flex;  justify-content: center;padding: 40px;position:relative; left: 200px;top: 120px;" *ngIf="data == ''">
                  
            <div>
             <img src="../../../assets/Empty state illustratio (1).png" style="margin-left: 50px;" width="80" alt="">
             <h5 style="color: #546881;margin-top: 2px;text-align: center;margin-bottom: 1px;">Select a query item to solve</h5>
             <p style="color: #546881;margin-top: 0px;text-align: center;font-size: 12px;">Nothing is selected</p>
            </div>
           </div>
           <br>
</div>
<div style="display: flex;  justify-content:right;padding: 40px;position:relative;top: 150px;left: 300px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>