import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-landing-faq-dialog-two',
  templateUrl: './landing-faq-dialog-two.component.html',
  styleUrls: ['./landing-faq-dialog-two.component.css']
})
export class LandingFaqDialogTwoComponent {
  isLoading = false;
  position:string = '';
    title:string = '';
    content:string = '';
    private apiKey:string = '';
  constructor(private http:HttpClient,private messageService:MessageService){
    this.apiKey = environment.apiUrl;
  }
    save(){
      this.isLoading = true;
  this.http.post(this.apiKey + '/zap/admin/landing/faq',{title:this.title,content:this.content}).subscribe({
    next:(res:any) => {this
      this.messageService.add({severity:'success',summary:'Successful', detail:res.message})
      this.isLoading = false;
  location.reload()
    }, error:(res:any) => {
      this.messageService.add({severity:'error',summary:'Rejected', detail:res.message})
      this.isLoading = false;
    }
  })
    }
    
}
