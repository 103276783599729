
  <div *ngIf="!isLoading">
  <br>
  
  <div *ngIf="data.length !== 0">
    <div class="header-four" *ngIf="!state">
      <div class="recent-transaction">
      <table>
       <thead>
          <tr>
              <th>Business Name</th>
              <th>Full Name</th>
              <th>NGN Wallet Balance</th>
              <th>USD Wallet Balance</th>
              <th>Date</th>
              <th>Time</th>
              <th>Subscription</th>
              <th>Allowed Number</th>
              <th>Status</th>
              <th></th>
          </tr>
       </thead>
      
         <tbody>
          <tr *ngFor="let transactions of data; let i = index">
            <td (click)="viewDetail(i)">{{transactions.business_name | titlecase}}</td>
            <td (click)="viewDetail(i)">{{transactions.full_name | titlecase }}</td>
            <td (click)="viewDetail(i)">₦{{transactions.ngn_wallet_balance | number }}</td>
            <td (click)="viewDetail(i)">${{transactions.usd_wallet_balance | number }}</td>
            <td (click)="viewDetail(i)">{{transactions.created_at | date}}</td>
            <td (click)="viewDetail(i)">{{transactions.created_at | date:'shortTime'}}</td>
            <td><p-inputSwitch *ngIf="is_active[i] !== ''"  [(ngModel)]="is_active[i]" (click)="isActive(i)"></p-inputSwitch></td>
           
            <td (click)="viewDetail(i)">{{ transactions.sendzap_subscription?.allowed_number || '' }}</td>

             <td (click) = 'viewDetail(i)' *ngIf="transactions.status.toLowerCase() == 'approved'"><p><span class="success">Approved</span></p></td>
              <td (click) = 'viewDetail(i)' *ngIf="transactions.status.toLowerCase() == 'pending' "><p><span class="failed">Pending</span></p></td>
              <td  mat-icon-button [matMenuTriggerFor]="menu"><img src="../../assets/Title (1).png" width="50" alt="">
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                      <span style="font-family: poppins; font-size: 13px;" (click)="delete(i)">Delete</span>
                    </button>
                  </mat-menu>
            </td>
          </tr>
      
      
         </tbody>
      </table>
      <br>
      </div>
      <p-dialog *ngIf="visible" [header]="modalName"[maximizable]="true"  [(visible)]="visible" [modal]="true" [style]="{ width: '30vw', 'height':'700px'}" [draggable]="false" [resizable]="false">
        <app-ab-dialog *ngIf="visible" [data]="singleData" style="font-family: poppins;"></app-ab-dialog>
      </p-dialog>
      </div>
      </div>
   <div *ngIf="state">
    <p-inputSwitch [(ngModel)]="stateView"></p-inputSwitch>
    <h4 style=" color: #546881;">Subscription</h4>
    <div style="display: flex; gap: 20px;zoom:80%" *ngIf="state">
        <p-calendar class="max-w-full" [(ngModel)]="end_date" [inline]="true" [showWeek]="true"></p-calendar>
        <p-calendar class="max-w-full" [(ngModel)]="start_date" [inline]="true" [showWeek]="true"></p-calendar>
    </div>
    <br>
    <button class="btn" (click)="isActiveRequest()">Save Subscription</button>
    <button class="btn" (click)="close()">Close</button>
   </div>
  </div>
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
  