import { Component } from '@angular/core';
import { cfTransactionService } from '../../card-funding/cf-transaction/cf-transaction.service';
import { WithdrawTransactionService } from '../withdraw-transaction/withdraw-transaction.service';

@Component({
  selector: 'app-withdraw-filter',
  templateUrl: './withdraw-filter.component.html',
  styleUrls: ['./withdraw-filter.component.css']
})
export class WithdrawFilterComponent {
  toggle = false
  date:any = '';
  dateTwo:any = '';
  amountOne:number = 0;
  amountTwo:number = 0;
  status = ''
 private filterSubscription:any;
constructor(private wTransactionService:WithdrawTransactionService){}
send(){
this.wTransactionService.filter.next({start_date:this.date,end_date:this.dateTwo,start_amount:this.amountOne,end_amount:this.amountTwo,status:this.status});
}
clear(){
this.wTransactionService.filter.next({start_date:'',end_date:'',start_amount:0,end_amount:0,status:'',order_type:''})
}
  ngOnInit(): void {
 this.filterSubscription =  this.wTransactionService.filter.subscribe(res => {
      this.date = res.start_date;
      this.dateTwo = res.end_date;
   if(res.start_amount !== undefined && res.end_amount !== undefined ){
    this.amountOne = res.start_amount;
    this.amountTwo = res.end_amount;
   }
   else {
    this.amountOne = 0;
    this.amountTwo = 0;
   }
    })
  }
  ngOnDestroy(): void {
   
    console.log('ngOnDestroy called');
    // Unsubscribe from the filter subscription to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}
