import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.css']
})
export class StepThreeComponent implements OnInit{
  minimumBalance:number = 0;
  maximumBalance:number = 0;
  monthlyTransactionAmount:number = 0;
  initialFunding:number = 0;
  maximumFunding:number = 0;
  minimumFunding:number = 0;
  secure:boolean = false;
  can_create:boolean = false;
  can_fund:boolean = false;
  can_withdrawal:boolean = false;
constructor(private route:Router){}
  save(){
let stepThree = {minimumBalance:this.minimumBalance,maximumBalance:this.maximumBalance,monthlyTransactionAmount:this.monthlyTransactionAmount
,initialFunding:this.initialFunding,maximumFunding:this.maximumFunding,minimumFunding:this.minimumFunding,secure:this.secure,
can_create:this.can_create,can_fund:this.can_fund,can_withdrawal:this.can_withdrawal
}

localStorage.setItem('stepThree', JSON.stringify(stepThree))
this.route.navigate(["/configurations/virtual-card/preview"])
  }

  ngOnInit(): void {
    let dataInString:any = localStorage.getItem('stepThree');
    let stepThreeData = JSON.parse(dataInString);
if(stepThreeData !== null){
  this.minimumBalance = stepThreeData.minimumBalance;
  this.maximumBalance = stepThreeData.maximumBalance;
  this.monthlyTransactionAmount = stepThreeData.monthlyTransactionAmount;
  this.initialFunding = stepThreeData.initialFunding;
  this.maximumFunding = stepThreeData.maximumFunding;
  this.minimumFunding = stepThreeData.minimumFunding;
  this.secure = stepThreeData.secure;
  this.can_create = stepThreeData.can_create;
  this.can_fund = stepThreeData.can_fund;
  this.can_withdrawal = stepThreeData.can_withdrawal;
}
  }
}
