import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-vc-overview',
  templateUrl: './vc-overview.component.html',
  styleUrls: ['./vc-overview.component.css']
})
export class VcOverviewComponent implements OnInit {
  isLoading = false
  overview:any = {total_cards:0,total_balance:0}
  modalName = '';
  visible: boolean = false;
  role:string = '';
  private apiKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService,private authService:AuthPageService){
    this.apiKey = environment.apiUrl;
  }
  showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
  reload(){
    location.reload()
  }
  ngOnInit(): void {
    this.authService.roleName.subscribe((res:any) => {
      this.role = res.data.role.role_name
    })
    this.isLoading = true;
    let dataInString:any = localStorage.getItem('userId');
    let userId = JSON.parse(dataInString)
    if(this.role == 'Administrator'){
      this.http.get(this.apiKey + '/zap/admin/funding/cards?page=1&limit=1').pipe(catchError(this.handleError)).subscribe({
      next:(res:any) => {
        this.isLoading = false
     this.overview = res.stats;
     console.log(res)
      },
      error:(res:any) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'error', detail:res,life:2000 });
      }
    })
    }
  }
  handleError(error:any) { let errorMessage = 'No internet (overview)';
  
  if(error.error.message){
    errorMessage = error.error.message
  }
  
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  
  }

}
