<div class="header-two">
    <h4>Banking Transfer</h4>

   <nav>
    <button class="btn-message" (click)="updateFee()">Save</button>
  </nav>
</div>
<div *ngIf="!isLoading">
  <div class="header-one">
    <div class="card">
        <div>
           <img src="../../../assets/Icons (4).png" width="30" alt="">
           <h4 style="color: #1D242D;margin-bottom: 2px;">Banking</h4>
           <p style="color: #546881;margin-top: 0px;font-size: 12px;">Manage Banking configuration, price, purchase fee.</p>
        </div>
               <div style="display: flex;align-items: flex-start;gap: 10px;">
<p-inputSwitch [(ngModel)]="checked" (click) = 'activateChecked()'></p-inputSwitch>
               </div>
           </div>
</div>
<div class="container">
    <p style="font-size: 14px; color: #546881;font-family: poppins;">Manage your package transaction fee.</p>
<p style="font-size: 14px;color: #111014;font-family: poppins;">Package</p>
<input type="text" class="package" placeholder="Bank" disabled>
<p style="font-size: 14px;color: #111014;font-family: poppins;">Transaction Fee</p>
<div class="inputWithIcon">
    <input type="number" placeholder="0" [(ngModel)]="fees">
    <img src="../../assets/Frame 40550.png" width="60" alt="">
  </div> 
</div>
<p-toast></p-toast>
</div>
<div style="display: flex;justify-content: center;padding: 40px;position:relative; right: 80px;top: 100px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>