import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.css']
})
export class SystemSettingsComponent implements OnInit{
maintenanceMode:boolean = false;
phoneNumber:string = ''
businessName:string = '';
promoUrl:string = '';
address:string = '';
message:string = '';
version:string = '';
promo_image:string = '';
welcomeMessage:string = '';
data:any = {phoneNumber:'',businessName:'',promoUrl:'',address:'',message:'',version:'',promo_image:'',welcomeMessage:''}
isLoading = false;
private apiKey:string = '';
constructor(private http:HttpClient,private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
save(){
  this.isLoading = true;
  this.http.post(this.apiKey + '/zap/admin/setting/system',{
    phone_number: this.phoneNumber,
    business_name:this.businessName,
    business_address: this.address,
    promo_banner:this.promo_image,
    promo_url:this.promoUrl,
    maintenance_message:this.message,
    maintenance_mode: this.maintenanceMode,
    app_version: this.version,
    welcome_message:this.welcomeMessage
  }).subscribe({
    next:(res:any) => {
this.fetchRequest()
    }
  })
}
fetchRequest(){
  this.http.get(this.apiKey + '/zap/admin/setting/system').subscribe({
    next:(res:any) => {
this.data  = res.data;
console.log(this.data)
this.maintenanceMode = res.data.maintenance_mode;
this.phoneNumber = res.data.phone_number;
this.businessName = res.data.business_name;
this.address = res.data.business_address;
this.message = res.data.maintenance_message
this.promo_image = res.data.promo_banner
this.promoUrl = res.data.promo_url
this.version = res.data.app_version
this.welcomeMessage =  res.data.welcome_message
this.isLoading = false;
    },error:(res:any) => {
      this.isLoading = false;
      this.messageService.add({severity:'error',summary:'Rejected', detail:'An error ocurred'})
    }
  })
}
ngOnInit(): void {
  this.isLoading  = true;
this.fetchRequest();
}
}
