import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { cfTransactionService } from './cf-transaction.service';
import { catchError, exhaustMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-cf-transaction',
  templateUrl: './cf-transaction.component.html',
  styleUrls: ['./cf-transaction.component.css']
})
export class CfTransactionComponent implements OnInit,OnDestroy{
  toggleActive:boolean = false
  isLoading:boolean = false;
  searchText:string = '';
  headerState:string = 'queued';
  selectedArray:any = [];
  selectedAllArray:any = []
  page:number = 1;
  data:any = []
  dataObject:any = {}
  selected = false;
  checked = false;
  visible:boolean = false;
  visibleTwo:boolean = false;
  modalName = ''
start_amount = '';
end_amount = '';
start_date = '';
end_date = '';
clearCheckBox = false;
private filterSubscription:any;
private apiKey:string = '';
private adminKey:string = '';
  constructor(private http:HttpClient, private messageService:MessageService,private cfTransactionService:cfTransactionService){
    this.apiKey = environment.apiUrl;
    this.adminKey = environment.admin_key
  }
  openSnackBar(id:any) {
    this.showInfo(id)
    navigator.clipboard.writeText(id);
   }
   onSelected(){
    this.selected  = true;
   }
   allChecked(){
    this.checked = !this.checked
    this.selectedArray = [];
    for (let index = 0; index < this.data.length; index++) {
      if(!this.selectedAllArray.includes(this.data[index].id)){
        this.selectedAllArray.push(this.data[index].id)
      }
    
        
      }
      console.log(this.selectedAllArray)
   }
   showDialog(name:string) {
    this.modalName = name;
      this.visible = true;
  }
  showDialogTwo(name:string) {
    this.modalName = name;
      this.visibleTwo = true;
  }
   toggle(){
    this.isLoading = true;
    this.toggleActive = !this.toggleActive;
      if(this.toggleActive == true){
this.headerState = 'processed';
this.getRequest().subscribe({
  next:(res:any) => {
    this.data = res.data;
    this.isLoading = false;
  }, 
  error:(res:any) => {
    this.isLoading = false;
  }
})
    }
   if(this.toggleActive == false) {
      this.headerState = 'queued';
      this.getRequest().subscribe({
        next:(res:any) => {
          this.data = res.data;
          this.isLoading = false;
        },
        error:(res:any) => {
          this.isLoading = false;
        }
      })
    }
  }
  showInfo(id:any) {
    this.messageService.add({ severity: 'info', summary: 'Copied', detail: id });
  }
  viewDetail(index:number){
    this.showDialogTwo("Card Funding")
    this.dataObject = this.data[index];
  }
  first: number = 1;
  rows: number = 10;
  totalRecord = 20;
  
  onPageChange(event:any) {
  this.first = event.first;
  this.rows = event.rows;
  this.page = event.page + 1;
  this.isLoading = true;
  this.getRequest().subscribe((res:any) => {
    this.data = res.data;
    this.isLoading = false;
   })
  }


      IndividalSelect(i:number,val:any){
        if (!this.selectedArray.includes(this.data[i].id)) {
          this.selectedArray.push(this.data[i].id);
          
          } 
          
          console.log(this.data[i].id)
          console.log(this.selectedArray)

      }
      search(){
        this.isLoading  = true;
        this.getRequest().subscribe((res:any) => {
          this.data = res.data;
        this.isLoading = false;
         })
      }
      approve(){
        this.isLoading = true;
        if(this.selectedArray.length !== 0){
        this.http.post(this.apiKey + '/zap/admin/funding/change-status',{id:this.selectedArray,admin_key:this.adminKey,status:'approve'}).subscribe({
          next:(res:any) => {
            console.log(res)
            this.selectedArray = [];
            this.checked = false;
            this.selected = false;
            this.filter()
            this.messageService.add({ severity: 'success', summary: 'Approved', detail:res.message});
          },error:(res:any) => {
            this.isLoading = false;
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message});
           
          }
        })
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail:'No id selected'});
      }
    }

    approveAll(){
      this.isLoading = true;
      if(this.selectedAllArray.length !== 0){
      this.http.post(this.apiKey + '/zap/admin/funding/change-status',{id:this.selectedAllArray,admin_key:this.adminKey,status:'approve'}).subscribe({
        next:(res:any) => {
          console.log(res)
          this.selectedAllArray = [];
          this.checked = false;
          this.selected = false;
          this.filter()
          this.messageService.add({ severity: 'success', summary: 'Approved', detail:res.message});
        },error:(res:any) => {
          this.messageService.add({ severity: 'error', summary: 'Rejected', detail:res.message});
         this.isLoading = false;
        }
      })
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Rejected', detail:'No id selected'});
    }
  }
  decline(){
    if(this.selectedArray.length !== 0){
this.showDialog('Decline')
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Rejected', detail:'No id selected'});
    }
  }

  declineAll(){
    if(this.selectedAllArray.length !== 0){
this.showDialog('Decline All')
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Rejected', detail:'No id selected'});
    }
  }

  filter(){
  this.filterSubscription = this.cfTransactionService.filter.pipe(tap((res:any) => {
      this.start_date = res.start_date == '' ? '' : '&start_date' + res.start_date.toISOString();;
      this.end_date = res.end_date == '' ? '' : '&end_date' + res.end_date.toISOString();;
      this.start_amount = '&start_amount=' + res.start_amount;
      this.end_amount = '&end_amount=' + res.end_amount;
      console.log(res.start_amount + 'amount')
      this.isLoading = true;
     }),exhaustMap((res:any) => {return this.getRequest()})).pipe(catchError(this.handleError)).subscribe({
      next:(res:any) => {
        this.isLoading = false;
        this.data = res.data;
        this.totalRecord = res.total;
      },
      error:(res) => {
        this.isLoading = false;
        setTimeout(() => {
          this.messageService.add({ severity: 'error', detail:res,life:2000 });
         }, 2000);
      }
    })
  }

  handleError(error:any) { let errorMessage = 'No internet (transactions)';
  
  if(error.error.message){
    errorMessage = error.error.message
  }
  
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });

  
  }
  getRequest(){
    // let dataInString:any = localStorage.getItem('userId');
    // let userId = JSON.parse(dataInString)
    return  this.http.get(this.apiKey + '/zap/admin/funding/?page=' + this.page + '&' + 'limit' + '=' + this.rows + '&search_query=' + this.searchText + '&sort_by' + '=' + this.headerState + this.start_amount + this.end_amount + this.start_date + this.end_date )
   }
   clear(){
  this.selectedArray = [];
  this.selectedAllArray = [];
  this.clearCheckBox  = true;
   } 

  ngOnInit(): void {
    this.isLoading = true;
    this.filter()
  }

  ngOnDestroy(): void {
   
    console.log('ngOnDestroy called');
    // Unsubscribe from the filter subscription to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  } 
    }
    

