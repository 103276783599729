import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';
import { cardTransactionsService } from 'src/app/transactions/card-transactions/card-transactions-transactions/card-transactions.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-vct-overview',
  templateUrl: './vct-overview.component.html',
  styleUrls: ['./vct-overview.component.css']
})
export class VctOverviewComponent {
  isLoading:boolean = false;
  overview:any = {total_credits_sum:0,total_debits_sum:0,total_transaction_count:0,total_transaction_sum:0}
  modalName = '';
visible: boolean = false;
role:string = '';
private apiKey:string = '';
constructor(private http:HttpClient,private messageService:MessageService,private cardTransactionsService:cardTransactionsService, private authService:AuthPageService){
  this.apiKey = environment.apiUrl;
}
showDialog(name:string) {
  this.modalName = name;
    this.visible = true;
}

reload(){
  location.reload()
}
ngOnInit(): void {
  this.authService.roleName.subscribe(res => {
    this.role = res.data.role.role_name
  })
  this.cardTransactionsService.filter.subscribe(res => {this.visible = false;})
 this.isLoading = true;
 let dataInString:any = localStorage.getItem('userId');
 let data = JSON.parse(dataInString);
  this.http.get(this.apiKey + '/zap/admin/funding/card-transactions/' + data + '/?page=10&limit=1').pipe(catchError(this.handleError)).subscribe({
    next:(res:any) => {
      this.isLoading = false
      this.overview = res.data.stats
   
    },
    error:(res:any) => {
      this.isLoading = false;
      this.messageService.add({ severity: 'error', detail:res,life:2000 });
    }
  })

}
handleError(error:any) { let errorMessage = 'No Internet (overview)';

if(error.error.message){
  errorMessage = error.error.message
}

  console.log(errorMessage);
  return throwError(() => {
      return errorMessage;
  });
}

}
