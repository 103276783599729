import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-analytics-total-transactions',
  templateUrl: './analytics-total-transactions.component.html',
  styleUrls: ['./analytics-total-transactions.component.css']
})
export class AnalyticsTotalTransactionsComponent implements OnInit {
  data: any;
  options: any;
  dataTwo: { dates: string[], data_count: number[], amounts: number[] } = { dates: [], data_count: [], amounts: [] }
  isLoading = false;
  private apiKey:string = '';
  constructor(private http: HttpClient) {
    this.apiKey = environment.apiUrl;
   }
  fetchRequest() {
    this.http.get(this.apiKey + '/zap/admin/analytics/reusable?filter_by=all').subscribe((res: any) => {
      this.isLoading = false;
      this.dataTwo = res.data;

      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

      this.data = {
        labels: this.dataTwo.dates.map((res: any) => {
          const timestampStr = res;
          const timestampObj = new Date(timestampStr);

          // Format time
          const timeOptions: any = { year: 'numeric', month: 'numeric', day: 'numeric' };
          const formattedTime = timestampObj.toLocaleString('en-US', timeOptions);
          return formattedTime;
        }),
        datasets: [
          {
            label: 'Transactions',
            data: this.dataTwo.amounts,
            fill: true,
            borderColor: "#673AB7",
            backgroundColor: 'rgba(208,194,233,0.5)',
            borderWidth: 1,
          },
        ]
      };

      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 0.9,
        plugins: {
          legend: {
            display: false,
            labels: {
              color: textColor,
              font: {
                family: 'arial', // Reference the custom font here
              },
            }
          },
          tooltip: {
            callbacks: {
              label: (context: any) => {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }

                // Format amount with commas
                const formattedAmount = '₦' + context.parsed.y.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                label += formattedAmount;

                return label;
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: 'black',
            },
            grid: {
              color: surfaceBorder,
              drawBorder: false,
            },
          },
          y: {
            ticks: {
              color: 'black',
              callback: (value: any) => {
                // Format y-axis ticks with commas
                return '₦' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
              },
            },
            grid: {
              color: surfaceBorder,
              drawBorder: true,
            },
          },
        }
      };
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.fetchRequest();
  }
}
