import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-landing-faq-dialog',
  templateUrl: './landing-faq-dialog.component.html',
  styleUrls: ['./landing-faq-dialog.component.css']
})
export class LandingFaqDialogComponent {
  isLoading = false;
position:string = '';
  title:string = '';
  content:string = '';
  private apiKey:string = '';
  @Input() data:any = {};
constructor(private http:HttpClient,private messageService:MessageService){
  this.apiKey = environment.apiUrl;
}
  save(){
    this.isLoading = true;
this.http.patch(this.apiKey + '/zap/admin/landing/faq',{id:this.data.id,title:this.title,content:this.content}).subscribe({
  next:(res:any) => {this
    this.messageService.add({severity:'success',summary:'Successful', detail:res.message})
    this.isLoading = false;
location.reload()
    location.reload()
  }, error:(res:any) => {
    this.messageService.add({severity:'error',summary:'Rejected', detail:res.message})
    this.isLoading = false;
  }
})
  }
  
  delete(){
    this.isLoading = true;
    this.http.post(this.apiKey + '/zap/admin/landing/faq/delete',{id:this.data.id}).subscribe({
      next:(res:any) => {
        this.messageService.add({severity:'success',summary:'Successful', detail:res.message})
        location.reload()
      }, error:(res:any) => {
        this.messageService.add({severity:'error',summary:'Rejected', detail:res.message})
        this.isLoading = false;
      }
    })
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.content = this.data.content;

  }
}
