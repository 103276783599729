<div class="container" *ngIf="!isLoading">
  
<h1 style="padding-bottom: 0px;margin: 0px; width:500px;"></h1>
<div class="header-two">
  <h5>Transaction Status</h5>

  <p *ngIf="data.status.toLowerCase() == 'success' || data.status.toLowerCase() == 'successful' || data.status.toLowerCase() == 'completed'"><span class="success">Successful</span></p>
  <p *ngIf="data.status.toLowerCase() == 'reversed'"><span class="failed">Reversed</span></p>
</div>
<table class="table-one">
  <tr>
    <th>Wallet Holder</th>
    <th>Customer Id</th>
    <th>Email</th>
  </tr>
  <tr>
      <td>{{data.name | titlecase}}</td>
    <td><div class="data-group">
      {{data.user_id | slice:0:8}} {{ data.user_id.length > 8 ? '...' : '' }}
      <img (click)="openSnackBar(data.user_id)"  src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
    </div></td>
    <td>
        <div class="data-group">
          
            <p>{{data.email}}</p>
            <img (click)="openSnackBar(data.email)"  src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
        </div>
    </td>
  </tr>
 
  <tr>
    <th>Reference</th>
    <th>Level</th>
    <th>Transaction ID</th>
  </tr>
  <tr>
      <td>{{data.reference}}</td>
    <td>
        <div class="data-group">
            <img  src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">
            <p>Tier {{data.level}}</p>
        </div>
    </td>
    <td>
        <div class="data-group">
            <p style="margin-right: 2px;">{{data.reference | slice:0:8}} {{ data.reference.length > 8 ? '...' : '' }}</p>
            <img (click)="openSnackBar(data.reference)"  src="../../../assets/Stockholm-icons (16).png" style="cursor: pointer;" width="25" alt="">

        </div>
    </td>
  </tr>
  <tr>
    <th>Order Type</th>
    <th>Transaction Type</th>
    <th>Card Type</th>
  </tr>
  <tr>
      <td>
   {{data.order_type | titlecase}}
     
      </td>
    <td>
     {{data.transaction_type | titlecase}}
    </td>
    <td>
      {{data.card_type | titlecase}}
    </td>
  </tr>
  <tr>
    <th>Price</th>
    <th>Time</th>
    <th>Date</th>
  </tr>
  <tr>
      <td>${{data.amount  | number}}</td>
    <td>{{data.created_at | date:'shortTime'}}</td>
    <td>{{data.created_at | date}}</td>
  </tr>

  <tr>
    <th>Balance Before</th>
    <th>Balance After</th>
  </tr>
  <tr>
      <td>${{data.balance_before | number}}</td>
    <td>${{data.balance_after | number}}</td>
  </tr>
</table>


</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>