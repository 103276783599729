import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ProductChartService } from './product-chart.service';
import { MessageService } from 'primeng/api';
import { AuthPageService } from 'src/app/auth-page/auth-page.service';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-product-chart',
  templateUrl: './product-chart.component.html',
  styleUrls: ['./product-chart.component.css']
})
export class ProductChartComponent implements OnInit{
  data: any;
  role:string = '';
  options: any;
  name = 'Bills'
   dataTwo:{dates:string[],amounts:number[]}= {dates:[],amounts:[]}
 isLoading = false;
 private apiKey:string = '';
constructor(private http:HttpClient,private pcService:ProductChartService,private messageService:MessageService,private authService:AuthPageService){
  // api key
  this.apiKey = environment.apiUrl;
}
fetchRequest(){
  this.isLoading = true;
    this.http.get(this.apiKey + '/zap/admin/home/plots/product?product=' + this.name).pipe(catchError(this.handleError)).subscribe({
      next:(res:any) => {
        this.isLoading = false;
this.dataTwo = res.data
   console.log(this.dataTwo)
   const documentStyle = getComputedStyle(document.documentElement);
   const textColor = documentStyle.getPropertyValue('--text-color');
   const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
   const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

   this.data = {
       labels: this.dataTwo.dates.map((res:any) => {
        const timestampStr = res;
        const timestampObj = new Date(timestampStr);
        
        // Format time
        const timeOptions:any = { year: 'numeric', month: 'numeric', day: 'numeric' };
        const formattedTime = timestampObj.toLocaleString('en-US', timeOptions);
        return formattedTime
       }),
       datasets: [
           {
               label: 'Transactions',
               data: this.dataTwo.amounts,
               fill: true,
               borderColor:"#673AB7",
               backgroundColor: 'rgba(208,194,233,0.5)',
               borderWidth: 1,
           },
       ]
   };

   this.options = {
     responsive: true,
       maintainAspectRatio: false,
       aspectRatio: 0.9,
       plugins: {
           legend: {
             display: false,
               labels: {
               
                   color: textColor,
                   font: {
                     family: 'arial', // Reference the custom font here
                   },
               }
           }
       },
       scales: {
           x: {
               ticks: {
               
                   color: 'black'
               },
               grid: {
                   color: surfaceBorder,
                   drawBorder: false
               }
           },
           y: {
               ticks: {
                   color: 'black',
                   callback: (value: any) => {
                    return value + 'k';
                  },
               },
               grid: {
                   color: surfaceBorder,
                   drawBorder: true
               }
           }
       }
   };


  },error:(res:any) => {
    this.isLoading = false;
setTimeout(() => {
 if(this.role == 'Administrator'){ this.messageService.add({ severity: 'error', detail:res,life:2000 });}
}, 4000);
   const documentStyle = getComputedStyle(document.documentElement);
   const textColor = documentStyle.getPropertyValue('--text-color');
   const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
   const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

   this.data = {
       labels: this.dataTwo.dates.map((res:any) => {
        const timestampStr = res;
        const timestampObj = new Date(timestampStr);
        
        // Format time
        const timeOptions:any = { year: 'numeric', month: 'numeric', day: 'numeric' };
        const formattedTime = timestampObj.toLocaleString('en-US', timeOptions);
        return formattedTime
       }),
       datasets: [
           {
               label: 'Transactions',
               data: [],
               fill: true,
               borderColor:"#673AB7",
               backgroundColor: 'rgba(208,194,233,0.5)',
               borderWidth: 1,
           },
       ]
   };

   this.options = {
     responsive: true,
       maintainAspectRatio: false,
       aspectRatio: 0.9,
       plugins: {
           legend: {
             display: false,
               labels: {
               
                   color: textColor,
                   font: {
                     family: 'arial', // Reference the custom font here
                   },
               }
           }
       },
       scales: {
           x: {
               ticks: {
               
                   color: 'black'
               },
               grid: {
                   color: surfaceBorder,
                   drawBorder: false
               }
           },
           y: {
               ticks: {
                   color: 'black',
               },
               grid: {
                   color: surfaceBorder,
                   drawBorder: true
               }
           }
       }
   };

  }

 })
}
handleError(error:any){ let errorMessage = 'No Internet (product chart)';

if(error.error.message && errorMessage == 'You do not have the Right Permission'){
  errorMessage = error.error.message
}
  console.log(errorMessage);
  return throwError(() => {
      return errorMessage;
  });
}
  ngOnInit() {
    this.authService.roleName.subscribe((res:any) => {
      this.role = res.data.role.role_name
    })
    this.isLoading = true;
  if(this.role == 'Administrator'){ this.fetchRequest();}
   this.pcService.fetched.subscribe(res => { this.name = res;
    if(res == 'Bills'){
      this.fetchRequest()
    
    }

    if(res == 'Giftcards'){
this.fetchRequest();
    }

    if(res == 'Zappins'){
      this.fetchRequest();
          }
})
    
  }
 }

