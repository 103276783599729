

<div class="header-one">
    <img src="../../../assets/Stockholm-icons (19).png" style="cursor: pointer;" width="20" routerLink = '../'  alt="">
    <p>Virtual Card</p>
    <img src="../../../../assets/Stockholm-icons (24).png" width="20" alt="">
    <p>Virtual Card Details</p>
</div>

<div class="header-two">
<p [class.active] = 'active("/user-management/virtual-card-management/virtual-card-detail/profile")' routerLink="profile">Profile</p>
    <p [class.active] = 'active("/user-management/virtual-card-management/virtual-card-detail/transaction-history")' routerLink="transaction-history">Transaction History</p>

</div>
<router-outlet></router-outlet>