<div *ngIf="!isLoading">
    <div class="header-one">
        <h4>Referral</h4>
    
       <!-- <nav>
          <div class="filter-group" (click)="showDialog('Filter')">
              <p>Filter</p>
              <img src="../../assets/Stockholm-icons (9).png" alt="">
          </div>
    <img src="../../../assets/Stockholm-icons (15).png" (click)="reload()" style="margin-left: 20px;" alt="">
      </nav> -->
    </div>
    <div class="payment-manager" *ngIf = 'role == "Administrator"'>
        <div class="card">
            <img src="../../assets/Widget icons.png" alt="">
            <p>Total Referral Value</p>
            
            <h4>₦{{overview.total_paid_referrals | number}}</h4>
        </div>
        <div class="card">
            <img src="../../assets/Widget icons (7).png" alt="">
            <p>Total Paid</p>
            <h4>₦{{overview.total_referrals_amount | number}}</h4>
        </div>

        <div class="card">
            <img src="../../../../assets/Icon.png" alt="">
            <p>Total Referral</p>
            <h4>{{overview.total_referrals_count | number}}</h4>
        </div>
    </div>
    
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 80px;" *ngIf = 'isLoading'>
    <app-spinner ></app-spinner>
    </div>
    <p-dialog [header]="modalName" [maximizable]="true" [(visible)]="visible" [modal]="true" [style]="{ width: '35vw',height:'100%' }" [draggable]="false" [resizable]="false">
    <app-referral-filter-modal></app-referral-filter-modal>
      </p-dialog>
      <app-referral-transaction></app-referral-transaction>