<div style="display: flex;justify-content: space-between;align-items: center;">
  <button class="btn-message" (click)="save()">Save</button>
</div>
<div class="container" *ngIf="!isLoading">
  <div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;top:-40px;" *ngIf = 'isLoadingTwo'>
    <app-spinner ></app-spinner>
    </div>
       <br>
       <div class="inputWithIcon">
          <p>Business Name</p>
      <input type="text" placeholder="Name" [(ngModel)] = 'title'>
        </div> 
      <div>
    <div class="inputWithIcon">
        <p>User id</p>
    <input type="text" placeholder="User id" [(ngModel)] ='userId'>
      </div> 
    <div>
      <p>Status</p>
<div style="zoom:80%;padding-bottom: 10px;">
  <div class="select">
    <p-dropdown [options]="citiesOne" [style]="{width:'100%'}"  [(ngModel)]="selectedCityOne"  [showClear]="true" placeholder="Select Status"></p-dropdown>
</div>
</div>
</div>
</div>
</div>
<div style="display: flex;  justify-content: center;padding: 40px;position:relative; right: 10px;top: 3px;" *ngIf = 'isLoading'>
  <app-spinner ></app-spinner>
  </div>
  
