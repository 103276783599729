import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit{
  data:any = [];
page = 1
first: number = 1;
rows: number = 10;
totalRecord = 20;
isLoading = false;
private apiKey:string = '';
constructor(private http:HttpClient){
  // api key
  this.apiKey = environment.apiUrl;
}
onPageChange(event:any) {
  this.isLoading = true;
  this.first = event.first;
  this.page = event.page + 1
  this.rows = event.rows;
  this.isLoading = true;
  this.fetchRequest()
}

fetchRequest(){
  this.http.get(this.apiKey + '/zap/admin/home/notifications?page=' + this.page + '&limit=' + this.rows).subscribe({
    next:(res:any) => {
      this.data = res.data.data;
      this.totalRecord = res.data.total;
      this.isLoading = false;
    }
  })
}
ngOnInit(): void {
  this.isLoading = true;
this.fetchRequest()
}
}
