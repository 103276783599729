import { Component } from '@angular/core';

@Component({
  selector: 'app-sp-modal',
  templateUrl: './sp-modal.component.html',
  styleUrls: ['./sp-modal.component.css']
})
export class SpModalComponent {

}
